import { inventories } from "@/api/inventories/useInventoriesQuery";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { LotLedgeTabs } from "../tabs/lotLedgersTabs";
import { LotDetailInForm } from "./lotDetailInForm";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
});
interface LotDetailFormProps {
  lotName: string | undefined;
  lotId?: number | undefined;
}
export const LotDetailForm = (params: LotDetailFormProps) => {
  const { lotName, lotId } = params;

  const { data } = useQuery(
    inventories.get({
      query: {
        $and: [{ lotName: { $eq: lotName } }],
      },
      populate: ["lot", "location", "item"],
    }),
  );

  return (
    <>
      <Detail.Container>
        <Detail.Wrapper>
          <Detail.Content>
            <LotDetailInForm data={data?.data?.rows && data?.data?.rows[0]} />
            <Tabs defaultValue="lotLedger">
              <LeftSpace />
              <Tabs.List>
                <Tabs.Tab value="lotLedger">로트 입출고 기록</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="lotLedger" p={20}>
                <Title order={3}>로트 입출고 기록</Title>
                <Text>우리회사에서 다루는 로트 입출고 기록에 대한 정보를 보여줍니다.</Text>
                <LotLedgeTabs lotId={lotId} />
              </Tabs.Panel>
            </Tabs>
          </Detail.Content>
        </Detail.Wrapper>
      </Detail.Container>
    </>
  );
};

const LeftSpace = styled.div`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
