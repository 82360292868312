type StatusObjType = {
  [key: string]: string;
};

export const counterStatusColor: StatusObjType = {
  COUNTER_AND_TEM_SAVE: "blue.7",
  COUNTER_AND_AUTO_REPORT: "green.7",
  HANDWRITING: "orange.7",
  COUNTER_AND_HANDWRITING: "violet.7",
};

export const setCounterStatus = (status: string | undefined): string => {
  if (!status) return "";
  return counterStatusColor[status];
};
