import { mutateAbstractItems } from "@/api/abstractItems/useAbstractItemsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { handleKeyDown } from "@/utils/event";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiAbstractItemsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

interface AbstractItemCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export const AbstractItemCreateForm = (params: AbstractItemCreateFormProps) => {
  const { code, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: createMutate } = useMutation(
    (params: DefaultApiAbstractItemsPostRequest) =>
      mutateAbstractItems
        .create(params)
        .mutationFn(params as DefaultApiAbstractItemsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["abstractItems"]);
        closeModal(form.values);
        customAlert(
          t("message.createSuccess", { item: t("abstractItem.abstractItem") }),
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("abstractItem.code") }),
          t("common.createFail", { item: t("abstractItem.abstractItem") }),
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: code ?? undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        abstractItemsGetRequest: {
          code: code ?? form.values.code!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("abstractItem.code") }),
        `${t("common.createFail", { item: `${t("abstractItem.abstractItem")}` })}`,
        "red",
      );
    }
  };

  return (
    <Flex w="100%" direction="column" gap="md">
      <Create.Input
        w="20rem"
        label={t("abstractItem.code")}
        placeholder={t("placeholder.input", { item: `${t("abstractItem.code")}` })}
        withAsterisk
        {...form.getInputProps("code")}
        onKeyDown={(e) =>
          handleKeyDown(e, () =>
            autoCompleteOnSubmit ? autoCompleteOnSubmit(form.values) : onSubmit(),
          )
        }
      />
      <Create.ButtonBox>
        <Create.Button color="gray" onClick={closeModal}>
          {t("common.cancel")}
        </Create.Button>
        <Create.Button
          onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
          disabled={validateFormValues({
            pageType: "abstractItem",
            values: form.values,
          })}
        >
          {t("common.create", { item: `${t("abstractItem.abstractItem")}` })}
        </Create.Button>
      </Create.ButtonBox>
    </Flex>
  );
};
