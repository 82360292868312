import { purchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { mutatePurchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { PurchaseOrderAutoComplete } from "@/features/ui/autoComplete/purchaseOrder/purchaseOrders-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { getUserCodeByUser } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  PurchaseOrdersGet200ResponseRowsInner,
  ScmApiPurchaseOrderItemsPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

interface PurchaseOrderCreateFormProps {
  purchaseOrderId?: number;
  purchaseOrder?: PurchaseOrdersGet200ResponseRowsInner;
}

export const PurchaseOrderItemsCreateForm = (props: PurchaseOrderCreateFormProps) => {
  const { purchaseOrderId, purchaseOrder } = props;
  const { closeModal } = useModal();
  const userCode = getUserCodeByUser();
  const { t } = useTranslation();
  const [id, setId] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    (params: ScmApiPurchaseOrderItemsPostRequest) =>
      mutatePurchaseOrderItems
        .create(params)
        .mutationFn(params as ScmApiPurchaseOrderItemsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["purchaseOrderItems"]);
        closeModal(form.values);
        customAlert(
          `${userCode === "PB" ? t("message.createSuccess", { item: t("orderItems.orderItems") }) : t("message.createSuccess", { item: t("purchaseOrderItems.purchaseOrderItems") })}`,
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({
            error,
            errorContext: `${userCode === "PB" ? t("orderItems.orderItems") : t("purchaseOrderItems.purchaseOrderItems")}`,
          }),
          `${userCode === "PB" ? t("common.createFail", { item: t("orderItems.orderItems") }) : t("common.createFail", { item: t("purchaseOrderItems.purchaseOrderItems") })}`,
          "red",
        );
      },
    },
  );

  const { data: purchaseOrderItem } = useQuery({
    ...purchaseOrders.detail({
      purchaseOrderId: purchaseOrderId ?? Number(id) ?? 0,
      populate: ["location", "user", "partner", "purchaseOrderItems"],
    }),
    enabled: !!purchaseOrderId || !!id,
  });

  const getInitialValues = (
    purchaseOrder: PurchaseOrdersGet200ResponseRowsInner | undefined,
    purchaseOrderItem: AxiosResponse<PurchaseOrdersGet200ResponseRowsInner, any> | undefined,
  ) => {
    return {
      purchaseOrderId: purchaseOrderId
        ? purchaseOrderId
        : purchaseOrder
          ? purchaseOrder.id
          : purchaseOrderItem
            ? purchaseOrderItem?.data?.id
            : undefined,
      partnerCode: purchaseOrderItem?.data?.partnerCode ?? purchaseOrder?.partnerCode ?? undefined,
      itemCode: undefined,
      quantity: undefined,
      orderDate: purchaseOrder?.orderDate
        ? new Date(purchaseOrder?.orderDate)
        : purchaseOrderItem
          ? new Date(purchaseOrderItem.data?.orderDate ?? "")
          : undefined,
      deliveryDeadline: purchaseOrder?.deliveryDeadline
        ? new Date(purchaseOrder?.deliveryDeadline)
        : purchaseOrderItem
          ? new Date(purchaseOrderItem?.data?.deliveryDeadline ?? "")
          : undefined,
      userCode: purchaseOrderItem?.data?.userCode ?? purchaseOrder?.userCode ?? undefined,
      sourceLocationCode:
        purchaseOrderItem?.data?.sourceLocationCode ??
        purchaseOrder?.sourceLocationCode ??
        undefined,
    };
  };

  const form = useForm({
    initialValues: getInitialValues(purchaseOrder, purchaseOrderItem),
  });

  useEffect(() => {
    if (purchaseOrderItem) {
      form.setValues(getInitialValues(purchaseOrder, purchaseOrderItem));
    }
  }, [purchaseOrderItem]);

  const onSubmit = async () => {
    try {
      createMutate({
        purchaseOrderItemsGetRequest: {
          purchaseOrderId: purchaseOrderId
            ? purchaseOrderId
            : purchaseOrder
              ? purchaseOrder.id
              : purchaseOrderItem
                ? purchaseOrderItem?.data?.id
                : form.values.purchaseOrderId!,
          partnerCode: purchaseOrder?.partnerCode ?? form.values.partnerCode!,
          direction: "INBOUND",
          itemCode: form.values.itemCode!,
          quantity: form.values.quantity!,
          orderDate: purchaseOrder?.orderDate?.toString()! ?? form.values.orderDate!,
          deliveryDeadline:
            purchaseOrder?.deliveryDeadline?.toString()! ?? form.values.deliveryDeadline!,
          userCode: purchaseOrder?.userCode ?? form.values.userCode!,
          sourceLocationCode: purchaseOrder?.sourceLocationCode ?? form.values.sourceLocationCode!,
        },
      });
    } catch (e) {
      customAlert(
        handleErrorResponse({
          error: e,
          errorContext: `${userCode === "PB" ? t("orderItems.orderItems") : t("purchaseOrderItems.purchaseOrderItems")}`,
        }),
        `${userCode === "PB" ? t("common.createFail", { item: t("orderItems.orderItems") }) : t("common.createFail", { item: t("purchaseOrderItems.purchaseOrderItems") })}`,
        "red",
      );
    }
  };

  return (
    <>
      <Create.Wrapper>
        {purchaseOrderId || purchaseOrder ? null : (
          <PurchaseOrderAutoComplete
            direction="INBOUND"
            label={`${userCode === "PB" ? t("order.code") : t("purchaseOrder.code")}`}
            placeholder={`${userCode === "PB" ? t("placeholder.select", { item: t("order.code") }) : t("placeholder.select", { item: t("purchaseOrder.code") })}`}
            {...form.getInputProps("purchaseOrderId")}
            clearable
            onChange={(e) => setId(e)}
          />
        )}
        <PartnerAutoComplete
          label={t("partner.partner")}
          placeholder={t("placeholder.select", { item: t("partner.partner") })}
          defaultValue={purchaseOrder?.partnerCode}
          {...form.getInputProps("partnerCode")}
          withAsterisk
        />
        <ItemAutoComplete
          label={t("item.code")}
          placeholder={t("placeholder.select", { item: t("item.code") })}
          {...form.getInputProps("itemCode")}
        />
        <Create.Input
          label={t("inventory.quantity")}
          placeholder={t("placeholder.input", { item: t("inventory.quantity") })}
          {...form.getInputProps("quantity")}
        />
        <UserAutoComplete
          label={t("order.manager")}
          placeholder={t("placeholder.select", { item: t("order.manager") })}
          defaultValue={purchaseOrder?.userCode}
          {...form.getInputProps("userCode")}
        />
        <LocationAutoComplete
          label={t("inventory.fromLocation")}
          placeholder={t("placeholder.select", { item: t("inventory.fromLocation") })}
          defaultValue={purchaseOrder?.sourceLocationCode}
          {...form.getInputProps("sourceLocationCode")}
        />
        <Create.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={t("purchaseOrder.purchaseOrderDate")}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("orderDate")}
        />
        <Create.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={t("order.deliveryDate")}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("deliveryDeadline")}
        />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "purchaseOrderItems",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            {userCode === "PB"
              ? t("common.create", { item: t("orderItems.orderItems") })
              : t("common.create", { item: t("purchaseOrderItems.purchaseOrderItems") })}
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
