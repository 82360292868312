import { mutateStockPlans, stockPlans } from "@/api/stockPlan/useStockPlanQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import customAlert from "@/features/ui/alert/alert";
import { TransportationsAutoComplete } from "@/features/ui/autoComplete/transportation/transportation-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import timeUtil from "@/utils/timeUtil";
import styled from "@emotion/styled";
import { Button, Flex, Table, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  StockPlansGet200ResponseRowsInner,
  WmsApiStockPlansStockPlanIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

interface StockPlanUpdateFormProps {
  formatterProps?: StockPlansGet200ResponseRowsInner;
  stockPlanId: number[];
}

export const StockPlanTransportationForm = (params: StockPlanUpdateFormProps) => {
  const { formatterProps, stockPlanId } = params;
  const { closeModal } = useModal();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: WmsApiStockPlansStockPlanIdPutRequest) =>
      mutateStockPlans
        .update(params)
        .mutationFn(params as WmsApiStockPlansStockPlanIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stockPlans"]);
        customAlert(
          t("transportation.registerMessage"),
          t("transportation.registerSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("transportation.transportation") }),
          t("transportation.registerFail"),
          "red",
        );
      },
    },
  );

  const stockPlanQueries = useQueries({
    queries: stockPlanId.map((id) => {
      const { queryKey, queryFn } = stockPlans.detail({ stockPlanId: id });
      return {
        queryKey,
        queryFn,
        // 필요 시 추가 옵션을 여기에 추가 가능합니다.
        // 예: enabled: !!id
      };
    }),
  });

  // 모든 쿼리가 성공적으로 완료되었는지 여부
  const allSuccess = stockPlanQueries.every((query) => query.isSuccess);

  // 모든 데이터 배열로 변환 (AxiosResponse에서 `data` 추출)
  const stockPlanData: StockPlansGet200ResponseRowsInner[] = stockPlanQueries
    .map((query) => (query.data as AxiosResponse<StockPlansGet200ResponseRowsInner>)?.data)
    .filter(Boolean);

  // Mantine useForm 초기화 (정확한 타입 명시)
  const form = useForm<{
    stockPlanData: StockPlansGet200ResponseRowsInner[];
  }>({
    initialValues: {
      stockPlanData: [],
    },
  });

  // stockPlanData를 form에 반영
  useEffect(() => {
    if (allSuccess) {
      form.setValues({
        stockPlanData: stockPlanData.map((data) => ({
          partnerCode: data?.partnerCode ?? undefined,
          scheduledAt: data?.scheduledAt ?? undefined,
          itemCode: data?.itemCode ?? undefined,
          lotId: data?.lotId ?? undefined,
          quantity: data?.quantity ?? undefined,
          userCode: data?.userCode ?? undefined,
          sourceLocationCode: data?.sourceLocationCode ?? undefined,
          targetLocationCode: data?.targetLocationCode ?? undefined,
          transportationId: data?.transportationId ?? undefined,
        })),
      });
    }
  }, [allSuccess]); // 🔥 `form.values.stockPlanData` 추가

  const onSubmit = async () => {
    try {
      await Promise.all(
        stockPlanData.map((stockPlan, index) =>
          updateMutate({
            stockPlanId: stockPlan?.id!,
            stockPlansGetRequest: {
              scheduledAt: stockPlan?.scheduledAt!,
              itemCode: stockPlan?.itemCode!,
              direction: stockPlan?.direction!,
              partnerCode: stockPlan?.partnerCode!,
              lotId: stockPlan?.lotId!,
              quantity: stockPlan?.quantity!,
              userCode: stockPlan?.userCode!,
              sourceLocationCode: stockPlan?.sourceLocationCode!,
              targetLocationCode: stockPlan?.targetLocationCode!,
              transportationId:
                form.values.stockPlanData[index].transportationId ?? stockPlan.transportationId,
            },
          }),
        ),
      );
      closeModal(form.values);
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("transportation.transportation") }),
        t("transportation.registerFail"),
        "red",
      );
    }
  };

  return (
    <>
      <Wrapper>
        {form.values.stockPlanData.map((stockPlan, index) => {
          return (
            <Table withBorder withColumnBorders w="60rem" my="md" key={index}>
              <thead>
                <tr>
                  <Th flex={1} className="small-column">
                    {t("transportation.index")}
                  </Th>
                  <Th flex={2}>{t("stockPlan.scheduledAt")}</Th>
                  <Th flex={2} className="big-column">
                    {t("item.code")}
                  </Th>
                  <Th flex={2}>{t("partner.partner")}</Th>
                  <Th flex={2}>{t("transportation.transportation")}</Th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Td flex={1} className="small-column">
                    {index + 1}
                  </Td>
                  <Td flex={2}>{timeUtil(stockPlan?.scheduledAt ?? "")}</Td>
                  <Td flex={2} className="big-column">
                    {stockPlan?.itemCode}
                  </Td>
                  <Td flex={2}>{stockPlan?.partnerCode}</Td>
                  <Td flex={2}>
                    <TransportationsAutoComplete
                      placeholder={t("placeholder.select", {
                        item: t("transportation.transportation"),
                      })}
                      {...form.getInputProps(`stockPlanData.${index}.transportationId`)}
                      value={stockPlan?.transportationId?.toString() ?? ""}
                    />
                  </Td>
                </tr>
              </tbody>
            </Table>
          );
        })}
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={() => closeModal(null)}>
            {t("common.cancel")}
          </Update.Button>
          <Update.Button onClick={onSubmit}>
            {t("common.additional", { item: t("transportation.transportation") })}
          </Update.Button>
        </Update.ButtonBox>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const Th = styled.th<{ flex: number }>`
  flex: ${({ flex }) => flex};
  text-align: center;
  min-width: 4rem;

  &.small-column {
    min-width: 2rem;
  }

  &.big-column {
    min-width: 8rem;
  }
`;

const Td = styled.td<{ flex: number }>`
  flex: ${({ flex }) => flex};
  text-align: center;

  &.small-column {
    min-width: 2rem;
  }

  &.big-column {
    min-width: 8rem; /* 순번 열의 크기를 작게 설정 */
  }
`;
