import DefaultInstance from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  DefaultApiOperationsFindPostRequest,
  DefaultApiOperationsGetRequest,
  DefaultApiOperationsOperationIdDeleteRequest,
  DefaultApiOperationsOperationIdPutRequest,
  DefaultApiOperationsPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const operations = createQueryKeys("operations", {
  all: null,
  get: (params: DefaultApiOperationsGetRequest) => {
    // const queryParams = { ...params };
    const queryParams = {
      ...params,
      query: JSON.stringify({ $and: params?.query }),
      sort: params.sort?.length ? params.sort : "-id",
    }; // 공정 조회 시 기본 정렬 표시

    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => DefaultInstance.operationsGet(queryParams),
    };
  },
  // get: (params: DefaultApiOperationsGetRequest) => {
  //     const query = { ...params }; // params 객체를 복사합니다.
  //     if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
  //         query["query"] = JSON.stringify(params.query);
  //     }
  //     // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
  //     if (params.search) {
  //         query["search"] = params.search;
  //         query["searchFields"] = params.searchFields;
  //     }
  //     return {
  //         queryKey: [query, JSON.stringify(query.query)],
  //         queryFn: () => DefaultInstance.operationsGet(query)
  //     }
  // },
  find: (params: DefaultApiOperationsFindPostRequest) => {
    const query = { ...params }; // params 객체를 복사합니다.
    if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.operationsFindPost(query),
    };
  },
});

export const mutateOperations = createMutationKeys("operations", {
  create: (params: DefaultApiOperationsPostRequest) => ({
    mutationKey: [params.operationsGetRequest],
    mutationFn: () => DefaultInstance.operationsPost(params),
  }),
  update: (params: DefaultApiOperationsOperationIdPutRequest) => ({
    mutationKey: [params.operationId, params.operationsGetRequest],
    mutationFn: () => DefaultInstance.operationsOperationIdPut(params),
  }),
  delete: (params: DefaultApiOperationsOperationIdDeleteRequest) => ({
    mutationKey: [params.operationId],
    mutationFn: () => DefaultInstance.operationsOperationIdDelete(params),
  }),
});

export const operationsQueryKeys = mergeQueryKeys(operations, mutateOperations);
