import DefaultInstance from "@/instance/axios";
import {
  DefaultApiErpSyncPushCancelSingleOutsourcingRecordPostRequest,
  ErpSyncPushErpWorkRecordPostRequest,
  ErpSyncPushPushSingleOutsourcingRecordPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

// 원하는 실적을 체크한 후 "실적 전달하기" 버튼 클릭 시 실적을 전달하는 API

export const setERPWorkRecord = (params: ErpSyncPushErpWorkRecordPostRequest) => {
  return DefaultInstance.erpSyncPushErpWorkRecordPost({
    erpSyncPushErpWorkRecordPostRequest: params,
  });
};

// export const useWorkRecordERP = (params: ErpSyncPushErpWorkRecordPostRequest) => {
//     return useQuery(
//         ["erp-work-record"],
//         () => setERPWorkRecord(params),
//     );
// };

// 원하는 외주 실적을 체크한 후 "외주 실적 전달하기" 버튼 클릭 시 외주 실적을 전달하는 API
export const setERPOutsourcingRecord = (params: ErpSyncPushErpWorkRecordPostRequest) => {
  return DefaultInstance.erpSyncPushErpOutsourcingRecordPost({
    erpSyncPushErpWorkRecordPostRequest: params,
  });
};

export const setERPOutsourcingSingleRecord = (
  params: ErpSyncPushPushSingleOutsourcingRecordPostRequest,
) => {
  return DefaultInstance.erpSyncPushPushSingleOutsourcingRecordPost({
    erpSyncPushPushSingleOutsourcingRecordPostRequest: params,
  });
};

export const setERPOutsourcingDeleteRecord = (
  params: DefaultApiErpSyncPushCancelSingleOutsourcingRecordPostRequest,
) => {
  return DefaultInstance.erpSyncPushCancelSingleOutsourcingRecordPost({
    erpSyncPushCancelSingleOutsourcingRecordPostRequest: {
      erpSync: params.erpSyncPushCancelSingleOutsourcingRecordPostRequest.erpSync,
    },
  });
};

// export const useWorkRecordERPOutsourcing = (params: DefaultApiErpSyncPushErpOutsourcingRecordPostRequest) => {
//     return useQuery(
//         ["erp-outsourcing-record"],
//         () => setERPOutsourcingRecord(params),
//     );
// };
