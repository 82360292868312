// @fileoverview : 기준정보 > 경남-기계관리
import { machineHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { MachineCreateForm } from "@/features/machine/form/createForm";
import { MachineUpdateForm } from "@/features/machine/form/updateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import customAlert from "@/features/ui/alert/alert";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import useQueryString from "@/hooks/useQueryString";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Flex, Pagination } from "@mantine/core";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

const MachineWrap = {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  Wrapper: Wrapper,
  CreateForm: MachineCreateForm,
  UpdateForm: MachineUpdateForm,
};

export const MachinePage = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const { openModal } = useModal();
  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<any> => new Set());

  const searchFieldsHeader = machineHeader.filter(
    (item) => item.category === "text" && !item.isEnum,
  );

  const pageSize = 10;
  const totalRows = MachinePageMockupData.length;

  // 페이지에 맞는 데이터 슬라이싱
  const paginatedRows = MachinePageMockupData.slice(
    (activePage - 1) * pageSize,
    activePage * pageSize,
  );

  const columns: readonly Column<any>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "machineCompany",
      name: "기계 제조사",
      sortable: true,
      resizable: true,
    },
    {
      key: "machineName",
      name: "기계명",
      sortable: true,
      resizable: true,
    },
    {
      key: "machineType",
      name: "기계유형",
      sortable: true,
      resizable: true,
    },
    {
      key: "manufacturedAt",
      name: "제조일",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <>{dayjs(formatterProps.row.manufacturedAt).format("YYYY-MM-DD")}</>;
      },
    },
    {
      key: "manufactureId",
      name: "제조번호",
      sortable: true,
      resizable: true,
    },
    {
      key: "userName",
      name: "담당자",
      sortable: true,
      resizable: true,
    },
    {
      key: "machinePicture",
      name: "장치 사진",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <img
            src={formatterProps.row.modelPicture}
            alt="modelPicture"
            style={{ width: "100px", height: "100px" }}
          />
        );
      },
    },
    {
      key: "specNamePlatePicture",
      name: "스펙 명판 사진",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <img
            src={formatterProps.row.specNamePlatePicture}
            alt="specNamePlatePicture"
            style={{ width: "100px", height: "100px" }}
          />
        );
      },
    },
    {
      key: "functionNamePlatePicture",
      name: "능력 명판 사진",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <img
            src={formatterProps.row.functionNamePlatePicture}
            alt="functionNamePlatePicture"
            style={{ width: "100px", height: "100px" }}
          />
        );
      },
    },
    {
      key: "manual",
      name: "사용 설명서",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <>{formatterProps.row.manual}</>;
      },
    },
    {
      key: "isOverHall",
      name: "오버홀",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <>{formatterProps.row.isOverHall ? "유" : "무"}</>;
      },
    },
    {
      key: "peripheral",
      name: "주변장치",
      sortable: true,
      resizable: true,
    },
    {
      key: "ton",
      name: "톤",
      sortable: true,
      resizable: true,
    },
    {
      key: "volt",
      name: "사용 전압",
      sortable: true,
      resizable: true,
    },
    {
      key: "factoryName",
      name: "공장명",
      sortable: true,
      resizable: true,
    },
    {
      key: "factoryDetailName",
      name: "공장 세분화명",
      sortable: true,
      resizable: true,
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <OptionBox>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconEdit />}
              color="yellow"
              onClick={() => {
                openModal(
                  <MachineWrap.UpdateForm formatterProps={formatterProps.row} />,
                  {},
                  "기계 수정",
                  true,
                );
              }}
            ></OptionBtn>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconTrash />}
              color="red"
              onClick={() => {
                window.confirm("정말로 삭제하시겠습니까?") &&
                  customAlert("기계 삭제에 실패하였습니다.", "삭제 실패", "red");
              }}
            ></OptionBtn>
          </OptionBox>
        );
      },
    },
  ];

  const deleteSelectedItems = () => {
    window.confirm("정말로 삭제하시겠습니까?") &&
      customAlert("선택된 행 삭제에 실패하였습니다.", "삭제 실패", "red");
  };

  return (
    <MachineWrap.Wrapper>
      <MachineWrap.ActionHeader>
        <MachineWrap.ActionButton
          leftIcon={<IconPlus />}
          onClick={() => openModal(<MachineWrap.CreateForm />, null, "기계 추가", true)}
        >
          추가
        </MachineWrap.ActionButton>
        <MachineWrap.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => deleteSelectedItems()}
        >
          선택된 행 삭제
        </MachineWrap.ActionButton>
      </MachineWrap.ActionHeader>
      <MachineWrap.Header>
        <MachineWrap.HeaderTitle>기계 관리</MachineWrap.HeaderTitle>
        <MachineWrap.HeaderSubTitle>
          {"우리 회사에서 다루는 기계에 대해 조회할 수 있는 페이지 입니다."}
        </MachineWrap.HeaderSubTitle>
      </MachineWrap.Header>
      <Flex w="100%" justify="space-between">
        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
          <CustomFilter filterType={machineHeader} setQuery={setQuery} query={query} />
          <CustomSorter sorterType={machineHeader} setSort={setSort} sort={sort} />
        </Flex>
        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
      </Flex>
      <Flex w="100%" h="100%">
        <GridWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={paginatedRows}
            rowHeight={40}
            rowKeyGetter={(row) => row.logDate}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
          <Pagination
            onChange={setPage}
            value={activePage}
            total={Math.ceil(totalRows / pageSize)} // 전체 페이지 계산
            size="lg"
            radius="sm"
            position="center"
          />
        </GridWrapper>
      </Flex>
    </MachineWrap.Wrapper>
  );
};

const MachinePageMockupData = [
  {
    machineCompany: "현대자동차",
    machineName: "XZ-750 1",
    machineType: "압축기",
    manufacturedAt: dayjs("2023-10-19").toDate(),
    manufactureId: 3834,
    userName: "이영희",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "컨베이어",
    ton: 1,
    volt: 380,
    factoryName: "광주사업장",
    factoryDetailName: "1층 생산동 5구역",
  },
  {
    machineCompany: "SK하이닉스",
    machineName: "PX-450 3",
    machineType: "가공장비",
    manufacturedAt: dayjs("2023-07-20").toDate(),
    manufactureId: 1114,
    userName: "유석환",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "컨베이어",
    ton: 3,
    volt: 220,
    factoryName: "광주사업장",
    factoryDetailName: "1층 생산동 2구역",
  },
  {
    machineCompany: "삼성전자",
    machineName: "AC-1200 4",
    machineType: "센서",
    manufacturedAt: dayjs("2023-09-11").toDate(),
    manufactureId: 8834,
    userName: "유재하",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "컨베이어",
    ton: 4,
    volt: 380,
    factoryName: "광주사업장",
    factoryDetailName: "4층 검사동 2구역",
  },
  {
    machineCompany: "LG전자",
    machineName: "PX-450 1",
    machineType: "로봇",
    manufacturedAt: dayjs("2024-09-01").toDate(),
    manufactureId: 2510,
    userName: "최지현",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "모터",
    ton: 4,
    volt: 220,
    factoryName: "광주사업장",
    factoryDetailName: "4층 검사동 5구역",
  },
  {
    machineCompany: "기아",
    machineName: "DC-3000 2",
    machineType: "검출장치",
    manufacturedAt: dayjs("2024-01-25").toDate(),
    manufactureId: 1892,
    userName: "이영희",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: true,
    peripheral: "모터",
    ton: 3,
    volt: 220,
    factoryName: "평택사업장",
    factoryDetailName: "2층 생산동 5구역",
  },
  {
    machineCompany: "SK하이닉스",
    machineName: "TR-900 1",
    machineType: "검출장치",
    manufacturedAt: dayjs("2023-06-01").toDate(),
    manufactureId: 9321,
    userName: "박민수",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "프로세서",
    ton: 8,
    volt: 220,
    factoryName: "광주사업장",
    factoryDetailName: "1층 생산동 2구역",
  },
  {
    machineCompany: "삼성전자",
    machineName: "TR-900 4",
    machineType: "검출장치",
    manufacturedAt: dayjs("2023-11-18").toDate(),
    manufactureId: 3871,
    userName: "김성민",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: true,
    peripheral: "프로세서",
    ton: 5,
    volt: 380,
    factoryName: "울산사업장",
    factoryDetailName: "3층 조립동 4구역",
  },
  {
    machineCompany: "LG전자",
    machineName: "TR-900 2",
    machineType: "가공장비",
    manufacturedAt: dayjs("2023-10-22").toDate(),
    manufactureId: 2494,
    userName: "박민수",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: true,
    peripheral: "프로세서",
    ton: 2,
    volt: 110,
    factoryName: "평택사업장",
    factoryDetailName: "2층 생산동 3구역",
  },
  {
    machineCompany: "LG전자",
    machineName: "HV-1000 4",
    machineType: "압축기",
    manufacturedAt: dayjs("2024-09-02").toDate(),
    manufactureId: 3531,
    userName: "오태곤",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: true,
    peripheral: "배터리",
    ton: 2,
    volt: 220,
    factoryName: "광주사업장",
    factoryDetailName: "3층 조립동 1구역",
  },
  {
    machineCompany: "LG전자",
    machineName: "PX-450 2",
    machineType: "가공장비",
    manufacturedAt: dayjs("2023-08-26").toDate(),
    manufactureId: 4607,
    userName: "최지현",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "배터리",
    ton: 7,
    volt: 380,
    factoryName: "울산사업장",
    factoryDetailName: "4층 검사동 5구역",
  },
  {
    machineCompany: "LG전자",
    machineName: "DC-3000 2",
    machineType: "센서",
    manufacturedAt: dayjs("2024-05-29").toDate(),
    manufactureId: 3444,
    userName: "최지현",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "모터",
    ton: 10,
    volt: 220,
    factoryName: "평택사업장",
    factoryDetailName: "2층 생산동 5구역",
  },
  {
    machineCompany: "삼성전자",
    machineName: "XZ-750 2",
    machineType: "검출장치",
    manufacturedAt: dayjs("2023-05-11").toDate(),
    manufactureId: 5262,
    userName: "오원석",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "배터리",
    ton: 5,
    volt: 110,
    factoryName: "화성사업장",
    factoryDetailName: "2층 생산동 5구역",
  },
  {
    machineCompany: "기아",
    machineName: "PX-450 3",
    machineType: "로봇",
    manufacturedAt: dayjs("2023-10-09").toDate(),
    manufactureId: 5329,
    userName: "유재하",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: true,
    peripheral: "프로세서",
    ton: 1,
    volt: 380,
    factoryName: "평택사업장",
    factoryDetailName: "4층 검사동 4구역",
  },
  {
    machineCompany: "SK하이닉스",
    machineName: "PX-450 1",
    machineType: "압축기",
    manufacturedAt: dayjs("2024-01-29").toDate(),
    manufactureId: 7456,
    userName: "이영희",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "모터",
    ton: 9,
    volt: 220,
    factoryName: "울산사업장",
    factoryDetailName: "3층 조립동 1구역",
  },
  {
    machineCompany: "LG전자",
    machineName: "DC-3000 4",
    machineType: "가공장비",
    manufacturedAt: dayjs("2024-03-30").toDate(),
    manufactureId: 1141,
    userName: "유재하",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: true,
    peripheral: "배터리",
    ton: 1,
    volt: 220,
    factoryName: "광주사업장",
    factoryDetailName: "4층 검사동 2구역",
  },
  {
    machineCompany: "SK하이닉스",
    machineName: "AC-1200 1",
    machineType: "압축기",
    manufacturedAt: dayjs("2023-07-15").toDate(),
    manufactureId: 2889,
    userName: "최지현",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "컨베이어",
    ton: 7,
    volt: 220,
    factoryName: "화성사업장",
    factoryDetailName: "3층 조립동 3구역",
  },
  {
    machineCompany: "삼성전자",
    machineName: "DC-3000 3",
    machineType: "가공장비",
    manufacturedAt: dayjs("2023-03-09").toDate(),
    manufactureId: 8329,
    userName: "유재하",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "모터",
    ton: 7,
    volt: 110,
    factoryName: "평택사업장",
    factoryDetailName: "2층 생산동 5구역",
  },
  {
    machineCompany: "현대자동차",
    machineName: "DC-3000 5",
    machineType: "검출장치",
    manufacturedAt: dayjs("2023-09-08").toDate(),
    manufactureId: 1687,
    userName: "유재하",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "프로세서",
    ton: 3,
    volt: 380,
    factoryName: "화성사업장",
    factoryDetailName: "4층 검사동 4구역",
  },
  {
    machineCompany: "LG전자",
    machineName: "HV-1000 4",
    machineType: "센서",
    manufacturedAt: dayjs("2024-07-25").toDate(),
    manufactureId: 3044,
    userName: "유재하",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: true,
    peripheral: "모터",
    ton: 7,
    volt: 220,
    factoryName: "화성사업장",
    factoryDetailName: "4층 검사동 2구역",
  },
  {
    machineCompany: "포스코",
    machineName: "HV-1000 3",
    machineType: "조립기",
    manufacturedAt: dayjs("2024-10-04").toDate(),
    manufactureId: 4257,
    userName: "이영희",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "모터",
    ton: 5,
    volt: 220,
    factoryName: "광주사업장",
    factoryDetailName: "3층 조립동 4구역",
  },
  {
    machineCompany: "현대자동차",
    machineName: "TR-900 2",
    machineType: "검출장치",
    manufacturedAt: dayjs("2023-09-20").toDate(),
    manufactureId: 2633,
    userName: "이영희",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "프로세서",
    ton: 3,
    volt: 220,
    factoryName: "광주사업장",
    factoryDetailName: "2층 생산동 5구역",
  },
  {
    machineCompany: "현대자동차",
    machineName: "HV-1000 5",
    machineType: "검출장치",
    manufacturedAt: dayjs("2024-10-11").toDate(),
    manufactureId: 1096,
    userName: "장원영",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: true,
    peripheral: "프로세서",
    ton: 8,
    volt: 380,
    factoryName: "광주사업장",
    factoryDetailName: "4층 검사동 2구역",
  },
  {
    machineCompany: "기아",
    machineName: "XZ-750 2",
    machineType: "조립기",
    manufacturedAt: dayjs("2023-11-04").toDate(),
    manufactureId: 6189,
    userName: "김철수",
    machinePicture: null,
    specNamePlatePicture: null,
    functionNamePlatePicture: null,
    manual: null,
    isOverHall: false,
    peripheral: "컨베이어",
    ton: 3,
    volt: 380,
    factoryName: "평택사업장",
    factoryDetailName: "2층 생산동 3구역",
  },
];

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    // --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    // --rdg-header-background-color: {(props) =>
    // props.isDarkMode ? "white" : "none"};
    // --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    // --rdg-row-hover-background-color: {(props) =>
    // props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
