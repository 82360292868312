import { Text } from "@mantine/core";

interface BadgeQuantityProps {
  children: React.ReactNode;
  color: string | undefined;
  fz?: string;
}

export const BadgeQuantity = (params: BadgeQuantityProps) => {
  const { children, color, fz } = params;

  return (
    <Text size="xl" color={color} ml="sm" fz={fz}>
      {children}
    </Text>
  );
};
