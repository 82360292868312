// @fileoverview : 기준정보 > 경남-부서관리
import { mutateDepartment } from "@/api/department/useDepartmentQuery";
import { DepartmentCreateForm } from "@/features/department/form/createForm";
import { DepartmentUpdateForm } from "@/features/department/form/updateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import {
  DepartmentsGet200Response,
  DepartmentsGet200ResponseRowsInner,
  MasterApiDepartmentsDepartmentIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import type { Column } from "react-data-grid";
import { SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const DepartmentPage = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { t } = useTranslation();
  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiDepartmentsDepartmentIdDeleteRequest) =>
      mutateDepartment.delete(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customAlert(
          `${t("message.deleteSuccess", { item: `${t("department.department")}` })}`,
          t("common.deleteSuccess"),
          "green",
        );
        refetch();
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("department.code") }),
          `${t("common.deleteFail", { item: `${t("department.department")}` })}`,
          "red",
        );
      },
    },
  );

  const columns: readonly Column<DepartmentsGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "code",
      name: t("department.code"),
      sortable: true,
      resizable: true,
    },
    {
      key: "name",
      name: t("department.name"),
      sortable: true,
      resizable: true,
    },
    {
      key: "options",
      name: t("common.action"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updateDepartmentActionView = () => {
          openModal(
            <DepartmentUpdateForm {...formatterProps.row} />,
            {},
            `${t("common.edit", { item: `${t("department.department")}` })}`,
            true,
          );
        };

        const deleteDepartmentAction = () => {
          openModal(
            <ConfirmForm
              message={t("message.rowDelete")}
              yesCallback={() => deleteMutate({ departmentId: formatterProps?.row.id as number })}
              noCallback={() => {}}
              commonCallback={() => closeModal(null)}
            />,
            null,
            `${t("common.delete", { item: `${t("department.department")}` })}`,
          );
        };

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon variant="subtle" onClick={updateDepartmentActionView} color="yellow">
              <IconEdit />
            </ActionIcon>
            <ActionIcon variant="subtle" onClick={() => deleteDepartmentAction()} color="red">
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: `${t("department.department")}` })}`,
        `${t("common.deleteFail", { item: `${t("department.department")}` })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ departmentId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      `${t("common.delete", { item: `${t("department.department")}` })}`,
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader
          createFn={() =>
            openModal(
              <DepartmentCreateForm code={""} />,
              null,
              `${t("common.additional", { item: `${t("department.department")}` })}`,
              true,
            )
          }
          createDataTitle={t("department.department")}
          deleteFn={() => handleDelete()}
        />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: `${t("department.department")}` })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${t("department.department")}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<DepartmentsGet200Response, DepartmentsGet200ResponseRowsInner>
            columns={columns}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<DepartmentsGet200Response> />
      </Flex>
    </Flex>
  );
};
