import { userLogs } from "@/api/userLogs/useUserLogsQuery";
import { userLogsHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import useQueryString from "@/hooks/useQueryString";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Badge, Button, Flex, Pagination } from "@mantine/core";
import { LoginLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

const UserLogsWrap = {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  Wrapper: Wrapper,
};

export const UserLogsPage = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const { openModal } = useModal();
  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<any> => new Set());

  const searchFieldsHeader = userLogsHeader.filter(
    (item) => item.category === "text" && !item.isEnum,
  );

  const { data: UserLogs } = useQuery(
    userLogs.get({
      query: query,
      search: search.search,
      searchFields: search.searchFields.length
        ? search.searchFields
        : searchFieldsHeader.map((item) => item.value),
      page: activePage,
      pageSize: Number(search.pageSize),
      sort: sort.length ? sort.join(",") : "-id",
    }),
  );

  const rows = UserLogs?.data.rows ?? [];
  const totalPage = UserLogs?.data.totalPages ?? 10;

  const columns: readonly Column<LoginLogsGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "username",
      name: "사용자명",
      sortable: true,
      resizable: true,
    },
    {
      key: "userIp",
      name: "사용자 아이피",
      sortable: true,
      resizable: true,
    },
    {
      key: "action",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Badge color={formatterProps.row.action === "로그인" ? "blue" : "red"}>
            {formatterProps.row.action}
          </Badge>
        );
      },
    },
    {
      key: "createdAt",
      name: "로그인 시각",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <>{DatetimeUtil(formatterProps.row.createdAt as string)}</>;
      },
    },
  ];

  return (
    <UserLogsWrap.Wrapper>
      <UserLogsWrap.Header>
        <UserLogsWrap.HeaderTitle>유저 로그관리</UserLogsWrap.HeaderTitle>
        <UserLogsWrap.HeaderSubTitle>
          {"우리 회사 사이트에 로그인/로그아웃 한 사용자에 대해 조회할 수 있는 페이지 입니다."}
        </UserLogsWrap.HeaderSubTitle>
      </UserLogsWrap.Header>
      <Flex w="100%" justify="space-between">
        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
          <CustomFilter filterType={userLogsHeader} setQuery={setQuery} query={query} />
          <CustomSorter sorterType={userLogsHeader} setSort={setSort} sort={sort} />
        </Flex>
        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
      </Flex>
      <Flex w="100%" h="100%">
        <GridWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={rows}
            rowHeight={40}
            rowKeyGetter={(row) => row.id}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
          <Pagination
            onChange={setPage}
            value={activePage}
            total={totalPage} // 전체 페이지 계산
            size="lg"
            radius="sm"
            position="center"
          />
        </GridWrapper>
      </Flex>
    </UserLogsWrap.Wrapper>
  );
};

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: {(props) =>
    props.isDarkMode ? "white" : "none"};
    --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: {(props) =>
    props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
