import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const CommonCodeUpdateForm = (params: any) => {
  const { formatterProps } = params;
  const { closeModal } = useModal();

  const form = useForm({
    initialValues: {
      commonCode: formatterProps.commonCode ?? undefined,
      codeName: formatterProps.codeName ?? undefined,
    },
  });

  const onSubmit = async () => {
    try {
      customAlert("공통 코드 생성에 실패하였습니다.", "생성 실패", "red");
    } catch (e) {
      customAlert("공통 코드 생성에 실패하였습니다.", "생성 실패", "red");
    }
  };

  return (
    <Update.Wrapper>
      <Update.Input label="공통코드" withAsterisk {...form.getInputProps("commonCode")} />
      <Update.Input label="코드명" withAsterisk {...form.getInputProps("codeName")} />
      <Update.ButtonBox>
        <Update.Button color="gray" onClick={closeModal}>
          취소
        </Update.Button>
        <Update.Button
          onClick={onSubmit}
          disabled={validateFormValues({
            pageType: "commonCode",
            values: form.values,
          })}
        >
          공통 코드 수정
        </Update.Button>
      </Update.ButtonBox>
    </Update.Wrapper>
  );
};
