import { productionPlans } from "@/api/productionPlan/useProductionPlanQuery";
import { OrderDetailActionBar } from "@/features/order/header/orderDetailActionBar";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import timeUtil from "@/utils/timeUtil";
import { Button, Table, Tabs } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ProductionPlanPurchaseOrderItemForm } from "./productionPlanPurchaseOrderItemForm";

const Detail = Object.assign(
  {},
  {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
    ActionBar: OrderDetailActionBar,
    ActionHeader: ActionHeader,
    ActionButtonBox: ActionButtonBox,
    ActionButton: Button,
  },
);

interface ProductionPlanFormProps {
  purchaseOrderItemID: number | undefined;
}
export const ProductionPlanForm = (params: ProductionPlanFormProps) => {
  const { purchaseOrderItemID } = params;
  const { t } = useTranslation();
  const { data } = useQuery(
    productionPlans.get({
      query: {
        $and: [{ purchaseOrderItemId: purchaseOrderItemID }],
      },
      populate: ["works", "equipment", "itemUnit", "purchaseOrderItem", "lastLotId"],
    }),
  );

  const purchaseOrderItemData: ProductionPlansGet200ResponseRowsInner | undefined =
    data?.data?.rows && data?.data?.rows[0];

  return (
    <Detail.Container>
      <Detail.Wrapper>
        <Detail.Content>
          <ProductionPlanPurchaseOrderItemForm purchaseOrderItemData={purchaseOrderItemData} />
          <Tabs defaultValue={"productionPlan"}>
            <Tabs.List>
              <Tabs.Tab value="productionPlan">{"생산계획"}</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="productionPlan">
              <Table>
                <thead>
                  <tr>
                    <th>생산계획번호</th>
                    <th>품명</th>
                    <th>품목코드</th>
                    <th>수량</th>
                    <th>상태</th>
                    <th>작업일</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.rows?.map((rows: ProductionPlansGet200ResponseRowsInner) => {
                    return (
                      <tr>
                        <td>{rows.id}</td>
                        <td>{rows.itemName}</td>
                        <td>{rows.itemCode}</td>
                        <td>
                          {rows.targetQuantity} {rows.unitText}
                        </td>
                        <td>
                          <DetailBadge>{t(rows.status ?? "")}</DetailBadge>
                        </td>
                        <td>{timeUtil(rows.scheduledAt ?? "")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Tabs.Panel>
          </Tabs>
        </Detail.Content>
      </Detail.Wrapper>
    </Detail.Container>
  );
};
