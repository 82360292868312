import { transportations } from "@/api/transportation/useTransportationQuery";
import { LeftSpace } from "@/features/location/detail/LocationDetailForm";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { Main } from "@/features/standard/Main";
import { Button, Tabs } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { TransportationDetailActionBar } from "../header/transportationDetailActionBar";
import { TransportationDetailDataHeader } from "../header/transportationDetailDataHeader";
import { TransportationDetailHeader } from "../header/transportationDetailHeader";
import { TransportationDetailTable } from "../table/transportationDetailTable";

const Detail = Object.assign({}, Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
  ActionBar: TransportationDetailActionBar,
  ActionHeader: ActionHeader,
  ActionButtonBox: ActionButtonBox,
  ActionBox: Button,
});

interface TransportationDetailFormProps {
  transportationID: number | undefined;
}

export const TransportationDetailForm = (params: TransportationDetailFormProps) => {
  const { transportationID } = params;

  const { data: transportationData } = useQuery(
    transportations.detail({
      transportationId: transportationID ?? 0,
    }),
  );

  const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
  const [selectedRowsInform, setSelectedRowsInform] = useState<
    ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]
  >([]);
  const [data, setData] = useState<any>([]);

  const handleRowSelect = (selectedRows: any, formatterPropsData: any) => {
    setIsRowBtnActive(selectedRows.size === 1);
    setData(formatterPropsData);
    setSelectedRowsInform(selectedRowsInform);
  };

  return (
    <Detail.Container>
      <Detail.ActionBar formatterProps={transportationData?.data} />
      <Detail.Wrapper>
        <Detail.Content>
          <TransportationDetailDataHeader />
          <TransportationDetailHeader data={transportationData} />
          <Tabs defaultValue="carryingItem">
            <LeftSpace />
            <Tabs.List>
              <Tabs.Tab value="carryingItem">적재 항목</Tabs.Tab>
              <Tabs.Tab value="history">히스토리</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="carryingItem">
              {/* <Detail.ActionHeader>
                <Detail.ActionButtonBox>
                  <Detail.ActionBox leftIcon={<IconPlus />}>입고</Detail.ActionBox>
                  <Detail.ActionBox leftIcon={<IconMinus />} color="red">
                    출고
                  </Detail.ActionBox>
                </Detail.ActionButtonBox>
                <Detail.ActionButtonBox>
                  <Detail.ActionBox rightIcon={<IconTrash />} color="red">
                    선택된 행 삭제
                  </Detail.ActionBox>
                </Detail.ActionButtonBox>
              </Detail.ActionHeader> */}
              <TransportationDetailTable onRowSelect={handleRowSelect} />
            </Tabs.Panel>
            <Tabs.Panel value="history">2</Tabs.Panel>
          </Tabs>
        </Detail.Content>
      </Detail.Wrapper>
    </Detail.Container>
  );
};
