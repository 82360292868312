import { useStandardLayout } from "@/features/standardLayout/Context";
import { handleKeyDown } from "@/utils/event";
import { Button, Flex, Select, TextInput } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const pageSizeOptions = [
  { value: "5", label: "page.5" },
  { value: "10", label: "page.10" },
  { value: "15", label: "page.15" },
  { value: "20", label: "page.20" },
];

export const SearchBox = () => {
  const {
    setPage,
    pageSize,
    setPageSize,
    setSelectedRows,
    searchFields,
    setSearchFields,
    search,
    setSearch,
    searchFieldsHeader,
  } = useStandardLayout();

  const [pageSizeState, setPageSizeState] = useState(pageSize);
  const [searchFieldsState, setSearchFieldsState] = useState(searchFields);
  const { t } = useTranslation();
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = search;
    }
    setPageSizeState(pageSize);
    setSearchFieldsState(searchFields);
  }, [pageSize, search, searchFields]);

  const onSubmit = () => {
    setPage(1); // 검색어 변경 시 항상 페이지를 1로 초기화
    setSelectedRows((): Set<any> => new Set()); // 검색어 변경 시 항상 선택된 행 초기화
    setPageSize(pageSizeState);
    setSearchFields(searchFieldsState);
    setSearch(searchRef.current?.value || "");
  };

  return (
    <Flex gap="sm">
      <Select
        data={pageSizeOptions.map((item) => ({ ...item, label: t(item.label ?? "") }))}
        value={pageSizeState.toString()}
        onChange={(e) => setPageSizeState(Number(e))}
      />
      <Select
        data={searchFieldsHeader
          .filter((item) => item.category === "text" && !item.isEnum)
          .map((item) => ({
            ...item,
            label: t(item.label ?? ""),
          }))}
        value={searchFieldsState.toString()}
        placeholder={t("common.totalSearch")}
        onChange={(e) => setSearchFieldsState(e ? [e] : [])}
      />
      <TextInput
        datatype="text"
        defaultValue={search}
        placeholder={t("message.search")}
        ref={searchRef}
        onKeyDown={(e) => handleKeyDown(e, onSubmit)}
      />
      <Button onClick={() => onSubmit()}>{t("common.search")}</Button>
    </Flex>
  );
};
