import { t } from "i18next";

type StatusObjType = {
  [key: string]: string;
};

// 수발주 상태
export const purchaseOrderStatusColor = {
  DRAFT: "dark.1",
  IN_PROGRESS: "indigo.6",
  DONE: "teal.9",
  CANCELED: "orange.4",
};

export const purchaseOrderStatus: StatusObjType = {
  DRAFT: t("purchaseStatus.DRAFT"),
  IN_PROGRESS: t("purchaseStatus.IN_PROGRESS"),
  DONE: t("purchaseStatus.DONE"),
  CANCELED: t("purchaseStatus.CANCELED"),
};

export const setPurchaseOrderStatus = (status: string) => {
  return purchaseOrderStatus[status];
};

// 배차 상태
export const transportationStatusColor = {
  SCHEDULED: "indigo.6",
  LOADING: "orange.4",
  DONE: "teal.9",
  ON_ROAD: "green.7",
  ERROR: "red.6",
};

export const transportationStatus: StatusObjType = {
  SCHEDULED: t("transportationStatus.SCHEDULED"),
  LOADING: t("transportationStatus.LOADING"),
  DONE: t("transportationStatus.DONE"),
  ON_ROAD: t("transportationStatus.ON_ROAD"),
  ERROR: t("transportationStatus.ERROR"),
};

export const setTransportationStatus = (status: string) => {
  return t(transportationStatus[status]);
};

// 협력사 상태
export const partnerStatusColor = {
  SALES: "indigo.6",
  PURCHASE: "orange.4",
  BI_DIRECTION: "green.7",
};

export const partnerStatus: StatusObjType = {
  SALES: "판매",
  PURCHASE: "구매",
  BI_DIRECTION: "양방향",
};

export const setPartnerStatus = (status: string) => {
  return partnerStatus[status];
};
