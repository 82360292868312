import { useModal } from "@/features/modal/ModalStackManager";
import DefaultInstance from "@/instance/axios";
import { useMutation } from "@tanstack/react-query";

const interportGetQuery = (file: File | undefined) =>
  DefaultInstance.interportImportPost({
    file: file,
  });

export const useInterportMutationQuery = (file: File | undefined) => {
  const { closeModal } = useModal();
  return useMutation(() => interportGetQuery(file), {
    onSuccess: () => {
      alert("성공적으로 업로드 되었습니다.");
      closeModal({});
    },
    onError: () => {
      alert("업로드에 실패했습니다.");
    },
  });
};
