// @fileoverview : 경남-품질관리 > 검사기준
import { mutateSpecifications } from "@/api/inspect/inspectSpecification/useSpecificationsQuery";
import { InspectSpecificationCreateFormWithItems } from "@/features/inspect/components/inspectSpecification/form/InspectSpecificationCreateFormWithItems";
import { InspectSpecificationUpdateForm } from "@/features/inspect/components/inspectSpecification/form/InspectSpecificationUpdateForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ActionIcon, Avatar, Badge, Flex, Text, Tooltip } from "@mantine/core";
import {
  SpcApiSpecificationsSpecificationIdDeleteRequest,
  SpecificationsGet200Response,
  SpecificationsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

// "우리 회사에서 사용하는 품목의 검사표준을 조회할 수 있는 페이지 입니다."
export const InspectSpecificationPage = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { t } = useTranslation();

  const { mutate: deleteMutate } = useMutation(
    (params: SpcApiSpecificationsSpecificationIdDeleteRequest) =>
      mutateSpecifications
        .delete(params)
        .mutationFn(params as SpcApiSpecificationsSpecificationIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          t("message.deleteSuccess", { item: t("inspect.inspectSpecification") }),
          t("common.deleteSuccess"),
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("inspect.inspectSpecification") }),
          t("common.deleteFail", { item: t("inspect.inspectSpecification") }),
          "red",
        );
      },
    },
  );

  const columns: readonly Column<SpecificationsGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "itemCode",
      name: t("item.code"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps: any) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(<ItemsDetailForm itemCode={formatterProps.row.itemCode} />, null, "")
            }
          >
            {formatterProps.row.itemCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "routingCode",
      name: t("inspect.type"),
      sortable: true,
      resizable: true,
      formatter: () => {
        return (
          <Badge color="blue" radius={2} fw={700} size={"9px"}>
            {t("inspect.inspect")}
          </Badge>
        );
      },
    },
    {
      key: "summary",
      name: t("inspect.summary"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        // criteria가 존재하고 배열인지 확인 후 처리
        const summaryNames = Array.isArray(formatterProps.row.criteria)
          ? formatterProps.row.criteria.map((data) => data.name)
          : [];

        return (
          <Flex>
            {summaryNames.length > 1
              ? summaryNames[0] + " 외 " + (summaryNames.length - 1) + "개"
              : (summaryNames[0] ?? "")}
          </Flex>
        );
      },
    },
    {
      key: "options",
      name: t("common.action"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updateInspectSpecificationActionView = () => {
          openModal(
            <InspectSpecificationUpdateForm
              data={{
                itemCode: formatterProps.row.itemCode,
              }}
            />,
            "",
            t("common.edit", { item: t("inspect.inspectSpecification") }),
            true,
          );
        };

        const deleteInspectSpecificationAction = () => {
          openModal(
            <ConfirmForm
              message={t("meesage.rowDelete")}
              yesCallback={() =>
                deleteMutate({ specificationId: formatterProps?.row?.id as number })
              }
              noCallback={() => {}}
              commonCallback={() => closeModal(null)}
            />,
            null,
            t("common.delete", { item: t("inspect.inspectSpecification") }),
          );
        };

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon
              variant="subtle"
              onClick={updateInspectSpecificationActionView}
              color="yellow"
            >
              <IconEdit />
            </ActionIcon>
            <ActionIcon
              variant="subtle"
              onClick={() => deleteInspectSpecificationAction()}
              color="red"
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        t("message.deleteSelect", { item: t("inspect.inspectSpecification") }),
        t("common.deleteFail", { item: t("inspect.inpectSpecification") }),
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ specificationId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      t("common.delete", { item: t("inspect.inspectSpecification") }),
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader
          createFn={() =>
            openModal(
              <InspectSpecificationCreateFormWithItems />,
              null,
              t("common.additional", { item: t("inspect.inspectSpecification") }),
              true,
            )
          }
          createDataTitle={t("inspect.inspectSpecification")}
          deleteFn={() => handleDelete()}
        />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: t("inspect.inspectSpecification") })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: t("inspect.inspectSpecification") })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<SpecificationsGet200Response, SpecificationsGet200ResponseRowsInner>
            columns={columns}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<SpecificationsGet200Response> />
      </Flex>
    </Flex>
  );
};
