import { mutateLots } from "@/api/lots/useLotsQuery";
import { DefaultApiLotsGetLotNamePostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

export const useCreateLot = () => {
  const [createdLotNames, setCreatedLotNames] = useState<string[]>([]);
  const { mutate: getLotNameMutate } = useMutation((params: DefaultApiLotsGetLotNamePostRequest) =>
    mutateLots.getLotName(params).mutationFn(undefined),
  );

  const createLot = async (itemCodeList: string[]) => {
    try {
      const lotNameList: string[] = await new Promise((resolve, reject) => {
        getLotNameMutate(
          {
            lotsGetLotNamePostRequest: {
              itemCode: itemCodeList,
            },
          },
          {
            onSuccess: (data) => {
              const lotName = data.data;
              resolve(lotName);
            },
            onError: (error) => {
              console.error("LOT 이름 가져오기 중 오류 발생:", error);
              reject(error);
            },
          },
        );
      });

      setCreatedLotNames(lotNameList);
    } catch (error) {
      console.error("로트 생성 중 오류 발생:", error);
      throw error;
    }
  };

  return { createLot, createdLotNames };
};
