import { ItemsGet200ResponseRowsInnerItemTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";

export const itemType: ItemType[] = [
  { label: "MATERIAL", value: "MATERIAL" },
  { label: "PRODUCT", value: "PRODUCT" },
  { label: "SUBSIDIARY", value: "SUBSIDIARY" },
  { label: "SEMI_PRODUCT", value: "SEMI_PRODUCT" },
];

export const itemTypeObj = {
  MATERIAL: "원자재",
  PRODUCT: "제품",
  SUBSIDIARY: "부자재",
  SEMI_PRODUCT: "반제품",
  "": "",
};

export const itemTypeColor = {
  MATERIAL: "blue.7",
  PRODUCT: "green.7",
  SUBSIDIARY: "yellow.7",
  SEMI_PRODUCT: "orange.7",
} as const;

interface ItemType {
  label: ItemsGet200ResponseRowsInnerItemTypeEnum;
  value: ItemsGet200ResponseRowsInnerItemTypeEnum;
}
