import DefaultInstance from "@/instance/axios";
import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import {
  DefaultApiWorksFindPostRequest,
  DefaultApiWorksGetRequest,
  DefaultApiWorksWorkIdDeleteRequest,
  DefaultApiWorksWorkIdGetRequest,
  DefaultApiWorksWorkIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const works = createQueryKeys("works", {
  all: null,
  get: (params: DefaultApiWorksGetRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }

    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.worksGet(query),
    };
  },
  find: (params: DefaultApiWorksFindPostRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }

    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.worksFindPost(query),
    };
  },
  detail: (params: DefaultApiWorksWorkIdGetRequest) => {
    return {
      queryKey: [params.workId],
      queryFn: () => DefaultInstance.worksGet(params),
    };
  },
});

export const mutateWorks = createMutationKeys("works", {
  update: (params: DefaultApiWorksWorkIdPutRequest) => {
    return {
      mutationKey: [params.workId, params.worksWorkIdDeleteRequest],
      mutationFn: () => DefaultInstance.worksWorkIdPut(params),
    };
  },
  delete: (params: DefaultApiWorksWorkIdDeleteRequest) => {
    return {
      mutationKey: [params.workId],
      mutationFn: () => DefaultInstance.worksWorkIdDelete(params),
    };
  },
});
