import { inventories, mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { useForm } from "@mantine/form";
import {
  AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
  DefaultApiWmsOutgoingPostRequest,
  ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner,
  WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const usePruchaseOrderOutgingLogic = (
  formatterProps: AuthSignupPost201ResponseEquipmentPurchaseOrderItem | undefined,
) => {
  const queryClient = useQueryClient();
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const [selectLot, setSelectLot] =
    useState<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner>();
  const { mutate: outgoingMutate } = useMutation(
    (params: DefaultApiWmsOutgoingPostRequest) =>
      mutateInventories
        .outgoing(params)
        .mutationFn(params as DefaultApiWmsOutgoingPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories", "purchaseOrderItems", "purchaseOrder"]);
        customAlert(t("delivery.successMessage"), t("delivery.success"), "green");
        closeModal(null);
      },
      onError: () => {
        customAlert(t("delivery.failMessage"), t("delivery.fail"), "red");
      },
    },
  );

  const { data: inventoryData } = useQuery({
    ...inventories.find({
      query: {
        $and: [{ itemCode: { $eq: formatterProps?.itemCode } }],
      },
      populate: ["lot", "location", "item"],
    }),
    enabled: !!formatterProps?.itemCode,
  });

  const form = useForm<Partial<WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner>>({
    initialValues: {
      itemCode: undefined,
      locationCode: undefined,
      quantity: undefined,
      lotId: null,
      lotInfo: {
        name: null,
        expiration: null,
      },
      purchaseOrderItemId: undefined,
      ...(customFunctions.ADD_PURCHASE_ORDER_INVOICE_DATE
        ? { invoiceDate: new Date().toISOString(), invoiceRefNo: undefined }
        : {}),
    },
  });

  const onSubmit = () => {
    try {
      outgoingMutate({
        workLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner: {
          itemCode: formatterProps?.itemCode ?? "",
          locationCode: (form.values.locationCode as string) ?? selectLot?.locationCode,
          quantity: form.values.quantity
            ? form.values.quantity
            : selectLot?.quantity || (form.values.quantity as string),
          lotId: Number(selectLot?.lotId || form.values.lotId),
          lotInfo: {
            name: selectLot?.lot?.name,
            expiration: selectLot?.lot?.expiration,
          },
          purchaseOrderItemId: formatterProps?.id,
          actionFrom: "WEB",
          ...(customFunctions.ADD_PURCHASE_ORDER_INVOICE_DATE
            ? { invoiceDate: form.values.invoiceDate, invoiceRefNo: undefined }
            : {}),
          // invoiceRefNo: undefined, // TODO: invoiceRefNo 추가 필요 (입력폼 필요)
        },
      });
    } catch (e) {
      customAlert(t("delivery.failMessage"), t("delivery.fail"), "red");
    }
  };

  return {
    inventoryData,
    form,
    onSubmit,
    setSelectLot,
    selectLot,
  };
};
