import { items } from "@/api/items/useItemQuery";
import { InspectSpecificationsByItemTable } from "@/features/inspect/components/inspectSpecification/table/inspectSpecificationsByItemTable";

import { LotList } from "@/features/item/components/form/itemsStepperForm/LotList";
import { RoutingBomList } from "@/features/item/components/form/itemsStepperForm/RoutingBomList";
import { ItemView } from "@/features/item/components/view/ItemView";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
});

interface ItemsDetailFormProps {
  itemCode: string | undefined;
}
export const ItemsDetailForm = ({ itemCode }: ItemsDetailFormProps) => {
  const { data: itemTypeData } = useQuery(
    items.codeSearch({
      query: {
        code: itemCode,
      },
    }),
  );
  const itemType = itemTypeData?.data?.rows?.[0]?.itemType;
  const { t } = useTranslation();

  const { data: item } = useQuery(
    items.typeGet({
      query: {
        code: itemCode,
        itemType: itemType,
      },
    }),
  );

  const itemData = item?.data?.rows?.[0];

  const lotData = itemData?.lots;
  const formData: ItemsGet200ResponseRowsInnerRoutingsInner[] = itemData?.routings ?? [];
  const unitText = itemData?.unitText;

  return (
    <Detail.Container>
      <Detail.Wrapper>
        <Detail.Content>
          {itemData && <ItemView itemData={itemData} />}
          <Tabs
            variant="outline"
            defaultValue="lot"
            styles={(theme) => ({
              tab: {
                "&:not([data-active])": {
                  backgroundColor: theme.colors?.gray?.[0],
                },
              },
            })}
          >
            <Tabs.List>
              <LeftSpace />
              <Tabs.Tab value="lot">LOT</Tabs.Tab>
              {(itemType === "PRODUCT" || itemType === "SEMI_PRODUCT") && (
                <Tabs.Tab value="routing">BOM</Tabs.Tab>
              )}
              {(itemType === "PRODUCT" || itemType === "SEMI_PRODUCT") && (
                <Tabs.Tab value="specifications">{t("inspect.inspectSpecification")}</Tabs.Tab>
              )}
              <RightSpace />
            </Tabs.List>

            <Tabs.Panel value="lot" style={{ overflow: "auto", maxHeight: "40rem" }}>
              <LotList lotsData={lotData} unitText={unitText} />
            </Tabs.Panel>
            {(itemType === "PRODUCT" || itemType === "SEMI_PRODUCT") && (
              <Tabs.Panel value="routing">
                <RoutingBomList formData={formData} />
              </Tabs.Panel>
            )}
            {(itemType === "PRODUCT" || itemType === "SEMI_PRODUCT") && (
              <Tabs.Panel value="specifications">
                <InspectSpecificationsByItemTable itemCode={itemCode} />
              </Tabs.Panel>
            )}
          </Tabs>
        </Detail.Content>
      </Detail.Wrapper>
    </Detail.Container>
  );
};

const ItemDetailFormContainer = styled.div`
  width: 75rem;
`;

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
