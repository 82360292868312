import { DateModal } from "@/features/ledgersAll/components/modal/DateModal";
import { useLedgersActions } from "@/features/ledgersAll/hook/useLedgersAction";
import { useLedgersState } from "@/features/ledgersAll/hook/useLedgersState";
import { useModal } from "@/features/modal/ModalStackManager";
import styled from "@emotion/styled";
import { ActionIcon, Title } from "@mantine/core";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";

export const TimeHeader = () => {
  const state: any = useLedgersState();
  const actions: any = useLedgersActions();
  const { openModal } = useModal();

  return (
    <>
      <TimeHeaderContent>
        <TimeContent>
          {dayjs(state?.Date[0] ?? undefined).format("YYYY-MM-DD")}~
          {dayjs(state?.Date[1] ?? undefined).format("YYYY-MM-DD")}
        </TimeContent>
        <CalendarContent>
          <ActionIcon
            size="lg"
            onClick={() => {
              openModal(<DateModal pageKey="ledgersAll" actions={actions} />, null, "");
            }}
          >
            <IconCalendar size="3rem" />{" "}
          </ActionIcon>
        </CalendarContent>
      </TimeHeaderContent>
    </>
  );
};

const TimeHeaderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const TimeContent = styled(Title)`
  font-family: Noto Sans;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
`;

const CalendarContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
`;
