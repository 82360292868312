import { ColumnHeaderProps } from "@/constants/columnHeader";
import { operatorMap } from "@/constants/filters";
import styled from "@emotion/styled";
import { Button, MultiSelect, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { MultiField } from "./MultiField";

export interface FormValues {
  itemCode?: string[];
  itemCategoryName?: string[];
  abstractItemCode?: string[];
  siteCode?: string[];
  operationCode?: string[];
  moldCode?: string[];
  equipmentCode?: string[];
  routingCode?: string[];
  locationCode?: string[];
  fromLocationCode?: string[];
  toLocationCode?: string[];
  userCode?: string[];
  itemType?: string[];
  routingType?: string[];
}

export interface FormTypeProps extends FormValues {
  name: string;
  label: string;
  type: string;
  content: string;
  category: string;
}

export const CustomFilterForm = (params: {
  filterType: ColumnHeaderProps[];
  onSubmit?: (values: any) => void;
}) => {
  const { filterType, onSubmit } = params;
  const [isMultiSelect, setIsMultiSelect] = useState<boolean>(false);
  const [selectedMulti, setSelectedMulti] = useState<string>("");

  const form = useForm<FormTypeProps>({
    initialValues: {
      name: "",
      label: "",
      type: "",
      content: "",
      category: "",
    },
  });

  const searchChange = (e: string, field: string) => {
    let value = e;
    if (field === "category") {
      value = filterType.find((item) => item.value === e)?.category ?? "";
    }
    form.setFieldValue(field, value);
  };

  return (
    <ModalContent>
      <Form onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}>
        <FilterSelect
          placeholder="컬럼 선택"
          data={filterType}
          {...form.getInputProps("name")}
          dropdownPosition="bottom"
          withinPortal={true}
          onChange={(e: string) => {
            searchChange(e, "name");
            searchChange(e, "category");
            // isMultiSelect가 true인 요소 찾기
            const selectedFilterType = filterType.find((item) => item.value === e);
            if (selectedFilterType?.isFK || selectedFilterType?.isEnum) {
              setIsMultiSelect((selectedFilterType?.isFK || selectedFilterType?.isEnum) ?? false);
              setSelectedMulti(selectedFilterType?.value ?? "");
              if (selectedFilterType?.value) {
                form.setFieldValue(selectedFilterType?.value, []);
              }
            } else {
              setIsMultiSelect(false);
              form.setFieldValue("type", "");
              form.setFieldValue("content", "");
            }
          }}
        />
        {!isMultiSelect ? (
          <TypeSelect
            placeholder="조건 선택"
            data={operatorMap.filter(
              (item) => item.category === form.values.category && item.label,
            )}
            {...form.getInputProps("type")}
            onSearchChange={(e: string) => searchChange(e, "label")}
            dropdownPosition="bottom"
            withinPortal={true}
          />
        ) : null}
        {!isMultiSelect ? (
          <Content placeholder="입력" {...form.getInputProps("content")} />
        ) : (
          <MultiField multiField={selectedMulti} formValue={form} />
        )}
        <Button type="submit">Submit</Button>
      </Form>
    </ModalContent>
  );
};

const ModalContent = styled.div`
  width: 620px;
  height: auto;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: top;
  position: relative;
`;

const FilterSelect = styled<any>(Select)`
  width: 30%;
`;

const TypeSelect = styled<any>(Select)`
  width: 25%;
`;

const Content = styled<any>(TextInput)`
  width: 45%;
`;

const MultiContent = styled<any>(MultiSelect)`
  width: 70%;
`;
