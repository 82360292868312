import { PropsWithStrictChildren } from "@/types/PropsWithStrictChildren";
import styled from "@emotion/styled";

export const LeftActionBar = ({ children }: PropsWithStrictChildren<any, any>) => {
  return <StyledLeftActionBar>{children}</StyledLeftActionBar>;
};

const StyledLeftActionBar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
