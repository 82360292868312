import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import { Button, Checkbox, Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Checkbox,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
});

export const SystemParameterUpdateForm = (params: any) => {
  const { formatterProps } = params;
  const { closeModal } = useModal();

  const form = useForm({
    initialValues: {
      systemKey: formatterProps.systemKey ?? undefined,
      systemValue: formatterProps.systemValue ?? undefined,
      isActive: formatterProps.isActive ?? false,
      description: formatterProps.description ?? undefined,
    },
  });

  const onSubmit = async () => {
    try {
      closeModal(form.values);

      customAlert("시스템 파라미터 수정에 실패하였습니다.", "수정 실패", "red");
    } catch (e) {
      customAlert("시스템 파라미터 수정에 실패하였습니다.", "수정 실패", "red");
    }
  };

  return (
    <Update.Wrapper>
      <Update.Input
        withAsterisk
        label="시스템 파라미터 키"
        placeholder="파라미터 키"
        {...form.getInputProps("systemKey")}
      />
      <Update.Input
        withAsterisk
        label="시스템 파라미터 키"
        placeholder="파라미터 키"
        {...form.getInputProps("systemValue")}
      />
      <Update.Checkbox label="비활성화(필수)" {...form.getInputProps("isActive")} />
      <Update.Input
        withAsterisk
        label="비고"
        placeholder="비고"
        {...form.getInputProps("description")}
      />
      <Update.ButtonBox>
        <Update.Button color="gray" onClick={closeModal}>
          취소
        </Update.Button>
        <Update.Button
          disabled={validateFormValues({
            pageType: "systemParameter",
            values: form.values,
          })}
          onClick={onSubmit}
        >
          시스템 파라미터 수정
        </Update.Button>
      </Update.ButtonBox>
    </Update.Wrapper>
  );
};
