// @fileoverview : 영업관리 > 발주항목
import { mutatePurchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { users } from "@/api/users/useUsersQuery";
import { customFunctions } from "@/config/customFunction";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { OrderDetailForm } from "@/features/order/form/detailForm";
import { OrderItemsCreateForm } from "@/features/orderItems/form/createForm";
import { DeliveryIncomingForm } from "@/features/orderItems/form/deliveryIncomingForm";
import { OrderItemsUpdateForm } from "@/features/orderItems/form/updarteForm";
import { OrderItemsTable } from "@/features/orderItems/table";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { DeliveredForm } from "@/features/purchaseOrderItems/delivered/deliveredForm";
import { ArrivalDeliveryStockPlanForm } from "@/features/purchaseOrderItems/form/arrivalDeliveryStockPlanForm";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { useLoader } from "@/hooks/useLoader";
import DatetimeUtil from "@/utils/dateTimeUtil";
import timeUtil from "@/utils/timeUtil";
import { ActionIcon, Avatar, Button, Flex, Text, Tooltip } from "@mantine/core";
import {
  AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
  PurchaseOrderItemsGet200Response,
  ScmApiPurchaseOrderItemsPurchaseOrderItemIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import {
  IconEdit,
  IconFilterPlus,
  IconPaperBag,
  IconPlus,
  IconRecycle,
  IconTrash,
} from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

const OrderItems = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  ActionButtonBox: ActionButtonBox,
  OrderItemsTable: OrderItemsTable,
  OrderItemsCreateForm: OrderItemsCreateForm,
});

const userAuth = customFunctions.ADD_ORDER_USER_AUTH;

export const OrderItemsPage = () => {
  const { data: userData } = useQuery(users.who({}));
  const userCode = userData?.data.code;
  const { t } = useTranslation();

  const { setLoading, LoadingOverlay } = useLoader();

  const orderTitle = userCode === "PB" ? t("order.order") : t("purchaseOrder.purchaseOrder"); // TODO: 다국어 처리

  const { openModal, closeModal } = useModal();
  const { query, setQuery, selectedRows, setSelectedRows, refetch, resetQueryStrings } =
    useStandardLayout();

  useEffect(() => {
    if (query.length === 0) {
      // query가 비어 있을 때만 실행
      setQuery([
        { direction: { $eq: "OUTBOUND" } },
        {
          ...(userAuth
            ? {}
            : {
                $or: [
                  { creatorUserCode: userCode },
                  { creatorUserCode: "FLOUR" }, // 추후 수정 필요
                  { partnerCode: userCode },
                ],
              }),
        },
      ]);
    }
  }, [query, userCode, userAuth]);

  const { mutate: deleteMutate } = useMutation(
    (params: ScmApiPurchaseOrderItemsPurchaseOrderItemIdDeleteRequest) =>
      mutatePurchaseOrderItems
        .delete(params)
        .mutationFn(params as any | ScmApiPurchaseOrderItemsPurchaseOrderItemIdDeleteRequest),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          `${t("message.deleteSuccess", { item: `${t("orderItems.orderItems")}` })}`,
          t("common.deleteSuccess"),
          "green",
        );
      },
      onError: () => {
        customAlert(
          `${t("message.deleteFail", { item: `${t("orderItems.orderItems")}` })}`,
          t("common.deleteFail"),
          "red",
        );
      },
    },
  );

  const columns: readonly Column<AuthSignupPost201ResponseEquipmentPurchaseOrderItem>[] = [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "partner.name",
      name: t("partner.name"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <PartnerDetailForm partnerCode={formatterProps.row.partnerCode} />,
                null,
                `${t("common.detail", { item: `${t("partner.partner")}` })}`,
              )
            }
          >
            {formatterProps.row.partner?.name}
          </LinkAnchor>
        );
      },
    },
    {
      key: "purchaseOrderId",
      name: `${userCode === "FLOUR" ? t("purchaseOrder.id") : t("order.id")}`,
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <OrderDetailForm purchaseOrderId={formatterProps?.row.purchaseOrderId} />,
                null,
                `${userCode === "FLOUR" ? `${t("common.detail", { item: `${t("purchaseOrder.purchaseOrder")}` })}` : `${t("common.detail", { item: `${t("order.order")}` })}`}`,
              )
            }
          >
            {formatterProps.row.purchaseOrderId}
          </LinkAnchor>
        );
      },
    },
    {
      key: "itemName",
      name: t("item.name"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <ItemsDetailForm itemCode={formatterProps?.row.itemCode} />,
                null,
                `${t("common.detail", { item: `${t("item.item")}` })}`,
              )
            }
          >
            {formatterProps.row.itemName}
          </LinkAnchor>
        );
      },
    },
    {
      key: "quantity",
      name: t("orderItems.quantity"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <Flex justify="flex-end">{formatterProps.row.quantity}</Flex>;
      },
    },
    {
      key: "inputEligibleQuantity",
      name: t("orderItems.inputEligibleQuantity"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <Flex justify="flex-end">{formatterProps.row.inputEligibleQuantity}</Flex>;
      },
    },
    {
      key: "sumOfStockPlanQuantity",
      name: t("orderItems.sumOfStockPlanQuantity"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <Flex justify="flex-end">{formatterProps.row.sumOfStockPlanQuantity}</Flex>;
      },
    },
    {
      key: "sumOfDeliveredQuantity",
      name: t("orderItems.sumOfDeliveryQuantity"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Flex h="100%" justify="flex-end" align="center">
            {formatterProps.row.sumOfDeliveredQuantity === "0" ? (
              <Text>{formatterProps.row.sumOfDeliveredQuantity}</Text>
            ) : (
              <LinkAnchor
                onClick={() =>
                  openModal(
                    <DeliveredForm purchaseOrderItemID={formatterProps?.row?.id} />,
                    null,
                    `${t("common.detail", { item: `${t("delivery.deliveryComplete")}` })}`,
                  )
                }
              >
                {formatterProps.row.sumOfDeliveredQuantity}
              </LinkAnchor>
            )}
          </Flex>
        );
      },
    },
    {
      key: "manager",
      name: t("order.manager"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <UsersDetailForm UserCode={formatterProps.row.userCode} />,
                null,
                `${t("common.detail", { item: `${t("order.manager")}` })}`,
              )
            }
          >
            {formatterProps.row.manager}
          </LinkAnchor>
        );
      },
    },
    {
      key: "createdAt",
      name: t("orderItems.createdAt"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Flex>
            {formatterProps.row.createdAt !== null
              ? DatetimeUtil(formatterProps.row.createdAt as string)
              : ""}
          </Flex>
        );
      },
    },
    {
      key: "deliveryDeadline",
      name: t("orderItems.deliveryDeadline"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Flex>
            {formatterProps.row.deliveryDeadline !== null
              ? timeUtil(formatterProps.row.deliveryDeadline as string)
              : ""}
          </Flex>
        );
      },
    },
    {
      key: "options",
      name: t("common.action"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updatePurchaseOrderActionView = () => {
          openModal(
            <OrderItemsUpdateForm formatterProps={formatterProps?.row} />,
            null,
            t("common.edit", { item: `${t("order.order")}` }),
            true,
          );
        };

        const deletePurchaseOrderAction = () => {
          openModal(
            <ConfirmForm
              message={t("message.rowDelete")}
              yesCallback={() =>
                deleteMutate({ purchaseOrderItemId: formatterProps?.row.id as number })
              }
              noCallback={() => {}}
              commonCallback={() => closeModal(null)}
            />,
            null,
            t("common.delete", { item: `${t("orderItems.orderItems")}` }),
          );
        };

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon variant="subtle" onClick={updatePurchaseOrderActionView} color="yellow">
              <IconEdit />
            </ActionIcon>
            <ActionIcon variant="subtle" onClick={() => deletePurchaseOrderAction()} color="red">
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: `${t("purchaseOrder.purchaseOrder")}` })}`,
        `${t("common.deleteFail", { item: `${t("purchaseOrder.purchaseOrder")}` })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ purchaseOrderItemId: JSON.parse(row).id });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      `${t("common.delete", { item: `${orderTitle}` })}`,
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <LoadingOverlay />
      <Flex p="sm" justify="space-between" rowGap="md">
        <Flex gap="sm">
          <Button
            leftIcon={<IconPlus />}
            onClick={() =>
              openModal(
                <OrderItems.OrderItemsCreateForm />,
                null,
                `${userCode === "FLOUR" ? t("common.additional", { item: `${t("purchaseOrderItems.purchaseOrderItems")}` }) : t("common.additional", { item: `${t("orderItems.orderItems")}` })}`,
              )
            }
          >
            {t("orderItems.create")}
          </Button>
          <Button
            leftIcon={<IconPaperBag />}
            color="green"
            disabled={!([...selectedRows].length === 1)}
            onClick={() =>
              openModal(
                <ArrivalDeliveryStockPlanForm
                  formatterProps={JSON.parse([...selectedRows].at(0))}
                />,
                null,
                t("orderItems.incomingStockPlan"),
                true,
              ).then((result) => {
                if (result) {
                  // 로직이 정상적으로 처리되면 선택된 행을 초기화
                  setSelectedRows((): Set<any> => new Set());
                }
              })
            }
          >
            {t("orderItems.incomingStockPlan")}
          </Button>
          {!customFunctions.ADD_ORDER_ITEMS_INCOMING_BUTTON && (
            <Button
              leftIcon={<IconPlus />}
              color="green"
              disabled={!([...selectedRows].length === 1)}
              onClick={() =>
                openModal(
                  <DeliveryIncomingForm
                    purchaseOrder={JSON.parse([...selectedRows].at(0))}
                    setLoading={setLoading}
                  />,
                  null,
                  t("inventory.incoming"),
                ).then((result) => {
                  if (result) {
                    // 로직이 정상적으로 처리되면 선택된 행을 초기화
                    setSelectedRows((): Set<any> => new Set());
                  }
                })
              }
            >
              {t("inventory.incoming")}
            </Button>
          )}
        </Flex>
        <Flex gap="sm">
          <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
            {t("common.reset")}
          </Button>
          <Button rightIcon={<IconTrash />} color="red" onClick={() => handleDelete()}>
            {t("common.rowDelete")}
          </Button>
        </Flex>
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", {
            item: `${userCode === "FLOUR" ? t("purchaseOrderItems.purchaseOrderItems") : t("orderItems.orderItems")}`,
          })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${t("orderItems.description")}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<
            PurchaseOrderItemsGet200Response,
            AuthSignupPost201ResponseEquipmentPurchaseOrderItem
          >
            columns={columns}
            rowKeyGetter={(row: AuthSignupPost201ResponseEquipmentPurchaseOrderItem) =>
              JSON.stringify(row)
            }
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<PurchaseOrderItemsGet200Response> />
      </Flex>
    </Flex>
  );
};
