import { Flex, Text } from "@mantine/core";

interface ColumnFlexProps {
  title: string;
  data?: string;
  dataUnit?: string;
  height?: string;
}

export const ColumnFlex = ({ title, data, height, dataUnit }: ColumnFlexProps) => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      style={{ backgroundColor: "#f5f5f5", borderRadius: "10px" }}
      w="9rem"
      h={height ? height : "3rem"}
    >
      <Text size="sm">{title}</Text>
      <Text size="sm">{data}</Text>
      {dataUnit && <Text size="sm">{dataUnit}</Text>}
    </Flex>
  );
};
