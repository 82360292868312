import { MasterInstance } from "@/instance/axios";
import { MoldsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export type MoldOption = {
  value: string;
  label: string;
  group: string;
};

const getAutoCompleteKeyword = () =>
  MasterInstance.moldsFindPost().then(
    (res: AxiosResponse<MoldsGet200ResponseRowsInner[]>) => res.data,
  );

export const useMoldsAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: MoldOption[]) => void;
  },
) => {
  return useQuery(["molds-get-autocomplete"], () => getAutoCompleteKeyword(), {
    enabled: focus,
    select: (data: MoldsGet200ResponseRowsInner[]) =>
      data?.map((mold) => {
        return {
          value: String(mold.code),
          label: String(mold.code),
          group: String(mold.siteCode),
        };
      }) ?? [],
    onSuccess: options?.onSuccess,
  });
};

const getMoldsByCode = (code: string | null) =>
  MasterInstance.moldsFindPost({
    ...(code && { search: code }),
    searchFields: ["code"],
  }).then((res: AxiosResponse<MoldsGet200ResponseRowsInner[]>) => res.data);

export const useMoldsCode = (
  enabled: boolean,
  code: string | null,
  options?: {
    onSuccess: (data?: MoldOption[]) => void;
  },
) => {
  return useQuery(["molds-get-code", code], () => getMoldsByCode(code), {
    enabled: enabled ?? false,
    select: (data: MoldsGet200ResponseRowsInner[]) =>
      data?.map((route) => {
        return {
          value: String(route.code),
          label: String(route.code),
          group: String(route.siteCode),
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
