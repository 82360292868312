import DefaultInstance, { ScmInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import {
  DefaultApiWmsBulkInvoicePostRequest,
  DefaultApiWmsInvoicePostRequest,
  ScmApiInvoicesGetRequest,
  ScmApiInvoicesInvoiceIdGetRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const invoice = createQueryKeys("invoice", {
  all: null,
  get: (params: ScmApiInvoicesGetRequest) => {
    const queryParams = {
      ...params,
      query: JSON.stringify({
        $and: [...(params?.query || [])],
        status: {
          $ne: "DONE",
        },
      }),
      sort: params.sort?.length ? params.sort : "-createdAt",
    };

    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => ScmInstance.invoicesGet(queryParams),
    };
  },
  complete: (params: ScmApiInvoicesGetRequest) => {
    const queryParams = {
      ...params,
      query: JSON.stringify({
        $and: [...(params?.query || [])],
        status: {
          $eq: "DONE",
        },
      }),
      sort: params.sort?.length ? params.sort : "-createdAt",
    };

    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => ScmInstance.invoicesGet(queryParams),
    };
  },
  detail: (params: ScmApiInvoicesInvoiceIdGetRequest) => {
    return {
      queryKey: [params],
      queryFn: () => ScmInstance.invoicesInvoiceIdGet(params),
    };
  },
});

export const mutateInvoice = createMutationKeys("invoice", {
  invoice: (params: DefaultApiWmsInvoicePostRequest) => {
    return {
      mutationKey: [params.wmsInvoicePostRequest],
      mutationFn: () => DefaultInstance.wmsInvoicePost(params),
    };
  },
  bulkInvoice: (params: DefaultApiWmsBulkInvoicePostRequest) => {
    return {
      mutationKey: [params.wmsBulkInvoicePostRequest],
      mutationFn: () => DefaultInstance.wmsBulkInvoicePost(params),
    };
  },
});
