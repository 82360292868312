import { customFunctions } from "@/config/customFunction";
import { itemTypeObj } from "@/constants/items";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { useLoader } from "@/hooks/useLoader";
import DefaultInstance from "@/instance/axios";
import { dataStyle, excelDownLoad, headerStyle } from "@/utils/excelDownLoad";
import { setToLocaleString } from "@/utils/unitMark";
import { setWorkStatus } from "@/utils/workStatus";
import { Button, Flex } from "@mantine/core";
import {
  ItemsGet200ResponseRowsInnerItemTypeEnum,
  WorkLogsWorksGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconFileSpreadsheet, IconRecycle } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export const ActionHeader = () => {
  const { LoadingOverlay, setLoading } = useLoader();
  const { query, search, searchFields, searchFieldsHeader, sort, populate, resetQueryStrings } =
    useStandardLayout();
  const { t } = useTranslation();

  const excelDownLoadAction = async () => {
    try {
      setLoading(true);

      const response = await DefaultInstance.workLogsWorksFindPost({
        query: JSON.stringify({ $and: query }),
        search,
        searchFields: searchFields.length
          ? searchFields
          : searchFieldsHeader
              .filter((item) => item.category === "text" && !item.isEnum)
              .map((item) => item.value),
        ...(sort.length ? { sort: sort.join(",") } : { sort: "-createdAt" }),
        populate: populate && populate.length ? populate : [],
      });

      const data = response.data;

      const headerRow = [
        { v: "로그번호", t: "s", s: headerStyle },
        ...(customFunctions.ADD_WORK_LOG_ERP_WORK_ORDER_COLUMN
          ? [{ v: "ERP 작업번호", t: "s", s: headerStyle }]
          : []),
        { v: "일시", t: "s", s: headerStyle },
        { v: "설비코드(설비명)", t: "s", s: headerStyle },
        { v: "라우팅코드", t: "s", s: headerStyle },
        { v: "공정코드", t: "s", s: headerStyle },
        { v: "타입", t: "s", s: headerStyle },
        { v: "수량", t: "s", s: headerStyle },
        { v: "양품수량", t: "s", s: headerStyle },
        { v: "품목코드", t: "s", s: headerStyle },
        { v: "품목명", t: "s", s: headerStyle },
        { v: "규격", t: "s", s: headerStyle },
        { v: "품목분류", t: "s", s: headerStyle },
        { v: "품목유형", t: "s", s: headerStyle },
        { v: "단위", t: "s", s: headerStyle },
        { v: "로트명", t: "s", s: headerStyle },
        { v: "로트 유효기한", t: "s", s: headerStyle },
        { v: "투입 로케이션", t: "s", s: headerStyle },
        { v: "작업지시번호", t: "s", s: headerStyle },
        { v: "생산계획번호", t: "s", s: headerStyle },
        { v: "작업자", t: "s", s: headerStyle },
      ];

      const dataRows =
        data.map((item: WorkLogsWorksGet200ResponseRowsInner) => [
          { v: item.id ? item.id.toString() : "", t: "s", s: dataStyle },
          ...(customFunctions.ADD_WORK_LOG_ERP_WORK_ORDER_COLUMN
            ? [
                {
                  v: item.erpWorkOrderNo ? item.erpWorkOrderNo.toString() : "",
                  t: "s",
                  s: dataStyle,
                },
              ]
            : []),
          {
            v: item.createdAt ? dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") : "-",
            t: "s",
            s: dataStyle,
          },
          {
            v: `${item.equipmentCode ?? ""}(${item?.equipment?.name ?? ""})`,
            t: "s",
            s: dataStyle,
          },
          {
            v: item.routingCode ? item.routingCode.toString() : "",
            t: "s",
            s: dataStyle,
          },
          {
            v: item.operationCode ? item.operationCode.toString() : "",
            t: "s",
            s: dataStyle,
          },
          {
            v: `${t(setWorkStatus(item.workLogType))}${item.workLogType === "TIME_TRACKING_PAUSE" ? `(${item.downtimeReasonName})` : item.workLogType === "DEFECT" ? `(${item.defectName})` : ""}`,
            t: "s",
            s: dataStyle,
          },
          {
            v:
              item.quantity !== null && item.quantity !== undefined
                ? item.workLogType === "CONSUME_IDENTIFIED"
                  ? Number(item.quantity ? -item.quantity : 0)
                  : Number(item.quantity ? item.quantity : 0)
                : 0,
            t: "n",
            s: { ...dataStyle, numFmt: "#,##0" },
          },
          {
            v: ["PRODUCTION", "LOSS", "CANCEL_PRODUCTION", "CANCEL_LOSS"].includes(
              item?.workLogType ?? "",
            )
              ? `${setToLocaleString(item?.currentLotSummary?.totalQuantity)} ${item.unitText}`
              : "",
            t: "s",
            s: dataStyle,
          },
          {
            v: item.logData?.incoming?.itemCode ?? item.logData?.outgoing?.itemCode ?? "",
            t: "s",
            s: dataStyle,
          },
          {
            v: item.logData?.incoming
              ? (item.logData?.incoming.itemData?.name ?? "")
              : (item.logData?.outgoing?.itemData?.name ?? ""),
            t: "s",
            s: dataStyle,
          },
          {
            v:
              item.logData?.incoming?.itemData?.spec ??
              item.logData?.outgoing?.itemData?.spec ??
              "",
            t: "s",
            s: dataStyle,
          },
          {
            v: item.itemData?.itemCategoryName ?? "",
            t: "s",
            s: dataStyle,
          },
          {
            v:
              itemTypeObj[
                (item.itemData?.itemType as ItemsGet200ResponseRowsInnerItemTypeEnum) ?? ""
              ] ?? "",
            t: "s",
            s: dataStyle,
          },
          {
            v: item.itemData?.unitText ?? "",
            t: "s",
            s: dataStyle,
          },
          {
            v: item.logData?.incoming?.lotData?.name ?? item.logData?.outgoing?.lotData?.name ?? "",
            t: "s",
            s: dataStyle,
          },
          {
            v: item.logData?.incoming?.lotData?.expiration
              ? dayjs(item.logData?.incoming?.lotData.expiration).format("YYYY-MM-DD HH:mm:ss")
              : item.logData?.outgoing?.lotData?.expiration
                ? dayjs(item.logData?.outgoing?.lotData.expiration).format("YYYY-MM-DD HH:mm:ss")
                : "",
            t: "s",
            s: dataStyle,
          },
          {
            v:
              item.logData?.incoming?.locationData?.name ??
              item.logData?.outgoing?.locationData?.name ??
              "",
            t: "s",
            s: dataStyle,
          },
          { v: item.workId ? item.workId.toString() : "", t: "s", s: dataStyle },
          {
            v: item.productionPlanId ? item.productionPlanId.toString() : "",
            t: "s",
            s: dataStyle,
          },
          { v: item.creatorUserName ?? "", t: "s", s: dataStyle },
        ]) ?? [];

      await excelDownLoad({
        headerRow,
        dataRows,
        colWidths: [
          120,
          ...(customFunctions.ADD_WORK_LOG_ERP_WORK_ORDER_COLUMN ? [120] : []),
          120,
          120,
          120,
          120,
          120,
          120,
          120,
          120,
          120,
          120,
          120,
          120,
          120,
          120,
          120,
          120,
          120,
          120,
          120,
        ],
        fileName: "작업일지",
      });
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex w="100%" justify="flex-end" wrap="wrap" gap="sm">
      <LoadingOverlay />
      <Button
        rightIcon={<IconFileSpreadsheet />}
        color="teal"
        onClick={() => excelDownLoadAction()}
      >
        {t("inventory.excelDownload")}
      </Button>
      <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
        {t("common.reset")}
      </Button>
    </Flex>
  );
};
