import { wmsLog } from "@/api/delivery/useDeliveryQuery";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { setWmsSatus, wmsStatusColor } from "@/utils/wmsStatus";
import styled from "@emotion/styled";
import { Badge, Flex, Table, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  LotTrackingSummaryContentProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "../forward/components/forwardSummary";
import { useLotTrackingState } from "../hook/useLotTrackingState";

const Detail = Object.assign(DetailHeader, {
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
});

export const LotTrackingLedgerTrackingContent = (props: LotTrackingSummaryContentProps) => {
  const { consumedLotData, reverce } = props;
  const { openModal } = useModal();
  const { t } = useTranslation();
  const state = useLotTrackingState();

  const consumedLotId = Array.from(
    new Set(
      consumedLotData?.map((lot) => {
        return lot.lotId;
      }),
    ),
  );

  const orQuery = consumedLotId.map((lot) => {
    return {
      actionParam: {
        params: {
          lotId: { $eq: lot },
        },
      },
    };
  });

  const stateData = useQuery({
    ...wmsLog.find({
      query: {
        $and: [
          { actionType: { $eq: "OUTGOING" } },
          state.productionPlan?.lotId
            ? { "actionParam.params.lotId": { $eq: state.productionPlan?.lotId } }
            : {
                $or: orQuery,
              },
          ,
        ],
      },
      populate: ["name", "wmsLogData", "purchaseOrderItem"],
    }),
  });

  const wmsLogsData = stateData.data?.data;

  return (
    <Container>
      <Title p={8} order={3}>
        {t("lotTracking.tracking")} ({t("inventory.outgoing")})
      </Title>
      <Text fz={12} px={8}>
        {reverce
          ? t("lotTracking.reverceOutgoingTracking")
          : t("lotTracking.forwardOutgoingTracking")}
      </Text>
      <TableContainer>
        <Table>
          <Thead backgroundColor="var(--indigo-0, #EDF2FF)">
            <Tr>
              <Th flex={1}>{t("workLog.createdAt")}</Th>
              <Th flex={1}>{t("lotTracking.type")}</Th>
              <Th flex={1}>{t("inventory.quantity")}</Th>
              <Th flex={1}>{t("location.location")}</Th>
              <Th flex={1}>{t("workLog.worker")}</Th>
              <Th flex={1}>{t("routing.descript")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {wmsLogsData?.length && wmsLogsData?.length > 0 ? (
              wmsLogsData.map((row: any, index: number) => {
                return (
                  <Tr key={index}>
                    <Td flex={1}>{DatetimeUtil(row?.createdAt ?? "")}</Td>
                    <Td flex={1}>
                      <Badge
                        size="lg"
                        variant="outline"
                        color={wmsStatusColor[row?.actionType as keyof typeof wmsStatusColor]}
                      >
                        {setWmsSatus(row?.actionType ?? "")}
                      </Badge>
                    </Td>
                    <Td flex={1} style={{ textAlign: "right" }}>
                      {row?.actionParam?.params?.quantity}
                    </Td>
                    <Td flex={1}>
                      <Detail.Link
                        onClick={() =>
                          openModal(
                            <LocationDetailForm
                              locationCode={row?.actionParam?.params?.locationCode}
                            />,
                            "",
                            "",
                          )
                        }
                      >
                        {row?.actionParam?.params?.locationCode}
                      </Detail.Link>
                    </Td>
                    <Td flex={1}>
                      <Detail.Link
                        onClick={() =>
                          openModal(<UsersDetailForm UserCode={row?.creatorUserCode} />, "", "")
                        }
                      >
                        {row?.creatorUserCode}
                      </Detail.Link>
                    </Td>
                    <Td flex={1}>
                      {row?.actionType === "OUTGOING" && row?.purchaseOrderItemId
                        ? `${t("lotTracking.purchaseOutgoing")} ${row?.purchaseOrderItemId}`
                        : ""}
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td colSpan={6}>
                  <Flex justify={"center"}>
                    <Text px={8}>{t("common.notFound")}</Text>
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  max-height: 334px; /* 최대 높이를 설정하여 3개 이상의 Tr 요소가 있을 때 스크롤이 생기도록 함 */
  padding: 8px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: var(--indigo-0, #edf2ff);
  overflow: auto; /* 높이가 초과될 경우 스크롤을 활성화 */
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto; /* 테이블 컨테이너에 스크롤을 추가 */
`;
