import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlayerPause, IconPlayerPlay, IconRepeat, IconX } from "@tabler/icons-react";

export type StatusObjType = {
  [key: string]: string;
};

export const trackingStatusColor: StatusObjType = {
  INPUT: "blue.7",
  CANCEL_INPUT: "red.7",
  CONSUME: "green.7",
  CONSUME_IDENTIFIED: "purple.7",
  CANCEL_CONSUME_IDENTIFIED: "yellow.7",
  PROGRESS: "orange.7",
  PRODUCTION: "teal.7",
  DEFECT: "pink.7",
  LOSS: "indigo.7",
  WIP_DECREASE: "cyan.7",
  REWORK: "lime.7",
  RESTORE: "lime.5",
  WIP_INCREASE: "deepPurple.7",
  START: "blue.6",
  END: "red.6",
  TIME_TRACKING_START: "green.6",
  TIME_TRACKING_END: "orange.6",
  TIME_TRACKING_PAUSE: "orange.6",
  TIME_TRACKING_RESUME: "lime.6",
  CANCEL_CONSUME: "pink.5",
  CANCEL_PROGRESS: "green.5",
  CANCEL_PRODUCTION: "blue.5",
  CANCEL_DEFECT: "red.5",
  CANCEL_REWORK: "blue.5",
  CANCEL_START: "orange.6",
  CANCEL_END: "cyan.6",
  CANCEL_LOSS: "indigo.6",
  PERFORMANCE: "green.5",
  CANCEL_WIP_DECREASE: "purple.6",
  SPLIT_LOT: "teal.6",
  CORRECTION: "teal.4",
  CANCEL_SPLIT_LOT: "skyBlue.7",
  CANCEL_CORRECTION: "skyBlue.8",
  CANCEL_PERFORMANCE: "green.4",
  RECYCLE: "green.4",
};

export const statusObj: StatusObjType = {
  INPUT: "workStatus.input",
  CANCEL_INPUT: "workStatus.cancelInput",
  CONSUME: "workStatus.consume",
  CONSUME_IDENTIFIED: "workStatus.consumeIdentified",
  CANCEL_CONSUME_IDENTIFIED: "workStatus.cancelConsumeIdentified",
  PROGRESS: "workStatus.progress",
  PRODUCTION: "workStatus.production",
  DEFECT: "workStatus.defect",
  LOSS: "workStatus.loss",
  WIP_DECREASE: "workStatus.wipDecrease",
  // REWORK: "복원",
  // RESTORE: "복원",
  WIP_INCREASE: "workStatus.wipIncrease",
  START: "workStatus.start",
  END: "workStatus.end",
  TIME_TRACKING_START: "workStatus.timeTrackingStart",
  TIME_TRACKING_END: "workStatus.timeTrackingEnd",
  TIME_TRACKING_PAUSE: "workStatus.timeTrackingPause",
  TIME_TRACKING_RESUME: "workStatus.timeTrackingResume",
  CANCEL_CONSUME: "workStatus.cancelConsume",
  CANCEL_PROGRESS: "workStatus.cancelProgress",
  CANCEL_PRODUCTION: "workStatus.cancelProduction",
  CANCEL_DEFECT: "workStatus.cancelDefect",
  // CANCEL_REWORK: "복원 취소",
  CANCEL_START: "workStatus.cancelStart",
  CANCEL_END: "workStatus.cancelEnd",
  CANCEL_LOSS: "workStatus.cancelLoss",
  PERFORMANCE: "workStatus.performance",
  CANCEL_WIP_DECREASE: "workStatus.cancelWipDecrease",
  SPLIT_LOT: "workStatus.splitLot",
  CORRECTION: "workStatus.correction",
  CANCEL_SPLIT_LOT: "workStatus.cancelSplitLot",
  CANCEL_CORRECTION: "workStatus.cancelCorrection",
  CANCEL_PERFORMANCE: "workStatus.cancelPerformance",
  RECYCLE: "workStatus.recycle",
};

export const setWorkStatus = (
  status: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum | undefined,
): string => {
  if (!status) return "";
  return statusObj[status];
};

export const setStatusIcon = (status: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum) => {
  const color = trackingStatusColor[status];

  switch (status) {
    case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_START:
      return { icon: IconPlayerPlay, color };
    case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_END:
      return { icon: IconX, color };
    case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_PAUSE:
      return { icon: IconPlayerPause, color };
    case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_RESUME:
      return { icon: IconRepeat, color };
    default:
      return { icon: IconX, color };
  }
};
