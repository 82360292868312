import { Chip, Group } from "@mantine/core";

interface ChipsBoxProps {
  children: React.ReactNode;
}

export const ChipsBox = ({ children }: ChipsBoxProps) => {
  return (
    <Chip.Group multiple>
      <Group>{children}</Group>
    </Chip.Group>
  );
};
