import { invoice, mutateInvoice } from "@/api/invoiceStatement/useInvoiceQuery";
import { LeftSpace } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { useLoader } from "@/hooks/useLoader";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Button, Tabs } from "@mantine/core";
import { DefaultApiWmsInvoicePostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconMinus } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { InvoiceStatementDetailHeader } from "./detailHeader";
import { InvoiceStatementDetailTable } from "./detailTable";

interface InvoiceStatementItemsSelectFormProps {
  invoiceId: number | undefined;
}

const Detail = Object.assign(
  {},
  {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
    ActionHeader: ActionHeader,
    ActionButtonBox: ActionButtonBox,
    ActionButton: Button,
    ActionBar: InvoiceStatementDetailHeader,
  },
);

export const InvoiceStatementDetailForm = (params: InvoiceStatementItemsSelectFormProps) => {
  const { invoiceId } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { LoadingOverlay, setLoading } = useLoader();
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const selectedKeys = Object.keys(rowSelection).filter((key) => rowSelection[key]);

  const { mutate: invoiceMutate } = useMutation(
    (params: DefaultApiWmsInvoicePostRequest) =>
      mutateInvoice.invoice(params).mutationFn(params as DefaultApiWmsInvoicePostRequest | any),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: (res) => {
        const success = res.data?.success;
        const failed = res.data?.fail;
        setRowSelection({});
        setLoading(false);
        queryClient.invalidateQueries(["invoice"]);
        closeModal(null);
        customAlert(
          `${success?.length ? `거래명세서 ID ${success}이 출고에 성공하였습니다.` : ""} 
                    ${failed?.length ? `거래명세서 ID ${failed}이 출고에 실패하였습니다.` : ""}`,
          "거래명세서 출고 성공",
          "green",
        );
      },
      onError: (error: any) => {
        setLoading(false);
        customAlert(
          handleErrorResponse({ error, errorContext: "거래명세서 출고" }),
          "거래명세서 출고 실패",
          "red",
        );
      },
    },
  );
  const { data } = useQuery(
    invoice.detail({
      invoiceId: invoiceId ?? 0,
      populate: [
        "populateLocationName",
        "populateUserName",
        "populateNumberOfInvoiceItems",
        "invoiceItems",
      ],
    }),
  );

  const arrayInvoiceId = [...selectedKeys].map((row) => JSON.parse(row));
  const selectedRowsArray =
    data?.data?.invoiceItems?.filter((row) => arrayInvoiceId.includes(row.id)) ?? [];

  // 선택한 데이터의 outgoingQuantity가 quantity보다 크거나 같은지 확인
  const isDisabled = selectedRowsArray.some(
    (row) => row.outgoingQuantity && row.outgoingQuantity >= (row.quantity ?? 0),
  );

  const invoiceOutgoingSubmit = () => {
    openModal(
      <ConfirmForm
        message={`선택한 거래명세서ID ${arrayInvoiceId}를 출고하시겠습니까?`}
        yesCallback={() => {
          invoiceMutate({
            wmsInvoicePostRequest: {
              invoiceItemIds: arrayInvoiceId,
            },
          });
        }}
        noCallback={() => {
          closeModal(null);
        }}
        commonCallback={() => {}}
      />,
      null,
      "거래명세서 출고",
    );
  };

  return (
    <Detail.Container>
      <LoadingOverlay />
      <Detail.Wrapper>
        <Detail.Content>
          {/* <OrderDetailDataHeader /> */}
          <InvoiceStatementDetailHeader data={data?.data} />
          <Tabs defaultValue={"invoiceItems"}>
            <LeftSpace />
            <Tabs.List>
              <Tabs.Tab value={"invoiceItems"}>거래명세서 항목</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value={"invoiceItems"}>
              <Detail.ActionHeader>
                <Detail.ActionButtonBox>
                  <Detail.ActionButton
                    leftIcon={<IconMinus />}
                    color="red"
                    onClick={invoiceOutgoingSubmit}
                    disabled={arrayInvoiceId.length === 0 || isDisabled}
                  >
                    출고
                  </Detail.ActionButton>
                </Detail.ActionButtonBox>
              </Detail.ActionHeader>
              <InvoiceStatementDetailTable
                data={data}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
              />
            </Tabs.Panel>
          </Tabs>
        </Detail.Content>
      </Detail.Wrapper>
    </Detail.Container>
  );
};
