import { operations } from "@/api/operations/useOperationsQuery";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { OperationsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { DetailHeader } from "../header/operationDetailHeader";

interface OperationDetailFormProps {
  operationCode: string | undefined;
}
export const OperationDetailForm = ({ operationCode }: OperationDetailFormProps) => {
  const { data: operation } = useQuery(
    operations.get({
      query: {
        $and: [{ code: operationCode }],
      },
      populate: ["location"],
    }),
  );
  const operationData = operation?.data?.rows?.[0] as OperationsGet200ResponseRowsInner;

  return (
    <OperationDetailFormContainer>
      <OperationContainer>
        <OperationWrapper>
          <DetailHeader operationData={operationData} />
        </OperationWrapper>
      </OperationContainer>
    </OperationDetailFormContainer>
  );
};

const OperationDetailFormContainer = styled.div`
  width: 75rem;
`;

const OperationContainer = styled.div`
  padding: 8px;
  background: ${theme.colors?.gray?.[2]};
`;

const OperationWrapper = styled.div`
  background-color: white;
`;
