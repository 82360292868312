import { InspectSpecificationCreateForm } from "@/features/inspect/components/inspectSpecification/form/InspectSpecificationCreateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";

interface RoutingDetailActionHeaderProps {
  data?: ItemsGet200ResponseRowsInnerRoutingsInner;
}

export const RoutingDetailActionHeader = (params: RoutingDetailActionHeaderProps) => {
  const { data } = params;
  const { openModal } = useModal();

  return (
    <ActionBarContainer>
      <LeftActionBar>
        <Button
          variant="outline"
          onClick={() => openModal(<InspectSpecificationCreateForm data={data} />, "", "", true)}
        >
          초중종 검사 표준 생성
        </Button>
      </LeftActionBar>
      <RightActionBar>
        {/* <Button rightIcon={<IconEdit />} color="orange">
          수정
        </Button> */}
      </RightActionBar>
    </ActionBarContainer>
  );
};

const ActionBarContainer = styled.div`
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  background: inherit;
`;

const LeftActionBar = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const RightActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
`;
