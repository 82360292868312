import { mutateEquipment } from "@/api/equipment/useEquipmentQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { DowntimeReasonsMultiAutoComplete } from "@/features/ui/multiAutoComplete/downtimeReason/downtimeReason-multiAutoComplete";
import { UsersMultiAutoComplete } from "@/features/ui/multiAutoComplete/user/user-multiAutoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, NumberInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiEquipmentsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface EquipmentCreateFormProps {
  name?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}
export const EquipmentCreateForm = (params: EquipmentCreateFormProps) => {
  const { name, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: postMutate } = useMutation(
    (params: MasterApiEquipmentsPostRequest) =>
      mutateEquipment.create(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["equipment"]);
        closeModal(form.values);
        customAlert(
          `${t("message.createSuccess", { item: `${t("equipment.equipment")}` })}`,
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("equipment.code") }),
          `${t("common.createFail", { item: `${t("equipment.equipment")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      siteCode: "",
      code: "",
      name: name ?? "",
      equipmentType: undefined,
      volt: undefined,
      seq: undefined,
      fromLocationCode: undefined,
      toLocationCode: undefined,
      targetLocationCode: undefined,
      downtimeReasonCode: undefined,
      workerGroup: [] as string[],
    },
  });

  const onSubmit = async () => {
    try {
      postMutate({
        equipmentsGetRequest: {
          siteCode: form?.values?.siteCode,
          code: form.values.code,
          name: name ?? form.values.name,
          equipmentType: form.values.equipmentType,
          volt: form.values.volt,
          seq: form.values.seq,
          fromLocationCode: form.values.fromLocationCode,
          toLocationCode: form.values.toLocationCode,
          targetLocationCode: form.values.targetLocationCode,
          downtimeReasonCode: form.values.downtimeReasonCode,
          workerGroup: form.values.workerGroup,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("equipment.code") }),
        `${t("common.createFail", { item: `${t("equipment.equipment")}` })}`,
        "red",
      );
    }
  };

  return (
    <Box w={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <SitesAutoComplete
            withAsterisk
            label={t("site.code")}
            width={"100%"}
            placeholder={t("placeholder.select", { item: `${t("site.code")}` })}
            {...form.getInputProps("siteCode")}
          />
          <TextInput
            withAsterisk
            label={t("equipment.code")}
            placeholder={t("placeholder.input", { item: `${t("equipment.code")}` })}
            {...form.getInputProps("code")}
          />
          <TextInput
            withAsterisk
            label={t("equipment.name")}
            {...form.getInputProps("name")}
            placeholder={t("placeholder.input", { item: `${t("equipment.name")}` })}
          />
          <TextInput
            label={t("equipment.type")}
            placeholder={t("placeholder.input", { item: `${t("equipment.type")}` })}
            {...form.getInputProps("equipmentType")}
          />
          <NumberInput
            label={t("equipment.volt")}
            placeholder={t("placeholder.input", { item: `${t("equipment.volt")}` })}
            {...form.getInputProps("volt")}
          />
          <LocationAutoComplete
            label={t("equipment.fromLocation")}
            placeholder={t("placeholder.select", { item: `${t("equipment.fromLocation")}` })}
            {...form.getInputProps("fromLocationCode")}
          />

          <LocationAutoComplete
            label={t("equipment.toLocation")}
            placeholder={t("placeholder.select", { item: `${t("equipment.toLocation")}` })}
            {...form.getInputProps("toLocationCode")}
          />
          <LocationAutoComplete
            label={t("equipment.targetLocation")}
            placeholder={t("placeholder.select", { item: `${t("equipment.targetLocation")}` })}
            {...form.getInputProps("targetLocationCode")}
          />
          <TextInput
            type="number"
            label={t("equipment.seq")}
            placeholder={t("placeholder.input", { item: `${t("equipment.seq")}` })}
            {...form.getInputProps("seq")}
          />
          <DowntimeReasonsMultiAutoComplete
            label={t("equipment.downtimeReason")}
            {...form.getInputProps("downtimeReasonCode")}
          />
          {customFunctions.ADD_WORKER_ADD_FUNCTION && (
            <UsersMultiAutoComplete
              label={t("equipment.workerGroup")}
              {...form.getInputProps("workerGroup")}
              onChange={(value) => form.setFieldValue("workerGroup", value ?? [])}
            />
          )}
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              {t("common.cancel")}
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "equipment",
                values: form.values,
              })}
              onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
            >
              {t("common.create", { item: `${t("equipment.equipment")}` })}
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
