import { useModal } from "@/features/modal/ModalStackManager";
import { OperationDetailForm } from "@/features/operations/components/form/operationDetailForm";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Checkbox } from "@mantine/core";
import { DefectsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";

const DetailDefect = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
  CheckBox: Checkbox,
});

export interface DetailDefectInformProps {
  data: DefectsGet200ResponseRowsInner | any;
}

export const DefectDetailInForm = (params: DetailDefectInformProps) => {
  const { data } = params;
  const defect = data?.data?.rows[0];
  const { t } = useTranslation();

  const { openModal } = useModal();

  return (
    <>
      <DetailDefect>
        <DetailDefect.Title>
          {t("common.detail", { item: `${t("defect.defect")}` })}
        </DetailDefect.Title>
        <DetailDefect.Field>
          <DetailDefect.Label>{t("defect.code")}</DetailDefect.Label>
          <DetailDefect.Content>{defect?.code}</DetailDefect.Content>
        </DetailDefect.Field>
        <DetailDefect.Field>
          <DetailDefect.Label>{t("defect.name")}</DetailDefect.Label>
          <DetailDefect.Content>{defect?.name}</DetailDefect.Content>
        </DetailDefect.Field>
        <DetailDefect.Field>
          <DetailDefect.Label>{t("operation.code")}</DetailDefect.Label>
          <DetailDefect.Link
            onClick={() =>
              openModal(<OperationDetailForm operationCode={defect?.operationCode} />, null, "")
            }
          >
            {defect?.operationCode}
          </DetailDefect.Link>
        </DetailDefect.Field>
      </DetailDefect>
    </>
  );
};
