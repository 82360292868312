// @fileoverview : 경남-품질관리 > 변경점정보관리
import { itemChanges } from "@/api/itemChange/useItemChangeQuery";
import { itemChangesHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { GridTable } from "@/features/ui/PMS/GridTable";
import useColumnList from "@/hooks/useColumnList";
import useQueryString from "@/hooks/useQueryString";
import { getStartAndEndOfDateRange } from "@/utils/dateTimeUtil";
import timeUtil from "@/utils/timeUtil";
import { ActionIcon, Button, Flex, Pagination, Text } from "@mantine/core";
import { IconCalendar } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { SelectColumn } from "react-data-grid";
import { useNavigate } from "react-router-dom";

export const ChangeListPage = () => {
  const columnlist = useColumnList();
  const today = dayjs().startOf("day").toDate();
  const oneWeekAgo = dayjs().subtract(7, "day").startOf("day").toDate();
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);
  const [content, setContent] = useState<any>([]);
  const [selectList, setSelectList] = useState<Set<number>>(new Set());
  const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);

  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });
  const [page, setPage] = useState(initialQueryString.page);

  const navigate = useNavigate();
  const { openModal } = useModal();

  const { data } = useQuery({
    ...itemChanges.get({
      query: {
        $and: [
          {
            createdAt: {
              $between: getStartAndEndOfDateRange(stateDate[0], stateDate[1]),
            },
          },
        ],
      },
      populate: ["item", "attachments", "creatorUserName"],
      page: page,
      sort: "-id",
      search: search.search,
      searchFields: search.searchFields.length
        ? search.searchFields
        : itemChangesHeader.map((item) => item.value),
    }),
  });

  useEffect(() => {
    if (data && page > (data?.data?.totalPages ?? 0)) {
      setPage(1);
      setQueryString((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [data, page, setQueryString]);

  useEffect(() => {
    if (data) {
      const transformedData = data.data.rows?.map((item: any) => ({
        id: item.id,
        itemCode: item.itemCode,
        itemName: item.itemName,
        itemSpec: item.itemSpec,
        title: item.title,
        createdAt: timeUtil(item.createdAt),
        creatorUserName: item.creatorUserName,
      }));
      setContent(transformedData);
    }
  }, [data]);

  const handleClick = () => {
    const selectedValues = Array.from(selectList);

    if (selectedValues.length === 1) {
      navigate("/quality/change-register", { state: { pageKey: "info", selectedValues } });
    } else {
      customAlert("선택된 항목이 없거나 2개 이상입니다.", "항목을 1개만 선택해주세요.", "red");
    }
  };

  return (
    <Flex direction={"column"} gap="md" w="100%" h="100%" p="sm">
      <Flex justify={"space-between"} align={"center"}>
        <Button
          onClick={() => navigate("/quality/change-register", { state: { pageKey: "register" } })}
        >
          등록하기
        </Button>
        <Flex justify={"center"}>
          <Flex
            gap="sm"
            align={"center"}
            onClick={() => {
              openModal(
                <Calendar dates={stateDate} type="range" isExclude={true} />,
                null,
                "날짜 선택",
              ).then((value) => {
                if (isCalendarDate(value)) {
                  setStateDate(value);
                }
              });
            }}
          >
            <Text size="2rem" fw="bold" style={{ cursor: "pointer" }}>
              {timeUtil(stateDate[0]?.toISOString() ?? "")} ~{" "}
              {timeUtil(stateDate[1]?.toISOString() ?? "")}
            </Text>
            <ActionIcon>
              <IconCalendar size="2rem" />
            </ActionIcon>
          </Flex>
        </Flex>
        <Button color="gray" onClick={handleClick}>
          자세히보기
        </Button>
      </Flex>
      <Flex justify={"space-between"}>
        <Text size="lg">변경점 정보 리스트</Text>
        <SearchBox searchType={itemChangesHeader} setSearch={setSearch} search={search} />
      </Flex>
      <GridTable
        headerList={[SelectColumn, columnlist.qualityChangeList]}
        row={content}
        setRow={setContent}
        selectList={selectList}
        //@ts-ignore
        setSelectList={setSelectList}
        width={"100%"}
        height={"100%"}
        resizable={true}
        // scrollEnd={(value) => {
        //     if(value){
        //         if(keyword !== undefined){
        //             if(searchPage.total_page > searchPage.current_page){
        //                 getPressDailyStatusSearchApi(keyword, searchPage.current_page+ 1)
        //             }
        //         }else{
        //             if(page.total_page > page.current_page){
        //                 getPressDailyStatusApi(page.current_page+ 1)
        //             }
        //         }
        //     }
        // }}
      />
      <Flex justify={"center"}>
        <Pagination
          onChange={setPage}
          value={page}
          total={data?.data?.totalPages ?? 0}
          size="lg"
          radius="sm"
        />
      </Flex>
    </Flex>
  );
};
