import { mutateTransportation } from "@/api/transportation/useTransportationQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Select, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  ScmApiTransportationTransportationIdPutRequest,
  TransportationGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

interface TransportationUpdateFormProps {
  orderId?: number;
  formatterProps: TransportationGet200ResponseRowsInner | undefined;
}

export const TransportationUpdateForm = (props: TransportationUpdateFormProps) => {
  const { orderId, formatterProps } = props;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: updateMutate } = useMutation(
    (params: ScmApiTransportationTransportationIdPutRequest) =>
      mutateTransportation
        .update(params)
        .mutationFn(params as ScmApiTransportationTransportationIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["transportation"]);
        closeModal(form.values);
        customAlert(
          t("message.editSuccess", { item: t("transportation.transportation") }),
          t("common.editSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("transportation.transportation") }),
          t("common.editFail", { item: t("transportation.transportation") }),
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      partnerCode: formatterProps?.partnerCode,
      code: formatterProps?.code,
      direction: formatterProps?.direction,
      status: formatterProps?.status,
      scheduledAt: formatterProps?.scheduledAt ? new Date(formatterProps.scheduledAt) : undefined,
      additional: formatterProps?.additional,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate({
        transportationId: formatterProps?.id ?? 0,
        transportationGetRequest: {
          partnerCode: form.values.partnerCode!,
          code: form.values.code!,
          direction: form.values.direction!,
          status: form.values.status!,
          scheduledAt: form.values.scheduledAt?.toDateString()!,
          additional: form.values.additional,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("transportation.transportation") }),
        t("common.editFail", { item: t("transportation.transportation") }),
        "red",
      );
    }
  };

  return (
    <Update.Wrapper>
      <PartnerAutoComplete
        label={t("partner.partner")}
        placeholder={t("placeholder.select", { item: t("partner.partner") })}
        {...form.getInputProps("partnerCode")}
        withAsterisk
        error={form.isDirty("partnerCode") ? t("common.toSave") : ""}
      />
      <TextInput
        label={t("transportation.code")}
        placeholder={t("placeholder.code", { item: t("transportation.code") })}
        {...form.getInputProps("code")}
        withAsterisk
      />
      <Select
        label={t("transportation.direction")}
        placeholder={t("placeholder.select", { item: t("transportation.direction") })}
        data={[
          { label: t("purchaseOrder.purchaseOrder"), value: "INBOUND" },
          { label: t("order.order"), value: "OUTBOUND" },
        ]}
        withAsterisk
        {...form.getInputProps("direction")}
        defaultValue={formatterProps?.direction}
        error={form.isDirty("direction") ? t("common.toSave") : ""}
        styles={{
          input: {
            color: form.isDirty("direction") ? "red !important" : "",
            borderColor: form.isDirty("direction") ? "red !important" : "",
          },
          error: { fontSize: 11, color: "red" },
        }}
      />
      <Select
        label={t("transportation.status")}
        placeholder={t("placeholder.select", { item: t("transportation.status") })}
        data={[
          { label: t("transportationStatus.SCHEDULED"), value: "SCHEDULED" },
          { label: t("transportationStatus.LOADING"), value: "LOADING" },
          { label: t("transportationStatus.DONE"), value: "DONE" },
          { label: t("transportationStatus.ON_ROAD"), value: "ON_ROAD" },
          { label: t("transportationStatus.ERROR"), value: "ERROR" },
        ]}
        {...form.getInputProps("status")}
        defaultValue={formatterProps?.status}
        error={form.isDirty("status") ? t("common.toSave") : ""}
        styles={{
          input: {
            color: form.isDirty("status") ? "red !important" : "",
            borderColor: form.isDirty("status") ? "red !important" : "",
          },
          error: { fontSize: 11, color: "red" },
        }}
      />
      <Update.DateInput
        popoverProps={{ withinPortal: true }}
        icon={<IconCalendar size="1.2rem" />}
        label={t("stockPlan.scheduledAt")}
        valueFormat="YYYY-MM-DD"
        {...form.getInputProps("scheduledAt")}
        error={form.isDirty("scheduledAt") ? t("common.toSave") : ""}
        styles={{
          input: {
            color: form.isDirty("scheduledAt") ? "red !important" : "",
            borderColor: form.isDirty("scheduledAt") ? "red !important" : "",
          },
          error: { fontSize: 11, color: "red" },
        }}
      />
      <Update.ButtonBox>
        <Update.Button color="gray" onClick={closeModal}>
          {t("common.cancel")}
        </Update.Button>
        <Update.Button
          disabled={validateFormValues({
            pageType: "transportation",
            values: form.values,
          })}
          onClick={onSubmit}
        >
          {t("common.edit", { item: t("transportation.transportation") })}
        </Update.Button>
      </Update.ButtonBox>
    </Update.Wrapper>
  );
};
