import { ScmInstance } from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  ScmApiPurchaseOrderItemsFindPostRequest,
  ScmApiPurchaseOrderItemsGetRequest,
  ScmApiPurchaseOrderItemsPostRequest,
  ScmApiPurchaseOrderItemsPurchaseOrderItemIdDeleteRequest,
  ScmApiPurchaseOrderItemsPurchaseOrderItemIdGetRequest,
  ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const purchaseOrderItems = createQueryKeys("purchaseOrderItems", {
  all: null,
  get: (params: ScmApiPurchaseOrderItemsGetRequest) => {
    // const queryParams = { ...params };
    const queryParams = {
      ...params,
      query: JSON.stringify({ $and: params?.query }),
      sort: params.sort?.length ? params.sort : "-id",
    }; // 품목 조회 시 기본 정렬 표시

    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => ScmInstance.purchaseOrderItemsGet(queryParams),
    };
  },
  detail: (params: ScmApiPurchaseOrderItemsPurchaseOrderItemIdGetRequest) => ({
    queryKey: [params.purchaseOrderItemId],
    queryFn: () => ScmInstance.purchaseOrderItemsPurchaseOrderItemIdGet(params),
  }),
  find: (params: ScmApiPurchaseOrderItemsFindPostRequest) => ({
    queryKey: [params],
    queryFn: () => ScmInstance.purchaseOrderItemsFindPost(params),
  }),
});

export const mutatePurchaseOrderItems = createMutationKeys("purchaseOrderItems", {
  create: (params: ScmApiPurchaseOrderItemsPostRequest) => ({
    mutationKey: [params.purchaseOrderItemsGetRequest],
    mutationFn: () => ScmInstance.purchaseOrderItemsPost(params),
  }),
  update: (params: ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest) => ({
    mutationKey: [params.purchaseOrderItemId, params.purchaseOrderItemsGetRequest],
    mutationFn: () => ScmInstance.purchaseOrderItemsPurchaseOrderItemIdPut(params),
  }),
  delete: (params: ScmApiPurchaseOrderItemsPurchaseOrderItemIdDeleteRequest) => ({
    mutationKey: [params.purchaseOrderItemId],
    mutationFn: () => ScmInstance.purchaseOrderItemsPurchaseOrderItemIdDelete(params),
  }),
});

export const purchaseOrderItemsQueryKeys = mergeQueryKeys(
  purchaseOrderItems,
  mutatePurchaseOrderItems,
);
