import { customFunctions } from "@/config/customFunction";
import { itemTypeColor } from "@/constants/items";
import { ItemCategoryDetailForm } from "@/features/itemCategory/form/DetailForm";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { Flex, Badge, Button, Text } from "@mantine/core";
import {
  ItemsGet200ResponseRowsInnerItemTypeEnum,
  AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { t } from "i18next";
import { ItemsDetailForm } from "../ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useQuery } from "@tanstack/react-query";
import { items } from "@/api/items/useItemQuery";

interface RoutingBomInnerRowProps {
  bom: AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner;
  isPreview: boolean;
  openEditModal: ({
    routingBom,
  }: {
    routingBom: AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner;
  }) => void;
  handleDeleteBom: ({ routingBomId }: { routingBomId: number | undefined }) => void;
}

export const RoutingBomInnerRow = ({
  bom,
  isPreview,
  openEditModal,
  handleDeleteBom,
}: RoutingBomInnerRowProps) => {
  const { openModal } = useModal();

  const { data } = useQuery(
    items.get({
      query: { $and: [{ code: { $eq: bom.itemCode } }] },
    }),
  );

  const itemData = data?.data?.rows && data?.data?.rows[0];

  return (
    <tr>
      <td>
        <Flex justify="flex-start">
          <DetailLink
            onClick={() =>
              openModal(
                <ItemsDetailForm itemCode={bom.itemCode as string} />,
                null,
                `${t("common.detail", { item: `${t("item.item")}` })}`,
              )
            }
          >
            {bom.itemCode}
          </DetailLink>
        </Flex>
      </td>
      <td>{itemData?.name}</td>
      <td>
        <Badge
          color={itemTypeColor[itemData?.itemType as ItemsGet200ResponseRowsInnerItemTypeEnum]}
        >
          {t(itemData?.itemType as ItemsGet200ResponseRowsInnerItemTypeEnum)}
        </Badge>
      </td>
      <td>{itemData?.spec}</td>
      <td>
        <DetailLink
          onClick={() => {
            openModal(
              <ItemCategoryDetailForm ItemCategoryName={itemData?.itemCategoryName as string} />,
              null,
              `${t("common.detail", { item: `${t("item.category")}` })}`,
            );
          }}
        >
          {itemData?.itemCategoryName}
        </DetailLink>
      </td>
      <td>
        <Text ta="end">{bom.quantityNumerator}</Text>
      </td>
      <td>{itemData?.unitText}</td>
      {customFunctions.ADD_ROUTING_BOM_DESCRIPTION && <td>{bom.description}</td>}
      {!isPreview && (
        <td width={20}>
          <Button
            w={32}
            variant="subtle"
            onClick={() => openEditModal({ routingBom: bom })}
            leftIcon={<IconEdit />}
            color="yellow"
          ></Button>
          <Button
            w={32}
            variant="subtle"
            onClick={() =>
              handleDeleteBom({
                routingBomId: (
                  bom as AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner
                ).id,
              })
            }
            leftIcon={<IconTrash />}
            color="red"
          ></Button>
        </td>
      )}
    </tr>
  );
};
