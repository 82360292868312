import { useQuery } from "@tanstack/react-query";

import { MasterInstance } from "@/instance/axios";
import {
  LocationsGet200Response,
  LocationsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";

export type LocationOption = {
  value: string;
  label: string;
};

export const useLocationsMultiAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: LocationOption[]) => void;
  },
) => {
  return useQuery(
    ["locations-get-multi-autocomplete-keyword", keyword],
    () =>
      MasterInstance.locationsFindPost({
        search: keyword,
        searchFields: ["name"],
      }).then((res) => res.data),
    {
      enabled: focus,
      select: (data: LocationsGet200ResponseRowsInner[]): LocationOption[] =>
        data.map((Location) => {
          return {
            value: String(Location.code),
            label: String(Location.name),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    },
  );
};

const getLocationByCode = (code: string | null) =>
  MasterInstance.locationsGet({
    ...(code && { search: code }),
    searchFields: ["name"],
  }).then((res) => res.data);

export const useLocationsMultiCode = (
  enabled: boolean = false,
  code: string | null,
  options?: {
    onSuccess: (data?: LocationOption[]) => void;
  },
) => {
  return useQuery(["locations-get-multi-code", code], () => getLocationByCode(code), {
    enabled,
    select: (data: LocationsGet200Response) =>
      data?.rows?.map((Location) => {
        return {
          value: Location.code as string,
          label: Location.name as string,
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
