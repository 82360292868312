import { useState } from "react";

export const useToggle = () => {
    const [state, setState] = useState<Record<string, boolean>>({});

    const toggle = (key: string) => {
        setState((prev) => ({ ...prev, [key]: !(prev[key] ?? false) }));
    };

    const isOpen = (key: string) => state[key] ?? false;

    return { isOpen, toggle, state };
};
