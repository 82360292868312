import styled from "@emotion/styled";
import { Button, Checkbox, Flex } from "@mantine/core";
import { IconLayoutColumns } from "@tabler/icons-react";

// 컬럼 편집 버튼 컴포넌트
export const ColumnEditButton = ({ onClick }: { onClick: () => void }) => (
  <Flex
    justify="flex-end"
    align="flex-end"
    px="sm"
    style={{ position: "absolute", top: -50, right: -10, zIndex: 190 }}
  >
    <Button leftIcon={<IconLayoutColumns />} color="gray" onClick={onClick}>
      컬럼편집
    </Button>
  </Flex>
);

// 체크박스 및 컬럼 이름 컴포넌트
export const ColumnItem = ({
  name,
  isChecked,
  onChange,
}: {
  name: string;
  isChecked: boolean;
  onChange: (id: string) => void;
}) => (
  <Flex justify="flex-start" align="center" gap={12}>
    <Checkbox style={{ cursor: "pointer" }} checked={isChecked} onChange={() => onChange(name)} />
    <Title>{name}</Title>
  </Flex>
);

const Title = styled.div`
  font-weight: 400;
`;
