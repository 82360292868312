import { Flex, MultiSelect, MultiSelectProps, Text } from "@mantine/core";
import { forwardRef, useMemo, useState } from "react";

import { FKInfoLabel } from "@/features/ui/Base/List/CustomFilter/MultiField";
import { IconX } from "@tabler/icons-react";
import {
  useDowntimeReasonsMultiAutoComplete,
  useDowntimeReasonsMultiCode,
} from "./multiAuto-useGetDowntimeReasonsQuery";

interface DowntimeReasonsMultiProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export interface DowntimeReasonsMultiAutoCompleteProps
  extends Partial<MultiSelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value: string[];
  onChange?: (e: string[]) => void; // (value: string[]) => React.Dispatch<React.SetStateAction<string>>;
  maxDropdownHeight?: number;
  width?: string;
}

export const DowntimeReasonsMultiAutoComplete = (params: DowntimeReasonsMultiAutoCompleteProps) => {
  const {
    value: downtimeReasons = [], // 기본값을 빈 배열로 설정
    onChange,
    maxDropdownHeight,
    width,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useDowntimeReasonsMultiAutoComplete(focused, searchKeyword);
  const { data: initialOptions } = useDowntimeReasonsMultiCode(
    !!searchKeyword,
    searchKeyword ?? null,
  );

  const onChangeHandler = (e: string[]) => {
    onChange && onChange(e);
  };

  const SelectDowntimeReasons = forwardRef<HTMLDivElement, DowntimeReasonsMultiProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            <Text fz="xs">(비가동사유코드: {code})</Text>
          </Flex>
        </Flex>
      </div>
    ),
  );

  // data prop에 들어가는 중복 제거된 data를 useMemo로 감싸기
  const filteredOptions = useMemo(() => {
    const combinedOptions = [
      ...(options ?? []),
      ...(initialOptions ?? []),
      ...(downtimeReasons?.map((value) => ({ label: value, value })) ?? []),
    ];

    return combinedOptions.reduce(
      (unique: DowntimeReasonsMultiProps[], option: DowntimeReasonsMultiProps) =>
        unique.some((u) => u.value === option.value) ? unique : [...unique, option],
      [],
    );
  }, [options, initialOptions, downtimeReasons]);

  return (
    <MultiSelect
      clearable
      value={downtimeReasons}
      data={filteredOptions}
      onChange={onChangeHandler}
      searchable
      itemComponent={SelectDowntimeReasons}
      searchValue={searchKeyword}
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onSearchChange={setSearchKeyword}
      nothingFound="No Options"
      filter={(value, selected, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      rightSection={DowntimeReasonInfo({
        DowntimeReason: downtimeReasons,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const DowntimeReasonInfo = (params: {
  DowntimeReason?: string[];
  onChange: (e: string[]) => void;
}) => {
  const { DowntimeReason, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange([]);
  };

  return DowntimeReason?.length ? (
    <FKInfoLabel>
      <IconX size="1rem" onClick={clearHandler} />
    </FKInfoLabel>
  ) : null;
};
