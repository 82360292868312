import { DateText, StyledTable, Wrapper } from "@/features/transportation/form/statementForm";
import styled from "@emotion/styled";
import { Button, Flex, Text } from "@mantine/core";
import { WorksItemGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPrinter } from "@tabler/icons-react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { QRCodeComponent } from "../qrCode";

interface OrderStatementFormProps {
  data: WorksItemGet200Response | undefined;
}

export const InvoiceStatementForm = (params: OrderStatementFormProps) => {
  const { data } = params;

  // const pruchaseCode = data?.code; // 발주 번호
  // const date = timeUtil(data?.orderDate ?? ""); // 발주 일자
  // const manager = data?.manager; // 발주 담당자
  // const partner = data?.partner?.name; // 협력사 명
  // const concat = data?.partner?.contact; // 협력사 연락처
  // const purchaseOrderItemNum = data?.numberOfPurchaseOrderItems; // 발주 품목 개수
  // const deadline = timeUtil(data?.deliveryDeadline ?? ""); // 납기일자
  // const description = data?.description; // 비고
  // const rows = Array.from({ length: 10 }, (_, index) => {
  //   const rowData = (data.purchaseOrderItems && data.purchaseOrderItems[index]) || {};
  //   return (
  //     <tr key={index}>
  //       <ContentTd>{index + 1}</ContentTd>
  //       <ContentTd colSpan={2}>{rowData.itemName || ""}</ContentTd>
  //       <ContentTd colSpan={2}>{rowData.itemCode || ""}</ContentTd>
  //       <ContentTd>{rowData.quantity || ""}</ContentTd>
  //       <ContentTd>{rowData.partnerCode || ""}</ContentTd>
  //       <ContentTd>{description || ""}</ContentTd>
  //     </tr>
  //   );
  // });

  const lotId = data?.rows && data?.rows[0]?.invoice?.lotId;
  const lotNo = data?.rows && data?.rows[0]?.invoice?.lotNo;
  const name = data?.rows && data?.rows[0]?.invoice?.name;
  const price = data?.rows && data?.rows[0]?.invoice?.price;
  const quantity = data?.rows && data?.rows[0]?.invoice?.qty;
  const spec = data?.rows && data?.rows[0]?.invoice?.spec;
  const totalPrice = data?.rows && data?.rows[0]?.invoice?.totalPrice;
  const unit = data?.rows && data?.rows[0]?.invoice?.unit;

  const today =
    new Date().getFullYear() +
    "년 " +
    (new Date().getMonth() + 1) +
    "월 " +
    new Date().getDate() +
    "일";

  const downloadPDF = () => {
    const input = document.getElementById("pdf-content");
    if (input) {
      html2canvas(input, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${lotId}_발주서.pdf`);
      });
    }
  };

  return (
    <>
      <Wrapper>
        <div id="pdf-content">
          <Flex justify="space-between" align="center">
            <Text size="3rem">MIRETECH</Text>
            <Flex align="center" gap="sm">
              <Text size="3rem" ta="center">
                거 래 명 세 표
              </Text>
              <QRCodeComponent lotId={lotId} />
            </Flex>
            <div style={{ width: "6rem" }} />
          </Flex>
          <Flex justify={"flex-end"}>
            <Button
              onClick={downloadPDF}
              leftIcon={<IconPrinter />}
              color="teal"
              className="no-print"
            >
              출력
            </Button>
          </Flex>
          <Flex justify={"flex-end"} mt="auto">
            <DateText>{today}</DateText>
          </Flex>
          <StyledTable withBorder withColumnBorders w="75rem">
            <thead>
              <tr>
                <Th colSpan={4}>청구처</Th>
                <Th colSpan={4}>공급자</Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <ContentTd>상호</ContentTd>
                <ContentTd colSpan={3}>미래테크 (주)</ContentTd>
                <ContentTd colSpan={4} rowSpan={4}></ContentTd>
              </tr>
              <tr>
                <ContentTd>사업자등록번호</ContentTd>
                <ContentTd>504-81-29089</ContentTd>
                <ContentTd>대표</ContentTd>
                <ContentTd>박해대,서순옥</ContentTd>
              </tr>
              <tr>
                <ContentTd>주소</ContentTd>
                <ContentTd colSpan={3}>대구광역시 북구 검단로 177-71(검단동)</ContentTd>
              </tr>
              <tr>
                <ContentTd>전화번호</ContentTd>
                <ContentTd>053-380-3400</ContentTd>
                <ContentTd>FAX</ContentTd>
                <ContentTd>053-383-7288</ContentTd>
              </tr>
            </tbody>
          </StyledTable>
          <StyledTable withBorder withColumnBorders w="100%">
            <thead>
              <tr>
                <th>금액</th>
                <th>품명</th>
                <th>규격</th>
                <th>단위</th>
                <th>단가</th>
                <th>수량</th>
                <th>원자재 LOT NO</th>
                <th>두께</th>
                <th>폭</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <ContentTd>{totalPrice}</ContentTd>
                <ContentTd>{name}</ContentTd>
                <ContentTd>{spec}</ContentTd>
                <ContentTd>{unit}</ContentTd>
                <ContentTd>{price}</ContentTd>
                <ContentTd>{quantity}</ContentTd>
                <ContentTd>{lotNo}</ContentTd>
                <ContentTd></ContentTd>
                <ContentTd></ContentTd>
                <ContentTd></ContentTd>
              </tr>
            </tbody>
          </StyledTable>
        </div>
      </Wrapper>
    </>
  );
};

export const ContentTd = styled.td`
  padding: 10px;
  height: 3rem;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #000;
  background-color: #fff;
`;

const Th = styled.th`
  padding: 10px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #000;
  background-color: #fff;
  width: 33%;
`;
