import { useModal } from "@/features/modal/ModalStackManager";
import { SubmitHandler, useForm } from "react-hook-form";

enum GenderEnum {
  female = "female",
  male = "male",
  other = "other",
}

interface IFormInput {
  firstName: string;
  gender: GenderEnum;
}

export function FormExample() {
  const { register, handleSubmit } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = (data) => console.log(data);
  const { openModal, closeModal } = useModal();

  const handleOpenModal2 = () => {
    openModal(<FormExample />, "모달 2 전달할 파라미터", "테스트").then((result) => {
      console.log("모달 2에서 전달된 결과값:", result);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>First Name</label>
      <input {...register("firstName")} />
      <label>Gender Selection</label>
      <select {...register("gender")}>
        <option value="female">female</option>
        <option value="male">male</option>
        <option value="other">other</option>
      </select>

      <button onClick={handleOpenModal2}>모달 안에서 또 모달 열기</button>

      <input type="submit" />
    </form>
  );
}
