import useWorksMutation from "@/api/works/useWorksMutation";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Button, Flex, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  DefaultApiProductionPlansProductionPlanIdPutRequest,
  DefaultApiWorksWorkIdPutRequest,
  ProductionPlansGet200ResponseRowsInnerWorksInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface ExpectedDeliveryDateFormProps {
  workData: ProductionPlansGet200ResponseRowsInnerWorksInner[];
}
export const ExpectedDeliveryDateForm = (params: ExpectedDeliveryDateFormProps) => {
  const workDataArray = params.workData;
  const [date, setDate] = useState<[Date | null, Date | null]>([null, null]);
  const { mutate: updateMutate } = useWorksMutation("update");
  const { closeModal, openModal } = useModal();
  const queryClient = useQueryClient();

  const expectedDeliveryDate = (
    workDataArray[0] as ProductionPlansGet200ResponseRowsInnerWorksInner
  ).expectedDeliveryDate
    ? dayjs(
        (workDataArray[0] as ProductionPlansGet200ResponseRowsInnerWorksInner).expectedDeliveryDate,
      ).format("YYYY-MM-DD")
    : "";

  useEffect(() => {
    if (expectedDeliveryDate) {
      setDate([new Date(expectedDeliveryDate), null]);
    }
  }, [expectedDeliveryDate]);

  const form = useForm<{
    works: Array<
      Partial<DefaultApiWorksWorkIdPutRequest & DefaultApiProductionPlansProductionPlanIdPutRequest>
    >;
  }>({
    initialValues: {
      works: workDataArray?.map((workData) => ({
        workId: workData.id,
        worksWorkIdDeleteRequest: {
          expectedDeliveryDate: (workData as ProductionPlansGet200ResponseRowsInnerWorksInner)
            .expectedDeliveryDate,
        },
        productionPlanId: workData.productionPlanId,
        productionPlansProductionPlanIdDeleteRequest: {},
      })),
    },
  });

  const onSave = () => {
    form.values.works.forEach((work, index) => {
      const { workId, worksWorkIdDeleteRequest, productionPlanId } = work;
      updateMutate(
        {
          workId: workId as number,
          worksWorkIdDeleteRequest: {
            expectedDeliveryDate: dayjs(date[0]?.toISOString()).format("YYYY-MM-DD"),
          },
          productionPlanId: productionPlanId as number,
          productionPlansProductionPlanIdDeleteRequest: {},
        },
        {
          onSuccess: () => {
            if (index === form.values.works.length - 1) {
              customAlert("예상 납기일 수정에 성공했습니다.", "예상 납기일 수정 성공", "green");
              closeModal(true);
              queryClient.invalidateQueries(["workPage"]);
            }
          },
          onError: (error) => {
            customAlert(
              handleErrorResponse({ error, errorContext: "예상 납기일" }),
              "예상 납기일 수정 실패",
              "red",
            );
            closeModal(false);
          },
        },
      );
    });
  };

  return (
    <Flex gap={"sm"}>
      <TextInput
        w="16rem"
        placeholder="예상 납기일을 입력하세요"
        onClick={() => {
          openModal(<Calendar dates={date} type="default" />, null, "").then((value) => {
            if (isCalendarDate(value)) {
              setDate(value);
            }
          });
        }}
        value={date[0] ? dayjs(date[0]).format("YYYY-MM-DD") : ""}
      />
      <Flex justify={"flex-end"}>
        <Button onClick={onSave}>저장</Button>
      </Flex>
    </Flex>
  );
};
