import { useModal } from "@/features/modal/ModalStackManager";
import { MoldDetailForm } from "@/features/mold/form/moldDetailForm";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { Anchor, Space } from "@mantine/core";
import { MoldStocksGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";

const DetailMold = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
});

interface DetailMoldInformProps {
  data: MoldStocksGet200ResponseRowsInner | any;
}

export const MoldStockDetailInform = (params: DetailMoldInformProps) => {
  const { data } = params;

  const moldStock = data?.data?.rows[0];
  const { t } = useTranslation();

  const { openModal } = useModal();
  return (
    <>
      <DetailMold>
        <DetailMold.Title>
          {t("common.detail", { item: `${t("moldStock.moldStock")}` })}
        </DetailMold.Title>
        <Space h={8} />
        <DetailMold.Field>
          <DetailMold.Label>{t("moldStock.code")}</DetailMold.Label>
          <DetailMold.Content>{moldStock?.code}</DetailMold.Content>
        </DetailMold.Field>
        <DetailMold.Field>
          <DetailMold.Label>{t("mold.mold")}</DetailMold.Label>
          <DetailLinkWrapper>
            <DetailMold.Badge>{t("mold.mold")}</DetailMold.Badge>
            <DetailMold.Link
              onClick={() => {
                openModal(<MoldDetailForm moldCode={moldStock?.moldCode} />, null, "");
              }}
            >
              {moldStock?.moldCode}
            </DetailMold.Link>
          </DetailLinkWrapper>
        </DetailMold.Field>
        <DetailMold.Field>
          <DetailMold.Label>{t("moldStock.limitCounter")}</DetailMold.Label>
          <DetailMold.Content>{moldStock?.limitCounter}</DetailMold.Content>
        </DetailMold.Field>
        <DetailMold.Field>
          <DetailMold.Label>{t("moldStock.inspectCounter")}</DetailMold.Label>
          <DetailMold.Content>{moldStock?.inspectCounter}</DetailMold.Content>
        </DetailMold.Field>
        <DetailMold.Field>
          <DetailMold.Label>{t("moldStock.currentCounter")}</DetailMold.Label>
          <DetailMold.Content>{moldStock?.currentCounter}</DetailMold.Content>
        </DetailMold.Field>
        <DetailMold.Field>
          <DetailMold.Label>{t("info.createInformation")}</DetailMold.Label>
          <DetailMold.Link>
            <Anchor
              onClick={() => {
                openModal(<UsersDetailForm UserCode={moldStock?.creatorUserCode} />, null, "");
              }}
            >
              {moldStock?.creatorUserCode}({DatetimeUtil(moldStock?.createdAt ?? "")})
            </Anchor>
          </DetailMold.Link>
        </DetailMold.Field>
        <DetailMold.Field>
          <DetailMold.Label>{t("info.updateInformation")}</DetailMold.Label>
          <DetailMold.Link>
            <Anchor
              onClick={() => {
                openModal(<UsersDetailForm UserCode={moldStock?.updaterUserCode} />, null, "");
              }}
            >
              {moldStock?.updaterUserCode}({DatetimeUtil(moldStock?.updatedAt ?? "")})
            </Anchor>
          </DetailMold.Link>
        </DetailMold.Field>
      </DetailMold>
    </>
  );
};
