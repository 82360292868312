import { mutateRoutingBoms } from "@/api/routingBoms/useRoutingBomsQuery";
import { customFunctions } from "@/config/customFunction";
import { consumeTypeColor, routingTypeColor } from "@/constants/routings";
import { BomByOperationCreateForm } from "@/features/bomSearch/table/form/BomByOperationCreateForm";
import { BomByOperationUpdateForm } from "@/features/bomSearch/table/form/BomByOperationUpdateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import styled from "@emotion/styled";
import { Badge, Button, Flex, Table, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner,
  DefaultApiRoutingBomsRoutingBomIdDeleteRequest,
  ItemsGet200ResponseRowsInnerRoutingsInner,
  ItemsOneStepCreatePostRequestRoutingsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { RoutingBomInnerRow } from "./RoutingBomInnerRow";

interface RoutingBomRowProps<T> {
  bomRow: T extends "PREVIEW"
    ? ItemsOneStepCreatePostRequestRoutingsInner
    : ItemsGet200ResponseRowsInnerRoutingsInner;
  rowIndex: number;
}

export const RoutingBomRow = <T,>({ bomRow, rowIndex }: RoutingBomRowProps<T>) => {
  const { t } = useTranslation();
  const [opened, { toggle }] = useDisclosure(false);
  const { openModal } = useModal();

  const queryClient = useQueryClient();

  const isPreview = (bomRow as ItemsGet200ResponseRowsInnerRoutingsInner)?.id === undefined;

  const { mutate: deleteMutate } = useMutation(
    (params: DefaultApiRoutingBomsRoutingBomIdDeleteRequest) =>
      mutateRoutingBoms.delete(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["items"]);
        customAlert(
          `${t("message.deleteSuccess", { item: `${t("item.bom")}` })}`,
          t("common.deleteSuccess"),
          "green",
        );
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("item.bom") }),
          `${t("common.deleteFail", { item: `${t("item.bom")}` })}`,
          "red",
        );
      },
    },
  );

  const openEditModal = ({
    routingBom,
  }: {
    routingBom: AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner;
  }) => {
    openModal(
      <BomByOperationUpdateForm data={routingBom} />,
      null,
      `${t("common.edit", { item: `${t("item.bom")}` })}`,
    );
  };

  const openCreateModal = ({ routingCode }: { routingCode: string | undefined }) => {
    openModal(
      <BomByOperationCreateForm routingCode={routingCode} />,
      null,
      `${t("common.additional", { item: `${t("item.bom")}` })}`,
    );
  };

  const handleDeleteBom = ({ routingBomId }: { routingBomId: number | undefined }) => {
    if (routingBomId === undefined) return;
    deleteMutate({
      routingBomId: routingBomId,
    });
  };

  return (
    <Fragment>
      <tr key={bomRow.code ?? "" + rowIndex}>
        <td>
          <Flex justify="center" align="center">
            {bomRow.routingBoms && bomRow.routingBoms.length && !opened ? (
              <IconPlus onClick={toggle} />
            ) : (
              <IconMinus onClick={toggle} />
            )}
          </Flex>
        </td>
        <td>
          <Text ta="end">{bomRow.seq}</Text>
        </td>
        <td>{bomRow.operationCode}</td>
        <td>{bomRow.code}</td>
        <td>
          <Badge color={routingTypeColor[bomRow.routingType!]}>{t(bomRow.routingType ?? "")}</Badge>
        </td>
        <td>
          <Badge color={consumeTypeColor[bomRow.consumeType!]}>{t(bomRow.consumeType ?? "")}</Badge>
        </td>
      </tr>
      {bomRow.routingBoms && opened && (
        <tr style={{ backgroundColor: "#f3f0ff" }}>
          <td></td>
          <td colSpan={5}>
            <Table withColumnBorders>
              <thead>
                <tr>
                  <Th width={12}>{t("item.materialItemcode")}</Th>
                  <Th width={10}>{t("item.name")}</Th>
                  <Th width={10}>{t("item.type")}</Th>
                  <Th width={8}>{t("item.spec")}</Th>
                  <Th width={10}>{t("item.category")}</Th>
                  <Th width={15}>{t("inventory.quantity")}</Th>
                  <Th>{t("item.unit")}</Th>
                  {customFunctions.ADD_ROUTING_BOM_DESCRIPTION && (
                    <Th width={15}>{t("routing.descript")}</Th>
                  )}
                  {!isPreview && <Th width={20}>{t("common.action")}</Th>}
                </tr>
              </thead>
              <tbody>
                {bomRow.routingBoms &&
                  bomRow.routingBoms.map((bom, i) => {
                    return (
                      <RoutingBomInnerRow
                        key={bom.id}
                        bom={bom}
                        isPreview={isPreview}
                        openEditModal={openEditModal}
                        handleDeleteBom={handleDeleteBom}
                      />
                    );
                  })}
                {!isPreview && (
                  <tr>
                    <td
                      colSpan={customFunctions.ADD_ROUTING_BOM_DESCRIPTION ? 9 : 8}
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        leftIcon={<IconPlus />}
                        variant={"subtle"}
                        onClick={() => openCreateModal({ routingCode: bomRow.code })}
                      >
                        {t("common.newAdditional", { item: t("item.bom") })}
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </td>
        </tr>
      )}
    </Fragment>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
`;
