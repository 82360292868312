import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Button, NumberInput, Text } from "@mantine/core";

import { mutateMoldCavity } from "@/api/mold/useMoldCavityQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { AbstractItemAutoComplete } from "@/features/ui/autoComplete/abstractItem/abstractItem-autoComplete";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import {
  DefaultApiMoldCavitiesPostRequest,
  ItemsGet200ResponseRowsInnerItemTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { MoldCodeProps } from "./cavityForm";

const Create = Object.assign(FormMain, {
  Button: Button,
  NumberInput: NumberInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
});

export const MoldCavityCreateForm = ({ moldCodeProps }: MoldCodeProps) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: createMutate } = useMutation(
    (params: DefaultApiMoldCavitiesPostRequest) =>
      mutateMoldCavity.create(params).mutationFn(params as DefaultApiMoldCavitiesPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["moldCavity"]);
        closeModal(form.values);
        customAlert(
          `${t("message.createSuccess", { item: `${t("moldCavity.moldCavity")}` })}`,
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("moldCavity.code") }),
          `${t("common.createFail", { item: `${t("moldCavity.moldCavity")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      moldCode: moldCodeProps,
      num: undefined,
      itemCode: undefined,
      abstractItemCode: undefined,
      seq: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        moldCavitiesGetRequest: {
          moldCode: form.values.moldCode!,
          num: form.values.num!,
          itemCode: form.values.itemCode!,
          abstractItemCode: form.values.abstractItemCode!,
          seq: form.values.seq!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("moldCavity.code") }),
        `${t("common.createFail", { item: `${t("moldCavity.moldCavity")}` })}`,
        "red",
      );
    }
  };

  return (
    <>
      <Create.Wrapper>
        <MoldAutoComplete
          label={t("mold.code")}
          width="100%"
          withAsterisk
          {...form.getInputProps("moldCode")}
          defaultValue={moldCodeProps}
        />
        <Create.NumberInput
          label={t("moldCavity.quantity")}
          withAsterisk
          {...form.getInputProps("num")}
        />
        <ItemAutoComplete
          getItemType={[
            ItemsGet200ResponseRowsInnerItemTypeEnum.PRODUCT,
            ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT,
          ]}
          label={t("item.code")}
          width="100%"
          {...form.getInputProps("itemCode")}
          disabled={form.values.abstractItemCode ? true : false}
        />
        <AbstractItemAutoComplete
          label={t("abstractItem.code")}
          width="100%"
          {...form.getInputProps("abstractItemCode")}
          disabled={form.values.itemCode ? true : false}
        />
        <Create.NumberInput label={t("moldCavity.cavityNumber")} {...form.getInputProps("seq")} />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal} className="cancel_btn">
            {t("common.cancel")}
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "moldCavity",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            {t("common.create", { item: `${t("moldCavity.moldCavity")}` })}
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
