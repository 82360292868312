import DefaultInstance, { ProductionInstance, TrackingInstance } from "@/instance/axios";
import {
  DefaultApiWorksWorkIdDeleteRequest,
  ProductionActionApiEquipmentsCorrectionPutRequest,
  ProductionActionApiWorksWorkIdDefectPutRequest,
  ProductionActionApiWorksWorkIdPerformancePutRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingStartPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation } from "@tanstack/react-query";
// Type definitions simplified for clarity
type UpdateQuantityParams = ProductionActionApiWorksWorkIdPerformancePutRequest;
type UpdateDefectParams = ProductionActionApiWorksWorkIdDefectPutRequest;
type DeleteParams = DefaultApiWorksWorkIdDeleteRequest;
type PutEquipmentCorrectionParams = ProductionActionApiEquipmentsCorrectionPutRequest;
type StartWorksParams = ProductionTimeTrackingActionApiWorksWorkIdTrackingStartPostRequest;
type EndWorksParams = ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest;

const useWorksMutation = () => {
  const updateQuantityMutation = useMutation(async (params: UpdateQuantityParams) => {
    await ProductionInstance.worksWorkIdPerformancePut(params);
  });

  const updateDefectMutation = useMutation(async (params: UpdateDefectParams) => {
    await ProductionInstance.worksWorkIdDefectPut(params);
  });

  const deleteMutation = useMutation(async (params: DeleteParams) => {
    return DefaultInstance.worksWorkIdDelete(params);
  });

  const putEquipmentCorrectionMutation = useMutation(
    async (params: PutEquipmentCorrectionParams) => {
      return ProductionInstance.equipmentsCorrectionPut(params);
    },
  );

  const workStartMutation = useMutation((params: StartWorksParams) => {
    return TrackingInstance.worksWorkIdTrackingStartPost(params);
  });

  const workEndMutation = useMutation((params: EndWorksParams) => {
    return TrackingInstance.worksWorkIdTrackingEndPost(params);
  });

  return {
    updateQuantityMutate: updateQuantityMutation.mutateAsync,
    updateDefectMutate: updateDefectMutation.mutateAsync,
    deleteMutate: deleteMutation.mutate,
    putEquipmentCorrectionMutate: putEquipmentCorrectionMutation.mutateAsync,
    workStartMutation: workStartMutation.mutate,
    workEndMutation: workEndMutation.mutate,
  };
};

export default useWorksMutation;
