import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  // createRow,
  type MRT_ColumnDef,
} from "mantine-react-table";
import { useMemo, useState } from "react";

import { works } from "@/api/works/useWorkQuery";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { RoutingOutsourceDetailForm } from "@/features/RoutingOutsource/form/DetailForm";
import { ChipsBox } from "@/features/standard/Chips/ChipsBox";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { MantineReactCommonTable } from "@/features/ui/mantineTable";
import { WorkView } from "@/features/work/WorkView/WorkView";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { useOutsourceWorkbyRowState } from "@/hooks/useReducerState";
import { trackingStatusObj } from "@/pages/operationOutsourceWorkByRow";
import { getStartAndEndOfDateRange } from "@/utils/dateTimeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { trackingStatusColor } from "@/utils/workStatus";
import { Anchor, Badge, Chip, Flex } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { DetailEquipmentProps } from "../form/EquipmentDetailForm";

export interface Row {
  id: number | any;
  productionPlanId: number | any;
  scheduledAt: string | any;
  targetQuantity: number | any;
  trackingStatus: string | any;
  item: {
    code: string | any;
    name: string | any;
    spec: string | any;
    unitText: string | any;
  };
  routingOutsourceId: number | any;
  routingOutsourceData: {
    name: string | any;
  };
  summary: {
    totalQuantity: number | any;
    defectTotal: number | any;
    todoQuantity: number | any;
  };
}

type TrackingStatus = "WAITING" | "WORKING" | "PAUSED" | "DONE" | "DEFAULT";

export const EquipmentWorksTabs = (
  { equipmentCode }: DetailEquipmentProps,
  trackingStatus: TrackingStatus,
) => {
  const { state: workRowState, toggle } = useOutsourceWorkbyRowState();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  // 각 컬럼 filter
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  // 전체 검색 (단, serchFields에 필드를 직접 입력해줘야함.)
  const [globalFilter, setGlobalFilter] = useState("");

  // filter를 id 별 like를 사용하여 단어가 포함되어있는지 확인
  const filterQuery: { [key: string]: { $iLike: string } } = columnFilters.reduce<{
    [key: string]: { $iLike: string };
  }>((acc, filter) => {
    acc[filter.id] = { $iLike: `%${filter.value}%` as string };
    return acc;
  }, {});
  const state = useWorkState();

  const { data } = useQuery(
    works.get({
      query: {
        $and: [
          { equipmentCode: { $eq: equipmentCode } },
          { trackingStatus: { $in: Object.keys(workRowState).filter((key) => workRowState[key]) } },
          ...Object.keys(filterQuery).map((key) => ({ [key]: filterQuery[key] })),
        ],
      },
      populate: ["item", "routingOutsource", "summary"],
      pageSize: pagination.pageSize,
      page: pagination.pageIndex,
      scheduledAtFrom: getStartAndEndOfDateRange(state.date[0], state.date[1])[0],
      scheduledAtTo: getStartAndEndOfDateRange(state.date[0], state.date[1])[1],
      search: globalFilter,
      searchFields: ["itemCode", "itemName", "targetQuantity"],
    }),
  );

  const rows: Row[] | ProductionPlansGet200ResponseRowsInnerWorksInner[] =
    (data?.data.rows && data?.data.rows) ?? [];

  const { openModal } = useModal();

  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<Row>[]>(
    () => [
      {
        accessorFn: (row) => row.id,
        accessorKey: "id",
        header: t("work.id"),
        enableColumnFilter: false,
        size: 60,
        Cell: (rows) => {
          return (
            <DetailLink
              onClick={() => {
                openModal(<WorkView workId={rows.row.original.id} />, null, "");
              }}
              justify="flex-end"
            >
              {rows.row.original.id}
            </DetailLink>
          );
        },
      },
      {
        accessorFn: (row: any) => (
          <DetailLink
            onClick={() => {
              openModal(
                <ItemsDetailForm itemCode={row.item.code} />,
                null,
                `${t("common.detail", { item: `${t("item.item")}` })}`,
              );
            }}
            justify="flex-start"
          >
            {row.item.code}
          </DetailLink>
        ),
        accessorKey: "itemCode",
        header: t("item.code"),
        size: 80,
      },
      {
        accessorFn: (row) => row.item.name,
        accessorKey: "itemName",
        header: t("item.name"),
        size: 120,
      },
      {
        accessorFn: (row) => row.item.spec,
        accessorKey: "spec",
        header: t("item.spec"),
        size: 160,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => t(row.trackingStatus),
        accessorKey: "trackingStatus",
        header: t("work.status"),
        enableColumnFilter: false,
        size: 80,
        Cell: (rows) => {
          const workLogType = rows.row._valuesCache.workLogType as keyof typeof trackingStatusColor;
          return (
            <Badge color={trackingStatus ? trackingStatusColor[trackingStatus] : ""}>
              {t(rows.row._valuesCache.trackingStatus)}
            </Badge>
          );
        },
      },
      {
        accessorFn: (row) => row.targetQuantity,
        accessorKey: "targetQuantity",
        header: t("work.targetQuantity"),
        size: 80,
        Cell: (rows) => {
          const unitText = rows.row.original.item.unitText;
          const targetQuantity =
            setToLocaleString(rows.row._valuesCache.targetQuantity) === "NaN"
              ? "0"
              : setToLocaleString(rows.row._valuesCache.targetQuantity);
          return (
            <div style={{ textAlign: "right" }}>
              {targetQuantity} {unitText}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.summary.todoQuantity,
        accessorKey: "todoQuantity",
        header: t("work.remainQuantity"),
        size: 80,
        Cell: (rows) => {
          const unitText = rows.row.original.item.unitText;
          return (
            <div style={{ textAlign: "right" }}>
              {setToLocaleString(rows.row._valuesCache.todoQuantity)} {unitText}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.summary.totalQuantity,
        accessorKey: "totalQuantity",
        header: t("work.totalQuantity"),
        size: 80,
        Cell: (rows) => {
          const unitText = rows.row.original.item.unitText;
          return (
            <div style={{ textAlign: "right" }}>
              {setToLocaleString(rows.row._valuesCache.totalQuantity)} {unitText}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.summary.defectTotal,
        accessorKey: "defectTotal",
        header: t("work.defectQuantity"),
        size: 80,
        Cell: (rows) => {
          const unitText = rows.row.original.item.unitText;
          return (
            <div style={{ textAlign: "right" }}>
              {setToLocaleString(rows.row._valuesCache.defectTotal)} {unitText}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.routingOutsourceData?.name,
        accessorKey: "routingOutsourceData",
        header: t("outsource.outsource"),
        size: 80,
        Cell: (rows) => {
          return (
            <Anchor
              onClick={() =>
                openModal(
                  <RoutingOutsourceDetailForm name={rows.row.original.routingOutsourceData.name} />,
                  null,
                  "",
                )
              }
            >
              {rows.row._valuesCache.routingOutsourceData !== null
                ? rows.row.original.routingOutsourceData?.name
                : ""}
            </Anchor>
          );
        },
      },
      {
        accessorFn: (row) => dayjs(row.scheduledAt).format("YYYY-MM-DD"),
        accessorKey: "scheduledAt",
        header: t("work.workDay"),
        size: 140,
      },
    ],
    [],
  );

  return (
    <Flex direction={"column"} gap="sm">
      <Flex justify={"flex-end"}>
        <ChipsBox>
          {Object.keys(trackingStatusObj).map((key) => (
            <Chip
              variant="filled"
              size="md"
              radius="sm"
              key={key}
              checked={workRowState[key] || false}
              onClick={() => toggle(key)}
            >
              {trackingStatusObj[key]}
            </Chip>
          ))}
        </ChipsBox>
      </Flex>
      <MantineReactCommonTable
        data={rows as unknown as Row[]}
        columns={columns}
        totalCount={data?.data.total}
        totalPages={data?.data.totalPages}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters} // 컬럼별 검색
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter} // 전체 검색
        setGlobalFilter={setGlobalFilter}
        enableTopToolbar={true}
      />
    </Flex>
  );
};
