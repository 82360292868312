import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Space, Table } from "@mantine/core";
import { OperationOutsourceWorkByRowViewProps } from "./OperationOutsourceWorkByRowView";

const DetailOutsource = Object.assign(DetailHeader, {
  Title: DetailTitle,
});

export const OperationOutsourceWorkByRowDetailView = (
  props: OperationOutsourceWorkByRowViewProps,
) => {
  const { data } = props;

  return (
    <DetailOutsource>
      <DetailOutsource.Title>{`자재소모내역`}</DetailOutsource.Title>
      <Space h={8} />
      <Table withColumnBorders>
        <thead>
          <tr>
            <th>품목코드</th>
            <th>품목명</th>
            <th>로트이름</th>
            <th>수량</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <tr key={index}>
                <td>{item?.itemCode}</td>
                <td>{item?.itemName}</td>
                <td>{item?.LotNo}</td>
                <td style={{ textAlign: "right" }}>{item?.Qty}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                해당 작업의 자재소모내역이 없습니다
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </DetailOutsource>
  );
};
