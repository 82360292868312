import { useModal } from "@/features/modal/ModalStackManager";
import { Calendar } from "@/features/ui/Calendar";
import { GridTable } from "@/features/ui/PMS/GridTable";
import useColumnList from "@/hooks/useColumnList";
import { ActionIcon, Button, Flex, Input, Select, Text } from "@mantine/core";
import { IconCalendar, IconSearch } from "@tabler/icons-react";
import { useState } from "react";
import { SelectColumn } from "react-data-grid";

export const MoldDailyRegisterPage = () => {
  const { openModal } = useModal();
  const columnlist = useColumnList();

  const [rows, setRows] = useState([
    { code: "code1", name: "name1" },
    { code: "code2", name: "name2" },
  ]);

  return (
    <Flex direction={"column"} gap="md">
      <Flex justify={"space-between"}>
        <Text size="xl">금형 일상점검 등록</Text>
        <Flex gap="sm">
          <Button
            color="gray.6"
            rightIcon={<IconCalendar />}
            onClick={() => {
              openModal(<Calendar dates={[null, null]} type="default" />, null, "날짜 선택");
            }}
          >
            <Text>등록날짜 2024.08.07</Text>
          </Button>
          <Flex align={"center"}>
            <Select
              w={"8rem"}
              defaultValue={"code"}
              data={[
                { label: "CODE", value: "code" },
                { label: "금형명", value: "name" },
              ]}
            />
            <Input w={"16rem"} placeholder="검색어를 입력하세요" />
            <ActionIcon>
              <IconSearch />
            </ActionIcon>
          </Flex>
        </Flex>
      </Flex>
      <GridTable
        headerList={[SelectColumn, columnlist.moldDailyRegisterTable]}
        row={rows}
        setRow={setRows}
      />
    </Flex>
  );
};
