import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { EquipmentDetailForm } from "@/features/equipment/components/form/EquipmentDetailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { useToggle } from "@/hooks/useToggle";
import DatetimeUtil from "@/utils/dateTimeUtil";
import styled from "@emotion/styled";
import { Flex, Table, Text } from "@mantine/core";
import {
  ProductionPlansGet200ResponseRowsInnerWorksInnerConsumeSummary,
  WorkLogsGetRequestWorkLogTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { useQueries } from "@tanstack/react-query";
import { Tbody, Td, Th, Thead, Tr } from "./forwardSummary";
export interface LotTrackingForwardWorkHistoryMaterialProps {
  consumeSummary: ProductionPlansGet200ResponseRowsInnerWorksInnerConsumeSummary[] | undefined;
}

const Links = Object.assign(DetailHeader, {
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
});

export const LotTrackingForwardWorkHistoryMaterial = (
  params: LotTrackingForwardWorkHistoryMaterialProps,
) => {
  const { consumeSummary } = params;
  const { isOpen, toggle } = useToggle();
  const { openModal } = useModal();

  const workLotId = Array.from(
    new Set(
      consumeSummary?.map((consume) => {
        return consume.lotId;
      }),
    ),
  );
  const workLot = useQueries({
    queries:
      workLotId &&
      workLotId.map((consume) => {
        return {
          ...workLogs.find({
            query: {
              $and: [
                { workId: consumeSummary && consumeSummary[0]?.workId },
                {
                  workLogType: [
                    WorkLogsGetRequestWorkLogTypeEnum.CONSUME,
                    WorkLogsGetRequestWorkLogTypeEnum.CANCEL_CONSUME,
                    WorkLogsGetRequestWorkLogTypeEnum.CONSUME_IDENTIFIED,
                    WorkLogsGetRequestWorkLogTypeEnum.CANCEL_CONSUME_IDENTIFIED,
                  ],
                },
                {
                  $or: [
                    { "logData.outgoing.lotId": consume },
                    { "logData.incoming.lotId": consume },
                  ],
                },
              ],
            },
            populate: ["getEquipment", "creatorUser"],
          }),
        };
      }),
  });

  return (
    <Container>
      <Table>
        <Thead backgroundColor="var(--green-0, #EBFBEE)">
          <Tr>
            <Th flex={0.1}></Th>
            <Th>원부자재</Th>
            <Th>로트명</Th>
            {/* <Th>로트 유효기한</Th> */}
            <Th>소모 수량</Th>
          </Tr>
        </Thead>
        <Tbody>
          {consumeSummary && consumeSummary.length > 0 ? (
            consumeSummary.map((consume, index) => {
              return (
                <>
                  <Tr key={index}>
                    <Td flex={0.1}>
                      <Text ta="center">
                        {!isOpen(index.toString()) ? (
                          <IconPlus
                            onClick={() => toggle(index.toString())}
                            style={{ verticalAlign: "middle", cursor: "pointer" }}
                          />
                        ) : (
                          <IconMinus
                            onClick={() => toggle(index.toString())}
                            style={{ verticalAlign: "middle", cursor: "pointer" }}
                          />
                        )}
                      </Text>
                    </Td>
                    <Td>
                      <Flex justify={"center"} align={"center"}>
                        <Links.Link
                          onClick={() =>
                            openModal(<ItemsDetailForm itemCode={consume.itemCode} />, "", "")
                          }
                        >
                          {consume.itemName ?? "-"}
                        </Links.Link>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex justify={"center"} align={"center"}>
                        {consume.lotName ?? "-"}
                      </Flex>
                    </Td>
                    {/* <Td>{consume.lotExpirationDate}</Td> */}
                    <Td>
                      <Flex justify={"flex-end"}>{consume.sumQuantity}</Flex>
                    </Td>
                  </Tr>
                  {isOpen(index.toString()) && (
                    <Tr>
                      <Td colSpan={4}>
                        <Table>
                          <Thead backgroundColor="var(--green-0, #EBFBEE)">
                            <Tr>
                              <Th flex={0.2}></Th>
                              <Th>작업일</Th>
                              <Th>작업자</Th>
                              <Th>설비명</Th>
                              <Th>수량</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {workLot[index]?.data?.data?.length === 0 && (
                              <Tr>
                                <Td colSpan={4}>
                                  <Flex justify={"center"} align={"center"}>
                                    <Text px={8}>데이터가 없습니다</Text>
                                  </Flex>
                                </Td>
                              </Tr>
                            )}
                            {workLot[index]?.data?.data?.map((workLog, workIndex) => {
                              return (
                                <Tr key={workIndex}>
                                  <Td flex={0.2}>{"."}</Td>
                                  <Td>{DatetimeUtil(workLog?.createdAt ?? "-") ?? "-"}</Td>
                                  <Td>
                                    <Links.Link
                                      onClick={() =>
                                        openModal(
                                          <UsersDetailForm UserCode={workLog?.creatorUserCode} />,
                                          "",
                                          "",
                                        )
                                      }
                                    >
                                      {workLog?.creatorUserName ?? "-"}
                                    </Links.Link>
                                  </Td>
                                  <Td>
                                    <Links.Link
                                      onClick={() =>
                                        openModal(
                                          <EquipmentDetailForm
                                            equipmentCode={workLog?.equipmentCode}
                                          />,
                                          "",
                                          "",
                                        )
                                      }
                                    >
                                      {workLog?.equipment?.name ?? "-"}
                                    </Links.Link>
                                  </Td>
                                  <Td>{workLog?.quantity ?? "-"}</Td>
                                </Tr>
                              );
                            })}
                          </Tbody>
                        </Table>
                      </Td>
                    </Tr>
                  )}
                </>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={4} align="center">
                데이터가 없습니다.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  max-height: 334px;
  padding: 8px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: var(--green-0, #ebfbee);
  overflow: auto;
`;
