import { MasterInstance } from "@/instance/axios";
import { PartnersGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export type PartnerOption = {
  value: string;
  label: string;
  group: string;
};

const getAutoCompleteKeyword = () =>
  MasterInstance.partnersFindPost().then(
    (res: AxiosResponse<PartnersGet200ResponseRowsInner[]>) => res.data,
  );

export const usePartnersAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: PartnerOption[]) => void;
  },
) => {
  return useQuery(["partners-get-autocomplete"], () => getAutoCompleteKeyword(), {
    enabled: focus,
    select: (data: PartnersGet200ResponseRowsInner[]) =>
      data?.map((partner) => {
        return {
          value: String(partner.code),
          label: String(partner.name),
          group: String(partner.type),
        };
      }) ?? [],
    onSuccess: options?.onSuccess,
  });
};

const getPartnersByCode = (code: string | null) =>
  MasterInstance.partnersFindPost({
    ...(code && { search: code }),
    searchFields: ["code"],
  }).then((res: AxiosResponse<PartnersGet200ResponseRowsInner[]>) => res.data);

export const usePartnersCode = (
  enabled: boolean,
  code: string | null,
  options?: {
    onSuccess: (data?: PartnerOption[]) => void;
  },
) => {
  return useQuery(["partners-get-code", code], () => getPartnersByCode(code), {
    enabled: enabled ?? false,
    select: (data: PartnersGet200ResponseRowsInner[]) =>
      data?.map((partner) => {
        return {
          value: String(partner.code),
          label: String(partner.name),
          group: String(partner.type),
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
