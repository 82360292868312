// @fileoverview : 기준정보 > 경남-BOM조회
import { mutateItems } from "@/api/items/useItemQuery";
import { customFunctions } from "@/config/customFunction";
import { itemTypeColor } from "@/constants/items";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { ItemsUpdateForm } from "@/features/item/components/form/ItemsUpdateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ActionIcon, Avatar, Badge, Button, Flex, Text, Tooltip } from "@mantine/core";
import {
  ItemsGet200Response,
  ItemsGet200ResponseRowsInner,
  ItemsGet200ResponseRowsInnerItemTypeEnum,
  MasterApiItemsItemIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconRecycle, IconSearch, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const BOMSearchPage = () => {
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const { query, setQuery, refetch, resetQueryStrings } = useStandardLayout();

  useEffect(() => {
    if (!query.length) {
      setQuery(() => [
        {
          itemType: {
            $in: [
              ItemsGet200ResponseRowsInnerItemTypeEnum.PRODUCT,
              ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT,
            ],
          },
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiItemsItemIdDeleteRequest) =>
      mutateItems.delete(params).mutationFn(params as MasterApiItemsItemIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          `${t("message.deleteSuccess", { item: `${t("item.item")}` })}`,
          t("common.deleteSuccess"),
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: `${t("item.code")}` }),
          `${t("common.deleteFail", { item: `${t("item.item")}` })}`,
          "red",
        );
      },
    },
  );

  const columns: readonly Column<ItemsGet200ResponseRowsInner>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "name",
        name: t("item.name"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const detailItemView = () => {
            openModal(
              <ItemsDetailForm itemCode={formatterProps?.row.code} />,
              null,
              `${t("common.detail", { item: `${t("item.item")}` })}`,
            );
          };

          return <LinkAnchor onClick={detailItemView}>{formatterProps.row.name}</LinkAnchor>;
        },
      },
      ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
        ? [
            {
              key: "gradeName",
              name: t("item.gradeName"),
              sortable: true,
              resizable: true,
            },
          ]
        : []),
      {
        key: "itemType",
        name: t("item.type"),
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          return (
            <Badge size="lg" color={itemTypeColor[row.itemType]}>
              {t(row.itemType)}
            </Badge>
          );
        },
      },
      {
        key: "code",
        name: t("item.code"),
        sortable: true,
        resizable: true,
      },
      {
        key: "options",
        name: t("common.action"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const detailItemView = () => {
            openModal(
              <ItemsDetailForm itemCode={formatterProps?.row.code} />,
              null,
              `${t("common.detail", { item: `${t("item.item")}` })}`,
            );
          };

          const updateItemActionView = () => {
            openModal(
              <ItemsUpdateForm formatterProps={formatterProps?.row} />,
              null,
              `${t("common.edit", { item: `${t("item.item")}` })}`,
              true,
            );
          };

          const deleteItemAction = () => {
            openModal(
              <ConfirmForm
                message={t("message.rowDelete")}
                yesCallback={() => {
                  deleteMutate({ itemId: formatterProps?.row.id });
                }}
                noCallback={() => {}}
                commonCallback={() => closeModal(null)}
              />,
              null,
              `${t("common.delete", { item: `${t("item.item")}` })}`,
            );
          };

          return (
            <Flex w="100%" h="100%" justify="center" align="center" gap="md">
              <ActionIcon variant="subtle" onClick={detailItemView} color="blue">
                <IconSearch />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={updateItemActionView} color="yellow">
                <IconEdit />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={() => deleteItemAction()} color="red">
                <IconTrash />
              </ActionIcon>
            </Flex>
          );
        },
      },
    ],
    [closeModal, deleteMutate, openModal, t],
  );

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="flex-end" rowGap="md">
        <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
          {t("common.reset")}
        </Button>
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: `${t("item.bomsearch")}` })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${t("item.itemOfbom")}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<ItemsGet200Response, ItemsGet200ResponseRowsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<ItemsGet200Response> />
      </Flex>
    </Flex>
  );
};
