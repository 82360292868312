import { endDateTime, QueryType, startDateTime } from "@/features/standardLayout/Context";
import { Dispatch, SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";

// 상태 관리 함수의 타입을 지정
interface ResetQueryStringsProps {
  setSearch: Dispatch<SetStateAction<string>>;
  setSearchFields: Dispatch<SetStateAction<string[]>>;
  setPage: Dispatch<SetStateAction<number>>;
  setPageSize: Dispatch<SetStateAction<number>>;
  setQuery: Dispatch<SetStateAction<QueryType[]>>;
  setSort: Dispatch<SetStateAction<string[]>>;
  setSelectedRows: Dispatch<SetStateAction<Set<any>>>;
  setStartDate?: Dispatch<SetStateAction<Date | null>>; // 선택적
  setEndDate?: Dispatch<SetStateAction<Date | null>>; // 선택적
}

// 커스텀 훅 정의
export const useResetQueryStrings = ({
  setSearch,
  setSearchFields,
  setPage,
  setPageSize,
  setQuery,
  setSort,
  setSelectedRows,
  setStartDate,
  setEndDate,
}: ResetQueryStringsProps) => {
  const [, setSearchParams] = useSearchParams();

  const resetQueryStrings = () => {
    // 상태 초기화
    setSearch("");
    setSearchFields([]);
    setPage(1);
    setPageSize(10);
    setQuery([]);
    setSort([]);
    setSelectedRows((): Set<any> => new Set());

    // 날짜 상태 초기화는 조건적으로 수행
    if (setStartDate) {
      setStartDate(startDateTime);
    }
    if (setEndDate) {
      setEndDate(endDateTime);
    }

    // URL 쿼리 파라미터 초기화
    setSearchParams(new URLSearchParams()); // URL의 모든 쿼리 파라미터 초기화
  };

  return resetQueryStrings;
};
