import { useModal } from "@/features/modal/ModalStackManager";
import { MoldStockCreateForm } from "@/features/moldStock/form/stockCreateForm";
import { FilterTable } from "@/features/productionPerformance/filterTable";
import { ProductionPerformanceOutsourcingTable } from "@/features/productionPerformanceOutsourcing/table";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { Calendar, CalendarDate, isCalendarDate } from "@/features/ui/Calendar";
import { ActionIcon, Button, Flex, Title } from "@mantine/core";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";

const ProductionPerformanceOutsourcing = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  ActionButtonBox: ActionButtonBox,
  ProductionPerformanceOutsourcingTable: ProductionPerformanceOutsourcingTable,
  MoldStockCreateForm: MoldStockCreateForm,
});

export const ProductionPerformanceOutsourcingPage = () => {
  const { openModal } = useModal();
  const [dateRange, setDateRange] = useState<CalendarDate>([
    dayjs().startOf("month").toDate(),
    dayjs().endOf("month").toDate(),
  ]);
  const searchInputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});
  const [isTableInputFocused, setIsTableInputFocused] = useState(false);
  const [filterQueries, setFilterQueries] = useState<{ [key: string]: string }>({
    equipmentName: "",
    itemName: "",
    itemCode: "",
    itemSpec: "",
    erpWorkOrderNo: "",
    creatorUserCode: "",
    lotName: "",
  });

  const handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    setFilterQueries((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    searchInputRefs.current[name]?.focus();
  };

  useEffect(() => {
    const changedField = Object.keys(filterQueries).find((key) => filterQueries[key] !== "");
    if (changedField && searchInputRefs.current[changedField]) {
      if (!isTableInputFocused) {
        searchInputRefs.current[changedField]?.focus();
      }
    }
  }, [filterQueries]);

  return (
    <>
      <ProductionPerformanceOutsourcing.Wrapper>
        <Flex w="100%" p="xs" justify="center" gap="xs" align="center">
          <Title size="h1" weight={700}>
            {dayjs(dateRange[0]).format("YYYY-MM-DD")} ~ {dayjs(dateRange[1]).format("YYYY-MM-DD")}
          </Title>
          <ActionIcon
            onClick={() =>
              openModal(<Calendar dates={dateRange} type="range" />, null, "날짜 선택").then(
                (result) => {
                  if (isCalendarDate(result)) {
                    setDateRange(result);
                  }
                },
              )
            }
          >
            <IconCalendar size="2rem" />
          </ActionIcon>
        </Flex>
        <FilterTable
          searchInputRefs={searchInputRefs}
          filterQueries={filterQueries}
          handleFilterChange={handleFilterChange}
        />
        <ProductionPerformanceOutsourcing.Header>
          <ProductionPerformanceOutsourcing.HeaderTitle>
            외주 실적 조회
          </ProductionPerformanceOutsourcing.HeaderTitle>
          <ProductionPerformanceOutsourcing.HeaderSubTitle>
            우리회사에서 다루는 외주 실적을 조회할 수 있는 페이지 입니다.
          </ProductionPerformanceOutsourcing.HeaderSubTitle>
        </ProductionPerformanceOutsourcing.Header>
        <ProductionPerformanceOutsourcing.ProductionPerformanceOutsourcingTable
          dates={[
            dayjs(dateRange[0]).startOf("day").toDate(),
            dayjs(dateRange[1]).endOf("day").toDate(),
          ]}
          filterQueries={filterQueries}
          isTableInputFocused={isTableInputFocused}
          setIsTableInputFocused={setIsTableInputFocused}
        />
      </ProductionPerformanceOutsourcing.Wrapper>
    </>
  );
};
