import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { InventoriesAutoComplete } from "@/features/ui/autoComplete/inventoryLot/inventory-autoComplete";
import { useWorkActions } from "@/features/workByManagement/hooks/useWorkActions";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Group, Radio, Select, Table, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  AuthSignupPost201ResponseEquipmentWorksInner,
  ProductionActionApiWorksWorkIdInputPutRequest,
  ProductionPlansGet200ResponseRowsInnerWorksInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

const Create = Object.assign({}, FormMain, {
  Button: Button,
  Input: TextInput,
  Select: Select,
  Date: DateInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  InventoriesAutoComplete: InventoriesAutoComplete,
});

type PageKeyType = "equipment" | "plan"; // pageKey의 가능한 값들을 정의합니다.

type ItemType<T extends PageKeyType> = T extends "equipment"
  ? AuthSignupPost201ResponseEquipmentWorksInner
  : ProductionPlansGet200ResponseRowsInnerWorksInner; // pageKey에 따른 item의 타입을 정의합니다.

interface WorkInputFormProps<T extends PageKeyType> {
  workRowData: ItemType<T>;
}

export function WorkInputForm<T extends PageKeyType>(params: WorkInputFormProps<T>): JSX.Element {
  const [itemCodes, setItemCodes] = useState<string | undefined>();
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const actions = useWorkActions();

  const { mutate: workInputMutate } = useMutation(
    (params: ProductionActionApiWorksWorkIdInputPutRequest) =>
      mutateInventories
        .workInput(params)
        .mutationFn(params as ProductionActionApiWorksWorkIdInputPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
        actions.setWorks((prevWorks) => []);
        closeModal(form.values);
        customAlert("원부자재 투입에 성공하였습니다.", "원부자재 투입 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "원부자재 투입" }),
          "원부자재 투입 실패",
          "red",
        );
      },
    },
  );

  const workData = params.workRowData;

  const form = useForm({
    initialValues: {
      workId: workData?.id,
      worksWorkIdInputPutRequest: {
        lotId: undefined,
        sourceLocationCode: undefined,
        // routingsData는 배열이므로, find로 해당 routingCode에 맞는 데이터를 찾아야 함
        targetLocationCode:
          (workData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.equipment
            ?.toLocationCode ??
          (workData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.routingData?.operation
            ?.toLocationCode ??
          (
            workData as ProductionPlansGet200ResponseRowsInnerWorksInner
          )?.productionPlan?.routingsData?.find((data: any) => data.code === workData.routingCode)
            ?.operation?.toLocationCode,
        quantity: undefined,
      },
    },
  });

  const setInventoryIdAndSourceLocationCode = (e: string | null) => {
    form.setFieldValue("worksWorkIdInputPutRequest.lotId", e ?? undefined);
    form.setFieldValue(
      "worksWorkIdInputPutRequest.sourceLocationCode",
      e
        ? (workData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.routingData?.operation
            ?.fromLocationCode
        : undefined,
    );
  };

  const onSubmit = () => {
    try {
      workInputMutate({
        workId: form.values.workId ?? 0,
        worksWorkIdInputPutRequest: {
          lotId: Number(form.values.worksWorkIdInputPutRequest.lotId),
          sourceLocationCode:
            form.values.worksWorkIdInputPutRequest.sourceLocationCode &&
            (workData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.routingData?.operation
              ?.fromLocationCode,
          targetLocationCode:
            form.values.worksWorkIdInputPutRequest.targetLocationCode &&
            (form.values.worksWorkIdInputPutRequest.targetLocationCode as string),
          quantity:
            form.values.worksWorkIdInputPutRequest.quantity &&
            (form.values.worksWorkIdInputPutRequest.quantity as string),
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "원부자재 투입" }),
        "원부자재 투입 실패",
        "red",
      );
    }
  };

  return (
    <>
      <Create.Wrapper>
        <Table>
          <thead>
            <tr>
              <th>
                <span style={{ marginInline: "2rem 2rem", fontSize: "0.8rem" }}>품목코드</span>
                <span style={{ fontSize: "0.8rem", marginInline: "2rem 4rem" }}>품목 명</span>
                <span style={{ fontSize: "0.8rem" }}>라우팅코드</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <Radio.Group
              value={itemCodes}
              onChange={(e) => {
                setItemCodes(e);
                form.setFieldValue("worksWorkIdInputPutRequest.lotId", undefined);
              }}
            >
              {(
                workData as ProductionPlansGet200ResponseRowsInnerWorksInner
              )?.routingData?.routingBoms?.map((value, index) => {
                return (
                  <tr key={index}>
                    <Group style={{ gap: "1rem" }}>
                      <Radio value={value.itemCode} />
                      <td style={{ wordBreak: "break-all", width: "9ch" }}>{value.itemCode}</td>
                      <td style={{ wordBreak: "break-all", width: "9ch" }}>{value?.item?.name}</td>
                      <td style={{ wordBreak: "break-all", width: "9ch" }}>{value.routingCode}</td>
                    </Group>
                  </tr>
                );
              })}
            </Radio.Group>
          </tbody>
        </Table>

        <Create.InventoriesAutoComplete
          key={form.values.worksWorkIdInputPutRequest.lotId}
          label="로트 정보"
          withAsterisk
          workData={workData}
          itemCodes={
            itemCodes ??
            (
              workData as ProductionPlansGet200ResponseRowsInnerWorksInner
            )?.routingData?.routingBoms?.map((value) => value.itemCode as string)
          }
          {...form.getInputProps("worksWorkIdInputPutRequest.lotId")}
          onChange={(e) => setInventoryIdAndSourceLocationCode(e)}
        />
        <Create.Input
          label="출고 로케이션코드"
          withAsterisk
          disabled
          value={
            (workData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.routingData?.operation
              ?.fromLocationCode
          }
        />
        <Create.Input
          label="입고 로케이션코드"
          disabled
          {...form.getInputProps("worksWorkIdInputPutRequest.targetLocationCode")}
        />
        <Create.Input
          type="number"
          label="수량"
          {...form.getInputProps("worksWorkIdInputPutRequest.quantity")}
        />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            취소
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "inputMaterial",
              values: form.values.worksWorkIdInputPutRequest,
            })}
            onClick={onSubmit}
          >
            원부자재 투입
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
}
