import { ColumnHeaderProps } from "@/constants/columnHeader";
import { operatorMap } from "@/constants/filters";
import { useModal } from "@/features/modal/ModalStackManager";
import { Button, Flex, Select, TextInput } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { MultiAutoCompleteSelector } from "./BestPracticeMultiAutoCompleteSeletor";

interface FilterFormProps {
  searchFieldsHeader: ColumnHeaderProps[];
}

type SearchType = "text" | "number" | "date" | "enum";

const isIncludeReturnContent = (condition: string, content: string) => {
  if (condition.toLowerCase().includes("like")) {
    return `%${content}%`;
  }
  return content;
};

const findHeadersBySearchType = (searchType: string, headers: ColumnHeaderProps[]) => {
  return headers.find((header) => header.value === searchType);
};

export const FilterForm = ({ searchFieldsHeader }: FilterFormProps) => {
  const { closeModal } = useModal();

  // Multi 선택 시 필요한 boolean 상태
  const [isMultiSelect, setIsMultiSelect] = useState<boolean>(false);
  const [selectedMultiField, setSelectedMultiField] = useState<string[]>([]);

  const [selectedElement, setSelectedElement] = useState<string>("");
  const [selectedSearchType, setSelectedSearchType] = useState<SearchType>("text");
  const [selectedCondition, setSelectedCondition] = useState<string>("");
  const selectedContent = useRef<HTMLInputElement>(null);

  const onSubmit = () => {
    const selectedObject = {
      [selectedElement]: isMultiSelect
        ? { $in: selectedMultiField }
        : {
            [selectedCondition]: isIncludeReturnContent(
              selectedCondition,
              selectedContent.current?.value ?? "",
            ),
          },
    };

    closeModal(selectedObject);
  };

  useEffect(() => {
    const header = findHeadersBySearchType(selectedElement, searchFieldsHeader);
    setSelectedSearchType((header?.category as SearchType) ?? "text");
    setIsMultiSelect(header?.isFK || header?.isEnum || false);
  }, [searchFieldsHeader, selectedElement]);

  return (
    <Flex gap="md" direction="column" w="20rem" h="auto">
      <Select
        label="필터링 요소"
        data={searchFieldsHeader}
        value={selectedElement}
        onChange={(e) => setSelectedElement(e ?? "")}
        withinPortal
      />
      {!isMultiSelect && (
        <>
          <Select
            label="필터링 조건"
            data={operatorMap.filter((item) => item.category === selectedSearchType)}
            value={selectedCondition}
            onChange={(e) => setSelectedCondition(e?.toString() ?? "")}
            withinPortal
          />
          <TextInput label="필터링 값" ref={selectedContent} />
        </>
      )}
      {isMultiSelect && (
        <MultiAutoCompleteSelector
          multiFieldName={selectedElement}
          selectedMultiField={selectedMultiField}
          setSelectedMultiField={setSelectedMultiField}
        />
      )}

      <Flex justify="flex-end" gap="xs">
        <Button color="gray" onClick={() => closeModal(null)}>
          취소
        </Button>
        <Button onClick={() => onSubmit()}>필터 추가</Button>
      </Flex>
    </Flex>
  );
};
