import { Table, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface FilterTableProps {
  searchInputRefs: React.MutableRefObject<{ [key: string]: HTMLInputElement | null }>;
  filterQueries: { [key: string]: string };
  handleFilterChange: (e: any) => void;
}

export const FilterTable = (params: FilterTableProps) => {
  const { searchInputRefs, filterQueries, handleFilterChange } = params;
  const { t } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <th>{t("equipment.name")}</th>
          <th>{t("item.name")}</th>
          <th>{t("item.code")}</th>
          <th>{t("item.spec")}</th>
          <th>{t("workLog.workId")}</th>
          <th>{t("workLog.worker")}</th>
          <th>{t("lot.name")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {Object.keys(filterQueries).map((key) => (
            <td key={key}>
              <TextInput
                ref={(el) => {
                  searchInputRefs.current[key] = el;
                }}
                placeholder={key}
                name={key}
                value={filterQueries[key]}
                onChange={handleFilterChange}
              />
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
};
