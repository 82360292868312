import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";

import { DefaultInstance, MasterInstance } from "@/instance/axios";
import {
  DefaultApiEquipmentsGetRequest,
  MasterApiEquipmentsEquipmentIdDeleteRequest,
  MasterApiEquipmentsEquipmentIdPutRequest,
  MasterApiEquipmentsFindPostRequest,
  MasterApiEquipmentsPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const equipments = createQueryKeys("equipment", {
  all: null,
  get: (params: DefaultApiEquipmentsGetRequest) => {
    // const queryParams = { ...params };
    const queryParams = {
      ...params,
      query: JSON.stringify({ $and: params?.query }),
      sort: params.sort?.length ? params.sort : "seq, name, code",
    }; // 설비 조회 시 기본 정렬 표시

    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => DefaultInstance.equipmentsGet(queryParams),
    };
  },
  find: (params: MasterApiEquipmentsFindPostRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => MasterInstance.equipmentsFindPost(query),
    };
  },
});

export const mutateEquipment = createMutationKeys("equipment", {
  create: (params: MasterApiEquipmentsPostRequest) => ({
    mutationKey: [params.equipmentsGetRequest],
    mutationFn: () => MasterInstance.equipmentsPost(params),
  }),
  update: (params: MasterApiEquipmentsEquipmentIdPutRequest) => ({
    mutationKey: [params.equipmentId, params.equipmentsEquipmentIdDeleteRequest],
    mutationFn: () => MasterInstance.equipmentsEquipmentIdPut(params),
  }),
  delete: (params: MasterApiEquipmentsEquipmentIdDeleteRequest) => ({
    mutationKey: [params.equipmentId],
    mutationFn: () => MasterInstance.equipmentsEquipmentIdDelete(params),
  }),
});

export const equipmentQueryKeys = mergeQueryKeys(equipments, mutateEquipment);
