import { EquipmentDetailForm } from "@/features/equipment/components/form/EquipmentDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { theme } from "@/styles/theme";
import { Flex, Text, Tooltip } from "@mantine/core";

interface WorkEquipmentCellProps {
  data: {
    lineName?: string;
    equipmentCode?: string;
    equipmentName?: string;
  };
}

export const WorkEquipmentCell = ({ data }: WorkEquipmentCellProps) => {
  const { openModal } = useModal();

  const { lineName, equipmentName, equipmentCode } = data;

  const name = equipmentName ?? "-";
  const code = equipmentCode ?? "-";

  return (
    <Flex direction="column">
      <Tooltip
        label={
          <Text fz="sm" fw={700}>
            {lineName}
          </Text>
        }
      >
        <Text fz="sm" fw={700} c={theme.colors?.blue?.[7]} span>
          {lineName}
        </Text>
      </Tooltip>
      <DetailLink
        onClick={() => {
          if (equipmentCode) openModal(<EquipmentDetailForm equipmentCode={code} />, null, "");
        }}
        justify="flex-start"
      >
        <Tooltip
          label={
            <Text fz="sm" fw={700}>
              {name} ({code})
            </Text>
          }
        >
          <Text
            fz="sm"
            fw={700}
            w="fit-content"
            c={equipmentCode ? theme.colors?.blue?.[7] : undefined}
            span
          >
            {name}
          </Text>
        </Tooltip>
      </DetailLink>
    </Flex>
  );
};
