import { mutateEquipment } from "@/api/equipment/useEquipmentQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { DowntimeReasonsMultiAutoComplete } from "@/features/ui/multiAutoComplete/downtimeReason/downtimeReason-multiAutoComplete";
import { UsersMultiAutoComplete } from "@/features/ui/multiAutoComplete/user/user-multiAutoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, NumberInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiEquipmentsEquipmentIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const EquipmentUpdateForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { formatterProps } = params;
  const { t } = useTranslation();

  const { mutate: updateMutate } = useMutation(
    (params: MasterApiEquipmentsEquipmentIdPutRequest) =>
      mutateEquipment
        .update(params)
        .mutationFn(params as MasterApiEquipmentsEquipmentIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["equipment"]);
        closeModal(formatterProps);
        customAlert(
          `${t("message.editSuccess", { item: `${t("equipment.equipment")}` })}`,
          `${t("common.editSuccess")}`,
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("equipment.code") }),
          `${t("common.editFail", { item: `${t("equipment.equipment")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      siteCode: formatterProps.siteCode,
      code: formatterProps.code,
      name: formatterProps.name,
      equipmentType: formatterProps.equipmentType,
      volt: formatterProps.volt,
      seq: formatterProps.seq,
      fromLocationCode: formatterProps.fromLocationCode,
      toLocationCode: formatterProps.toLocationCode,
      targetLocationCode: formatterProps.targetLocationCode,
      downtimeReasonCode: formatterProps.downtimeReasonCode,
      workerGroup: formatterProps.workerGroup ?? [],
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate({
        equipmentId: formatterProps?.id,
        equipmentsEquipmentIdDeleteRequest: {
          siteCode: form.values.siteCode,
          code: form.values.code,
          name: form.values.name,
          equipmentType: form.values.equipmentType,
          volt: form.values.volt,
          seq: form.values.seq,
          fromLocationCode: form.values.fromLocationCode,
          toLocationCode: form.values.toLocationCode,
          targetLocationCode: form.values.targetLocationCode,
          downtimeReasonCode: form.values.downtimeReasonCode,
          workerGroup: form.values.workerGroup,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: `${t("equipment.code")}` }),
        `${t("common.editFail", { item: `${t("equipment.equipment")}` })}`,
        "red",
      );
    }
  };

  return (
    <Box w={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <SitesAutoComplete
            withAsterisk
            label={t("site.code")}
            width={"100%"}
            {...form.getInputProps("siteCode")}
            defaultValue={formatterProps?.siteCode}
            error={form.isDirty("siteCode") ? t("common.toSave") : ""}
          />
          <TextInput
            withAsterisk
            label={t("equipment.code")}
            {...form.getInputProps("code")}
            defaultValue={formatterProps?.code}
            disabled
          />
          <TextInput
            withAsterisk
            label={t("equipment.name")}
            {...form.getInputProps("name")}
            defaultValue={formatterProps?.name}
            placeholder={t("placeholder.input", { item: `${t("equipment.name")}` })}
            error={form.isDirty("name") ? t("common.toSave") : ""}
            styles={{
              input: {
                color: form.isDirty("name") ? "red !important" : "",
                borderColor: form.isDirty("name") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <TextInput
            label={t("equipment.type")}
            {...form.getInputProps("equipmentType")}
            placeholder={t("placeholder.input", { item: `${t("equipment.type")}` })}
            defaultValue={formatterProps?.equipmentType}
            error={form.isDirty("equipmentType") ? t("common.toSave") : ""}
            styles={{
              input: {
                color: form.isDirty("equipmentType") ? "red !important" : "",
                borderColor: form.isDirty("equipmentType") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <NumberInput
            label={t("equipment.volt")}
            {...form.getInputProps("volt")}
            value={!formatterProps.volt ? 0 : formatterProps.volt}
            error={form.isDirty("volt") ? t("common.toSave") : ""}
            placeholder={t("placeholder.input", { item: `${t("equipment.volt")}` })}
            styles={{
              input: {
                color: form.isDirty("volt") ? "red !important" : "",
                borderColor: form.isDirty("volt") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />

          <LocationAutoComplete
            label={t("equipment.fromLocation")}
            defaultValue={formatterProps?.fromLocationCode}
            placeholder={t("placeholder.select", { item: `${t("equipment.fromLocation")}` })}
            error={form.isDirty("") ? t("common.toSave") : ""}
            {...form.getInputProps("fromLocationCode")}
          />
          <LocationAutoComplete
            label={t("equipment.toLocation")}
            defaultValue={formatterProps?.toLocationCode}
            placeholder={t("placeholder.select", { item: `${t("equipment.toLocation")}` })}
            error={form.isDirty("toLocationCode") ? t("common.toSave") : ""}
            {...form.getInputProps("toLocationCode")}
          />
          <LocationAutoComplete
            label={t("equipment.targetLocation")}
            defaultValue={formatterProps?.targetLocationCode}
            placeholder={t("placeholder.select", { item: `${t("equipment.targetLocation")}` })}
            error={form.isDirty("targetLocationCode") ? t("common.toSave") : ""}
            {...form.getInputProps("targetLocationCode")}
          />
          <TextInput
            type="number"
            label={t("equipment.seq")}
            {...form.getInputProps("seq")}
            defaultValue={formatterProps?.seq}
            placeholder={t("placeholder.input", { item: `${t("equipment.seq")}` })}
            error={form.isDirty("seq") ? t("common.toSave") : ""}
            styles={{
              input: {
                color: form.isDirty("seq") ? "red !important" : "",
                borderColor: form.isDirty("seq") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <DowntimeReasonsMultiAutoComplete
            label={t("equipment.downtimeReason")}
            defaultValue={formatterProps?.downtimeReasonCode}
            error={form.isDirty("downtimeReasonCode") ? t("common.toSave") : ""}
            {...form.getInputProps("downtimeReasonCode")}
          />
          {customFunctions.ADD_WORKER_ADD_FUNCTION && (
            <UsersMultiAutoComplete
              label={t("equipment.workerGroup")}
              defaultValue={formatterProps?.workerGroup}
              {...form.getInputProps("workerGroup")}
              onChange={(value) => form.setFieldValue("workerGroup", value ?? [])}
            />
          )}
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              {t("common.cancel")}
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "equipment",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              {t("common.edit", { item: `${t("equipment.equipment")}` })}
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.div`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
