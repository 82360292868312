// @fileoverview : 재고관리 > 경남-재고수명조회
import { inventoryLifeHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import useQueryString from "@/hooks/useQueryString";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Flex, Group, Pagination, Radio, Text } from "@mantine/core";
import { useRef, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

const InventoryLifeWrap = {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  Wrapper: Wrapper,
};

export const InventoryLifePage = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);
  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<any> => new Set());

  const searchFieldsHeader = inventoryLifeHeader.filter(
    (item) => item.category === "text" && !item.isEnum,
  );

  const pageSize = 10;
  const totalRows = InventoryLifePageMockupData.length;

  // 선택한 단위에 따른 유효일수 필터링 기준 설정
  const filterCriteria = (timeUnit: string) => {
    switch (timeUnit) {
      case "day":
        return 1; // 1일 이상 남은 데이터
      case "week":
        return 7; // 7일 이상 남은 데이터
      case "month":
        return 30; // 30일 이상 남은 데이터
      case "quarter":
        return 90; // 90일 이상 남은 데이터
      case "half":
        return 180; // 180일 이상 남은 데이터
      default:
        return 1; // 기본값은 1일 이상
    }
  };

  const [timeUnit, setTimeUnit] = useState("day"); // 유효 기간 표시 단위 (day, week, month 등)

  // deadLine을 기준으로 필터링된 데이터 가져오기
  const filteredRows = InventoryLifePageMockupData.filter((row) => {
    return row.deadLine >= filterCriteria(timeUnit); // deadLine 기준으로 필터링
  });

  // 페이지에 맞는 데이터 슬라이싱
  const paginatedRows = filteredRows.slice((activePage - 1) * pageSize, activePage * pageSize);

  const columns: readonly Column<any>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "itemName",
      name: "품목명",
      sortable: true,
      resizable: true,
    },
    {
      key: "itemCode",
      name: "품목코드",
      sortable: true,
      resizable: true,
    },
    {
      key: "spec",
      name: "규격",
      sortable: true,
      resizable: true,
    },
    {
      key: "locationName",
      name: "로케이션/공정명",
      sortable: true,
      resizable: true,
    },
    {
      key: "lotNo",
      name: "Lot 번호",
      sortable: true,
      resizable: true,
    },
    {
      key: "serialNumber",
      name: "Serial 번호",
      sortable: true,
      resizable: true,
    },
    {
      key: "currentStock",
      name: "현재고",
      sortable: true,
      resizable: true,
    },
    {
      key: "deadLine",
      name: "남은 유효일수",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Text fw={700} color={row.deadLine > 0 ? "blue" : "red"}>
            {row.deadLine}
          </Text>
        );
      },
    },
    {
      key: "description",
      name: "비고",
      sortable: true,
      resizable: true,
    },
  ];

  return (
    <InventoryLifeWrap.Wrapper>
      <InventoryLifeWrap.Header>
        <InventoryLifeWrap.HeaderTitle>재고 수명 조회</InventoryLifeWrap.HeaderTitle>
        <InventoryLifeWrap.HeaderSubTitle>
          {"우리 회사의 재고의 유효 기간을 관리하고 추적합니다."}
        </InventoryLifeWrap.HeaderSubTitle>
      </InventoryLifeWrap.Header>
      <Flex w="100%" justify="space-between" gap="xs" align="flex-end" py="sm">
        <Radio.Group
          label="유효 일수를 선택해 주세요"
          withAsterisk
          value={timeUnit}
          onChange={(newTimeUnit) => {
            setTimeUnit(newTimeUnit); // 선택한 단위에 따라 유효 일수 필터링
            setPage(1); // 라디오 버튼 변경 시 페이지를 1로 초기화
          }}
        >
          <Group mt="xs">
            <Radio value="day" label="Day" />
            <Radio value="week" label="Week" />
            <Radio value="month" label="Month" />
            <Radio value="quarter" label="3 Month" />
            <Radio value="half" label="6 Month" />
          </Group>
        </Radio.Group>
      </Flex>
      <Flex w="100%" justify="space-between">
        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
          <CustomFilter filterType={inventoryLifeHeader} setQuery={setQuery} query={query} />
          <CustomSorter sorterType={inventoryLifeHeader} setSort={setSort} sort={sort} />
        </Flex>
        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
      </Flex>
      <Flex w="100%" h="100%">
        <GridWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={paginatedRows}
            rowHeight={40}
            rowKeyGetter={(row) => row.lotNo}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
          <Pagination
            onChange={setPage}
            value={activePage}
            total={Math.ceil(filteredRows.length / pageSize)} // 필터링된 데이터 기준으로 전체 페이지 계산
            size="lg"
            radius="sm"
            position="center"
          />
        </GridWrapper>
      </Flex>
    </InventoryLifeWrap.Wrapper>
  );
};

const InventoryLifePageMockupData = [
  {
    itemName: "Bolt 10mm",
    itemCode: "B001",
    spec: "10mm",
    locationName: "창고 A",
    lotNo: "L001",
    lotDate: "2024-12-30 15:45:30",
    serialNumber: "SN001",
    currentStock: 80,
    deadLine: 186,
    description: "",
  },
  {
    itemName: "Nut 12mm",
    itemCode: "N002",
    spec: "12mm",
    locationName: "창고 B",
    lotNo: "L002",
    lotDate: "2024-12-29 14:32:15",
    serialNumber: "SN002",
    currentStock: 230,
    deadLine: 183,
    description: "",
  },
  {
    itemName: "Washer 15mm",
    itemCode: "W003",
    spec: "15mm",
    locationName: "창고 A",
    lotNo: "L003",
    lotDate: "2024-12-28 13:22:50",
    serialNumber: "SN003",
    currentStock: 100,
    deadLine: 182,
    description: "",
  },
  {
    itemName: "Screw M5",
    itemCode: "S004",
    spec: "M5",
    locationName: "공정 1",
    lotNo: "L004",
    lotDate: "2024-12-27 12:15:00",
    serialNumber: "SN004",
    currentStock: 450,
    deadLine: 179,
    description: "",
  },
  {
    itemName: "Pipe 25mm",
    itemCode: "P005",
    spec: "25mm",
    locationName: "창고 C",
    lotNo: "L005",
    lotDate: "2024-12-26 11:45:45",
    serialNumber: "SN005",
    currentStock: 350,
    deadLine: 98,
    description: "",
  },
  {
    itemName: "Clamp 20mm",
    itemCode: "C006",
    spec: "20mm",
    locationName: "공정 2",
    lotNo: "L006",
    lotDate: "2024-12-25 10:22:12",
    serialNumber: "SN006",
    currentStock: 390,
    deadLine: 120,
    description: "",
  },
  {
    itemName: "Gear S1",
    itemCode: "G007",
    spec: "S1",
    locationName: "창고 B",
    lotNo: "L007",
    lotDate: "2024-12-24 09:18:20",
    serialNumber: "SN007",
    currentStock: 220,
    deadLine: 45,
    description: "",
  },
  {
    itemName: "Bearing 6200",
    itemCode: "B008",
    spec: "6200",
    locationName: "창고 D",
    lotNo: "L008",
    lotDate: "2024-12-23 08:45:00",
    serialNumber: "SN008",
    currentStock: 110,
    deadLine: 43,
    description: "",
  },
  {
    itemName: "Filter 50mm",
    itemCode: "F009",
    spec: "50mm",
    locationName: "공정 3",
    lotNo: "L009",
    lotDate: "2024-12-22 07:32:33",
    serialNumber: "SN009",
    currentStock: 50,
    deadLine: 42,
    description: "",
  },
  {
    itemName: "Spring Tension",
    itemCode: "S010",
    spec: "Tension",
    locationName: "창고 E",
    lotNo: "L010",
    lotDate: "2024-12-21 06:25:10",
    serialNumber: "SN010",
    currentStock: 90,
    deadLine: 41,
    description: "",
  },
  {
    itemName: "Valve 2inch",
    itemCode: "V011",
    spec: "2inch",
    locationName: "공정 4",
    lotNo: "L011",
    lotDate: "2024-12-20 05:50:45",
    serialNumber: "SN011",
    currentStock: 60,
    deadLine: 40,
    description: "",
  },
  {
    itemName: "Connector 15mm",
    itemCode: "C012",
    spec: "15mm",
    locationName: "창고 F",
    lotNo: "L012",
    lotDate: "2024-12-19 04:10:30",
    serialNumber: "SN012",
    currentStock: 100,
    deadLine: 39,
    description: "",
  },
  {
    itemName: "Gasket 3mm",
    itemCode: "G013",
    spec: "3mm",
    locationName: "창고 B",
    lotNo: "L013",
    lotDate: "2024-12-18 03:35:22",
    serialNumber: "SN013",
    currentStock: 80,
    deadLine: 38,
    description: "",
  },
  {
    itemName: "O-Ring 10mm",
    itemCode: "O014",
    spec: "10mm",
    locationName: "공정 5",
    lotNo: "L014",
    lotDate: "2024-12-17 02:50:55",
    serialNumber: "SN014",
    currentStock: 30,
    deadLine: 37,
    description: "",
  },
  {
    itemName: "Seal 5mm",
    itemCode: "S015",
    spec: "5mm",
    locationName: "창고 G",
    lotNo: "L015",
    lotDate: "2024-12-16 01:22:13",
    serialNumber: "SN015",
    currentStock: 65,
    deadLine: 36,
    description: "",
  },
  {
    itemName: "Rod 2m",
    itemCode: "R016",
    spec: "2m",
    locationName: "공정 6",
    lotNo: "L016",
    lotDate: "2024-12-15 00:10:00",
    serialNumber: "SN016",
    currentStock: 140,
    deadLine: 35,
    description: "",
  },
  {
    itemName: "Hose 1/2inch",
    itemCode: "H017",
    spec: "1/2inch",
    locationName: "창고 H",
    lotNo: "L017",
    lotDate: "2024-12-14 23:50:20",
    serialNumber: "SN017",
    currentStock: 220,
    deadLine: 34,
    description: "",
  },
  {
    itemName: "Chain S2",
    itemCode: "C018",
    spec: "S2",
    locationName: "창고 A",
    lotNo: "L018",
    lotDate: "2024-12-13 22:45:30",
    serialNumber: "SN018",
    currentStock: 70,
    deadLine: 33,
    description: "",
  },
  {
    itemName: "Tape Industrial",
    itemCode: "T019",
    spec: "Industrial",
    locationName: "창고 B",
    lotNo: "L019",
    lotDate: "2024-12-12 21:10:45",
    serialNumber: "SN019",
    currentStock: 350,
    deadLine: 32,
    description: "",
  },
  {
    itemName: "Pin 5mm",
    itemCode: "P020",
    spec: "5mm",
    locationName: "공정 7",
    lotNo: "L020",
    lotDate: "2024-12-11 20:22:30",
    serialNumber: "SN020",
    currentStock: 100,
    deadLine: 31,
    description: "",
  },
  {
    itemName: "Bracket Large",
    itemCode: "B021",
    spec: "Large",
    locationName: "창고 C",
    lotNo: "L021",
    lotDate: "2024-12-10 19:50:50",
    serialNumber: "SN021",
    currentStock: 75,
    deadLine: 30,
    description: "",
  },
  {
    itemName: "Sleeve 20mm",
    itemCode: "S022",
    spec: "20mm",
    locationName: "창고 D",
    lotNo: "L022",
    lotDate: "2024-12-09 18:45:00",
    serialNumber: "SN022",
    currentStock: 140,
    deadLine: 29,
    description: "",
  },
  {
    itemName: "Blade Sharp",
    itemCode: "B023",
    spec: "Sharp",
    locationName: "공정 8",
    lotNo: "L023",
    lotDate: "2024-12-08 17:10:40",
    serialNumber: "SN023",
    currentStock: 45,
    deadLine: 28,
    description: "",
  },
  {
    itemName: "Belt Drive",
    itemCode: "B024",
    spec: "Drive",
    locationName: "창고 E",
    lotNo: "L024",
    lotDate: "2024-12-07 16:22:13",
    serialNumber: "SN024",
    currentStock: 95,
    deadLine: 27,
    description: "",
  },
  {
    itemName: "Cover Plastic",
    itemCode: "C025",
    spec: "Plastic",
    locationName: "창고 F",
    lotNo: "L025",
    lotDate: "2024-12-06 15:12:05",
    serialNumber: "SN025",
    currentStock: 170,
    deadLine: 10,
    description: "",
  },
  {
    itemName: "Panel Control",
    itemCode: "P026",
    spec: "Control",
    locationName: "공정 9",
    lotNo: "L026",
    lotDate: "2024-12-05 14:55:55",
    serialNumber: "SN026",
    currentStock: 40,
    deadLine: 2,
    description: "",
  },
  {
    itemName: "Motor Electric",
    itemCode: "M027",
    spec: "Electric",
    locationName: "창고 G",
    lotNo: "L027",
    lotDate: "2024-12-04 13:25:25",
    serialNumber: "SN027",
    currentStock: 290,
    deadLine: 24,
    description: "",
  },
  {
    itemName: "Shaft 10mm",
    itemCode: "S028",
    spec: "10mm",
    locationName: "창고 H",
    lotNo: "L028",
    lotDate: "2024-12-03 12:32:00",
    serialNumber: "SN028",
    currentStock: 110,
    deadLine: 0,
    description: "",
  },
  {
    itemName: "Bearing Roller",
    itemCode: "B029",
    spec: "Roller",
    locationName: "창고 A",
    lotNo: "L029",
    lotDate: "2024-12-02 11:15:45",
    serialNumber: "SN029",
    currentStock: 190,
    deadLine: 22,
    description: "",
  },
  {
    itemName: "Switch On/Off",
    itemCode: "S030",
    spec: "On/Off",
    locationName: "창고 B",
    lotNo: "L030",
    lotDate: "2024-12-01 10:22:13",
    serialNumber: "SN030",
    currentStock: 55,
    deadLine: 21,
    description: "",
  },
  {
    itemName: "Wheel Small",
    itemCode: "W031",
    spec: "Small",
    locationName: "공정 10",
    lotNo: "L031",
    lotDate: "2024-11-30 09:45:10",
    serialNumber: "SN031",
    currentStock: 50,
    deadLine: 14,
    description: "",
  },
];

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    // --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    // --rdg-header-background-color: {(props) =>
    // props.isDarkMode ? "white" : "none"};
    // --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    // --rdg-row-hover-background-color: {(props) =>
    // props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
