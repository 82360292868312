// @fileoverview : 경남-PMS > 고속성형기데이터통계및분석 > 전력
import { useModal } from "@/features/modal/ModalStackManager";
import PMV2PressCapacityChart from "@/features/ui/PMS/chart/AnalysisPressChart";
import { MachineSearchBox } from "@/features/ui/PMS/machineSearchBox";
import CustomTable from "@/features/ui/table/CustomTable";
import { ActionIcon, Flex, Text } from "@mantine/core";
import { IconReportSearch } from "@tabler/icons-react";

export const AnalysisPowerPage = () => {
  const { openModal } = useModal();

  const Header = {
    equipment: ["기계 제조사", "기계 이름", "제조 연월일", "제조 번호"],
    item: ["모델", "CODE", "품명", "SPEC"],
    todayCount: ["일 생산 수량/카운트"],
    todayPower: ["일 개당 평균 소비 전력"],
    todayTotal: ["일 총 소비 전력"],
  };

  const BodyData = {
    equipment: [
      {
        "기계 제조사": "제조사1",
        "기계 이름": "이름1",
        "제조 연월일": "2022-01-01",
        "제조 번호": "1234",
      },
    ],
    item: [{ 모델: "전체", CODE: "-", 품명: "-", SPEC: "-" }],
    todayCount: [{ "일 생산 수량/카운트": "-" }],
    todayPower: [{ "일 개당 평균 소비 전력": "-" }],
    todayTotal: [{ "일 총 소비 전력": "-" }],
  };

  return (
    <Flex gap="md" direction="column">
      <Flex justify="space-between">
        <Text size="xl">전력</Text>
        <ActionIcon
          onClick={() => {
            openModal(<MachineSearchBox />, null, "기계 검색");
          }}
        >
          <IconReportSearch />
        </ActionIcon>
      </Flex>
      <CustomTable headers={Header.equipment} data={BodyData.equipment} />
      <Flex gap="md">
        <CustomTable headers={Header.item} data={BodyData.item} width="30%" />
        <PMV2PressCapacityChart />
      </Flex>
      <Flex gap="md">
        <CustomTable headers={Header.todayCount} data={BodyData.todayCount} />
        <CustomTable headers={Header.todayPower} data={BodyData.todayPower} />
        <CustomTable headers={Header.todayTotal} data={BodyData.todayTotal} />
      </Flex>
    </Flex>
  );
};
