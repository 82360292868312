import { mutateItemCategories } from "@/api/itemCategories/useItemCategoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  ItemCategoriesGet200ResponseRowsInner,
  MasterApiItemCategoriesItemCategoryIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const ItemCategoryUpdateForm = (params: {
  formatterProps: ItemCategoriesGet200ResponseRowsInner;
}) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiItemCategoriesItemCategoryIdPutRequest) =>
      mutateItemCategories
        .update(params)
        .mutationFn(params as MasterApiItemCategoriesItemCategoryIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["itemCategories"]);
        closeModal(formatterProps);
        customAlert(
          `${t("message.editSuccess", { item: `${t("category.category")}` })}`,
          `${t("common.editSuccess")}`,
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("category.name") }),
          `${t("common.editFail", { item: `${t("category.category")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      name: formatterProps.name,
    },
  });

  const onSubmit = () => {
    try {
      updateMutate({
        itemCategoryId: formatterProps.id as number,
        itemCategoriesGetRequest: {
          name: form.values.name!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("category.name") }),
        `${t("common.editFail", { item: `${t("category.category")}` })}`,
        "red",
      );
    }
  };

  return (
    <Update.Wrapper>
      <Update.Input
        label={t("category.category")}
        withAsterisk
        placeholder={t("placeholder.input", { item: `${t("category.name")}` })}
        defaultValue={formatterProps?.name}
        {...form.getInputProps("name")}
        error={form.isDirty("name") ? t("common.toSave") : ""}
        styles={{
          input: {
            color: form.isDirty("name") ? "red !important" : "",
            borderColor: form.isDirty("name") ? "red !important" : "",
          },
          error: { fontSize: 11, color: "red" },
        }}
      />
      <Update.ButtonBox>
        <Update.Button color="gray" onClick={closeModal}>
          {t("common.cancel")}
        </Update.Button>
        <Update.Button
          color="blue"
          disabled={validateFormValues({
            pageType: "itemCategory",
            values: form.values,
          })}
          onClick={onSubmit}
        >
          {t("common.edit", { item: `${t("category.category")}` })}
        </Update.Button>
      </Update.ButtonBox>
    </Update.Wrapper>
  );
};
