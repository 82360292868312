import QRCode from "qrcode-svg";

export const QRCodeComponent = ({ lotId }: { lotId: number | undefined }) => {
  const svg = new QRCode({
    content: `${lotId}`,
    width: 180,
    height: 180,
    color: "#000000",
    background: "#fff",
    xmlDeclaration: true,
    ecl: "M",
    join: true,
    container: "svg",
  }).svg();

  return <div dangerouslySetInnerHTML={{ __html: svg }} style={{ width: 180, height: 180 }} />;
};
