import { customFunctions } from "@/config/customFunction";
import { OrderItemIncomingFormProps } from "@/features/order/form/orderItemsIncomingForm";
import { useCreateLot } from "@/hooks/useCreateLot";
import { PurchaseOrdersGet200ResponseRowsInnerPurchaseOrderItemsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useEffect, useMemo } from "react";

type useCreatePurchaseOrderItemsWithLotProps = {
  items: PurchaseOrdersGet200ResponseRowsInnerPurchaseOrderItemsInner[] | undefined;
};

export const useCreatePurchaseOrderItemsWithLot = ({
  items,
}: useCreatePurchaseOrderItemsWithLotProps) => {
  const { createLot, createdLotNames } = useCreateLot();

  const usesLotNameItem = items?.filter((item) => item.item?.usesLotName);
  const itemCodeList = usesLotNameItem?.map((item) => item.itemCode) as string[];

  const updatedPurchaseOrderItemsWithLot = useMemo(() => {
    let lotNameIndex = 0;
    return items?.map((item) => {
      const lotName = item.item?.usesLotName ? createdLotNames[lotNameIndex++] : undefined;
      return {
        ...item,
        inputValue: customFunctions.ADD_ORDER_AUTO_CREATE_LOT
          ? item.inputEligibleQuantity
          : undefined,
        purchaseOrderItemQty: "1",
        lotInfo: {
          name: lotName,
          expiration: undefined,
        },
      };
    }) as OrderItemIncomingFormProps[];
  }, [createdLotNames]);

  useEffect(() => {
    if (items && customFunctions.ADD_ORDER_AUTO_CREATE_LOT) {
      createLot(itemCodeList);
    }
  }, []);

  return { updatedPurchaseOrderItemsWithLot };
};
