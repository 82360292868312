import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { ExtractChildrenType } from "@/features/productionPlan/form/CreateForm";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { RoutingOutsourceAutoComplete } from "@/features/ui/autoComplete/routingOutsources/routingOutsources-autoComplete";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { UsersMultiAutoComplete } from "@/features/ui/multiAutoComplete/user/user-multiAutoComplete";
import { setDateFormatString } from "@/utils/dateTimeUtil";
import styled from "@emotion/styled";
import { Checkbox, Flex, Input, Table, Text, TextInput, Title } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { UseFormReturnType } from "@mantine/form";
import {
  ProductionPlansWithWorksPostRequest,
  ProductionPlansWithWorksPostRequestWorksInner,
  ProductionPlansWithWorksPostRequestWorksInnerWorkersInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";

interface WorkFormProps {
  form: UseFormReturnType<
    Partial<ProductionPlansWithWorksPostRequest> & {
      scheduledAtDate: Date;
      works?: (ProductionPlansWithWorksPostRequestWorksInner & {
        checked?: boolean;
        equipmentType?: string;
        routingEquipmentCode?: string[] | null;
        workers?: ProductionPlansWithWorksPostRequestWorksInnerWorkersInner[] | string[];
      })[];
      siteCode?: string | undefined;
      siteName?: string | undefined;
      children?: ExtractChildrenType[];
    }
  >;
  setName: (index: number, name: string, InnerIndex?: number) => void;
  setSubName: (index: number, subIndex: number, name: string) => void;
  setCheckedAll?: (value: boolean) => void;
  setWorkers?: (index: number, subIndex: number, workers: string[]) => void;
  checkedAll?: boolean;
}
export const WorkForm = (params: WorkFormProps) => {
  const { form, setName, setSubName, setCheckedAll, setWorkers, checkedAll } = params;
  const { openModal } = useModal();

  const renderMainWorksHtml = () => {
    if (Array.isArray(form.values.works)) {
      return form.values.works.flatMap((work, i) => (
        <tr key={i}>
          {customFunctions.ADD_PROCESS_BASED_PRODUCTION_PLAN && (
            <Td>
              <Checkbox
                checked={form?.values?.works?.[i]?.checked}
                onChange={(e) => {
                  form.setFieldValue(`works.${i}.checked`, e.currentTarget.checked);
                }}
              />
            </Td>
          )}
          <Td>
            <DateInput
              readOnly
              popoverProps={{ withinPortal: true }}
              icon={<IconCalendar size="1.2rem" />}
              w={"100%"}
              valueFormat="YYYY-MM-DD"
              onClick={() =>
                openModal(
                  <Calendar dates={[form.values.scheduledAtDate, form.values.scheduledAtDate]} />,
                  null,
                  "날짜 선택",
                ).then((value) => {
                  if (isCalendarDate(value))
                    form.setFieldValue(`works.${i}.scheduledAt`, dayjs(value[0]).toDate());
                })
              }
              placeholder={setDateFormatString(form.values.scheduledAtDate)}
              clearable
              {...form.getInputProps(`works.${i}.scheduledAt`)}
            />
          </Td>
          <Td>
            <Text>{form.values.itemCode}</Text>
          </Td>
          <Td>
            <Text>{work.routingCode}</Text>
          </Td>
          <Td>
            <EquipmentAutoComplete
              disabled={
                customFunctions.ADD_PROCESS_BASED_PRODUCTION_PLAN &&
                !form?.values?.works?.[i]?.checked
              }
              placeholder="설비 선택"
              initCodes={form?.values?.works?.[i]?.routingEquipmentCode}
              onSelectedData={(data) => {
                form.setFieldValue(`works.${i}.equipmentType`, data?.equipmentType);
              }}
              {...form.getInputProps(`works.${i}.equipmentCode`)}
            />
          </Td>
          <Td>
            <RoutingOutsourceAutoComplete
              placeholder="외주처 선택"
              {...form.getInputProps(`works.${i}.routingOutsourceId`)}
              setName={(e: any) => setName(i, e)}
            />
          </Td>
          {customFunctions.ADD_WORKER_ADD_FUNCTION && (
            <Td>
              <Flex w="15rem">
                <UsersMultiAutoComplete
                  w="100%"
                  value={
                    form.values.works?.[i]?.workers?.map((worker) =>
                      typeof worker === "string" ? worker : worker.userCode,
                    ) ?? []
                  }
                  onChange={(value) => form.setFieldValue(`works.${i}.workers`, value)}
                  equipmentCode={form.values.works?.[i]?.equipmentCode}
                />
              </Flex>
            </Td>
          )}
          {customFunctions.ADD_DEFECT_LOCATION && (
            <Td>
              <LocationAutoComplete
                placeholder="불량창고 선택"
                isAvailable={false}
                {...form.getInputProps(`works.${i}.defectLocationCode`)}
              />
            </Td>
          )}
          {customFunctions.ADD_SETTING_PACKAGING_MACHINE && (
            <Td>
              <Input
                disabled={form.values.works?.[i]?.equipmentType !== "PACKING"}
                placeholder="포장단위 입력"
                {...form.getInputProps(`works.${i}.kgPerCount`)}
              />
            </Td>
          )}
          <Td>
            <TextInput type="number" {...form.getInputProps(`works.${i}.targetQuantity`)} />
          </Td>
        </tr>
      ));
    }
    return null;
  };

  const renderChildWorksHtml = () => {
    if (customFunctions.ADD_PROCESS_BASED_PRODUCTION_PLAN && Array.isArray(form.values.children)) {
      return form.values.children.flatMap((child, i) => {
        return child.works?.map((work, j) => (
          <tr key={`${i}-${j}`}>
            <Td>
              <Checkbox
                checked={form?.values?.children?.[i]?.works?.[j]?.checked}
                onChange={(e) => {
                  form.setFieldValue(`children.${i}.works.${j}.checked`, e.target.checked);
                }}
              />
            </Td>
            <Td>
              <DateInput
                readOnly
                popoverProps={{ withinPortal: true }}
                icon={<IconCalendar size="1.2rem" />}
                w={"100%"}
                valueFormat="YYYY-MM-DD"
                onClick={() =>
                  openModal(
                    <Calendar dates={[form.values.scheduledAtDate, form.values.scheduledAtDate]} />,
                    null,
                    "날짜 선택",
                  ).then((value) => {
                    if (isCalendarDate(value))
                      form.setFieldValue(
                        `children.${i}.works.${j}.scheduledAt`,
                        dayjs(value[0]).toDate(),
                      );
                  })
                }
                placeholder={setDateFormatString(form.values.scheduledAtDate)}
                clearable
                {...form.getInputProps(`children.${i}.works.${j}.scheduledAt`)}
              />
            </Td>
            <Td>
              <Text>{child.itemCode}</Text>
            </Td>
            <Td>
              <Text>{work.routingCode}</Text>
            </Td>
            <Td>
              <EquipmentAutoComplete
                disabled={
                  customFunctions.ADD_PROCESS_BASED_PRODUCTION_PLAN &&
                  !form?.values?.children?.[i]?.works?.[j]?.checked
                }
                placeholder="설비 선택"
                initCodes={form?.values?.children?.[i]?.works?.[j]?.routingEquipmentCode}
                onSelectedData={(data) => {
                  form.setFieldValue(`children.${i}.works.${j}.equipmentType`, data?.equipmentType);
                }}
                {...form.getInputProps(`children.${i}.works.${j}.equipmentCode`)}
              />
            </Td>
            <Td>
              <RoutingOutsourceAutoComplete
                placeholder="외주처 선택"
                {...form.getInputProps(`children.${i}.works.${j}.routingOutsourceId`)}
                setName={(e: any) => setName(i, e, j)}
              />
            </Td>
            {customFunctions.ADD_WORKER_ADD_FUNCTION && (
              <Td>
                <Flex w="15rem">
                  <UsersMultiAutoComplete
                    width="100%"
                    value={
                      form.values.children?.[i]?.works?.[j]?.workers?.map((worker) =>
                        typeof worker === "string" ? worker : worker.userCode,
                      ) ?? []
                    }
                    onChange={(value) =>
                      form.setFieldValue(`children.${i}.works.${j}.workers`, value)
                    }
                    equipmentCode={form.values.children?.[i]?.works?.[j]?.equipmentCode}
                  />
                </Flex>
              </Td>
            )}
            {customFunctions.ADD_DEFECT_LOCATION && (
              <Td>
                <LocationAutoComplete
                  placeholder="불량창고 선택"
                  isAvailable={false}
                  {...form.getInputProps(`children.${i}.works.${j}.defectLocationCode`)}
                />
              </Td>
            )}
            {customFunctions.ADD_SETTING_PACKAGING_MACHINE && (
              <Td>
                <Input
                  disabled={form.values.children?.[i]?.works?.[j]?.equipmentType !== "PACKING"}
                  placeholder="세팅값 입력"
                  {...form.getInputProps(`children.${i}.works.${j}.kgPerCount`)}
                />
              </Td>
            )}
            <Td>
              <TextInput
                type="number"
                {...form.getInputProps(`children.${i}.works.${j}.targetQuantity`)}
              />
            </Td>
          </tr>
        ));
      });
    }
    return null;
  };

  const renderOrder = customFunctions.ADD_PROCESS_BASED_PRODUCTION_PLAN_SORT_ASC ? [1, 2] : [2, 1];

  const renderHtml = renderOrder.map((order) => {
    switch (order) {
      case 1:
        return <>{renderChildWorksHtml()}</>;
      case 2:
        return <>{renderMainWorksHtml()}</>;
      default:
        return null;
    }
  });

  return (
    <>
      <TableWrapper>
        <thead>
          <tr>
            <th colSpan={7}>
              <Title order={3}>
                {form.values.purchaseOrderItemId ? "수주 생산계획" : "기본 생산계획"}
              </Title>
              <Text fz="sm">생산계획 일자: {setDateFormatString(form.values.scheduledAtDate)}</Text>
            </th>
          </tr>
          <tr>
            {customFunctions.ADD_PROCESS_BASED_PRODUCTION_PLAN && (
              <Th width={1}>
                <Checkbox
                  checked={checkedAll}
                  onChange={(event) => setCheckedAll && setCheckedAll(event.currentTarget.checked)}
                />
              </Th>
            )}
            <Th width={15}>일자</Th>
            <Th width={10}>품목코드</Th>
            <Th width={10}>라우팅코드</Th>
            <Th width={19}>
              설비 <span style={{ color: "red" }}>*</span>
            </Th>
            <Th width={19}>외주처</Th>
            {customFunctions.ADD_WORKER_ADD_FUNCTION && <Th width={19}>작업자</Th>}
            {customFunctions.ADD_DEFECT_LOCATION && <Th width={19}>불량 창고</Th>}
            {customFunctions.ADD_SETTING_PACKAGING_MACHINE && <Th width={8}>포장단위</Th>}
            <Th width={8}>목표수량</Th>
          </tr>
        </thead>
        <tbody>{renderHtml}</tbody>
      </TableWrapper>

      {/* 패밀리 금형일 경우 sub 데이터 추가 */}
      {Array.isArray(form.values.sub) ? (
        <TableWrapper>
          <thead style={{ width: "100%" }}>
            <tr>
              <Title order={3}>패밀리 금형 생산계획</Title>
            </tr>
            <tr>
              <Th width={15}>일자</Th>
              <Th width={10}>품목코드</Th>
              <Th width={10}>라우팅코드</Th>
              <Th width={19}>
                설비 <span style={{ color: "red" }}>*</span>
              </Th>
              <Th width={19}>외주처</Th>
              {customFunctions.ADD_WORKER_ADD_FUNCTION && <Th width={19}>작업자</Th>}
              {customFunctions.ADD_DEFECT_LOCATION && <Th width={19}>불량 창고</Th>}
              <Th width={8}>목표수량</Th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(form.values.sub) &&
              form.values.sub?.map((subData, i) => {
                return subData.works.map((work, j) => {
                  return (
                    <tr key={j}>
                      <Td width={15}>
                        <DateInput
                          readOnly
                          popoverProps={{ withinPortal: true }}
                          icon={<IconCalendar size="1.2rem" />}
                          w={"100%"}
                          valueFormat="YYYY-MM-DD"
                          onClick={() =>
                            openModal(
                              <Calendar
                                dates={[form.values.scheduledAtDate, form.values.scheduledAtDate]}
                              />,
                              null,
                              "날짜 선택",
                            ).then((value) => {
                              if (isCalendarDate(value))
                                form.setFieldValue(
                                  `sub.${i}.works.${j}.scheduledAt`,
                                  dayjs(value[0]).toDate(),
                                );
                            })
                          }
                          placeholder={setDateFormatString(form.values.scheduledAtDate)}
                          clearable
                          {...form.getInputProps(`sub.${i}.works.${j}.scheduledAt`)}
                        />
                      </Td>
                      <Td width={10}>
                        <Text>{subData.itemCode}</Text>
                      </Td>
                      <Td width={10}>
                        <Text>{work.routingCode}</Text>
                      </Td>
                      <Td width={19}>
                        <EquipmentAutoComplete
                          placeholder="설비 선택"
                          {...form.getInputProps(`sub.${i}.works.${j}.equipmentCode`)}
                        />
                      </Td>
                      <Td width={19}>
                        <RoutingOutsourceAutoComplete
                          placeholder="외주처 선택"
                          {...form.getInputProps(`sub.${i}.works.${j}.routingOutsourceId`)}
                          setName={(e: any) => setSubName(i, j, e)}
                        />
                      </Td>
                      {customFunctions.ADD_WORKER_ADD_FUNCTION && (
                        <Td>
                          <Flex w="15rem">
                            <UsersMultiAutoComplete
                              w="100%"
                              value={
                                form.values.sub?.[i]?.works?.[j]?.workers?.map((worker) =>
                                  typeof worker === "string" ? worker : worker.userCode,
                                ) ?? []
                              }
                              onChange={(value) =>
                                form.setFieldValue(`sub.${i}.works.${j}.workers`, value)
                              }
                              equipmentCode={form.values.sub?.[i]?.works?.[j]?.equipmentCode}
                              setWorkers={(value) => setWorkers && setWorkers(i, j, value)}
                            />
                          </Flex>
                        </Td>
                      )}
                      {customFunctions.ADD_DEFECT_LOCATION && (
                        <Td width={19}>
                          <LocationAutoComplete
                            placeholder="불량창고 선택"
                            isAvailable={false}
                            {...form.getInputProps(`subData.${i}.works.${i}.defectLocationCode`)}
                          />
                        </Td>
                      )}
                      <Td width={8}>
                        <TextInput
                          type="number"
                          {...form.getInputProps(`sub.${i}.works.${j}.targetQuantity`)}
                          defaultValue={subData.targetQuantity}
                        />
                      </Td>
                    </tr>
                  );
                });
              })}
          </tbody>
        </TableWrapper>
      ) : null}
    </>
  );
};

const TableWrapper = styled(Table)`
  /* width: 500px; */
  /* height: 300px; */
  margin-top: 4px;
  width: 100%;
  overflow: hidden;
`;

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
  height: auto;
`;

const Td = styled.td<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
  height: auto;
`;
