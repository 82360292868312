import useInventoriesGetQuery from "@/api/inventories/useInventoriesGetQuery";
import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { mutateLots } from "@/api/lots/useLotsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { HeaderTitle } from "@/features/standard/Title";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import styled from "@emotion/styled";
import { Button, Input, Table, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  DefaultApiLotsGetLotNamePostRequest,
  DefaultApiLotsPostRequest,
  DefaultApiWmsBulkInputPostRequest,
  WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner,
  WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInnerLotInfo,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BulkIncomingFormRow } from "./BulkIncomingFormRow";
import { Td } from "./OutgoingAllForm";

interface IncomingBulkFormProps
  extends WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner {
  items: {
    itemCode: string;
    itemName: string;
    locationCode: string;
    locationName: string;
    spec: string;
    quantity: string;
    lotId: number;
    lotInfo: WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInnerLotInfo;
    actionFrom: string;
  }[];
}

export const IncomingBulkForm = () => {
  const queryClient = useQueryClient();
  const { closeModal } = useModal();
  const { t } = useTranslation();

  const form = useForm<Partial<IncomingBulkFormProps>>({
    initialValues: {
      items: [],
      itemCode: undefined,
      locationCode: undefined,
      quantity: undefined,
      lotId: undefined,
      lotInfo: {
        name: undefined,
        expiration: undefined,
      },
      actionFrom: undefined,
    },
  });

  const { mutate: bulkIncomingMutate } = useMutation(
    (params: DefaultApiWmsBulkInputPostRequest) =>
      mutateInventories
        .bulkIncoming(params)
        .mutationFn(params as DefaultApiWmsBulkInputPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
        closeModal(form.values);
        customAlert(
          `${t("inventory.successMessage", { item: `${t("inventory.stockSerial")}` })}`,
          `${t("inventory.success", { item: `${t("inventory.stockSerial")}` })}`,
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("inventory.stockSerial") }),
          `${t("inventory.fail", { item: `${t("inventory.stockSerial")}` })}`,
          "red",
        );
      },
    },
  );

  const { mutate: getLotName } = useMutation((params: DefaultApiLotsGetLotNamePostRequest) =>
    mutateLots.getLotName(params).mutationFn(params as DefaultApiLotsGetLotNamePostRequest | any),
  );

  const { mutate: postLotsMutate } = useMutation(
    (params: DefaultApiLotsPostRequest) =>
      mutateLots.create(params).mutationFn(params as DefaultApiLotsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["lots"]);
      },
    },
  );

  const [lotId, setLotId] = useState<string>("");

  let tempLotId = "";

  const onSubmit = () => {
    const items: IncomingBulkFormProps["items"] = form.values?.items || [];

    bulkIncomingMutate({
      wmsBulkInputPostRequest: {
        locationCode: items.map((item) => item.locationCode),
        itemCode: items.map((item) => item.itemCode),
        quantity: items.map((item) => item.quantity),
        lotId: items.map((item) => item.lotId),
        lotInfo: items.map((item) => item.lotInfo),
        actionFrom: items.map((item) => item.actionFrom),
      },
    });
  };

  useInventoriesGetQuery(
    {
      query: lotId
        ? {
            $and: [
              {
                lotId: { $eq: lotId },
              },
              {
                quantity: { $gt: 0 },
              },
            ],
          }
        : {},
    },
    {
      onSuccess: (response) => {
        const lotData = response.rows?.[0];
        tempLotId = "";
        setLotId("");
        if (!lotData) {
          alert(`${t("lot.notFound")}`);
          return;
        }

        const { lot, itemCode, itemName, spec, quantity, locationCode, locationName } = lotData;

        getLotName(
          {
            lotsGetLotNamePostRequest: {
              itemCode: [lotData.itemCode],
            },
          },
          {
            onSuccess: (res) => {
              postLotsMutate(
                {
                  lotsGetRequest: {
                    name: res.data[0],
                    itemCode: lotData.itemCode,
                  },
                },
                {
                  onSuccess: (res) => {
                    form.insertListItem("items", {
                      lotId: res?.data?.id,
                      itemCode,
                      itemName,
                      spec,
                      quantity,
                      locationCode,
                      locationName,
                      lotInfo: { name: res?.data?.name, expiration: res?.data?.expiration },
                      actionFrom: "WEB",
                    });
                  },
                },
              );
            },
          },
        );
      },
    },
  );

  const handleRowChange = (index: number, quantity: string) => {
    form.setFieldValue(`items.${index}.quantity`, quantity);
  };

  const handleRowDelete = (index: number) => {
    form.removeListItem("items", index);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setLotId(tempLotId);
    } else {
      tempLotId += e.key;
    }
  };

  return (
    <IncomingBarcodeFormContainer>
      <TitleHeader>
        <HeaderTitle>{t("barcode.lot")}</HeaderTitle>
        <HeaderSubTitle>{t("barcode.lotDescription")}</HeaderSubTitle>
      </TitleHeader>
      <BarcodeInputContainer>
        <Input.Wrapper label={t("barcode.field")}>
          <Input
            placeholder={t("barcode.placeholder")}
            onKeyDown={handleKeyDown}
            value={tempLotId}
            data-autofocus
          />
        </Input.Wrapper>
      </BarcodeInputContainer>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <Th width={8}>{t("lot.id<")}</Th>
              <Th width={14}>{t("lot.name")}</Th>
              <Th width={14}>{t("location.code")}</Th>
              <Th width={14}>{t("location.name")}</Th>
              <Th width={14}>{t("item.code")}</Th>
              <Th width={14}>{t("item.name")}</Th>
              <Th width={10}>{t("item.spec")}</Th>
              <Th width={14}>{t("inventory.quantity")}</Th>
              <Th width={7}>&nbsp;</Th>
            </tr>
          </thead>
          <tbody>
            {form.values?.items?.map((formData, index) => (
              <BulkIncomingFormRow
                formData={formData}
                seq={index}
                key={formData.lotId}
                onChange={handleRowChange}
                onDelete={handleRowDelete}
              />
            ))}
            {form.values.items && form.values.items.length === 0 && (
              <tr>
                <Td colSpan={8} width={100} style={{ textAlign: "center" }}>
                  {t("barcode.lotScan")}
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      <ButtonBox>
        <Button color="gray" onClick={closeModal}>
          {t("common.cancel")}
        </Button>
        <Button onClick={onSubmit}>{t("inventory.stockSerial")}</Button>
      </ButtonBox>
    </IncomingBarcodeFormContainer>
  );
};

const IncomingBarcodeFormContainer = styled.div`
  display: flex;
  width: 70rem;
  min-height: 20rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const TitleHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const HeaderSubTitle = styled(Title)`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: -0.24px;
  white-space: pre-wrap;
`;

const BarcodeInputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  padding: 8px;
  align-items: flex-end;
  gap: 10px;
  flex-shrink: 0;
`;

const ButtonBox = styled.div`
  display: flex;
  height: 44px;
  padding: 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
`;
