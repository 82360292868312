import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { Td } from "@/features/workByManagement/WorkTableRow";
import { theme } from "@/styles/theme";
import { setToLocaleString } from "@/utils/unitMark";
import { Text, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconMinus, IconPlus } from "@tabler/icons-react";

interface WorkProductionPlanTableRowProps {
  data: any; // TODO: 품목코드, 품목명, 목표수량
  children: React.ReactNode;
}

export const WorkProductionPlanTableRow = ({ data, children }: WorkProductionPlanTableRowProps) => {
  const { id, itemCode, itemName, targetQuantity, unit } = data;
  const { openModal } = useModal();
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <>
      <tr>
        <Td width={3}>
          <Text ta="center">
            {!opened && (
              <IconPlus onClick={toggle} style={{ verticalAlign: "middle", cursor: "pointer" }} />
            )}
            {opened && (
              <IconMinus onClick={toggle} style={{ verticalAlign: "middle", cursor: "pointer" }} />
            )}
          </Text>
        </Td>
        <Td width={19}>
          <DetailLink
            onClick={() => openModal(<ProductionPlanViewForm ProductionPlanId={id} />, null, "")}
            justify="flex-start"
          >
            <Tooltip label={itemName}>
              <Text c={theme.colors?.blue?.[7]} fw={700} span>
                {itemName}
              </Text>
            </Tooltip>
          </DetailLink>
        </Td>
        <Td width={23}>
          <DetailLink
            onClick={() => {
              openModal(<ItemsDetailForm itemCode={itemCode} />, null, "품목 상세");
            }}
            justify="flex-start"
          >
            <Tooltip label={itemCode}>
              <Text c={theme.colors?.blue?.[7]} fz="sm" fw={700} span>
                {itemCode}
              </Text>
            </Tooltip>
          </DetailLink>
        </Td>
        <Td width={23}>
          <Text fz="md" ta="right">
            {setToLocaleString(targetQuantity)} {unit}
          </Text>
        </Td>
        <Td width={32}>-</Td>
      </tr>
      {opened && children}
    </>
  );
};
