// @fileoverview : 재고관리 > WMS로그

import { mutatePalletErps } from "@/api/palletErp/usePalletErpQuery";
import { customFunctions } from "@/config/customFunction";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ChipsBox } from "@/features/standard/Chips/ChipsBox";
import { Main } from "@/features/standard/Main";
import { Wrapper } from "@/features/standard/Wrapper";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { DateHeader } from "@/features/workLog/DateHeader";
import { usePub } from "@/hooks";
import { useLoader } from "@/hooks/useLoader";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { wmsStatus } from "@/utils/wmsStatus";
import { css } from "@emotion/css";

import { Avatar, Button, Chip, Flex, Group, Text, Tooltip } from "@mantine/core";

import {
  DefaultApiErpSyncPushPushStockMovePostRequest,
  WlWithEslViewGet200Response,
  WlWithEslViewGet200ResponseRowsInner,
  WmsLogsGet200Response,
  WmsLogsGet200ResponseRowsInnerActionTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconFilterPlus, IconFolder, IconFolderCancel, IconRecycle } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

const WmsLogs = Object.assign({}, Main, {
  Wrapper: Wrapper,
});

export const WmsLogsPage = () => {
  const { openModal } = useModal();
  const { t } = useTranslation();
  const [filterErpSync, setFilterErpSync] = useState(false);
  const { setLoading, LoadingOverlay, loading } = useLoader();
  const publish = usePub();

  const {
    data,
    query,
    setQuery,
    startDate,
    endDate,
    resetQueryStrings,
    refetch,
    selectedRows,
    setSelectedRows,
  } = useStandardLayout<AxiosResponse<WlWithEslViewGet200Response>>();

  const rows = data?.data.rows ?? [];

  const selectedRowsArray = Array.from(selectedRows);

  const disabledStockMove =
    rows.some((row) => selectedRowsArray.includes(row.id) && !row.checkErpStockMove) ||
    rows.some((row) => selectedRowsArray.includes(row.id) && row.ifWorkingTag === "A") ||
    loading;

  const disabledStockMoveCancel =
    rows.some((row) => selectedRowsArray.includes(row.id) && !row.checkErpStockMove) ||
    rows.some((row) => selectedRowsArray.includes(row.id) && row.ifWorkingTag !== "A") ||
    loading;

  useEffect(() => {
    setQuery((prev) => {
      if (filterErpSync) {
        return [
          ...prev,
          {
            ifWorkingTag: {
              $or: [{ $eq: null }, { $eq: "D" }],
            },
            checkErpStockMove: { $eq: true },
          },
        ];
      }
      // filterErpSync가 없을 경우 checkErpSync 객체를 제거
      return prev.filter((item) => !item.ifWorkingTag);
    });
  }, [filterErpSync]);

  useEffect(() => {
    setQuery((prev) => {
      // 기존 query에서 createdAt 조건이 이미 존재하는지 확인
      const hasCreatedAt = prev.some(
        (item) =>
          item.createdAt?.$between?.[0] === startDate && item.createdAt?.$between?.[1] === endDate,
      );

      if (hasCreatedAt) {
        // 조건이 동일하면 상태를 업데이트하지 않음
        return prev;
      }

      // 기존 query에서 createdAt 조건 제거
      const filteredQuery = prev.filter((item) => !item.createdAt);

      // 새로운 createdAt 조건 추가
      return [
        ...filteredQuery,
        {
          createdAt: {
            $between: [startDate, endDate],
          },
        },
      ];
    });
  }, [endDate, startDate, setQuery]);

  const [chipState, setChipState] = useState(() => {
    // query 배열에서 $or 키를 가진 객체를 찾고, 그 값을 반환하거나 wmsStatus의 키 목록으로 초기화
    const orQuery = query.find((q) => q && "$or" in q);
    const orQueryData = orQuery?.$or;

    // response 초기화
    const response: string[] = [];

    if (orQueryData) {
      // 1. orQueryData의 0번째 인덱스에서 string 요소들을 response에 추가
      if (Array.isArray(orQueryData[0]?.actionType)) {
        response.push(...orQueryData[0].actionType);
      }

      // 2. orQueryData의 1번째 인덱스 확인 후 조건에 따라 response에 추가
      if (orQueryData[1]?.$and) {
        const condition = orQueryData[1].$and;
        if (condition.actionFrom === "WEB" && condition.actionType === "TRANSFER") {
          response.push("TRANSFER");
        }
        if (condition.actionFrom === "ACTION" && condition.actionType === "TRANSFER") {
          response.push("TRANSFER_INPUT");
        }
      }

      // 3. orQueryData의 2번째 인덱스 확인 후 조건에 따라 response에 추가
      if (orQueryData[2]?.$and) {
        const condition = orQueryData[2].$and;
        if (condition.actionFrom === "WEB" && condition.actionType === "TRANSFER") {
          response.push("TRANSFER");
        }
        if (condition.actionFrom === "ACTION" && condition.actionType === "TRANSFER") {
          response.push("TRANSFER_INPUT");
        }
      }
    }

    // response가 비어있다면 wmsStatus의 키 목록을 기본값으로 사용
    return response.length > 0 ? response : Object.keys(wmsStatus);
  });

  // 새로고침 시 query에 따라 chipState 업데이트
  useEffect(() => {
    const orQuery = query.find((q) => q && "$or" in q);
    if (orQuery?.$or?.actionType) {
      setChipState(orQuery.$or.actionType);
    }
    if (query.length === 0) {
      setChipState(Object.keys(wmsStatus));
    }
    setSelectedRows(new Set());
  }, [query]);

  // chipState가 변경될 때 query 업데이트
  useEffect(() => {
    setQuery((prev) => {
      const newQuery: { $or: any[] } = { $or: [] };

      // chipState가 빈 배열일 때도 $or 조건 유지
      const safeChipState = chipState ?? [];
      const actionTypes = safeChipState.filter(
        (key: string) => key !== "TRANSFER" && key !== "TRANSFER_INPUT",
      );

      if (actionTypes.length > 0) {
        newQuery.$or.push({ actionType: actionTypes });
      } else {
        // chipState가 빈 배열일 경우에도 빈 actionType 조건 유지
        newQuery.$or.push({ actionType: [] });
      }

      if (safeChipState.includes("TRANSFER")) {
        newQuery.$or.push({ $and: { actionFrom: "WEB", actionType: "TRANSFER" } });
      }

      if (safeChipState.includes("TRANSFER_INPUT")) {
        newQuery.$or.push({ $and: { actionFrom: "ACTION", actionType: "TRANSFER" } });
      }

      // 기존 쿼리에서 $or 제거 후 병합
      const filteredPrev = prev.filter((q) => !("$or" in q));
      return [...filteredPrev, newQuery];
    });
  }, [chipState]);

  // api 쪽 수정 (query 간소화)
  // Chip 쪽 쿼리 확인해서 기본적으로 넣어주는 로직 추가 필요
  const columns: readonly Column<WlWithEslViewGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "id",
      name: t("wms.logId"),
      sortable: true,
      resizable: true,
      width: 80,
    },
    {
      key: "actionType",
      name: t("wms.actionType"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Flex>
            {row.actionFrom === "ACTION" && row.actionType === "TRANSFER"
              ? "자재투입"
              : t(row.actionType as WmsLogsGet200ResponseRowsInnerActionTypeEnum)}
          </Flex>
        );
      },
    },
    ...(customFunctions.ADD_WMS_LOG_ERP
      ? [
          {
            key: "checkErpStockMove",
            name: "연동대상 여부",
            sortable: true,
            resizable: true,
            formatter: ({ row }: any) => {
              return <div>{row.checkErpStockMove ? "연동 대상" : "연동 미대상"}</div>;
            },
          },
          {
            key: "ifWorkingTag",
            name: "전송여부",
            sortable: true,
            resizable: true,
            formatter: ({ row }: any) => {
              return (
                <div>
                  {row.ifWorkingTag === "A"
                    ? "전송완료"
                    : row.ifWorkingTag === "D"
                      ? "전송취소"
                      : "전송대기"}
                </div>
              );
            },
          },
          {
            key: "erpSyncedAt",
            name: "전송일시",
            sortable: true,
            resizable: true,
            formatter: ({ row }: any) => {
              return (
                <div>
                  {row.ifDateTime !== null
                    ? dayjs(row.ifDateTime).format("YYYY-MM-DD HH:mm:ss")
                    : "-"}
                </div>
              );
            },
          },
        ]
      : []),
    {
      key: "itemName",
      name: t("item.name"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const typeBatch = row.actionType === "BATCH";
        const itemName = typeBatch
          ? row.batchLotInfo?.map((item) => item.itemInfo?.name).join(", ")
          : row?.itemInfo?.name;

        return <div>{itemName}</div>;
      },
    },
    {
      key: "itemCode",
      name: t("item.code"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const typeBatch = row.actionType === "BATCH";
        const itemCode = typeBatch
          ? row.batchLotInfo?.map((item) => item.itemInfo?.code).join(", ")
          : row?.itemInfo?.code;

        return <div>{itemCode}</div>;
      },
    },
    {
      key: "lotName",
      name: t("lot.name"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const typeBatch = row.actionType === "BATCH";
        const lotName = typeBatch
          ? row.batchLotInfo?.map((item) => item.lotInfo?.name).join(", ")
          : row.actionParam?.params?.lotInfo?.name;

        return <div>{lotName}</div>;
      },
    },
    {
      key: "spec",
      name: t("item.spec"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const typeBatch = row.actionType === "BATCH";
        const spec = typeBatch
          ? row.batchLotInfo?.map((item) => item.itemInfo?.spec).join(", ")
          : row?.itemInfo?.spec;

        return <div>{spec}</div>;
      },
    },
    {
      key: "quantity",
      name: t("inventory.quantity"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const typeBatch = row.actionType === "BATCH";
        const unitText = row.itemInfo?.unitText;
        const quantity = typeBatch
          ? row.batchLotInfo
              ?.map((item) => `${setToLocaleString(item.quantity)}${item.itemInfo?.unitText}`)
              .join(", ")
          : setToLocaleString(row.actionParam?.params?.quantity ?? 0);

        return (
          <div>
            {quantity}
            {unitText}
          </div>
        );
      },
    },
    {
      key: "locationCode",
      name: t("wms.fromLocation"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(
                <LocationDetailForm
                  locationCode={
                    row.actionParam?.params?.locationCode ||
                    row.actionParam?.params?.sourceLocationCode ||
                    row.actionParam?.params?.fromLocationCode
                  }
                />,
                null,
                "",
                false,
              );
            }}
          >
            {row.actionParam?.params?.sourceLocationName}
          </LinkAnchor>
        );
      },
    },
    {
      key: "actionFrom",
      name: t("wms.toLocation"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(
                <LocationDetailForm
                  locationCode={
                    row.actionParam?.params?.locationCode ||
                    row.actionParam?.params?.targetLocationCode ||
                    row.actionParam?.params?.toLocationCode
                  }
                />,
                null,
                "",
                false,
              );
            }}
          >
            {row.actionParam?.params?.targetLocationName}
          </LinkAnchor>
        );
      },
    },
    {
      key: "creatorUserCode",
      name: t("wms.worker"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(<UsersDetailForm UserCode={row.creatorUserCode} />, null, "", false);
            }}
          >
            {row.creatorUserCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "createdAt",
      name: t("wms.createdAt"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return <span>{timeUtil(row.createdAt ?? "")}</span>;
      },
    },
  ];

  // 재고이동 전달
  const { mutate: postERP } = useMutation(
    (params: DefaultApiErpSyncPushPushStockMovePostRequest) =>
      mutatePalletErps.stockMove(params).mutationFn(undefined),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: (res) => {
        const successResult = res.data.filter((item) => item.success);
        const failResult = res.data.filter((item) => !item.success);

        if (successResult.length) {
          const workStr = successResult.map((item) => item.wmsLogId).join(", ");
          customAlert(
            `작업번호 ${workStr}번 재고가 성공적으로 이동되었습니다.`,
            "재고 이동",
            "info",
          );
        }

        if (failResult.length) {
          const workStr = failResult.map((item) => item.wmsLogId).join(", ");
          customAlert(`작업번호 ${workStr}번 재고 이동이 실패했습니다.`, "재고 이동", "red");
        }
        setSelectedRows(new Set());
        setLoading(false);
        refetch();
      },
      onError: (err) => {
        setLoading(false);
      },
    },
  );

  /// 재고이동 취소
  const { mutate: cancelERP } = useMutation(
    (params: DefaultApiErpSyncPushPushStockMovePostRequest) =>
      mutatePalletErps.stockDelete(params).mutationFn(undefined),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: (res) => {
        const successResult = res.data.filter((item) => item.success);
        const failResult = res.data.filter((item) => !item.success);

        if (successResult.length) {
          const workStr = successResult.map((item) => item.wmsLogId).join(", ");
          customAlert(
            `작업번호 ${workStr}번 재고가 성공적으로 취소되었습니다.`,
            "재고 취소",
            "info",
          );
        }

        if (failResult.length) {
          const workStr = failResult.map((item) => item.wmsLogId).join(", ");
          customAlert(`작업번호 ${workStr}번 재고 이동취소가 실패했습니다.`, "재고 취소", "red");
        }
        setSelectedRows(new Set());
        setLoading(false);
        refetch();
      },
      onError: (err) => {
        setLoading(false);
      },
    },
  );

  return (
    <WmsLogs.Wrapper>
      <LoadingOverlay />
      <Flex w="100%" h="100%" gap="xs" direction="column">
        <Flex p="sm" justify="flex-end" rowGap="md">
          <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
            {t("common.reset")}
          </Button>
        </Flex>
        <Flex w="100%" p="xs" justify="center" gap="xs" align="center">
          <DateHeader />
        </Flex>
        <Flex bg="white" px="sm" direction="column" gap="xs">
          <Text fz="xl" fw={600}>
            {t("wms.wms")}
          </Text>
          <Text fz="xs">{t("common.subTitle", { item: `${t("wms.wms")}` })}</Text>
        </Flex>
        <Flex justify="space-between" align="flex-start" px="sm">
          <Flex wrap="wrap" styles={flexGrowable}>
            <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
              <Tooltip label={t("common.filterDescription")}>
                <Avatar color="blue" variant="outline">
                  <IconFilterPlus />
                </Avatar>
              </Tooltip>
              <Flex direction="column" gap="xs" wrap="wrap">
                <Filter />
                <Sorter />
              </Flex>
            </Flex>
          </Flex>
          <Flex styles={flexStable}>
            <SearchBox />
          </Flex>
        </Flex>
        <Chip.Group multiple value={chipState}>
          <Group px="md">
            {Object.keys(wmsStatus).map((key: string) => {
              return (
                <Chip
                  variant="filled"
                  size="sm"
                  radius="xs"
                  key={key}
                  value={key}
                  checked={chipState && chipState.includes(key)}
                  onClick={(e) => {
                    const targetValue = e.currentTarget.value;
                    if (chipState.includes(targetValue)) {
                      setChipState(chipState.filter((chip: string) => chip !== targetValue));
                    } else {
                      setChipState([...chipState, targetValue]);
                    }
                  }}
                >
                  {t(wmsStatus[key])}
                </Chip>
              );
            })}
          </Group>
        </Chip.Group>
        {customFunctions.ADD_WMS_LOG_ERP && (
          <Flex justify={"space-between"} align={"center"}>
            <ChipsBox>
              <Chip
                variant="filled"
                size="sm"
                radius="xs"
                checked={filterErpSync || false}
                onClick={() => {
                  setFilterErpSync((prev) => !prev);
                }}
              >
                ERP 전송 필요건 조회
              </Chip>
            </ChipsBox>
            <Flex gap="1rem">
              <Button
                leftIcon={<IconFolder />}
                onClick={() =>
                  postERP({
                    erpSyncPushPushStockMovePostRequest: {
                      wmsLogIds: selectedRowsArray,
                    },
                  })
                }
                disabled={disabledStockMove}
              >
                재고이동 전달
              </Button>
              <Button
                color="orange"
                leftIcon={<IconFolderCancel />}
                onClick={() =>
                  cancelERP({
                    erpSyncPushPushStockMovePostRequest: {
                      wmsLogIds: selectedRowsArray,
                    },
                  })
                }
                disabled={disabledStockMoveCancel}
              >
                재고이동 전달 취소
              </Button>
            </Flex>
          </Flex>
        )}
        <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
          <StandardLayoutTableWrapper>
            <ListTable<WlWithEslViewGet200Response, WlWithEslViewGet200ResponseRowsInner>
              columns={columns}
            />
          </StandardLayoutTableWrapper>
          <PaginationBox<WmsLogsGet200Response> />
        </Flex>
        {/* <WmsLogsTable /> */}
      </Flex>
    </WmsLogs.Wrapper>
  );
};
