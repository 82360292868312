import DefaultInstance from "@/instance/axios";
import { OperationsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export type OperationOption = {
  value: string;
  label: string;
  group: string;
};

const getAutoCompleteKeyword = () =>
  DefaultInstance.operationsFindPost().then(
    (res: AxiosResponse<OperationsGet200ResponseRowsInner[]>) => res.data,
  );

export const useOperationsAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: OperationOption[]) => void;
  },
) => {
  return useQuery(["operations-get-autocomplete"], () => getAutoCompleteKeyword(), {
    enabled: focus,
    select: (data: OperationsGet200ResponseRowsInner[]) =>
      data?.map((route) => {
        return {
          value: String(route.code),
          label: String(route.name),
          group: String(route.siteCode),
        };
      }) ?? [],
    onSuccess: options?.onSuccess,
  });
};

const getOperationsByCode = (code: string | null) =>
  DefaultInstance.operationsFindPost({
    ...(code && { search: code }),
    searchFields: ["code"],
  }).then((res: AxiosResponse<OperationsGet200ResponseRowsInner[]>) => res.data);

export const useOperationsCode = (
  enabled: boolean,
  code: string | null,
  options?: {
    onSuccess: (data?: OperationOption[]) => void;
  },
) => {
  return useQuery(["operations-get-code", code], () => getOperationsByCode(code), {
    enabled: enabled ?? false,
    select: (data: OperationsGet200ResponseRowsInner[]) =>
      data?.map((route) => {
        return {
          value: String(route.code),
          label: String(route.name),
          group: String(route.siteCode),
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
