import { Loader } from "@mantine/core";
import { useState } from "react";

interface LoaderProps {
  width?: string;
  height?: string;
}

export function useLoader({ width = "100vw", height = "100vh" }: LoaderProps = {}) {
  const [loading, setLoading] = useState(false);

  const LoadingOverlay = () =>
    loading ? (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: width,
          height: height,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          zIndex: 9999,
        }}
      >
        <Loader size="xl" />
      </div>
    ) : null;

  return { setLoading, LoadingOverlay, loading };
}
