import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import {
  Button,
  Checkbox,
  FileInput,
  Flex,
  NumberInput,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Checkbox,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
});

export const MachineUpdateForm = (params: any) => {
  const { formatterProps } = params;
  const { closeModal } = useModal();
  // 데이터 수정 필요
  const form = useForm({
    initialValues: {
      machineCompany: formatterProps.machineCompany ?? undefined,
      machineName: formatterProps.machineName ?? undefined,
      machineType: formatterProps.machineType ?? undefined,
      manufacturedAt: formatterProps.manufacturedAt ?? undefined,
      manufactureId: formatterProps.manufactureId ?? undefined,
      userName: formatterProps.userName ?? undefined,
      machinePicture: formatterProps.machinePicture ?? undefined,
      specNamePlatePicture: formatterProps.specNamePlatePicture ?? undefined,
      functionNamePlatePicture: formatterProps.functionNamePlatePicture ?? undefined,
      manual: formatterProps.manual ?? undefined,
      isOverHall: formatterProps.isOverHall ?? undefined,
      peripheral: formatterProps.peripheral ?? undefined,
      ton: formatterProps.ton ?? undefined,
      volt: formatterProps.volt ?? undefined,
      factoryName: formatterProps.factoryName ?? undefined,
      factoryDetailName: formatterProps.factoryDetailName ?? undefined,
    },
  });

  const onSubmit = async () => {
    try {
      closeModal(form.values);

      customAlert("기계 수정에 실패하였습니다.", "수정 실패", "red");
    } catch (e) {
      customAlert("기계 수정에 실패하였습니다.", "수정 실패", "red");
    }
  };

  return (
    <Create.Wrapper>
      <Create.Input
        withAsterisk
        label="기계 제조사"
        placeholder="기계 제조사"
        {...form.getInputProps("machineCompany")}
      />
      <Create.Input
        withAsterisk
        label="기계명"
        placeholder="기계명"
        {...form.getInputProps("machineName")}
      />
      <Select
        withAsterisk
        data={["압축기", "가공장비", "센서", "검출장치", "조립기", "로봇"]}
        label="기계유형"
        placeholder="기계유형"
        {...form.getInputProps("machineType")}
      />
      <DateInput
        withAsterisk
        label="제조일"
        placeholder="제조일"
        {...form.getInputProps("manufacturedAt")}
      />
      <NumberInput
        withAsterisk
        label="제조번호"
        placeholder="제조번호"
        {...form.getInputProps("manufactureId")}
      />
      <Create.Input
        withAsterisk
        label="담당자"
        placeholder="담당자"
        {...form.getInputProps("userName")}
      />
      <FileInput label="장치 사진" {...form.getInputProps("modelPicture")} />
      <FileInput label="스펙 명판 사진" {...form.getInputProps("specNamePlatePicture")} />
      <FileInput label="능력 명판 사진" {...form.getInputProps("functionNamePlatePicture")} />
      <FileInput label="사용 설명서" {...form.getInputProps("manual")} />
      <Checkbox label="오버홀" {...form.getInputProps("isOverHall")} />
      <Create.Input
        withAsterisk
        label="주변장치"
        placeholder="주변장치"
        {...form.getInputProps("peripheral")}
      />
      <NumberInput withAsterisk label="톤" placeholder="톤" {...form.getInputProps("ton")} />
      <NumberInput
        withAsterisk
        label="사용 전압"
        placeholder="사용 전압"
        {...form.getInputProps("volt")}
      />
      <Create.Input
        withAsterisk
        label="공장명"
        placeholder="공장명"
        {...form.getInputProps("factoryName")}
      />
      <Create.Input
        withAsterisk
        label="공장 세분화명"
        placeholder="공장 세분화명"
        {...form.getInputProps("factoryDetailName")}
      />
      <Create.ButtonBox>
        <Create.Button color="gray" onClick={closeModal}>
          취소
        </Create.Button>
        <Create.Button
          disabled={validateFormValues({
            pageType: "machine",
            values: form.values,
          })}
          onClick={onSubmit}
        >
          기계 수정
        </Create.Button>
      </Create.ButtonBox>
    </Create.Wrapper>
  );
};
