import "@glideapps/glide-data-grid/dist/index.css";
import { DataEditor, GridCell, GridColumn, Item } from "@glideapps/glide-data-grid";
import { allCells } from "@glideapps/glide-data-grid-cells";
import { GridSelection } from "@glideapps/glide-data-grid";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useStandardLayout } from "./Context";
import { InventoriesBetweenGet200Response } from "@sizlcorp/sizl-api-document/dist/models";

interface ListTableGlideProps {
  cols: GridColumn[];
  rows: (cell: Item) => GridCell;
  length: number;
  list: any[];
}
//## 여기서는 오직 공용테이블만 존재한다 로직과 데이터는 xx
export const ListTableGlide = ({ cols, rows, length, list }: ListTableGlideProps) => {
  const [columns, setColumns] = useState<GridColumn[]>(cols);
  const { setSelectedRows } = useStandardLayout<AxiosResponse<InventoriesBetweenGet200Response>>();

  const [selection, setSelection] = useState<GridSelection>();

  //선택한 인덱스 배열 정리!!
  function mergeIntervals(rows: any) {
    let arrays = [...rows.items];
    let result: any = [];

    // 각 배열을 순차적으로 처리
    arrays.forEach((arr) => {
      for (let i = arr[0] + 1; i <= arr[1]; i++) {
        // arr[0]을 포함하지 않음
        result.push(i);
      }
    });

    return result;
  }
  useEffect(() => {
    // select 된 인덱스들 데이터 new set 하기
    if (!selection) return;

    let arr: any = [];

    mergeIntervals(selection.rows).map((e: number) => {
      arr?.push(JSON.stringify(list[e - 1]));
    });

    const setArr = new Set(arr);
    setSelectedRows(setArr);
  }, [selection]);

  return (
    <DataEditor
      width={"100%"}
      // height={300}
      className="grid"
      columns={columns}
      getCellContent={rows}
      rows={length}
      maxColumnAutoWidth={200}
      rowHeight={40}
      // onHeaderClicked={onHeaderClicked}
      // onHeaderMenuClick={onHeaderMenuClick}
      theme={{
        // bgCell: "rgba(234,255,255,30)",
        editorFontSize: "20px",
        baseFontStyle: "500 15px",
        // cellHorizontalPadding: 6,
        cellVerticalPadding: 6,
      }}
      onPaste={true}
      // rowSelect="single"
      getCellsForSelection={true}
      customRenderers={allCells}
      // 검색바
      // showSearch={true}
      //컬럼 리사이징
      onColumnResize={(row, newsize) => {
        setColumns((prevColumns) => {
          return prevColumns.map((col) => {
            if (col.id === row.id) {
              return { ...col, width: newsize };
            }
            return col;
          });
        });
      }}
      gridSelection={selection}
      onGridSelectionChange={(d) => {
        setSelection(d);
      }}
      rowMarkers={"checkbox-visible"}
      rowSelectionMode={"multi"}
      // onCellClicked={(c, i) => console.log("체크된 로우번호", c, i)}
      // onCellActivated={(cell) => console.log(" onCellActivated ?  ", cell)}
      // maxColumnWidth={200}
      // minColumnWidth={10}
    />
  );
};
