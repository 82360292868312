import { wmsLog } from "@/api/delivery/useDeliveryQuery";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import timeUtil from "@/utils/timeUtil";
import { Button, Table } from "@mantine/core";
import { WmsLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";

interface DeliveredFormProps {
  purchaseOrderItemID: number | undefined;
}

const Detail = Object.assign(
  {},
  {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
    ActionHeader: ActionHeader,
    ActionButtonBox: ActionButtonBox,
    ActionButton: Button,
  },
);

export const DeliveredForm = (params: DeliveredFormProps) => {
  const { purchaseOrderItemID } = params;
  const { openModal } = useModal();
  const { data } = useQuery(
    wmsLog.get({
      query: [{ purchaseOrderItemId: purchaseOrderItemID }],
    }),
  );

  return (
    <Detail.Container>
      <Detail.Wrapper>
        <Detail.Content>
          <Table>
            <thead>
              <tr>
                <th>납품번호</th>
                <th>품목코드</th>
                <th>로케이션 코드</th>
                <th>로트명</th>
                <th>수량</th>
                <th>납품자</th>
                <th>납품일</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.rows?.map((rows: WmsLogsGet200ResponseRowsInner) => {
                return (
                  <tr>
                    <td>{rows?.id}</td>
                    <td>
                      <DetailLink
                        onClick={() => {
                          openModal(
                            <ItemsDetailForm itemCode={rows?.actionParam?.params?.itemCode} />,
                            null,
                            "",
                          );
                        }}
                      >
                        {rows?.actionParam?.params?.itemCode}
                      </DetailLink>
                    </td>
                    <td>
                      <DetailLink
                        onClick={() => {
                          openModal(
                            <LocationDetailForm
                              locationCode={rows?.actionParam?.params?.locationCode}
                            />,
                            null,
                            "",
                          );
                        }}
                      >
                        {rows?.actionParam?.params?.locationCode}
                      </DetailLink>
                    </td>
                    <td>{rows?.actionParam?.params?.lotInfo?.name}</td>
                    <td>{rows?.actionParam?.params?.quantity}</td>
                    <td>
                      <DetailLink
                        onClick={() => {
                          openModal(<UsersDetailForm UserCode={rows?.creatorUserCode} />, null, "");
                        }}
                      >
                        {rows?.creatorUserCode}
                      </DetailLink>
                    </td>
                    <td>{timeUtil(rows?.createdAt ?? "")}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Detail.Content>
      </Detail.Wrapper>
    </Detail.Container>
  );
};
