import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Space } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const DetailRouting = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
});

interface InspectSpecificationInformProps {
  data?: ItemsGet200ResponseRowsInnerRoutingsInner;
}

export const InspectSpecificationInform = (params: InspectSpecificationInformProps) => {
  const { data } = params;
  const { t } = useTranslation();
  return (
    <DetailRouting>
      <DetailRouting.Title>{t("inspect.inspectSpecification")}</DetailRouting.Title>
      <DetailRouting.Inform>{t("inspect.inspectSpecification")}</DetailRouting.Inform>
      <Space h={8} />
      <DetailRouting.Field>
        <DetailRouting.Label>{t("item.code")}</DetailRouting.Label>
        <DetailRouting.LinkWrapper>
          <DetailRouting.Badge>{t("item.product")}</DetailRouting.Badge>
          <DetailRouting.Link>{data?.itemCode}</DetailRouting.Link>
        </DetailRouting.LinkWrapper>
      </DetailRouting.Field>
      <DetailRouting.Field>
        <DetailRouting.Label>{t("routing.code")}</DetailRouting.Label>
        <DetailRouting.Content>{data?.code}</DetailRouting.Content>
      </DetailRouting.Field>
      <DetailRouting.Field>
        <DetailRouting.Label>{t("inspect.type")}</DetailRouting.Label>
        <DetailRouting.Content>{t("inspect.inspect")}</DetailRouting.Content>
      </DetailRouting.Field>
      <DetailRouting.Field>
        <DetailRouting.Label>{t("info.createInformation")}</DetailRouting.Label>
        <DetailRouting.Content>
          {`${data?.creatorUserCode ?? ""}(${dayjs(data?.createdAt).format(
            "YYYY-MM-DD HH:mm:ss",
          )})`}
        </DetailRouting.Content>
      </DetailRouting.Field>
      <DetailRouting.Field>
        <DetailRouting.Label>{t("info.updateInformation")}</DetailRouting.Label>
      </DetailRouting.Field>
    </DetailRouting>
  );
};
