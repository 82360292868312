import {
  DOWN_TIME_REASONS,
  mutateDownTimeReasons,
} from "@/api/downtimeReasons/useDowntimeReasonsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { OperationAutoComplete } from "@/features/ui/autoComplete/operation/operation-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiDowntimeReasonsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

type DowntimeReasonsCreateFormProps = {
  maxSeq?: number;
};

export const DowntimeReasonsCreateForm = ({ maxSeq }: DowntimeReasonsCreateFormProps) => {
  const { closeModal } = useModal();

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: createMutate } = useMutation(
    (params: DefaultApiDowntimeReasonsPostRequest) =>
      mutateDownTimeReasons
        .create(params)
        .mutationFn(params as DefaultApiDowntimeReasonsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([DOWN_TIME_REASONS]);
        closeModal(form.values);
        customAlert(
          `${t("message.createSuccess", { item: `${t("downtimeReason.downtimeReason")}` })}`,
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("downtimeReason.code") }),
          `${t("common.createFail", { item: `${t("downtimeReason.downtimeReason")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      seq: maxSeq,
      code: undefined,
      name: undefined,
      operationCode: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        downtimeReasonsGetRequest: {
          seq: form.values.seq,
          code: form.values.code!,
          name: form.values.name!,
          operationCode: form.values.operationCode!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("downtimeReason.code") }),
        `${t("common.createFail", { item: `${t("downtimeReason.downtimeReason")}` })}`,
        "red",
      );
    }
  };

  return (
    <Create.Wrapper>
      <Create.Input
        disabled
        label={t("downtimeReason.seq")}
        placeholder={t("placeholder.input", { item: `${t("downtimeReason.seq")}` })}
        withAsterisk
        {...form.getInputProps("seq")}
      />
      <Create.Input
        label={t("downtimeReason.code")}
        placeholder={t("placeholder.input", { item: `${t("downtimeReason.code")}` })}
        withAsterisk
        {...form.getInputProps("code")}
      />
      <Create.Input
        label={t("downtimeReason.name")}
        placeholder={t("placeholder.input", { item: `${t("downtimeReason.name")}` })}
        withAsterisk
        {...form.getInputProps("name")}
      />
      <OperationAutoComplete
        label={t("operation.code")}
        placeholder={t("placeholder.select", { item: `${t("operation.code")}` })}
        {...form.getInputProps("operationCode")}
      />
      <Create.ButtonBox>
        <Create.Button color="gray" onClick={closeModal}>
          {t("common.cancel")}
        </Create.Button>
        <Create.Button
          disabled={validateFormValues({
            pageType: "downtimeReasons",
            values: form.values,
          })}
          onClick={onSubmit}
        >
          {t("common.create", { item: `${t("downtimeReason.downtimeReason")}` })}
        </Create.Button>
      </Create.ButtonBox>
    </Create.Wrapper>
  );
};
