// @fileoverview : 경남-품질관리 > 검사기록
import { InspectionDetailForm } from "@/features/inspectionRecords/form/detailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { ActionIcon, Avatar, Button, Flex, Text, Tooltip } from "@mantine/core";
import {
  InspectionsGet200Response,
  InspectionsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconFilterPlus, IconRecycle, IconSearch } from "@tabler/icons-react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

// 우리 회사에서 다루는 모든 검사 기록을 확인할 수 있습니다.
export const InspectionRecordsPage = () => {
  const { openModal, closeModal } = useModal();
  const { resetQueryStrings } = useStandardLayout();
  const { t } = useTranslation();

  const columns: readonly Column<InspectionsGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "itemCode",
      name: t("item.code"),
      sortable: true,
      resizable: true,
      formatter: ({ row }: any) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <ItemsDetailForm itemCode={row.itemCode} />,
                null,
                t("common.detail", { item: t("item.code") }),
              )
            }
          >
            {row.itemCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "lotName",
      name: t("lot.name"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return <Flex>{row.lot?.name}</Flex>;
      },
    },
    {
      key: "additional.productionPlanId",
      name: t("productionPlan.id"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Flex w="100%" h="100%" justify="flex-end" align="center">
            <LinkAnchor
              onClick={() =>
                openModal(
                  <ProductionPlanViewForm ProductionPlanId={row?.productionPlanId} />,
                  null,
                  t("common.detail", { item: t("productionPlan.productionPlan") }),
                )
              }
            >
              {/* <Text ta="end">{row?.productionPlanId}</Text> */}
              {row?.productionPlanId}
            </LinkAnchor>
          </Flex>
        );
      },
    },
    {
      key: "summary",
      name: t("inspect.summary"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const summaryNames = formatterProps.row.specificationData?.criteria?.map(
          (data) => data.name,
        );

        return (
          <Flex>
            {summaryNames && summaryNames?.length > 1
              ? summaryNames[0] + " 외 " + (summaryNames.length - 1) + "개"
              : summaryNames && summaryNames[0]}
          </Flex>
        );
      },
    },
    {
      key: "updatedAt",
      name: t("inspect.updatedAt"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => <Flex>{DatetimeUtil(row.updatedAt ?? "")}</Flex>,
    },
    {
      key: "options",
      name: t("common.action"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const detailInspectionActionView = () => {
          openModal(
            <InspectionDetailForm inspectionId={formatterProps?.row.id} />,
            null,
            t("common.detail", { item: t("inspect.inspectSpecification") }),
          );
        };

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon variant="subtle" onClick={detailInspectionActionView} color="blue">
              <IconSearch />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="flex-end" rowGap="md">
        <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
          {t("common.reset")}
        </Button>
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: t("inspectRecord.inspectRecord") })}
        </Text>
        <Text fz="xs">
          {t("common.subTitle", { item: `${t("item.item")} ${t("inspectRecord.inspectRecord")}` })}
        </Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<InspectionsGet200Response, InspectionsGet200ResponseRowsInner>
            columns={columns}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<InspectionsGet200Response> />
      </Flex>
    </Flex>
  );
};
