import { useModal } from "@/features/modal/ModalStackManager";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import timeUtil from "@/utils/timeUtil";
import { ActionIcon, Flex, Space, Text } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerItemTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";

interface KPIActionHeaderProps {
  stateDate: [Date | null, Date | null];
  setStateDate: React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>;
  setItem: React.Dispatch<React.SetStateAction<string | null>>;
  equipment?: boolean;
}
export const KPIActionHeader = (props: KPIActionHeaderProps) => {
  const { stateDate, setStateDate, setItem, equipment } = props;

  const { openModal } = useModal();

  return (
    <Flex justify={"space-between"} align={"center"}>
      <Space miw={"22rem"} />
      <Flex align={"center"}>
        <Flex
          align="center"
          gap="sm"
          onClick={() => {
            openModal(
              <Calendar dates={stateDate} type="range" isExclude={true} />,
              null,
              "날짜 선택",
            ).then((value) => {
              if (isCalendarDate(value)) {
                setStateDate(value);
              }
            });
          }}
        >
          <Text size="2rem" fw="bold" style={{ cursor: "pointer" }}>
            {timeUtil(stateDate[0]?.toISOString() ?? "")} ~{" "}
            {timeUtil(stateDate[1]?.toISOString() ?? "")}
          </Text>
          <ActionIcon>
            <IconCalendar size="2rem" />
          </ActionIcon>
        </Flex>
      </Flex>
      {equipment ? (
        <EquipmentAutoComplete
          placeholder="설비를 선택해주세요"
          w="22rem"
          size="lg"
          onChange={(value) => {
            setItem(value);
          }}
        />
      ) : (
        <ItemAutoComplete
          placeholder="품목을 선택해주세요"
          w="22rem"
          size="lg"
          getItemType={[
            ItemsGet200ResponseRowsInnerItemTypeEnum.PRODUCT,
            ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT,
          ]}
          onChange={(value) => setItem(value)}
        />
      )}
    </Flex>
  );
};
