import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import { Button, Checkbox, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const CommonPartCodeCreateForm = () => {
  const { closeModal } = useModal();

  const form = useForm({
    initialValues: {
      categoryCode: undefined,
      categoryName: undefined,
      systemUse: false,
      active: false,
    },
  });

  const onSubmit = async () => {
    try {
      customAlert("구분 코드 생성에 실패하였습니다.", "생성 실패", "red");
    } catch (e) {
      customAlert("구분 코드 생성에 실패하였습니다.", "생성 실패", "red");
    }
  };

  return (
    <Create.Wrapper>
      <Create.Input label="구분코드" withAsterisk {...form.getInputProps("categoryCode")} />
      <Create.Input label="구분명" withAsterisk {...form.getInputProps("categoryName")} />
      <Checkbox label="시스템 사용" {...form.getInputProps("systemUse")} />
      <Checkbox label="사용 여부" {...form.getInputProps("active")} />
      <Create.ButtonBox>
        <Create.Button color="gray" onClick={closeModal}>
          취소
        </Create.Button>
        <Create.Button
          onClick={onSubmit}
          disabled={validateFormValues({
            pageType: "partCommonCode",
            values: form.values,
          })}
        >
          구분 코드 생성
        </Create.Button>
      </Create.ButtonBox>
    </Create.Wrapper>
  );
};
