import { LedgerActionsContext } from "@/features/ledgersAll/context/ledgerProvider";
import { useContext } from "react";

export function useLedgersActions() {
  const value = useContext(LedgerActionsContext);
  if (value === undefined) {
    throw new Error("useLedgersActions should be used within LedgerProvider");
  }
  return value;
}
