import DirectTimeInput from "@/features/ui/PMS/directTimeInput";
import styled from "@emotion/styled";
import React from "react";

interface Props {
  title: string;
  active: boolean;
  times: any;
  setTimes: (data: any) => void;
}

const FullTimeInput: React.FunctionComponent<Props> = ({ title, active, times, setTimes }) => {
  return (
    <TimeContainer active={active}>
      <LabelP>{title}</LabelP>
      <div style={{ display: "flex" }}>
        <DirectTimeInput
          type={"hour"}
          active={active}
          time={String(times.hour)}
          times={times}
          setTime={setTimes}
        />
        <DirectTimeInput
          type={"minute"}
          active={active}
          time={String(times.minute)}
          times={times}
          setTime={setTimes}
        />
        <DirectTimeInput
          type={"second"}
          active={active}
          time={String(times.second)}
          times={times}
          setTime={setTimes}
        />
      </div>
    </TimeContainer>
  );
};

export default FullTimeInput;

const TimeContainer = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LabelP = styled.p`
  font-size: 15px;
  font-weight: bold;
`;
