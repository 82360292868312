import { useQuery } from "@tanstack/react-query";

import DefaultInstance from "@/instance/axios";
import {
  OperationsGet200Response,
  OperationsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";

export type OperationOption = {
  value: string;
  label: string;
};

export const useOperationsMultiAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: OperationOption[]) => void;
  },
) => {
  return useQuery(
    ["operations-get-multi-autocomplete-keyword", keyword],
    () =>
      DefaultInstance.operationsFindPost({
        search: keyword,
        searchFields: ["name"],
      }).then((res) => res.data),
    {
      enabled: focus,
      select: (data: OperationsGet200ResponseRowsInner[]): OperationOption[] =>
        data.map((Operation) => {
          return {
            value: String(Operation.code),
            label: String(Operation.name),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    },
  );
};

const getOperationByName = (name: string | null) =>
  DefaultInstance.operationsGet({
    ...(name && { search: name }),
    searchFields: ["name"],
  }).then((res) => res.data);

export const useOperationsMultiCode = (
  enabled: boolean = false,
  name: string | null,
  options?: {
    onSuccess: (data?: OperationOption[]) => void;
  },
) => {
  return useQuery(["operations-multi-get-name", name], () => getOperationByName(name), {
    enabled,
    select: (data: OperationsGet200Response) =>
      data?.rows?.map((Operation) => {
        return {
          value: Operation.name as string,
          label: Operation.name as string,
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
