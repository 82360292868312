import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PurchaseOrderItemsAutoComplete } from "@/features/ui/autoComplete/purchaseOrderItems/purchaseOrderItems-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Button, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  DefaultApiWmsOutgoingPostRequest,
  WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Date: DateInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  ItemAutoComplete: ItemAutoComplete,
});

export const OutgoingForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: outgoingMutate } = useMutation(
    (params: DefaultApiWmsOutgoingPostRequest) =>
      mutateInventories
        .outgoing(params)
        .mutationFn(params as DefaultApiWmsOutgoingPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
        closeModal(form.values);
        customAlert(
          `${t("inventory.successMessage", { item: `${t("inventory.outgoing")}` })}`,
          `${t("inventory.success", { item: `${t("inventory.outgoing")}` })}`,
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("inventory.outgoing") }),
          `${t("inventory.fail", { item: `${t("inventory.outgoing")}` })}`,
          "red",
        );
      },
    },
  );
  const form = useForm<Partial<WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner>>({
    initialValues: {
      itemCode: formatterProps.itemCode,
      locationCode: formatterProps.locationCode,
      quantity: undefined,
      lotId: formatterProps.lotId,
      purchaseOrderItemId: undefined,
      lotInfo: {
        name: formatterProps.lotName,
        expiration:
          formatterProps.lotExpiration !== null
            ? new Date(formatterProps.lotExpiration).toISOString()
            : null,
      },
    },
  });

  const onSubmit = () => {
    try {
      outgoingMutate({
        workLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner: {
          itemCode: formatterProps.itemCode,
          purchaseOrderItemId: Number(form.values.purchaseOrderItemId),
          locationCode: formatterProps.locationCode,
          quantity: form.values.quantity
            ? form.values.quantity
            : (formatterProps.quantity as string),
          lotId: Number(formatterProps.lotId || form.values.lotId),
          lotInfo: {
            name:
              formatterProps.lot.name ||
              (form.values.lotInfo && form.values.lotInfo.name ? form.values.lotInfo.name : null),
            expiration: form.values.lotInfo?.expiration
              ? new Date(form.values.lotInfo.expiration).toISOString()
              : null,
          },
          actionFrom: "WEB",
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("inventory.outgoing") }),
        `${t("inventory.fail", { item: `${t("inventory.outgoing")}` })}`,
        "red",
      );
    }
  };

  return (
    <>
      <Create.Wrapper>
        <Create.ItemAutoComplete
          disabled
          label={t("item.code")}
          withAsterisk
          {...form.getInputProps("itemCode")}
        />
        <LocationAutoComplete
          disabled
          label={t("location.code")}
          withAsterisk
          {...form.getInputProps("locationCode")}
        />
        <PurchaseOrderItemsAutoComplete
          label={t("purchaseOrder.code")}
          placeholder="수주 선택"
          w={"100%"}
          itemCodes={formatterProps?.itemCode}
          {...form.getInputProps("purchaseOrderItemId")}
        />
        <Create.Input
          label={t("inventory.quantity")}
          withAsterisk
          type="number"
          {...form.getInputProps("quantity")}
          defaultValue={formatterProps?.quantity}
        />
        <Create.Input disabled label={t("lot.id")} {...form.getInputProps("lotId")} />
        <Create.Input disabled label={t("lot.name")} {...form.getInputProps("lotInfo.name")} />
        <Create.Date
          disabled
          label={t("lot.expiration")}
          minDate={new Date()}
          valueFormat="YYYY-MM-DD HH:mm:ss"
          value={
            form.values.lotInfo?.expiration ? new Date(form.values.lotInfo.expiration) : undefined
          }
        />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Create.Button>
          <Create.Button onClick={onSubmit}>{t("inventory.outgoing")}</Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
