import { Avatar, Flex, Text, Tooltip } from "@mantine/core";
import { useModal } from "@/features/modal/ModalStackManager";
import { IconFilterPlus } from "@tabler/icons-react";
import { customFunctions } from "@/config/customFunction";

import { DateHeader } from "@/features/inventory/components/DateHeader";

import { ActionHeader } from "@/features/inventory/components/InventoryActionHeader";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import timeUtil from "@/utils/timeUtil";
import { badgeCell, textCell, linkCell, numCell } from "@/features/ui/tableGlide/cells";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import { LedgerDetailForm } from "@/features/inventory/components/Form/detail/DetailForm";
import { AxiosResponse } from "axios";
import {
  InventoriesBetweenGet200Response,
  InventoriesBetweenGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { GridCell, GridColumn, Item } from "@glideapps/glide-data-grid";
import { ListTableGlide } from "@/features/standardLayout/ListTableGlide";

type ExtendedGridColumn = GridColumn & {
  type: "link" | "badge" | "text"; // type 속성을 추가
};
const columns: ExtendedGridColumn[] = [
  { title: "품목코드", hasMenu: false, width: 160, icon: "headerUri", id: "0", type: "link" },
  { title: "품목명", id: "1", width: 200, type: "link" },
  { title: "규격", id: "2", type: "text" },
  { title: "품목유형", width: 80, id: "3", type: "badge" },
  { title: "로트명", width: 100, id: "4", type: "text" },
  { title: "로트유효기한", width: 50, id: "5", type: "text" },
  { title: "위치", width: 100, id: "6", icon: "headerUri", type: "badge" },
  { title: "입고일", width: 100, id: "7", type: "text" },
  { title: "마감재고", width: 100, id: "8", type: "text" },
  { title: "마감일시", width: 100, id: "9", type: "text" },
  { title: "추가된재고", width: 70, id: "10", type: "text" },
  { title: "감소된재고", width: 70, id: "11", type: "text" },
  { title: "재고변화량", width: 70, id: "12", type: "text" },
  { title: "이월재고", width: 70, id: "13", type: "text" },
  { title: "최종재고", width: 80, icon: "headerUri", id: "14", type: "link" },
];
export const NewInventory = () => {
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const { sort, setSort, endDate, setParams, startDate, data } =
    useStandardLayout<AxiosResponse<InventoriesBetweenGet200Response>>();
  const response = data?.data || { rows: [] as InventoriesBetweenGet200ResponseRowsInner[] }; // rows가 기본적으로 빈 배열을 가지도록 설정
  let list: InventoriesBetweenGet200ResponseRowsInner[] = response.rows ?? []; // rows가 undefined일 경우 빈 배열을 사용

  useEffect(() => {
    const updatedParams = {
      targetDateString: dayjs(startDate).startOf("day").toISOString(),
      targetDateEndString: dayjs(endDate).endOf("day").toISOString(),
    };

    setParams((prev) => ({
      ...prev,
      ...updatedParams,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    // 경남케미칼 커스텀
    if (customFunctions.ADD_INVENTORY_QUANTITY_SORT_FIX && !sort.length) {
      setSort(() => ["-quantity"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const rows = ([col, row]: Item) => {
    const product: any = list[row];

    const columnHandlers: Record<number, () => GridCell> = {
      0: () =>
        linkCell(`${product?.itemCode}`, () => {
          const detailItemAction = () => {
            openModal(
              <ItemsDetailForm itemCode={product?.itemCode} />,
              null,
              `${t("common.detail", { item: `${t("item.item")}` })}`,
            );
          };
          detailItemAction();
        }),
      1: () => textCell(product?.itemName),
      2: () => textCell(product?.spec ?? ""),
      3: () => badgeCell(product?.itemType),
      4: () => textCell(product?.lot?.name ?? ""),
      5: () => textCell(product.lot.expiration ?? ""),
      6: () =>
        linkCell(product.locationName, () => {
          const detailItemAction = () => {
            openModal(
              <LocationDetailForm locationCode={product.locationName ?? ""} />,
              null,
              "로케이션 상세",
            );
          };
          detailItemAction();
        }),
      7: () =>
        textCell(product.lotCreatedAt, product.lotCreatedAt ? timeUtil(product.lotCreatedAt) : ""),
      8: () => numCell(product.closedQuantity ?? ""),
      9: () => textCell(product.closedAt ?? ""),
      10: () => numCell(`${product.betweenIncreaseQuantity}EA`),
      11: () => numCell(`${product.betweenDecreaseQuantity}EA`),
      12: () => numCell(`${product.deltaQuantity}EA`),
      13: () => numCell(`${product.quantityAtStart}EA`),
      14: () =>
        linkCell(`${product.quantityAtEnd}EA`, () => {
          const detailItemAction = () => {
            openModal(<LedgerDetailForm data={product} />, null, "재고 상세");
          };
          detailItemAction();
        }),
    };

    const handler = columnHandlers[col];
    if (!handler) {
      throw new Error(`Unhandled column: ${col}`);
    }

    return handler();
  };

  if (!data) return null;
  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <ActionHeader />
      <DateHeader />
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: `${t("inventory.inventory")}` })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${t("inventory.inventory")}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTableGlide cols={columns} rows={rows} length={list.length} list={list} />
        </StandardLayoutTableWrapper>
        <PaginationBox<InventoriesBetweenGet200Response> />
      </Flex>
    </Flex>
  );
};
