import { SitesAutoComplete } from "../ui/autoComplete/site/sites-autoComplete";
import { useWorkActions } from "./hooks/useWorkActions";

export const WorkSiteSelect = () => {
  const actions = useWorkActions();

  return (
    <SitesAutoComplete
      width={"100%"}
      placeholder="사업장을 선택하세요."
      onChange={(value) => {
        actions.setSite(value ?? undefined);
      }}
    />
  );
};
