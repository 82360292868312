import { theme } from "@/styles/theme";
import { Anchor, Flex } from "@mantine/core";
import { IconLink } from "@tabler/icons-react";

interface LinkAnchorProps {
  children: React.ReactNode;
  onClick?: React.ReactEventHandler;
}

export const LinkAnchor = ({ children, onClick }: LinkAnchorProps) => {
  return (
    <Flex w="100%" h="100%" gap="xs" align="center">
      <Anchor fw={700} size="sm" c={theme.colors?.blue?.[7]} onClick={onClick}>
        {children}
      </Anchor>
      {children && <IconLink width={16} height={16} color={theme.colors?.blue?.[6]} />}
    </Flex>
  );
};
