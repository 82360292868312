// @fileoverview : 경남-KPI > 작업공수(C)
import { KPIActionHeader } from "@/features/kpi/actionHeader";
import { useKPIData } from "@/features/kpi/hook/useKPIData";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { GridTable } from "@/features/ui/PMS/GridTable";
import CustomTable from "@/features/ui/table/CustomTable";
import useColumnList from "@/hooks/useColumnList";
import { DatetimeUtilMinute } from "@/utils/dateTimeUtil";
import { Flex } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const KPIManhour = Object.assign(
  {},
  {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    KPIActionHeader: KPIActionHeader,
    CustomTable: CustomTable,
  },
);

export const KPIManhourPage = () => {
  const today = dayjs().subtract(1, "day").startOf("day").toDate();
  const oneWeekAgo = dayjs().subtract(7, "day").startOf("day").toDate();
  const { t } = useTranslation();
  const columnlist = useColumnList();

  const [content, setContent] = useState<any>([]);
  const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);
  const [item, setItem] = useState<string | null>("");
  const [averageManHourCost, setAverageManHourCost] = useState<string>("");
  const [selectList, setSelectList] = useState<Set<number>>(new Set());

  const { itemData, manhourData } = useKPIData({
    item,
    stateDate,
    options: { fetchManhour: true },
  });

  useEffect(() => {
    if (manhourData?.data) {
      const transformedData = manhourData.data.map((item: any) => ({
        id: item.id,
        productionPlanId: item.productionPlanId,
        scheduledAt: item.scheduledAt,
        operationName: item.operationName,
        equipmentName: item.equipmentName,
        itemCode: item.itemCode,
        itemSpec: item.itemSpec,
        workerName: item.workerName,
        workStartTime: DatetimeUtilMinute(item.workStartTime),
        workEndTime: DatetimeUtilMinute(item.workEndTime),
        pauseDuration: item.pauseDuration,
        totalQuantity: `${item.totalQuantity} ${itemData?.unitText}`,
        goodQuantity: `${item.goodQuantity} ${itemData?.unitText}`,
        defectQuantity: `${item.defectQuantity} ${itemData?.unitText}`,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        manHourCost: item.manHourCost,
      }));

      const totalManHourCost =
        manhourData?.data?.reduce(
          (sum: number, item: any) => sum + parseFloat(item.manHourCost),
          0,
        ) ?? 0;
      const averageManHourCost = (totalManHourCost / manhourData.data.length).toFixed(2);
      setAverageManHourCost(averageManHourCost);
      setContent(transformedData);
    } else {
      setContent([]);
    }
  }, [manhourData]);

  const TableHeader = {
    machine: [t("item.code"), t("item.name"), t("item.spec"), t("kpi.manhour.averageTime")],
  };

  const BodyData = {
    machine: [
      {
        [t("item.code")]: itemData?.code ?? "-",
        [t("item.name")]: itemData?.name,
        [t("item.spec")]: itemData?.spec ?? "-",
        [t("kpi.manhour.averageTime")]: averageManHourCost === "NaN" ? "-" : averageManHourCost,
      },
    ],
  };

  return (
    <Flex gap="md" direction={"column"} p="sm">
      <KPIManhour.Header>
        <KPIManhour.HeaderTitle>{t("kpi.manhour.manhour")}(C)</KPIManhour.HeaderTitle>
        <KPIManhour.HeaderSubTitle>{t("kpi.manhour.subTitle")}</KPIManhour.HeaderSubTitle>
      </KPIManhour.Header>
      <KPIManhour.KPIActionHeader
        setItem={setItem}
        stateDate={stateDate}
        setStateDate={setStateDate}
      />

      <KPIManhour.CustomTable headers={TableHeader.machine} data={BodyData.machine} />
      <GridTable
        headerList={columnlist.manhourTable}
        row={content}
        setRow={setContent}
        selectList={selectList}
        //@ts-ignore
        setSelectList={setSelectList}
        width={"100%"}
        height={750}
        resizable={true}
      />
    </Flex>
  );
};
