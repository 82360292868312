import { MasterInstance } from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  MasterApiDepartmentsDepartmentIdDeleteRequest,
  MasterApiDepartmentsDepartmentIdGetRequest,
  MasterApiDepartmentsDepartmentIdPutRequest,
  MasterApiDepartmentsGetRequest,
  MasterApiDepartmentsPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const DEPARTMENT_QUERY_KEY = "department";

export const department = createQueryKeys(DEPARTMENT_QUERY_KEY, {
  all: null,
  get: (params: MasterApiDepartmentsGetRequest) => {
    // const queryParams = { ...params };
    const queryParams = {
      ...params,
      query: JSON.stringify({ $and: params?.query }),
      sort: params.sort?.length ? params.sort : "-id",
    }; // 부서 조회 시 기본 정렬 표시

    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => MasterInstance.departmentsGet(queryParams),
    };
  },
  detail: (params: MasterApiDepartmentsDepartmentIdGetRequest) => {
    return {
      queryKey: [params.departmentId],
      queryFn: () => MasterInstance.departmentsDepartmentIdGet(params),
    };
  },
});

export const mutateDepartment = createMutationKeys(DEPARTMENT_QUERY_KEY, {
  create: (params: MasterApiDepartmentsPostRequest) => ({
    mutationKey: [params.departmentsGetRequest],
    mutationFn: () => MasterInstance.departmentsPost(params),
  }),
  update: (params: MasterApiDepartmentsDepartmentIdPutRequest) => ({
    mutationKey: [params.departmentsGetRequest],
    mutationFn: () => MasterInstance.departmentsDepartmentIdPut(params),
  }),
  delete: (params: MasterApiDepartmentsDepartmentIdDeleteRequest) => ({
    mutationKey: [params.departmentId],
    mutationFn: () => MasterInstance.departmentsDepartmentIdDelete(params),
  }),
});

export const wmsLogQuery = mergeQueryKeys(department, mutateDepartment);
