import { defects } from "@/api/defect/useDefectsQuery";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { useQuery } from "@tanstack/react-query";
import { DefectDetailInForm } from "./DefectDetailInForm";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
});

interface DefectDetailFormProps {
  defectCode: string | undefined;
}

export const DefectDetailForm = (params: DefectDetailFormProps) => {
  const { defectCode } = params;

  const { data: defectData } = useQuery(
    defects.get({
      query: { $and: [{ code: { $eq: defectCode } }] },
    }),
  );

  return (
    <>
      <Detail.Container>
        <Detail.Wrapper>
          <Detail.Content>
            <DefectDetailInForm data={defectData} />
          </Detail.Content>
        </Detail.Wrapper>
      </Detail.Container>
    </>
  );
};
