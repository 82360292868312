// @fileoverview : 기준정보 > 금형재고
import { mutateMoldStocks } from "@/api/moldStock/useMoldStockQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { MoldDetailForm } from "@/features/mold/form/moldDetailForm";
import { MoldStockDetailForm } from "@/features/moldStock/form/moldStockDeatailForm";
import { MoldStockCreateForm } from "@/features/moldStock/form/stockCreateForm";
import { MoldStockUpdateForm } from "@/features/moldStock/form/stockUpdateForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import {
  MasterApiMoldStocksMoldStockIdDeleteRequest,
  MoldStocksGet200Response,
  MoldStocksGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

//금형 재고 / 실 금형

export const MoldStockPage = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { t } = useTranslation();
  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiMoldStocksMoldStockIdDeleteRequest) =>
      mutateMoldStocks
        .delete(params)
        .mutationFn(params as MasterApiMoldStocksMoldStockIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          `${t("message.deleteSuccess", { item: `${t("moldStock.moldStock")}` })}`,
          t("common.deleteSuccess"),
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("moldStock.code") }),
          `${t("common.deleteFail", { item: `${t("moldStock.moldStock")}` })}`,
          "red",
        );
      },
    },
  );

  const columns: readonly Column<MoldStocksGet200ResponseRowsInner>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "code",
        name: t("moldStock.code"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <MoldStockDetailForm moldStockCode={formatterProps?.row.code} />,
                  null,
                  "",
                )
              }
            >
              {formatterProps.row.code}
            </LinkAnchor>
          );
        },
      },
      {
        key: "moldCode",
        name: t("mold.code"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(<MoldDetailForm moldCode={formatterProps.row?.moldCode} />, null, "")
              }
            >
              {formatterProps.row.moldCode}
            </LinkAnchor>
          );
        },
      },
      {
        key: "limitCounter",
        name: t("moldStock.limitCounter"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return <Flex justify="flex-end">{formatterProps.row.limitCounter}</Flex>;
        },
      },
      {
        key: "inspectCounter",
        name: t("moldStock.inspectCounter"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return <Flex justify="flex-end">{formatterProps.row.inspectCounter}</Flex>;
        },
      },
      {
        key: "currentCounter",
        name: t("moldStock.currentCounter"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return <Flex justify="flex-end">{formatterProps.row.currentCounter}</Flex>;
        },
      },
      {
        key: "options",
        name: t("common.action"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const updateMoldStockActionView = () => {
            openModal(
              <MoldStockUpdateForm formatterProps={formatterProps?.row} />,
              null,
              `${t("common.edit", { item: `${t("moldStock.moldStock")}` })}`,
              true,
            );
          };

          const deleteMoldStockAction = () => {
            openModal(
              <ConfirmForm
                message={t("message.rowDelete")}
                yesCallback={() => deleteMutate({ moldStockId: formatterProps?.row.id as number })}
                noCallback={() => {}}
                commonCallback={() => closeModal(null)}
              />,
              null,
              `${t("common.delete", { item: `${t("moldStock.moldStock")}` })}`,
            );
          };

          return (
            <Flex w="100%" h="100%" justify="center" align="center" gap="md">
              <ActionIcon variant="subtle" onClick={updateMoldStockActionView} color="yellow">
                <IconEdit />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={() => deleteMoldStockAction()} color="red">
                <IconTrash />
              </ActionIcon>
            </Flex>
          );
        },
      },
    ],
    [closeModal, deleteMutate, openModal, t],
  );

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: `${t("moldStock.moldStock")}` })}`,
        `${t("common.deleteFail", { item: `${t("moldStock.moldStock")}` })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ moldStockId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      `${t("common.delete", { item: `${t("moldStock.moldStock")}` })}`,
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader
          createFn={() =>
            openModal(
              <MoldStockCreateForm />,
              null,
              `${t("common.additional", { item: `${t("moldStock.moldStock")}` })}`,
              true,
            )
          }
          createDataTitle={t("moldStock.moldStock")}
          deleteFn={() => handleDelete()}
        />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: `${t("moldStock.moldStock")}` })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${t("moldStock.moldStock")}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<MoldStocksGet200Response, MoldStocksGet200ResponseRowsInner>
            columns={columns}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<MoldStocksGet200Response> />
      </Flex>
    </Flex>
  );
};
