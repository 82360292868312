import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { Main } from "@/features/standard/Main";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerAllLotsSummaryInnerInputInfoInner } from "@sizlcorp/sizl-api-document/dist/models";
import { OperationOutsourceWorkByRowDetailView } from "./OperationOutsourceWorkByRowDetailView";

export interface OperationOutsourceWorkByRowViewProps {
  data:
    | ProductionPlansGet200ResponseRowsInnerWorksInnerAllLotsSummaryInnerInputInfoInner[]
    | undefined;
}

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
});
export const OperationOutsourceWorkByRowView = (props: OperationOutsourceWorkByRowViewProps) => {
  const { data } = props;

  return (
    <Detail.Container>
      <Detail.Wrapper>
        <Detail.Content>
          <OperationOutsourceWorkByRowDetailView data={data} />
        </Detail.Content>
      </Detail.Wrapper>
    </Detail.Container>
  );
};
