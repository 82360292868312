import { CavityForm } from "@/features/mold/cavity/form/cavityForm";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Button, Flex, NumberInput, Text, TextInput } from "@mantine/core";

import { mutateMoldStocks } from "@/api/moldStock/useMoldStockQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import { MasterApiMoldStocksPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  NumberInput: NumberInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  CavityForm: CavityForm,
});

export const MoldStockCreateForm = () => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: createMutate } = useMutation(
    (params: MasterApiMoldStocksPostRequest) =>
      mutateMoldStocks.create(params).mutationFn(params as MasterApiMoldStocksPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["moldStock"]);
        closeModal(form.values);
        customAlert(
          `${t("message.createSuccess", { item: `${t("moldStock.moldStock")}` })}`,
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("moldStock.code") }),
          `${t("common.createFail", { item: `${t("moldStock.moldStock")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: undefined,
      moldCode: undefined,
      limitCounter: undefined,
      inspectCounter: undefined,
      currentCounter: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        moldStocksGetRequest: {
          code: form.values.code!,
          moldCode: form.values.moldCode!,
          limitCounter: form.values.limitCounter!,
          inspectCounter: form.values.inspectCounter!,
          currentCounter: form.values.currentCounter!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("moldStock.code") }),
        `${t("common.createFail", { item: `${t("moldStock.moldStock")}` })}`,
        "red",
      );
    }
  };

  return (
    <Create.Wrapper>
      <Create.Input label={t("moldStock.code")} withAsterisk {...form.getInputProps("code")} />
      <MoldAutoComplete label={t("mold.code")} withAsterisk {...form.getInputProps("moldCode")} />
      <Create.NumberInput
        label={t("moldStock.limitCounter")}
        {...form.getInputProps("limitCounter")}
      />
      <Create.NumberInput
        label={t("moldStock.inspectCounter")}
        {...form.getInputProps("inspectCounter")}
      />
      <Create.NumberInput
        label={t("moldStock.currentCounter")}
        {...form.getInputProps("currentCounter")}
      />
      <Create.ButtonBox>
        <Create.Button color="gray" onClick={closeModal}>
          {t("common.cancel")}
        </Create.Button>
        <Create.Button
          disabled={validateFormValues({
            pageType: "moldStock",
            values: form.values,
          })}
          onClick={onSubmit}
        >
          {t("common.create", { item: `${t("moldStock.moldStock")}` })}
        </Create.Button>
      </Create.ButtonBox>
    </Create.Wrapper>
  );
};
