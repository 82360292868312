import { useWorkActions } from "@/features/workByManagement/hooks/useWorkActions";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { ActionIcon, Paper, Title } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import dayjs from "dayjs";

interface WorkHeaderProps {
  dateType?: "default" | "range";
  startDate?: Date | null;
  endDate?: Date | null;
  setStartDate?: (date: Date) => void;
  setEndDate?: (date: Date) => void;
}

export const WorkHeader = ({
  dateType,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: WorkHeaderProps) => {
  const state = useWorkState();
  const actions = useWorkActions();
  const type = "default";

  const setPrevDate = () => {
    const newDate = dayjs(startDate).subtract(1, "day").startOf("day");
    actions.setDate([newDate.toDate(), newDate.toDate()]); // 하루 전으로 설정
    if (setStartDate) setStartDate(newDate.toDate()); // QueryString 업데이트
    if (setEndDate) setEndDate(newDate.toDate());
  };

  const setNextDate = () => {
    const newDate = dayjs(startDate).add(1, "day").startOf("day");
    actions.setDate([newDate.toDate(), newDate.toDate()]); // 하루 후로 설정
    if (setStartDate) setStartDate(newDate.toDate()); // QueryString 업데이트
    if (setEndDate) setEndDate(newDate.toDate());
  };

  const dateTitle =
    type === dateType
      ? dayjs(startDate).format("YYYY-MM-DD")
      : dayjs(startDate).format("YYYY-MM-DD") +
        " ~ " +
        dayjs(endDate ?? new Date()).format("YYYY-MM-DD");

  return (
    <WorkHeaderWrapper withBorder>
      {type === dateType && (
        <ActionIcon color={theme.colors?.blue?.[6]} onClick={() => setPrevDate()}>
          <IconChevronLeft />
        </ActionIcon>
      )}
      <Title size="h1" weight={700}>
        {dateTitle}
      </Title>
      {type === dateType && (
        <ActionIcon color={theme.colors?.blue?.[6]} onClick={() => setNextDate()}>
          <IconChevronRight />
        </ActionIcon>
      )}
    </WorkHeaderWrapper>
  );
};

const WorkHeaderWrapper = styled<any>(Paper)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
