import Factory from "@/images/notfound/factory.png";
import { Button, Flex, Image, Text, Tooltip } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigator = useNavigate();

  return (
    <Flex justify={"center"} align={"center"} h={"100%"} direction={"column"} gap="xl">
      <Text size="8rem" color="blue.7">
        404 ERROR
      </Text>
      <Flex justify={"center"} align={"center"} direction={"column"} gap="sm">
        <Text size="xl" fw={700}>
          페이지를 찾을 수 없습니다.
        </Text>
        <Text size="xl" fw={700}>
          존재하지 않는 주소를 입력하셨거나,
        </Text>
        <Text size="xl" fw={700}>
          요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.
        </Text>
      </Flex>
      <Flex my="4rem">
        <Tooltip
          label={
            <span style={{ fontSize: "0.8rem" }}>천연 가스 아이콘 제작자: Freepik - Flaticon</span>
          }
          position="bottom"
        >
          <Image src={Factory} alt="https://www.flaticon.com/kr/free-icons/-" />
        </Tooltip>
      </Flex>
      <Button onClick={() => navigator("/")} size="lg">
        메인 페이지로 이동
      </Button>
    </Flex>
  );
};
