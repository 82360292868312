import { QueryDefaultValuesTypeValue } from "@/constants/queryDefaultValues";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface QueryStringState {
  page: number;
  pageSize: string;
  search: string;
  searchFields: string[];
  query: string;
  sort: string[];
}

const useQueryString = (initialValues: QueryDefaultValuesTypeValue) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [queryString, setQueryString] = useState<QueryStringState>({
    page: parseInt(searchParams.get("page") || initialValues.page.toString()),
    pageSize: searchParams.get("pageSize") || initialValues.pageSize,
    search: searchParams.get("search") || initialValues.search,
    searchFields: searchParams.get("searchFields")
      ? searchParams.get("searchFields")!.split(",")
      : [],
    query: searchParams.get("query") || JSON.stringify(initialValues.query),
    sort: searchParams.get("sort") ? searchParams.get("sort")!.split(",") : [],
  });

  useEffect(() => {
    const queryParams: Record<string, any> = {};
    Object.keys(queryString).forEach((key) => {
      if (
        JSON.stringify(queryString[key as keyof QueryStringState]) !==
        JSON.stringify(initialValues[key as keyof QueryDefaultValuesTypeValue])
      ) {
        queryParams[key] = Array.isArray(queryString[key as keyof QueryStringState])
          ? (queryString[key as keyof QueryStringState] as any).join(",")
          : queryString[key as keyof QueryStringState].toString();
      }
    });

    navigate({
      search: new URLSearchParams(queryParams).toString(),
    });
  }, [queryString, initialValues, navigate]);

  return [queryString, setQueryString] as const;
};

export default useQueryString;
