import { CancelButton } from "@/features/ui/cancel/CancelButton";
import { SaveButton } from "@/features/ui/save/SaveButton";
import { Group } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";

interface FormActionButtonsProps<T> {
  form?: UseFormReturnType<T>;
  disabled?: boolean;
  onSubmit?: (values?: T) => void;
  onCancel?: () => void;
  children?: React.ReactNode;
}

export const FormActionButtonGroup = <T,>({
  form,
  disabled,
  onSubmit,
  onCancel,
  children,
}: FormActionButtonsProps<T>) => {
  return (
    <Group position="right" spacing="sm">
      <CancelButton onCancel={onCancel}>{"취소"}</CancelButton>
      <SaveButton disabled={disabled} form={form} onSubmit={onSubmit}>
        {children}
      </SaveButton>
    </Group>
  );
};
