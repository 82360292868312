import { ItemsCreateForm } from "@/features/item/components/form/ItemsCreateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Button, Flex } from "@mantine/core";
import { IconPlus, IconRecycle, IconTrash } from "@tabler/icons-react";

// 제네릭 타입을 포함하는 ActionHeaderProps 인터페이스 정의
interface ActionHeaderProps {
  deleteFn: () => void;
}

// 제네릭을 포함한 ES6 문법의 ActionHeader 컴포넌트
export const ActionHeader = ({ deleteFn }: ActionHeaderProps) => {
  const { openModal } = useModal();
  const { resetQueryStrings } = useStandardLayout();

  return (
    <>
      <Flex>
        <Button
          leftIcon={<IconPlus />}
          onClick={() => openModal(<ItemsCreateForm />, null, "품목 추가", true)}
        >
          품목 추가
        </Button>
      </Flex>
      <Flex gap="md">
        <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
          검색값 초기화
        </Button>
        <Button rightIcon={<IconTrash />} color="red" onClick={() => deleteFn()}>
          선택된 행 삭제
        </Button>
      </Flex>
    </>
  );
};
