import { useQuery } from "@tanstack/react-query";

import DefaultInstance from "@/instance/axios";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";

export const GET_WORK_QUERY_KEY = "get-works";

const getWorkFetch = (param?: number) => {
  if (param) {
    return DefaultInstance.worksWorkIdGet({
      workId: param,
      populate: [
        "routingData",
        "item",
        "summary",
        "locationSetting",
        "productionPlan",
        "equipment",
        "purchaseOrderItem",
      ],
    });
  } else return Promise.reject("workId is not provided");
};

const useWorkGetQuery = (param?: number) => {
  return useQuery<AxiosResponse<ProductionPlansGet200ResponseRowsInnerWorksInner, any[]>>({
    queryKey: [GET_WORK_QUERY_KEY, JSON.stringify(param)],
    queryFn: () => getWorkFetch(param),
    enabled: !!param,
  });
};

export default useWorkGetQuery;
