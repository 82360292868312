import FrequentlyLabel from "@/features/PMS/frequently/FrequentlyLabel";
import FrequentlyProgressBar from "@/features/PMS/frequently/FrequentlyProgressBar";
import styled from "@emotion/styled";
import { ActionIcon, Flex, Text } from "@mantine/core";
import { IconArrowDown } from "@tabler/icons-react";
import Notiflix from "notiflix";
import React, { useState } from "react";

interface SLIDSETTINGPROPS {
  title: string;
  type: string;
  background?: string;
  prevReadOnly?: boolean;
  nextReadOnly?: boolean;
}

interface Props {
  name: string | number;
  code: string | number;
  onChange: any;
  containerStyles?: Record<string, string | number>;
  data?: any;
}

const slidSettingList: SLIDSETTINGPROPS[] = [
  {
    title: "정상",
    type: "normal",
    background: "#205200",
    prevReadOnly: true,
    nextReadOnly: false,
  },

  {
    title: "주의",
    type: "caution",
    background: "#848901",
    prevReadOnly: true,
    nextReadOnly: false,
  },

  {
    title: "위험",
    type: "warn",
    background: "#7C0A00",
    prevReadOnly: true,
    nextReadOnly: true,
  },
];

const SlipDropDown: React.FunctionComponent<Props> = ({
  containerStyles,
  name,
  code,
  onChange,
  data,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const filterData = () => {
    return slidSettingList.map((slide) => {
      if (slide.type === "normal") {
        return { ...slide, prevNum: 0, nextNum: data.normal ?? "-" };
      } else if (slide.type === "caution") {
        return { ...slide, prevNum: data.normal ?? "-", nextNum: data.caution ?? "-" };
      } else if (slide.type === "warn") {
        return { ...slide, prevNum: data.caution ?? "-", nextNum: 360 };
      }
    });
  };

  const validationDegree = (e: any, slid: any) => {
    if (slid.prevNum > slid.nextNum || slid.nextNum > 360) {
      Notiflix.Report.warning("오류", "각도에 이상이 있습니다", "확인");
      onChange({ ...data, [e.target.name]: 0, isChange: true });
    }
  };

  return (
    <SlipDropDownContainer>
      <Flex
        justify={"space-between"}
        align={"center"}
        px={"16px"}
        py={"10px"}
        style={data.isChange && { backgroundColor: "#19B9DF" }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Text
          fw="bold"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {code}
        </Text>
        <Flex justify={"space-between"}>
          {filterData().map((slid, index) => (
            <div style={{ display: "flex", width: 150 }}>
              <FrequentlyLabel
                text={`${slid?.title} :`}
                size={16}
                containerStyles={{ marginRight: 5 }}
              />
              <p>{`${slid?.prevNum}° ~ ${slid?.nextNum}°`}</p>
            </div>
          ))}
        </Flex>
        <ActionIcon
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <IconArrowDown size="2rem" />
        </ActionIcon>
      </Flex>
      {isOpen && (
        <div style={{ padding: "16px 32px", background: "#353B48" }}>
          <Flex align={"center"}>
            {filterData().map((slid, index) => (
              <div style={{ flex: "1 0 33.3333%" }}>
                <Flex mx={"30px"} my={"20px"}>
                  <FrequentlyLabel
                    text={slid?.title}
                    size={35}
                    weight={600}
                    containerStyles={{ marginRight: 30 }}
                  />
                  <Flex align={"center"}>
                    <DegreeItem>
                      <DegreeInput
                        type="text"
                        readOnly={slid?.prevReadOnly}
                        value={slid?.prevNum}
                        maxLength={3}
                      />
                    </DegreeItem>
                    <p style={{ padding: "0px 10px" }}>{"~"}</p>
                    <DegreeItem>
                      <DegreeInput
                        type="text"
                        name={slid?.type}
                        readOnly={slid?.nextReadOnly}
                        key={`degree${index}`}
                        maxLength={3}
                        value={slid?.nextNum}
                        onBlur={(e) => validationDegree(e, slid)}
                        onChange={(e: any) => {
                          const re = /^[0-9\b]+$/;
                          if (e.target.value === "" || re.test(e.target.value)) {
                            onChange({
                              ...data,
                              [e.target.name]: Number(e.target.value),
                              isChange: true,
                            });
                          }
                        }}
                      />
                    </DegreeItem>
                  </Flex>
                </Flex>

                <FrequentlyProgressBar
                  maximum={180}
                  type={"value"}
                  Drawscale={true}
                  value={[
                    {
                      value: 1,
                      color: "#169EBA",
                      name: "가동시간",
                    },
                  ]}
                  progressContainerStyle={{
                    width: "100%",
                    height: "15px",
                    background: `${slid?.background}`,
                  }}
                />

                <Flex justify={"space-between"}>
                  <Text color="white">{`${slid?.prevNum}°`}</Text>
                  {index > filterData().length - 2 && (
                    <Text color="white">{`${slid?.nextNum}°`}</Text>
                  )}
                </Flex>
              </div>
            ))}
          </Flex>
        </div>
      )}
    </SlipDropDownContainer>
  );
};

export default SlipDropDown;

const SlipDropDownContainer = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const DegreeItem = styled.div`
  border-radius: 6px;
  opacity: 0.5;
  background: #111319;
  width: 120px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:focus-within {
    outline: none;
    box-shadow: 0px 0px 7px #19b9df;
  }

  &:after {
    content: "°";
    padding: 0px 30px 0px 0px;
  }
`;

const DegreeInput = styled.input`
  width: 100%;
  background: #111319;
  border: 0px;
  color: #ffffff;
  text-align: right;
  font-size: 18px;
  padding: 0px 5px 0px 0px;
`;
