import { Button, TextInput } from "@mantine/core";
import { WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInnerLotInfo } from "@sizlcorp/sizl-api-document/dist/models";
import { IconTrash } from "@tabler/icons-react";
import { Td } from "./OutgoingAllForm";

interface IncomingBulkFormItemProps {
  formData: {
    itemCode: string;
    itemName: string;
    locationCode: string;
    locationName: string;
    spec: string;
    quantity: string;
    lotId: number;
    lotInfo: WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInnerLotInfo;
    actionFrom: string;
  };
  seq: number;
  onChange: (index: number, quantity: string) => void;
  onDelete: (index: number) => void;
}

export const BulkIncomingFormRow: React.FC<IncomingBulkFormItemProps> = ({
  formData,
  seq,
  onChange,
  onDelete,
}) => {
  const { itemCode, itemName, locationCode, locationName, spec, quantity, lotId, lotInfo } =
    formData;

  return (
    <tr>
      <Td width={8}>{lotId}</Td>
      <Td width={14}>{lotInfo.name}</Td>
      <Td width={14}>{locationCode}</Td>
      <Td width={14}>{locationName}</Td>
      <Td width={14}>{itemCode}</Td>
      <Td width={14}>{itemName}</Td>
      <Td width={10}>{spec}</Td>
      <Td width={14}>
        <TextInput type="number" value={quantity} onChange={(e) => onChange(seq, e.target.value)} />
      </Td>
      <Td width={7}>
        <Button leftIcon={<IconTrash />} color="red" variant="white" onClick={() => onDelete(seq)}>
          삭제
        </Button>
      </Td>
    </tr>
  );
};
