import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { ActionIcon, Badge, Button, Flex, Text } from "@mantine/core";
import { IconPlus, IconX } from "@tabler/icons-react";
import { SorterForm } from "./StandardInfoBestPracticeSorterForm";

const getSortItem = (sortItem: string) => {
  const item = sortItem.split("-");

  if (item.length > 1) {
    return "내림차순";
  }

  return "오름차순";
};

export const Sorter = () => {
  const { openModal } = useModal();
  const { setPage, sort, setSort, searchFieldsHeader } = useStandardLayout();

  const onSubmit = (result: string) => {
    if (!result) return;
    setPage(1); // 검색어 변경 시 항상 페이지를 1로 초기화
    setSort((prev: string[]) => [...prev, result]); // query는 id 속성 없이 그대로 업데이트
  };

  const removeSorter = (sortItem: string) => {
    setPage(1); // 검색어 변경 시 항상 페이지를 1로 초기화
    setSort((prev: string[]) => prev.filter((item) => item !== sortItem));
  };

  return (
    <Flex gap="sm" align="center" h="100%" wrap="wrap">
      {sort.map((item: string) => {
        const sortText = getSortItem(item);

        const findHeadersByKeys = (key: string) => {
          const data = searchFieldsHeader.find((header) => header.value === key);
          return data;
        };

        const header = findHeadersByKeys(item.replace("-", ""));

        return (
          <Badge
            key={item}
            variant="filled"
            size="xl"
            rightSection={
              <ActionIcon variant="filled" size="md" color="blue" radius="xl" onClick={() => {}}>
                <IconX />
              </ActionIcon>
            }
            onClick={() => removeSorter(item)}
          >
            <Text>{`${header?.label} - ${sortText}`} 정렬</Text>
          </Badge>
        );
      })}
      <Button
        variant="subtle"
        color="dark"
        leftIcon={<IconPlus />}
        onClick={() =>
          openModal(
            <SorterForm searchFieldsHeader={searchFieldsHeader} />,
            null,
            "정렬 추가",
            true,
          ).then((result) => onSubmit(result))
        }
      >
        정렬 추가
      </Button>
    </Flex>
  );
};
