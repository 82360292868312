import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { EventHandler, forwardRef, useState } from "react";

import { useModal } from "@/features/modal/ModalStackManager";
import {
  ItemCategoryOption,
  useItemCategoriesAutoComplete,
  useItemCategoriesCode,
} from "@/features/ui/autoComplete/itemCategory/auto-useGetItemCategoriesQuery";
import styled from "@emotion/styled";

import { mutateItemCategories } from "@/api/itemCategories/useItemCategoriesQuery";
import { ItemCategoryCreateForm } from "@/features/itemCategory/form/CreateForm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { MasterApiItemCategoriesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import customAlert from "../../alert/alert";

interface ItemCategoryProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export interface ItemCategoryAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (ItemCategoryName: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
}

// 입력값: value (ItemCategory 모델의 code)
// 출력값: onChange (ItemCategory 모델의 code)
export const ItemCategoryAutoComplete = (params: ItemCategoryAutoCompleteProps) => {
  const { value: ItemCategoryName, onChange, maxDropdownHeight, width, ...etcParams } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useItemCategoriesAutoComplete(focused, searchKeyword);

  const { data: initialOptions } = useItemCategoriesCode(
    !!ItemCategoryName,
    ItemCategoryName ?? null,
  );

  let selectedItemCategory = initialOptions?.find(
    (ItemCategory) => ItemCategory.value === ItemCategoryName,
  );
  const onChangeHandler = (e: string | null) => {
    selectedItemCategory = options?.find((ItemCategory) => ItemCategory.value === e);
    onChange && onChange(e);
  };

  const SelectItemCategory = forwardRef<HTMLDivElement, ItemCategoryProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            <Text fz="xs">(품목분류명: {code})</Text>
          </Flex>
        </Flex>
      </div>
    ),
  );

  return (
    <ItemCategorySelect
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      value={ItemCategoryName}
      itemComponent={SelectItemCategory}
      searchValue={searchKeyword}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: ItemCategoryProps[], option: ItemCategoryProps) => {
          return unique.some((u) => u.value === option.value) ? unique : [...unique, option];
        },
        [],
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={ItemCategoryInfo({
        ItemCategory: selectedItemCategory as ItemCategoryOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewItemCategory({
        ItemCategory: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const ItemCategoryInfo = (params: {
  ItemCategory?: ItemCategoryOption;
  onChange: (ItemCategoryName: string | null) => void;
}) => {
  const { ItemCategory, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return ItemCategory?.value ? (
    <ItemCategoryInfoLabel>
      <IconX size="1rem" onClick={clearHandler} />
    </ItemCategoryInfoLabel>
  ) : null;
};

const AddNewItemCategory = (params: {
  ItemCategory: string;
  onChange: (ItemCategoryName: string) => void;
}) => {
  const { ItemCategory, onChange } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: postMutate } = useMutation(
    (params: MasterApiItemCategoriesPostRequest) =>
      mutateItemCategories
        .create(params)
        .mutationFn(params as MasterApiItemCategoriesPostRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["itemCategories"]);
        closeModal(res?.data);
        customAlert(
          t("message.createSuccess", { item: t("item.category") }),
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("item.category") }),
          t("common.createFail", { item: t("item.category") }),
          "red",
        );
      },
    },
  );

  const onCloseHandler: EventHandler<any> = (values) => {
    postMutate({
      itemCategoriesGetRequest: {
        name: values.name!,
      },
    });
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    openModal(
      <ItemCategoryCreateForm name={ItemCategory} autoCompleteOnSubmit={onCloseHandler} />,
      null,
      t("common.newCreate", { item: t("item.category") }),
      true,
    ).then((result) => {
      onChange(result.name);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      {t("common.newCreate", { item: `${t("item.category")} ${ItemCategory}` })}
    </Button>
  );
};

const ItemCategoryInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
`;

const ItemCategorySelect = styled(Select)<{ value: string | null | undefined }>`
  .mantine-Select-input {
    font-size: 0.8rem;
    padding-bottom: ${(props) => (props.value ? "0.8rem !important" : "0.4rem !important")};

    &.item-dirty {
      color: cadetblue !important;
      border-color: cadetblue;
    }
  }
  .mantine-Select-error {
    font-size: 11px;
    color: red;
  }
`;
