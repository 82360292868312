import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { invoiceTypeColor, setInvoiceStatus } from "@/utils/invoiceStatus";
import { purchaseOrderStatusColor, setPurchaseOrderStatus } from "@/utils/scmDataStatus";
import { Badge, Checkbox, Space } from "@mantine/core";
import { InvoicesGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";

export interface InvoiceStatementItemsSelectFormProps {
  data: InvoicesGet200ResponseRowsInner | undefined;
}

const OrderDetail = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Field: DetailField,
  Label: DetailLabel,
  Content: DetailContent,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  CheckBox: Checkbox,
  Inform: DetailInformTitle,
});
export const InvoiceStatementDetailHeader = (params: InvoiceStatementItemsSelectFormProps) => {
  const { data } = params;
  const { openModal } = useModal();

  return (
    <OrderDetail>
      <OrderDetail.Title>{data?.code}</OrderDetail.Title>
      <Space h={8} />
      <OrderDetail.Field>
        <OrderDetail.Label>거래명세서 번호</OrderDetail.Label>
        <OrderDetail.Content>{data?.id}</OrderDetail.Content>
      </OrderDetail.Field>
      <OrderDetail.Field>
        <OrderDetail.Label>거래명세서 코드</OrderDetail.Label>
        <OrderDetail.Content>{data?.code}</OrderDetail.Content>
      </OrderDetail.Field>
      <OrderDetail.Field>
        <OrderDetail.Label>거래명세서 타입</OrderDetail.Label>
        <OrderDetail.Content>
          <Badge
            variant="filled"
            color={invoiceTypeColor[data?.invoiceType as keyof typeof invoiceTypeColor]}
          >
            {setInvoiceStatus(data?.invoiceType ?? "")}
          </Badge>
        </OrderDetail.Content>
      </OrderDetail.Field>
      <OrderDetail.Field>
        <OrderDetail.Label>거래명세서 상태</OrderDetail.Label>
        <OrderDetail.Content>
          <Badge
            variant="filled"
            color={purchaseOrderStatusColor[data?.status as keyof typeof purchaseOrderStatusColor]}
          >
            {setPurchaseOrderStatus(data?.status ?? "")}
          </Badge>
        </OrderDetail.Content>
      </OrderDetail.Field>
      <OrderDetail.Field>
        <OrderDetail.Label>{"출고 로케이션"}</OrderDetail.Label>
        <OrderDetail.Content>
          <OrderDetail.Link
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={data?.locationCode} />,
                null,
                "로케이션 상세",
              )
            }
          >
            {data?.locationName}
          </OrderDetail.Link>
        </OrderDetail.Content>
      </OrderDetail.Field>
      <OrderDetail.Field>
        <OrderDetail.Label>{"거래명세서 갯수"}</OrderDetail.Label>
        <OrderDetail.Content>{data?.numberOfInvoiceItems}</OrderDetail.Content>
      </OrderDetail.Field>
      <OrderDetail.Field>
        <OrderDetail.Label>{"작성자"}</OrderDetail.Label>
        <OrderDetail.Content>{data?.creatorUserName}</OrderDetail.Content>
      </OrderDetail.Field>
      <OrderDetail.Field>
        <OrderDetail.Label>{"거래명세서일"}</OrderDetail.Label>
        <OrderDetail.Content>{dayjs(data?.invoiceDate).format("YYYY-MM-DD")}</OrderDetail.Content>
      </OrderDetail.Field>
      <OrderDetail.Field>
        <OrderDetail.Label>{"접수일시"}</OrderDetail.Label>
        <OrderDetail.Content>{dayjs(data?.createdAt).format("YYYY-MM-DD")}</OrderDetail.Content>
      </OrderDetail.Field>
      <OrderDetail.Field>
        <OrderDetail.Label>{"수정정보"}</OrderDetail.Label>
        <OrderDetail.Content>
          {data?.updatedAt === null ? "" : dayjs(data?.updatedAt).format("YYYY-MM-DD")}
        </OrderDetail.Content>
      </OrderDetail.Field>
    </OrderDetail>
  );
};
