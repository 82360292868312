import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs } from "@mantine/core";

import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface WorkTabProps {
  children: ReactNode;
}

export const WorkTab = ({ children }: WorkTabProps) => {
  const { t } = useTranslation();
  return (
    <WorkTabContainer>
      <Tabs
        variant="outline"
        defaultValue="routingInfo" // productionHistory
        styles={(theme) => ({
          tab: {
            "&:not([data-active])": {
              backgroundColor: theme.colors?.gray?.[0],
            },
          },
        })}
      >
        <Tabs.List>
          <LeftSpace />
          <Tabs.Tab value="routingInfo">{t("workTab.routing")}</Tabs.Tab>
          <Tabs.Tab value="inputLot">{t("workTab.lot")}</Tabs.Tab>
          <Tabs.Tab value="productionInventory">{t("workTab.productionInventory")}</Tabs.Tab>
          <Tabs.Tab value="productionHistory">{t("workTab.productionHistory")}</Tabs.Tab>
          <Tabs.Tab value="inspectRecord">{t("workTab.inspectRecord")}</Tabs.Tab>
          <Tabs.Tab value="statusHistory">{t("workTab.statusHistory")}</Tabs.Tab>
          <Tabs.Tab value="statusTimeLine">{t("workTab.statusTime")}</Tabs.Tab>
          {/* <Tabs.Tab value="inventory">미투입 선차감 재고 현황</Tabs.Tab> */}
          <RightSpace />
        </Tabs.List>
        {children}
      </Tabs>
    </WorkTabContainer>
  );
};

const WorkTabContainer = styled.div`
  width: 100%; //80rem;
`;

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
