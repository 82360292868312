import DefaultInstance from "@/instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  DefaultApiWorkLogsWorksFindPostRequest,
  DefaultApiWorkLogsWorksGetRequest,
  DefaultApiWorkLogsWorksWorkLogIdGetRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const workLogsWorks = createQueryKeys("workLogsWorks", {
  all: null,
  get: (params: DefaultApiWorkLogsWorksGetRequest) => {
    // const queryParams = { ...params };
    const queryParams = {
      ...params,
      query: JSON.stringify({ $and: params?.query }),
      sort: params.sort?.length ? params.sort : "-createdAt",
    }; // 작업 일지 조회 시 기본 정렬 표시

    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => DefaultInstance.workLogsWorksGet(queryParams),
    };
  },
  find: (params: DefaultApiWorkLogsWorksFindPostRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }

    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.workLogsWorksFindPost(query),
    };
  },
  detail: (params: DefaultApiWorkLogsWorksWorkLogIdGetRequest) => {
    return {
      queryKey: [params],
      queryFn: () => DefaultInstance.workLogsWorksWorkLogIdGet(params),
    };
  },
});
