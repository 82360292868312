import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";
import { useEffect, useState } from "react";

interface CustomTableOptions<TData extends Record<string, any>> extends MRT_TableOptions<TData> {
  isRowSelectable?: (row: any) => boolean;
}

interface MantineSelectableTableProps<TData extends Record<string, any>>
  extends CustomTableOptions<TData> {
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
  onRowSelection?: (selectedRows: TData[]) => void; // 선택된 로우 변경 시 호출되는 핸들러
}

export const MantineSelectableTable = <TData extends Record<string, any>>({
  columns,
  data,
  onRowSelection,
  ...props
}: MantineSelectableTableProps<TData>) => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  useEffect(() => {
    if (onRowSelection) {
      const selectedRows = Object.keys(rowSelection)
        .filter((key) => rowSelection[key]) // 선택된 로우만 필터링
        .map((key) => data[Number(key)]);
      onRowSelection(selectedRows);
    }
  }, [rowSelection]);

  const table = useMantineReactTable<TData>({
    columns,
    data,
    enableRowSelection: true,
    enableRowNumbers: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
    ...props,
  });

  return <MantineReactTable table={table} />;
};
