import { Step, Stepper } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { DeepMap, FieldError, useForm } from "react-hook-form";
// import { saveDataToServer } from '여기에_서버에_저장하는_함수_또는_API_요청';

function saveDataToServer(data: FormData) {
  console.log(data);
}

interface FormData {
  name: string;
  email: string;
  message: string;
}

const Step1: React.FC<{
  register: any;
  errors: DeepMap<Partial<FormData>, FieldError | undefined>;
}> = ({ register, errors }) => {
  return (
    <div>
      <input type="text" name="name" ref={register({ required: true })} />
      {errors && errors.name && <span>이름을 입력해주세요.</span>}
    </div>
  );
};

const Step2: React.FC<{
  register: any;
  errors: DeepMap<Partial<FormData>, FieldError | undefined>;
}> = ({ register, errors }) => {
  return (
    <div>
      <input type="email" name="email" ref={register({ required: true, pattern: /^\S+@\S+$/i })} />
      {errors && errors.email && <span>유효한 이메일을 입력해주세요.</span>}
    </div>
  );
};

const Step3: React.FC<{
  register: any;
  errors: DeepMap<Partial<FormData>, FieldError | undefined>;
}> = ({ register, errors }) => {
  return (
    <div>
      <textarea name="message" ref={register({ required: true })}></textarea>
      {errors && errors.message && <span>메시지를 입력해주세요.</span>}
    </div>
  );
};

const WizardForm: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  // const { handleSubmit, register, formState: { errors } } = useForm<FormData>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const steps = [
    { label: "Step 1", component: Step1 },
    { label: "Step 2", component: Step2 },
    { label: "Step 3", component: Step3 },
  ];

  const onSubmit = async (data: FormData) => {
    await saveDataToServer(data);
    // 서버에 저장이 완료되었을 때 필요한 작업 수행
  };

  const mutation = useMutation(onSubmit);

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleFormSubmit = (data: FormData) => {
    mutation.mutate(data);
  };

  const StepComponent = steps[activeStep].component;

  return (
    <div>
      <Stepper active={activeStep}>
        {steps.map((step, index) => (
          <Step key={index}>{step.label}</Step>
        ))}
      </Stepper>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div>
          <h2>{steps[activeStep].label}</h2>
          <StepComponent register={register} errors={errors} />

          {/* 이전/다음 버튼 */}
          {activeStep > 0 && (
            <button type="button" onClick={handlePrevStep}>
              이전
            </button>
          )}

          {activeStep < steps.length - 1 && (
            <button type="button" onClick={handleNextStep}>
              다음
            </button>
          )}

          {activeStep === steps.length - 1 && (
            <button type="submit" disabled={mutation.isLoading}>
              저장
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default WizardForm;
