// @fileoverview : 기준정보 > 경남-협력사
import { mutatePartners } from "@/api/partner/usePartnerQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { PartnerCreateForm } from "@/features/partner/form/createForm";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { PartnerUpdateForm } from "@/features/partner/form/updateForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { partnerStatusColor, setPartnerStatus } from "@/utils/scmDataStatus";
import { ActionIcon, Avatar, Badge, Flex, Text, Tooltip } from "@mantine/core";
import {
  MasterApiPartnersPartnerIdDeleteRequest,
  PartnersGet200Response,
  PartnersGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

// 우리 회사에서 다루는 협력사에 대해 조회할 수 있는 페이지 입니다.
export const PartnerPage = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { t } = useTranslation();
  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiPartnersPartnerIdDeleteRequest) =>
      mutatePartners
        .delete(params)
        .mutationFn(params as MasterApiPartnersPartnerIdDeleteRequest | any),
    {
      onSuccess: () => {
        customAlert(
          `${t("message.deleteSuccess", { item: `${t("partner.partner")}` })}`,
          t("common.deleteSuccess"),
          "green",
        );
        refetch();
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("partner.code") }),
          `${t("common.deleteFail", { item: `${t("partner.partner")}` })}`,
          "red",
        );
      },
    },
  );

  const columns: readonly Column<PartnersGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "code",
      name: t("partner.code"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <PartnerDetailForm partnerCode={formatterProps?.row?.code} />,
                null,
                `${t("common.detail", { item: `${t("partner.partner")}` })}`,
              )
            }
          >
            {formatterProps.row.code}
          </LinkAnchor>
        );
      },
    },
    {
      key: "name",
      name: t("partner.name"),
      sortable: true,
      resizable: true,
    },
    {
      key: "type",
      name: t("partner.type"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Badge
            color={partnerStatusColor[formatterProps.row.type as keyof typeof partnerStatusColor]}
          >
            {setPartnerStatus(formatterProps.row.type ?? "")}
          </Badge>
        );
      },
    },
    {
      key: "contact",
      name: t("partner.contact"),
      sortable: true,
      resizable: true,
    },
    {
      key: "options",
      name: t("common.action"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updatePartnerActionView = () => {
          openModal(
            <PartnerUpdateForm formatterProps={formatterProps.row} />,
            null,
            `${t("common.edit", { item: `${t("partner.partner")}` })}`,
            true,
          );
        };

        const deletePartnerAction = () => {
          openModal(
            <ConfirmForm
              message={t("message.rowDelete")}
              yesCallback={() => deleteMutate({ partnerId: formatterProps?.row.id as number })}
              noCallback={() => {}}
              commonCallback={() => closeModal(null)}
            />,
            null,
            `${t("common.delete", { item: `${t("partner.partner")}` })}`,
          );
        };

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon variant="subtle" onClick={updatePartnerActionView} color="yellow">
              <IconEdit />
            </ActionIcon>
            <ActionIcon variant="subtle" onClick={() => deletePartnerAction()} color="red">
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: `${t("partner.partner")}` })}`,
        `${t("common.deleteFail", { item: `${t("partner.partner")}` })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ partnerId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      `${t("common.delete", { item: `${t("partner.partner")}` })}`,
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader
          createFn={() =>
            openModal(
              <PartnerCreateForm code={""} />,
              null,
              `${t("common.additional", { item: `${t("partner.partner")}` })}`,
              true,
            )
          }
          createDataTitle={t("partner.partner")}
          deleteFn={() => handleDelete()}
        />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: `${t("partner.partner")}` })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${t("partner.partner")}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<PartnersGet200Response, PartnersGet200ResponseRowsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<PartnersGet200Response> />
      </Flex>
    </Flex>
  );
};
