import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { setDateFormatStringWithTime } from "@/utils/dateTimeUtil";
import { setStatusIcon, statusObj } from "@/utils/workStatus";
import styled from "@emotion/styled";
import { Text, ThemeIcon, Timeline } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { WorkViewProps } from "./WorkView";
export const WorkStatusTimeLine = ({ workId }: WorkViewProps) => {
  const { t } = useTranslation();
  const { data: workLogsData } = useQuery(
    workLogs.find({
      query: {
        $and: [
          { workId: { $eq: workId ?? 0 } },
          {
            workLogType: [
              WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_START,
              WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_END,
              WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_PAUSE,
              WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_RESUME,
            ],
          },
        ],
      },
      sort: "-id",
      populate: ["downtimeReasonName"],
    }),
  );
  // workLogs와 statusObj는 해당 컴포넌트의 상태 또는 props를 참조해야 합니다.

  return (
    <WorkStatusTimeLineWrapper>
      <Timeline active={0} bulletSize={24} lineWidth={2}>
        {workLogsData?.data?.map((log, index) => {
          const { icon: IconComponent, color } = setStatusIcon(log.workLogType!);
          return (
            <Timeline.Item
              key={index}
              bullet={
                <ThemeIcon color={color} size={24} radius="xl">
                  <IconComponent size={16} />
                </ThemeIcon>
              }
              title={`${t(statusObj[log.workLogType!])} ${log.downtimeReasonName ? `(${log.downtimeReasonName})` : ""}`}
            >
              {/* Timeline의 내용은 실제 로그 데이터를 참조하여 동적으로 생성합니다. */}
              <Text size="xs" mt={4}>
                {setDateFormatStringWithTime(log.createdAt)}
              </Text>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </WorkStatusTimeLineWrapper>
  );
};

const WorkStatusTimeLineWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;
