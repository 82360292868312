import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs } from "@mantine/core";

import { specifications } from "@/api/inspect/inspectSpecification/useSpecificationsQuery";
import { InspectSpecificationInform } from "@/features/inspect/components/inspectSpecification/inform/InspectSpecificationInform";
import { ItemsGet200ResponseRowsInnerRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { InspectCriteriaCreateInform } from "../../inspectCriteria/inform/InspectCriteriaICreateInform";
import { InspectCriterialTable } from "../../inspectCriteria/table/InspectCriterialTable";

interface InspectSpecificationCreateFormProps {
  data?: ItemsGet200ResponseRowsInnerRoutingsInner;
}

export const InspectSpecificationCreateForm = (params: InspectSpecificationCreateFormProps) => {
  const { data } = params;

  const { data: specification } = useQuery(
    specifications.get({
      query: {
        $and: [
          {
            itemCode: data?.itemCode,
          },
        ],
      },
    }),
  );

  return (
    <>
      <RoutingWrapper>
        <RoutingDetailFormContainer>
          <InspectSpecificationInform data={data} />
          <Tabs
            variant="outline"
            defaultValue="inspectCriteria"
            styles={(theme) => ({
              tab: {
                "&:not([data-active])": {
                  backgroundColor: theme.colors?.gray?.[0],
                },
              },
            })}
          >
            <Tabs.List>
              <LeftSpace />
              <Tabs.Tab value="inspectCriteria">검사기준</Tabs.Tab>
              <RightSpace />
            </Tabs.List>
            <Tabs.Panel value="inspectCriteria">
              <InspectCriteriaCreateInform
                data={{
                  ...data,
                  criteria: specification?.data?.rows?.[0]?.criteria,
                  specificationId: specification?.data?.rows?.[0]?.id,
                }}
              />
              <InspectCriterialTable data={specification?.data.rows} />
            </Tabs.Panel>
          </Tabs>
        </RoutingDetailFormContainer>
      </RoutingWrapper>
    </>
  );
};

const InspectStandardInformWrapper = styled.div`
  padding: 16px 32px;
`;

const RoutingDetailFormContainer = styled.div`
  width: 75rem;
  padding-top: 60px;
  background: #ffffff;
  border: 1px solid #ebeef1;
`;

const RoutingWrapper = styled.div`
  padding: 10px 10px;
  background-color: #f8f9fa;
`;

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
