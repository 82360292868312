import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import {
  ActionBarContainer,
  LeftActionBar,
  RightActionBar,
} from "@/features/work/WorkView/ActionBar";
import { getUserCodeByUser } from "@/utils/checkData";
import { Button } from "@mantine/core";
import { ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconRefresh, IconTrash } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { PurchaseOrderStatusForm } from "../form/statusForm";
import { PurchaseOrderUpdateForm } from "../form/updateForm";
import { PurchaseOrderDetailHeaderProps } from "./purchaseOrderDetailHeader";

export const PurchaseOrderDetailActionBar = (params: PurchaseOrderDetailHeaderProps) => {
  const { data } = params;
  const userCode = getUserCodeByUser();
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: deleteMutate } = useMutation(
    (params: ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest) =>
      mutatePurchaseOrders
        .delete(params)
        .mutationFn(params as any | ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["purchaseOrders"]);
      },
    },
  );

  const deletePurchaseOrder = () => {
    if (window.confirm(t("message.deleteItem", { item: data?.id }))) {
      deleteMutate(
        {
          purchaseOrderId: data?.id ?? 0,
        },
        {
          onSuccess: (res) => {
            if (res.status === 200) {
              customAlert(
                `${userCode === "PB" ? t("message.deleteSuccess", { item: t("order.order") }) : t("message.deleteSuccess", { item: t("purchaseOrder.purchaseOrder") })}`,
                t("common.deleteSuccess"),
                "green",
              );
            }
          },
          onError: (error: any) => {
            customAlert(
              `${error.response?.data?.message ?? `${userCode === "PB" ? t("message.deleteFail", { item: t("order.order") }) : t("message.deleteFail", { item: t("purchaseOrder.purchaseOrder") })}`}`,
              t("common.deleteFail"),
              "red",
            );
          },
        },
      );
    }
  };

  return (
    <ActionBarContainer>
      <LeftActionBar>
        <Button
          variant="outline"
          leftIcon={<IconRefresh />}
          onClick={() =>
            openModal(
              <PurchaseOrderStatusForm formatterProps={data} />,
              null,
              `${userCode === "PB" ? t("common.change", { item: t("order.status") }) : t("common.change", { item: t("purchaseOrder.status") })}`,
            )
          }
        >
          {userCode === "PB"
            ? t("common.change", { item: t("order.status") })
            : t("common.change", { item: t("purchaseOrder.status") })}
        </Button>
      </LeftActionBar>

      <RightActionBar>
        <Button
          color="orange"
          rightIcon={<IconEdit />}
          onClick={() =>
            openModal(
              <PurchaseOrderUpdateForm formatterProps={data} />,
              null,
              `${userCode === "PB" ? t("common.edit", { item: t("order.order") }) : t("common.edit", { item: t("purchaseOrder.purchaseOrder") })}`,
            )
          }
        >
          {userCode === "PB"
            ? t("common.edit", { item: t("order.order") })
            : t("common.edit", { item: t("purchaseOrder.purchaseOrder") })}
        </Button>
        <Button color="red" rightIcon={<IconTrash />} onClick={deletePurchaseOrder}>
          {userCode === "PB"
            ? t("common.delete", { item: t("order.order") })
            : t("common.delete", { item: t("purchaseOrder.purchaseOrder") })}
        </Button>
      </RightActionBar>
    </ActionBarContainer>
  );
};
