import { useEffect, useState } from "react";

// useLocalStorage 훅 정의
/*
    타입스크립트의 제네릭을 사용하여 어떤 종류의 데이터도 저장하고 검색할 수 있게 합니다. 
    이 훅은 초기값을 선택적으로 받아들이며, 값이 없는 경우 해당 초기값을 사용합니다. 
    또한, useEffect를 사용하여 컴포넌트가 마운트되거나 키가 변경될 때마다 자동으로 localStorage에서 값을 업데이트합니다.

    이 커스텀 훅은 서버사이드 렌더링에서도 문제 없이 작동하도록 설계되었습니다. 
    typeof window === 'undefined'를 사용하여 window 객체의 존재 여부를 체크함으로써, 서버에서 실행될 때 오류를 방지합니다.
 */

/**
 * useLocalStorage 훅은 지정된 키에 대해 localStorage에 데이터를 저장하고 검색합니다.
 * @param key - localStorage에서 사용할 키 이름
 * @param initialValue - 키에 데이터가 없을 때 사용할 초기값
 * @returns [storedValue, setValue] - 저장된 값과 값을 설정하는 함수
 */
function useLocalStorage<T>(
  key: string,
  initialValue: T | null = null,
): [T | null, (value: T | null) => void] {
  // localStorage에서 값을 읽어와 반환하는 함수
  // localStorage에서 지정된 키로 저장된 값을 읽습니다. 만약 해당 키에 데이터가 없거나 오류가 발생한 경우, 사용자가 지정한 초기값을 반환합니다.
  const readValue = (): T | null => {
    // 서버사이드 렌더링 환경에서는 window 객체가 존재하지 않으므로, 초기값을 반환
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      // 항목이 존재하면 JSON으로 파싱하여 반환, 없으면 초기값 반환
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // localStorage 읽기 시 오류 발생시 경고 로그 출력 후 초기값 반환
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  };

  // 컴포넌트의 상태로 localStorage의 값을 저장
  const [storedValue, setStoredValue] = useState<T | null>(readValue);

  // 주어진 값을 localStorage에 저장하는 함수
  // 새로운 값을 받아 localStorage에 저장하고, 컴포넌트의 상태를 업데이트합니다. 이 함수는 또한 값이 null로 설정되면 해당 키를 localStorage에서 삭제합니다.
  const setValue = (value: T | null): void => {
    if (typeof window == "undefined") {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment does not support localStorage.`,
      );
      return;
    }

    try {
      if (value === null) {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
      // 상태 업데이트
      setStoredValue(value);
    } catch (error) {
      // localStorage 쓰기 시 오류 발생시 경고 로그 출력
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  // 훅이 사용되는 컴포넌트가 마운트되거나 키가 변경될 때마다
  // localStorage에서 해당 키의 값을 다시 읽어옴
  useEffect(() => {
    setStoredValue(readValue());
  }, [key]);

  return [storedValue, setValue];
}

export default useLocalStorage;
