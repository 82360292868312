import styled from "@emotion/styled";

export type WorkActionBarProps = {
  children: React.ReactNode;
};

export const WorkActionBar = ({ children }: WorkActionBarProps) => {
  return <WorkActionBarContainer>{children}</WorkActionBarContainer>;
};

const WorkActionBarContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`;
