import { useStandardLayout } from "@/features/standardLayout/Context";
import { AxiosResponse } from "axios";
import DataGrid, { Column } from "react-data-grid";

// rows 내 각 항목의 타입에 맞는 컬럼 정의
interface StandardInfoBestPracticeTableProps<TRow> {
  columns: readonly Column<TRow>[];
}

// TResponse는 rows 속성을 포함해야 함을 명시
export const StandardInfoBestPracticeTable = <TResponse extends { rows?: TRow[] }, TRow>({
  columns,
}: StandardInfoBestPracticeTableProps<TRow>) => {
  // provider에서 data를 받아와서 바인딩
  const { data, selectedRows, setSelectedRows } = useStandardLayout<AxiosResponse<TResponse>>();
  const response = data?.data || { rows: [] as TRow[] }; // rows가 기본적으로 빈 배열을 가지도록 설정
  const rows = response.rows ?? []; // rows가 undefined일 경우 빈 배열을 사용

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      rowHeight={40}
      rowKeyGetter={(row) => (row as any).id} // row의 id를 키로 사용
      selectedRows={selectedRows}
      onSelectedRowsChange={(e) => {
        setSelectedRows(e);
      }}
    />
  );
};
