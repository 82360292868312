import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Flex } from "@mantine/core";

export const flexGrowable = css`
  flex: 1 1 auto;
`;

export const flexStable = css`
  flex: 0 0 auto;
`;

export const StandardLayoutTableWrapper = styled(Flex)`
  & *[role="grid"] {
    width: 100%;
    height: 100%;
  }
  min-height: 80px;
`;
