import { mutateItemCategories } from "@/api/itemCategories/useItemCategoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { handleKeyDown } from "@/utils/event";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiItemCategoriesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

interface ItemCategoryCreateFormProps {
  name?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export const ItemCategoryCreateForm = (params: ItemCategoryCreateFormProps) => {
  const { name, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: createMutate } = useMutation(
    (params: MasterApiItemCategoriesPostRequest) =>
      mutateItemCategories
        .create(params)
        .mutationFn(params as MasterApiItemCategoriesPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["itemCategories"]);
        closeModal(form.values);
        customAlert(
          `${t("message.createSuccess", { item: `${t("category.category")}` })}`,
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("category.name") }),
          `${t("common.createFail", { item: `${t("category.category")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      name: name ?? undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        itemCategoriesGetRequest: {
          name: name ?? form.values.name!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("category.name") }),
        `${t("common.createFail", { item: `${t("category.category")}` })}`,
        "red",
      );
    }
  };

  return (
    <Flex w="100%" direction="column" gap="md">
      <Create.Input
        w="20rem"
        label={t("category.category")}
        placeholder={t("placeholder.input", { item: `${t("category.name")}` })}
        withAsterisk
        {...form.getInputProps("name")}
        onKeyDown={(e) =>
          handleKeyDown(e, () =>
            autoCompleteOnSubmit ? autoCompleteOnSubmit(form.values) : onSubmit(),
          )
        }
      />
      <Create.ButtonBox>
        <Create.Button color="gray" onClick={closeModal}>
          {t("common.cancel")}
        </Create.Button>
        <Create.Button
          onClick={() => (autoCompleteOnSubmit ? autoCompleteOnSubmit(form.values) : onSubmit)}
          disabled={validateFormValues({
            pageType: "itemCategory",
            values: form.values,
          })}
        >
          {t("common.create", { item: `${t("category.category")}` })}
        </Create.Button>
      </Create.ButtonBox>
    </Flex>
  );
};
