import { customFunctions } from "@/config/customFunction";
import { consumeTypeColor, routingTypeColor } from "@/constants/routings";
import { MaterialAutoComplete } from "@/features/ui/autoComplete/material/Material-autoComplete";
import styled from "@emotion/styled";
import { Badge, Button, Flex, Input, NumberInput, Radio, Table, Text, Title } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import {
  AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner,
  ItemsOneStepCreatePostRequestRoutingsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlus, IconTrashX } from "@tabler/icons-react";
import { ChangeEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { RoutingFormProps } from "../ItemsCreateForm";

interface RoutingsListProps {
  formData: UseFormReturnType<Partial<RoutingFormProps>>;
}

export const RoutingsList = ({ formData }: RoutingsListProps) => {
  const { t } = useTranslation();
  const [routingIndex, setRoutingIndex] = useState<number>(0);

  const handleCheckRadio: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRoutingIndex(Number(e.target.value));
  };

  const handleClickBomAdd = (routingIndex: number) => {
    formData.insertListItem(`routingRows.${routingIndex}.routingBoms`, {
      itemCode: "",
      quantityNumerator: 0,
      description: "",
    });
  };

  const handleClickBomDelete = (bomIndex: number) => {
    formData.removeListItem(`routingRows.${routingIndex}.routingBoms`, bomIndex);
  };

  return (
    <Flex w="100%" h="100%" gap="md">
      <Flex w="50%">
        <Table withBorder withColumnBorders>
          <thead>
            <tr>
              <Th width={6}>{t("routing.select")}</Th>
              <Th width={12}>{t("routing.sequence")}</Th>
              <Th width={12}>{t("operation.operation")}</Th>
              <Th width={18}>{t("routing.code")}</Th>
              <Th width={12}>{t("routing.type")}</Th>
              <Th width={12}>{t("routing.consumeType")}</Th>
            </tr>
          </thead>
          <tbody>
            {formData.values.routingRows &&
              formData.values.routingRows.map(
                (data: ItemsOneStepCreatePostRequestRoutingsInner, i: number) => {
                  return (
                    <tr key={data.code ?? "" + i}>
                      <td>
                        <Radio
                          name="routing"
                          value={i}
                          checked={routingIndex === i}
                          onChange={(e) => handleCheckRadio(e)}
                        />
                      </td>
                      <td>{data.seq}</td>
                      <td>{data.operationCode}</td>
                      <td>{data.code}</td>
                      <td>
                        <Badge color={routingTypeColor[data.routingType!]}>
                          {t(data.routingType ?? "")}
                        </Badge>
                      </td>
                      <td>
                        <Badge color={consumeTypeColor[data.consumeType!]}>
                          {t(data.consumeType ?? "")}
                        </Badge>
                      </td>
                    </tr>
                  );
                },
              )}
          </tbody>
        </Table>
      </Flex>
      <Flex direction="column" gap="xs" w="50%">
        <Flex direction="column" gap="xs">
          <Title order={2}>{t("routingBoms.routingBoms")}</Title>
          <Flex direction="column">
            <Text fz="xs">{t("routingBoms.description")}</Text>
            <Text fz="xs">{t("routingBoms.description2")}</Text>
          </Flex>
        </Flex>
        <Table withBorder withColumnBorders>
          <thead>
            <tr>
              <Th width={45}>{t("inputLot.material")}</Th>
              <Th width={30}>{t("inventory.quantity")}</Th>
              {customFunctions.ADD_ROUTING_BOM_DESCRIPTION && (
                <Th width={25}>{t("routing.descript")}</Th>
              )}
              <Th width={10}>{t("common.action")}</Th>
            </tr>
          </thead>
          <tbody>
            {formData.values.routingRows &&
              formData.values.routingRows[routingIndex].routingBoms &&
              formData.values.routingRows[routingIndex].routingBoms?.map(
                (
                  data: AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner,
                  bomIndex: number,
                ) => {
                  return (
                    <tr key={routingIndex + bomIndex}>
                      <td>
                        <MaterialAutoComplete
                          {...formData.getInputProps(
                            `routingRows.${routingIndex}.routingBoms.${bomIndex}.itemCode`,
                          )}
                        />
                      </td>
                      <td>
                        <NumberInput
                          {...formData.getInputProps(
                            `routingRows.${routingIndex}.routingBoms.${bomIndex}.quantityNumerator`,
                          )}
                        />
                      </td>
                      {customFunctions.ADD_ROUTING_BOM_DESCRIPTION && (
                        <td>
                          <Input
                            {...formData.getInputProps(
                              `routingRows.${routingIndex}.routingBoms.${bomIndex}.description`,
                            )}
                          />
                        </td>
                      )}
                      <td>
                        <Button
                          leftIcon={<IconTrashX />}
                          disabled={
                            !(
                              formData.values.routingRows &&
                              formData.values.routingRows.length &&
                              formData.values.routingRows[routingIndex] &&
                              formData.values.routingRows[routingIndex].routingBoms &&
                              formData.values.routingRows[routingIndex].routingBoms?.length &&
                              (formData.values.routingRows[routingIndex].routingBoms?.length ?? 0) >
                                1
                            )
                          }
                          onClick={() => handleClickBomDelete(bomIndex)}
                          color="red"
                          w="100%"
                        >
                          {t("workButton.delete")}
                        </Button>
                      </td>
                    </tr>
                  );
                },
              )}
          </tbody>
        </Table>
        <Flex w="100%" justify="center">
          <Button
            variant="outline"
            color="dark"
            leftIcon={<IconPlus />}
            onClick={() => handleClickBomAdd(routingIndex)}
          >
            {t("common.newAdditional", { item: "BOM" })}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
`;
