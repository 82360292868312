import DefaultInstance from "@/instance/axios";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export const GET_WORKS_QUERY_KEY = "get-works";

const getWorksFetch = (params?: any) => {
  if (params) {
    const query: any = {};
    if (params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return DefaultInstance.worksGet({
      ...query,
      page: params.activePage ?? 1,
      pageSize: 10,
      populate: ["productionPlans"],
    });
  } else return DefaultInstance.worksGet();
};

const useWorksGetQuery = (
  params?: any,
  options?: {
    onSuccess: (data: any) => void;
  },
) => {
  return useQuery<AxiosResponse<any, any[]>>({
    queryKey: params
      ? [
          GET_WORKS_QUERY_KEY,
          params.activePage,
          JSON.stringify(params.query),
          params.searchFields,
          params.search,
        ]
      : [GET_WORKS_QUERY_KEY],
    queryFn: () => (params ? getWorksFetch(params) : getWorksFetch()),
  });
};

export default useWorksGetQuery;
