import { productionPlans } from "@/api/productionPlan/useProductionPlanQuery";
import { LotTrackingLedgerTrackingContent } from "@/features/lotTracking/components/ledgerTracking";
import { LotTrackingForwardItemContent } from "@/features/lotTracking/forward/components/forwardItem";
import { LotTrackingForwardLedgerTracking } from "@/features/lotTracking/forward/components/forwardLedgerTacking";
import { LotTrackingForwardSummaryContent } from "@/features/lotTracking/forward/components/forwardSummary";
import { LotTrackingForwardWorkHistoryContent } from "@/features/lotTracking/forward/components/forwardWorkHistory";
import { LotTrackingForwardActionHeader } from "@/features/lotTracking/forward/header/lotTrackingActionHeader";
import { useLotTrackingActions } from "@/features/lotTracking/hook/useLotTrackingActions";
import { useLotTrackingState } from "@/features/lotTracking/hook/useLotTrackingState";
import { Flex, Text, Title } from "@mantine/core";
import { ProductionPlansConsumedLotIdLotIdGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const LotTrackingForwardPage = () => {
  const state = useLotTrackingState();
  const actions = useLotTrackingActions();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    // 필요한 경우 조건 추가
    if (state.itemCodeValue && state.lotId && location.state?.value === "click") {
      actions.setProductionPlan(undefined);
    } else if (state.itemCodeValue || state.lotId || state.productionPlan) {
      actions.setProductionPlan(undefined);
      actions.setLotId(null);
      actions.setItemCodeValue(null);
    }
  }, []);

  const { data: consumedLot } = useQuery({
    ...productionPlans.consumedLot({
      lotId: Number(state.lotId),
    }),
    enabled: !!state.lotId,
  });

  return (
    <Flex w="100%" h="100%" direction="column">
      <Flex px="sm">
        <LotTrackingForwardActionHeader />
      </Flex>
      <Flex direction="column">
        <Title p={"sm"} mb={8} order={1}>
          {t("lotTracking.forwardLotTracking")}
        </Title>
        <Text fz={12} p={"sm"}>
          {t("lotTracking.forwardDescription")}
        </Text>
        <LotTrackingForwardItemContent />
        <LotTrackingForwardSummaryContent
          consumedLotData={consumedLot?.data as ProductionPlansConsumedLotIdLotIdGet200Response[]}
        />
        <LotTrackingForwardWorkHistoryContent />
        <LotTrackingLedgerTrackingContent
          consumedLotData={consumedLot?.data as ProductionPlansConsumedLotIdLotIdGet200Response[]}
        />
        <LotTrackingForwardLedgerTracking />
        {/* <LotTrackingQualityHistoryContent /> */}
      </Flex>
    </Flex>
  );
};
