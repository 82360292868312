import { MasterInstance } from "@/instance/axios";
import { AuthSignupPost201Response } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export type UserOption = {
  value: string;
  label: string;
  group: string;
};

const getAutoCompleteKeyword = () =>
  MasterInstance.usersFindPost().then(
    (res: AxiosResponse<AuthSignupPost201Response[]>) => res.data,
  );

export const useUsersAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: UserOption[]) => void;
  },
) => {
  return useQuery(["users-get-autocomplete"], () => getAutoCompleteKeyword(), {
    enabled: focus,
    select: (data: AuthSignupPost201Response[]) =>
      data?.map((user) => {
        return {
          value: String(user.code),
          label: String(user.name),
          group: String(user.equipmentCode),
        };
      }) ?? [],
    onSuccess: options?.onSuccess,
  });
};

const getUsersByCode = (code: string | null) =>
  MasterInstance.partnersFindPost({
    ...(code && { search: code }),
    searchFields: ["code"],
  }).then((res: AxiosResponse<AuthSignupPost201Response[]>) => res.data);

export const useUsersCode = (
  enabled: boolean,
  code: string | null,
  options?: {
    onSuccess: (data?: UserOption[]) => void;
  },
) => {
  return useQuery(["users-get-code", code], () => getUsersByCode(code), {
    enabled: enabled ?? false,
    select: (data: AuthSignupPost201Response[]) =>
      data?.map((user) => {
        return {
          value: String(user.code),
          label: String(user.name),
          group: String(user.equipmentCode),
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
