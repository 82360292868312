import { useModal } from "@/features/modal/ModalStackManager";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { mutateRoutingOutsources } from "@/api/routingOutsource/useRoutingOutsourcesQuery";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { RoutingAutoComplete } from "@/features/ui/autoComplete/routing/routing-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { DefaultApiRoutingOutsourcesRoutingOutsourceIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const RoutingOutsourceUpdateForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: updateMutate } = useMutation(
    (params: DefaultApiRoutingOutsourcesRoutingOutsourceIdPutRequest) =>
      mutateRoutingOutsources
        .update(params)
        .mutationFn(params as DefaultApiRoutingOutsourcesRoutingOutsourceIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["routingOutsources"]);
        closeModal(formatterProps);
        customAlert(
          `${t("message.editSuccess", { item: `${t("outsource.outsource")}` })}`,
          `${t("common.editSuccess")}`,
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("outsource.outsource") }),
          `${t("common.editFail", { item: `${t("outsource.outsource")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      name: formatterProps.name,
      routingCode: formatterProps.routingCode,
      fromLocationCode: formatterProps.fromLocationCode,
      toLocationCode: formatterProps.toLocationCode,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate({
        routingOutsourceId: formatterProps?.id,
        routingOutsourcesGetRequest: {
          name: form.values.name!,
          routingCode: form.values.routingCode!, // sample data -> "opop", "op001"
          fromLocationCode: form.values.fromLocationCode!,
          toLocationCode: form.values.toLocationCode!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("outsource.outsource") }),
        `${t("common.editFail", { item: `${t("outsource.outsource")}` })}`,
        "red",
      );
    }
  };

  return (
    <Box w={420} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            label={t("outsource.name")}
            withAsterisk
            placeholder={t("placeholder.input", { item: `${t("outsource.name")}` })}
            defaultValue={formatterProps?.name}
            {...form.getInputProps("name")}
            error={form.isDirty("name") ? t("common.toSave") : ""}
            styles={{
              input: {
                color: form.isDirty("name") ? "red !important" : "",
                borderColor: form.isDirty("name") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <RoutingAutoComplete
            label={t("routing.code")}
            withAsterisk
            placeholder={t("placeholder.select", { item: `${t("routing.code")}` })}
            width={"100%"}
            defaultValue={formatterProps?.routingCode}
            {...form.getInputProps("routingCode")}
            error={form.isDirty("routingCode") ? t("common.toSave") : ""}
          />
          <LocationAutoComplete
            label={t("outsource.fromLocationCode")}
            withAsterisk
            placeholder={t("placeholder.select", { item: `${t("outsource.fromLocationCode")}` })}
            width={"100%"}
            defaultValue={formatterProps?.fromLocationCode}
            {...form.getInputProps("fromLocationCode")}
            error={form.isDirty("fromLocationCode") ? t("common.toSave") : ""}
          />
          <LocationAutoComplete
            label={t("outsource.toLocationCode")}
            withAsterisk
            placeholder={t("placeholder.select", { item: `${t("outsource.toLocationCode")}` })}
            width={"100%"}
            defaultValue={formatterProps?.toLocationCode}
            {...form.getInputProps("toLocationCode")}
            error={form.isDirty("toLocationCode") ? t("common.toSave") : ""}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              {t("common.cancel")}
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "routingOutsource",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              {t("common.edit", { item: `${t("outsource.outsource")}` })}
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
