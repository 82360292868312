import DefaultInstance from "@/instance/axios";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";

export const GET_LEDGERS_QUERY_KEY = "/ledgers";

const getLedgersAllFetch = (params?: any) => {
  if (params) {
    const query: any = {};

    if (params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    } else if (params.query.createdAt.$between[0] !== null) {
      const [beforeDate, afterDate] = params.query.createdAt.$between;

      const before = dayjs(dayjs(new Date(beforeDate)).format("YYYY-MM-DD")).toISOString();

      const after = afterDate
        ? dayjs(afterDate).add(1, "day").subtract(1, "seconds").toISOString()
        : dayjs(before).add(1, "day").subtract(1, "seconds").toISOString();

      const date = after ? [before, after] : [before, before];

      query["query"] = JSON.stringify({
        $and: [],
        createdAt: {
          $between: date,
        },
      });
      // query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }

    return DefaultInstance.ledgersGet({
      ...query,
      page: params.activePage ?? 1,
      pageSize: params.pageSize ?? 10,
      sort: params?.sort,
      populate: ["item", "location", "user"],
    });
  } else return DefaultInstance.ledgersGet();
};

const useLedgersAllQuery = (
  params?: any,
  options?: {
    onSuccess: (data: any) => void;
  },
) => {
  return useQuery<AxiosResponse<any, any[]>>({
    queryKey: params
      ? [
          GET_LEDGERS_QUERY_KEY,
          params.activePage,
          JSON.stringify(params.query),
          params.searchFields,
          params.search,
        ]
      : [GET_LEDGERS_QUERY_KEY],
    queryFn: () => (params ? getLedgersAllFetch(params) : getLedgersAllFetch()),
  });
};

export default useLedgersAllQuery;
