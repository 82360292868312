import { Flex, MultiSelect, MultiSelectProps, Text } from "@mantine/core";
import { forwardRef, useState } from "react";

import { routingType } from "@/constants/routings";
import { useTranslation } from "react-i18next";

interface RoutingTypeMultiProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export interface RoutingTypesMultiAutoCompleteProps
  extends Partial<MultiSelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value: string[];
  onChange?: (e: string[]) => void; // (value: string[]) => React.Dispatch<React.SetStateAction<string>>;
  maxDropdownHeight?: number;
  width?: string;
}

export const RoutingTypesMultiAutoComplete = (params: RoutingTypesMultiAutoCompleteProps) => {
  const { value, onChange, maxDropdownHeight, width, ...etcParams } = params;

  const { t } = useTranslation();
  const translatedRoutings = routingType.map((item) => ({ ...item, label: t(item.label) }));

  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const onChangeHandler = (e: string[]) => {
    onChange && onChange(e);
  };

  const SelectRoutingType = forwardRef<HTMLDivElement, RoutingTypeMultiProps>(
    ({ label: name, value: code, ...others }, ref) => {
      const { t } = useTranslation();
      return (
        <div ref={ref} {...others}>
          <Flex direction="row" justify="space-between" align="center">
            <Flex align={"center"}>
              <Text>{t(name)}</Text>
              <Text fz="xs">(품목유형: {code})</Text>
            </Flex>
          </Flex>
        </div>
      );
    },
  );

  return (
    <MultiSelect
      clearable
      data={translatedRoutings}
      onChange={onChangeHandler}
      searchable
      itemComponent={SelectRoutingType}
      searchValue={searchKeyword}
      withinPortal
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onSearchChange={setSearchKeyword}
      nothingFound="No Options"
      {...etcParams}
    />
  );
};
