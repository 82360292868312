import { DefectTypeItem } from "@/features/ui/DefectTypeSet";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { ActionIcon, Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { memo, useCallback, useEffect, useState } from "react";

interface WorkNumberInputCellProps {
  data: {
    value: string;
    key: string;
    onChange: ({
      key,
      value,
      label,
      operationCode,
    }: {
      key: string;
      value: string;
      label?: string;
      operationCode?: string;
    }) => void;
    defectType?: DefectTypeItem;
    defaultValue: string;
    formReset: boolean;
    operationCode?: string;
  };
}

export const WorkNumberInputCell = memo(({ data }: WorkNumberInputCellProps) => {
  const { value, key, onChange, defectType, formReset, defaultValue, operationCode } = data;
  const form = useForm({ initialValues: { [key]: value } });

  // 외부에서 전달된 value를 관찰하고 form 필드 값을 업데이트
  useEffect(() => {
    form.setFieldValue(key, value);
  }, [value]);

  useEffect(() => {
    if (!formReset) {
      form.setInitialValues({ [key]: value });
    } else {
      form.resetDirty();
      if (form.values[key] !== value) {
        form.setFieldValue(key, value);
      }
    }
  }, [value, key, formReset, form]);

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(BigInt(value) <= BigInt(defaultValue));
  }, [value, defaultValue]);

  const modifyValue = useCallback(
    (delta: number) => {
      const newValue = String(BigInt(form.values[key]) + BigInt(delta));
      form.setFieldValue(key, newValue);
      setDisabled(BigInt(newValue) <= BigInt(defaultValue));
      onChange({
        key,
        value: newValue,
        ...(defectType?.label ? { label: defectType?.label } : {}),
        operationCode: defectType?.operationCode,
      });
    },
    [form, key, onChange, defaultValue, defectType],
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value.replace(/[^0-9.]/g, "");
      form.setFieldValue(key, newValue);
      setDisabled(BigInt(newValue) <= BigInt(defaultValue));
      onChange({
        key,
        value: newValue,
        ...(defectType?.label ? { label: defectType?.label } : {}),
        operationCode: defectType?.operationCode,
      });
    },
    [form, key, onChange, defaultValue, defectType],
  );

  const handleBlur = useCallback(() => {
    if (BigInt(defaultValue) > BigInt(form.values[key])) {
      form.setFieldValue(key, defaultValue);
      onChange({
        key,
        value: defaultValue,
        ...(defectType?.label ? { label: defectType?.label } : {}),
        operationCode: defectType?.operationCode,
      });
    }
  }, [form, key, onChange, defaultValue, defectType]);

  return (
    <WorkNumberInputCellWrapper isDirty={form.isDirty(key) || defaultValue !== value}>
      <TextInput
        // operationCode가 undefined일 경우 disabled가 false임
        // operationCode가 undefined가 아닐 경우, defectType?.operationCode와 같은지 비교한다. 비교해서 같은 경우 disabled가 true가 되고, 다른 경우 false가 된다.
        disabled={!!defectType?.operationCode ? defectType?.operationCode !== operationCode : false}
        type="number"
        {...form.getInputProps(key)}
        onChange={handleInputChange}
        onBlur={handleBlur}
        rightSection={
          <Flex direction="column">
            <ActionIcon variant="default" size="xs" radius="xs" onClick={() => modifyValue(1)}>
              <IconChevronUp stroke={1.5} />
            </ActionIcon>
            <ActionIcon
              variant="default"
              size="xs"
              radius="xs"
              onClick={() => modifyValue(-1)}
              disabled={disabled}
              style={{ border: disabled ? "0.0625rem solid #ced4da" : undefined }}
            >
              <IconChevronDown stroke={1.5} />
            </ActionIcon>
          </Flex>
        }
        rightSectionProps={{ style: { width: "auto" } }}
      />
      <SaveMessageWrapper isDirty={form.isDirty(key) || defaultValue !== value}>
        <Text color={theme.colors?.red?.[6]}>
          {form.isDirty(key) || defaultValue !== value ? "저장 필요" : ""}
        </Text>
      </SaveMessageWrapper>
    </WorkNumberInputCellWrapper>
  );
});

const WorkNumberInputCellWrapper = styled.div<{ isDirty: boolean }>`
  box-sizing: border-box;
  flex-shrink: 0;
  position: relative;
  ${({ isDirty }) =>
    isDirty
      ? `.mantine-Input-input {
                    border-style: solid;
                    border-color: ${theme.colors?.grape?.[6]};
                    border-width: 1px;
                }
                .mantine-Input-rightSection > div > button {
                    border-color: ${theme.colors?.grape?.[6]};
                }`
      : ""};
`;

// 메시지를 담을 div에 대한 스타일 추가
const SaveMessageWrapper = styled.div<{ isDirty: boolean }>`
  position: absolute;
  top: 100%; // 입력란의 전체 높이만큼 아래에 위치
  left: 0;
  visibility: hidden; // 기본적으로 숨겨둠
  ${({ isDirty }) =>
    isDirty
      ? `
    visibility: visible; // 'isDirty' 상태일 때만 보임
    // 여기에 필요한 스타일 추가
  `
      : ""}
`;
