import { CavityForm } from "@/features/mold/cavity/form/cavityForm";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Button, Flex, NumberInput, Text, TextInput } from "@mantine/core";

import { mutateMoldStocks } from "@/api/moldStock/useMoldStockQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import {
  MasterApiMoldStocksMoldStockIdPutRequest,
  MoldStocksGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  NumberInput: NumberInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  CavityForm: CavityForm,
});

interface MoldStockUpdateFormProps {
  formatterProps: MoldStocksGet200ResponseRowsInner;
}

export const MoldStockUpdateForm = ({ formatterProps }: MoldStockUpdateFormProps) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: createMutate } = useMutation(
    (params: MasterApiMoldStocksMoldStockIdPutRequest) =>
      mutateMoldStocks
        .update(params)
        .mutationFn(params as MasterApiMoldStocksMoldStockIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["moldStock"]);
        closeModal(form.values);
        customAlert(
          `${t("message.editSuccess", { item: `${t("moldStock.moldStock")}` })}`,
          `${t("common.editSuccess")}`,
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("moldStock.code") }),
          `${t("common.editFail", { item: `${t("moldStock.moldStock")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      moldCode: formatterProps.moldCode,
      limitCounter: formatterProps.limitCounter ?? 0,
      inspectCounter: formatterProps.inspectCounter ?? 0,
      currentCounter: formatterProps.currentCounter ?? 0,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        moldStockId: formatterProps.id ?? 0,
        moldStocksGetRequest: {
          code: form.values.code!,
          moldCode: form.values.moldCode!,
          limitCounter: form.values.limitCounter!,
          inspectCounter: form.values.inspectCounter!,
          currentCounter: form.values.currentCounter!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("moldStock.code") }),
        `${t("common.editFail", { item: `${t("moldStock.moldStock")}` })}`,
        "red",
      );
    }
  };

  return (
    <Update.Wrapper>
      <Update.Input
        label={t("moldStock.code")}
        withAsterisk
        {...form.getInputProps("code")}
        defaultValue={formatterProps.code}
        error={form.isDirty("code") ? t("common.toSave") : ""}
        styles={{
          input: {
            color: form.isDirty("code") ? "red !important" : "",
            borderColor: form.isDirty("code") ? "red !important" : "",
          },
          error: { fontSize: 11, color: "red" },
        }}
      />
      <MoldAutoComplete
        label={t("mold.code")}
        withAsterisk
        {...form.getInputProps("moldCode")}
        defaultValue={formatterProps.moldCode}
        error={form.isDirty("moldCode") ? t("common.toSave") : ""}
      />
      <Update.NumberInput
        label={t("moldStock.limitCounter")}
        {...form.getInputProps("limitCounter")}
        defaultValue={formatterProps.limitCounter}
        error={form.isDirty("limitCounter") ? t("common.toSave") : ""}
        styles={{
          input: {
            color: form.isDirty("limitCounter") ? "red !important" : "",
            borderColor: form.isDirty("limitCounter") ? "red !important" : "",
          },
          error: { fontSize: 11, color: "red" },
        }}
      />
      <Update.NumberInput
        label={t("moldStock.inspectCounter")}
        {...form.getInputProps("inspectCounter")}
        defaultValue={formatterProps.inspectCounter}
        error={form.isDirty("inspectCounter") ? t("common.toSave") : ""}
        styles={{
          input: {
            color: form.isDirty("inspectCounter") ? "red !important" : "",
            borderColor: form.isDirty("inspectCounter") ? "red !important" : "",
          },
          error: { fontSize: 11, color: "red" },
        }}
      />
      <Update.NumberInput
        label={t("moldStock.currentCounter")}
        {...form.getInputProps("currentCounter")}
        defaultValue={formatterProps.currentCounter}
        error={form.isDirty("currentCounter") ? t("common.toSave") : ""}
        styles={{
          input: {
            color: form.isDirty("currentCounter") ? "red !important" : "",
            borderColor: form.isDirty("currentCounter") ? "red !important" : "",
          },
          error: { fontSize: 11, color: "red" },
        }}
      />
      <Update.ButtonBox>
        <Update.Button color="gray" onClick={closeModal}>
          {t("common.cancel")}
        </Update.Button>
        <Update.Button
          disabled={validateFormValues({
            pageType: "moldStock",
            values: form.values,
          })}
          onClick={onSubmit}
        >
          {t("common.edit", { item: `${t("moldStock.moldStock")}` })}
        </Update.Button>
      </Update.ButtonBox>
    </Update.Wrapper>
  );
};
