import { WorkLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { createContext, useMemo, useState } from "react";

export interface LotTrackingContextProps {
  productionPlan: WorkLogsGet200ResponseRowsInner | undefined;
  itemCodeValue: string | null;
  lotId: string | null;
}

// 초기 상태에 대한 타입 안정성 강화
const initialLotTrackingState: LotTrackingContextProps = {
  productionPlan: undefined,
  itemCodeValue: null,
  lotId: null,
};

export const LotTrackingStateContext =
  createContext<LotTrackingContextProps>(initialLotTrackingState);

export const LotTrackingActionsContext = createContext<{
  setProductionPlan: (productionPlan: WorkLogsGet200ResponseRowsInner | undefined) => void;
  setItemCodeValue: (itemCodeValue: string | null) => void;
  setLotId: (lotId: string | null) => void;
}>({
  setProductionPlan: () => {},
  setItemCodeValue: () => {},
  setLotId: () => {},
});

export const LotTrackingProvider = ({ children }: { children: React.ReactNode }) => {
  const [lotTrackingState, setLotTrackingState] =
    useState<LotTrackingContextProps>(initialLotTrackingState);

  const actions = useMemo(
    () => ({
      setProductionPlan: (productionPlan: WorkLogsGet200ResponseRowsInner | undefined) => {
        setLotTrackingState((prevState) => ({
          ...prevState,
          productionPlan: productionPlan,
        }));
      },
      setItemCodeValue: (itemCodeValue: string | null) => {
        setLotTrackingState((prevState) => ({
          ...prevState,
          itemCodeValue: itemCodeValue,
        }));
      },
      setLotId: (lotId: string | null) => {
        setLotTrackingState((prevState) => ({
          ...prevState,
          lotId: lotId,
        }));
      },
    }),
    [lotTrackingState],
  );

  return (
    <LotTrackingActionsContext.Provider value={actions}>
      <LotTrackingStateContext.Provider value={lotTrackingState}>
        {children}
      </LotTrackingStateContext.Provider>
    </LotTrackingActionsContext.Provider>
  );
};
