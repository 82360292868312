const timeUtil = (utcTimeString: string) => {
  const curr = new Date(utcTimeString);
  // 2. UTC 시간 계산
  const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;

  // 3. UTC to KST (UTC + 9시간)
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000; //한국 시간(KST)은 UTC시간보다 9시간 더 빠르므로 9시간을 밀리초 단위로 변환.

  const kr_curr = new Date(utc + KR_TIME_DIFF);

  const timeUtil = `${kr_curr.getFullYear()}-${String(kr_curr.getMonth() + 1).padStart(
    2,
    "0",
  )}-${String(kr_curr.getDate()).padStart(2, "0")}`;

  return timeUtil;
};

export default timeUtil;
