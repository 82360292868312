import { mutatePurchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { PurchaseOrderAutoComplete } from "@/features/ui/autoComplete/purchaseOrder/purchaseOrders-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
  ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

interface OrderUpdateFormProps {
  orderId?: number;
  formatterProps: AuthSignupPost201ResponseEquipmentPurchaseOrderItem;
}

export const OrderItemsUpdateForm = (props: OrderUpdateFormProps) => {
  const { orderId, formatterProps } = props;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: createMutate } = useMutation(
    (params: ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest) =>
      mutatePurchaseOrderItems
        .update(params)
        .mutationFn(params as ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["purchaseOrderItems"]);
        closeModal(form.values);
        customAlert(
          t("message.editSuccess", { item: t("orderItems.orderItems") }),
          t("common.editSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("orderItems.orderItems") }),
          t("common.editFail", { item: t("orderItems.orderItems") }),
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      partnerCode: formatterProps.partnerCode,
      itemCode: formatterProps.itemCode,
      quantity: formatterProps.quantity,
      orderDate: formatterProps.orderDate ? new Date(formatterProps.orderDate) : undefined,
      deliveryDeadline: formatterProps.deliveryDeadline
        ? new Date(formatterProps.deliveryDeadline)
        : undefined,
      userCode: formatterProps.userCode,
      targetLocationCode: formatterProps.targetLocationCode,
    },
  });

  const onSubmit = () => {
    try {
      createMutate({
        purchaseOrderItemId: formatterProps.id ?? 0,
        purchaseOrderItemsGetRequest: {
          purchaseOrderId: formatterProps.purchaseOrderId,
          partnerCode: form.values.partnerCode!,
          direction: "OUTBOUND",
          itemCode: form.values.itemCode!,
          quantity: form.values.quantity!,
          orderDate: form.values.orderDate?.toDateString()!,
          deliveryDeadline: form.values.deliveryDeadline!?.toDateString(),
          userCode: form.values.userCode!,
          targetLocationCode: form.values.targetLocationCode!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("orderItems.orderItems") }),
        t("common.editFail", { item: t("orderItems.orderItems") }),
        "red",
      );
    }
  };

  return (
    <>
      <Create.Wrapper>
        <PurchaseOrderAutoComplete
          label={t("order.code")}
          placeholder={t("placeholder.select", { item: t("order.code") })}
          {...form.getInputProps("purchaseOrderId")}
          defaultValue={formatterProps.purchaseOrderCode}
          direction="OUTBOUND"
          disabled
        />
        <PartnerAutoComplete
          label={t("partner.partner")}
          placeholder={t("placeholder.select", { item: t("partner.partner") })}
          defaultValue={formatterProps.partnerCode}
          {...form.getInputProps("partnerCode")}
          withAsterisk
          disabled
        />
        <ItemAutoComplete
          label={t("item.code")}
          placeholder={t("placeholder.select", { item: t("item.code") })}
          {...form.getInputProps("itemCode")}
          defaultValue={formatterProps.itemCode}
          disabled
        />
        <Create.Input
          label={t("inventory.quantity")}
          placeholder={t("placeholder.input", { item: t("inventory.quantity") })}
          {...form.getInputProps("quantity")}
          defaultValue={formatterProps.quantity}
          disabled
        />
        <UserAutoComplete
          label={t("order.manager")}
          placeholder={t("placeholder.select", { item: t("order.manager") })}
          {...form.getInputProps("userCode")}
          defaultValue={formatterProps.userCode}
          error={form.isDirty("userCode") ? t("common.toSave") : ""}
        />
        <LocationAutoComplete
          label={t("inventory.toLocation")}
          placeholder={t("placeholder.select", { item: t("inventory.toLocation") })}
          {...form.getInputProps("targetLocationCode")}
          defaultValue={formatterProps.targetLocationCode}
          error={form.isDirty("targetLocationCode") ? t("common.toSave") : ""}
        />
        <Create.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={t("order.orderDate")}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("orderDate")}
          disabled
        />
        <Create.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={t("order.deliveryDate")}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("deliveryDeadline")}
          error={form.isDirty("deliveryDeadline") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("deliveryDeadline") ? "red !important" : "",
              borderColor: form.isDirty("deliveryDeadline") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "purchaseOrderItems",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            {t("common.edit", { item: t("orderItems.orderItems") })}
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
