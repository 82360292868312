// @fileoverview : 영업관리 > 수주항목
import { mutatePurchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { users } from "@/api/users/useUsersQuery";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { ProductionPlanCreateForm } from "@/features/productionPlan/form/CreateForm";
import { DeliveryForm } from "@/features/purchaseOrder/form/deliveryForm";
import { PurchaseOrderDetailForm } from "@/features/purchaseOrder/form/detailForm";
import { DeliveredForm } from "@/features/purchaseOrderItems/delivered/deliveredForm";
import { ArrivalDeliveryStockPlanForm } from "@/features/purchaseOrderItems/form/arrivalDeliveryStockPlanForm";
import { PurchaseOrderItemsCreateForm } from "@/features/purchaseOrderItems/form/createForm";
import { PurchaseOrderItemsUpdateForm } from "@/features/purchaseOrderItems/form/updateForm";
import { ProductionPlanForm } from "@/features/purchaseOrderItems/productionPlan/productionPlanForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { handleErrorResponse } from "@/utils/errorMessage";
import timeUtil from "@/utils/timeUtil";
import { ActionIcon, Avatar, Button, Flex, Text, Tooltip } from "@mantine/core";
import {
  AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
  PurchaseOrderItemsGet200Response,
  ScmApiPurchaseOrderItemsPurchaseOrderItemIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconPlus, IconRecycle, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const PurchaseOrderItemsPage = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery(users.who({}));
  const userCode = userData?.data.code;
  const orderTitle =
    userCode === "PB" ? t("orderItems.orderItems") : t("purchaseOrderItems.purchaseOrderItems"); // TODO: 다국어 처리

  const { openModal, closeModal } = useModal();
  const { query, setQuery, selectedRows, refetch, resetQueryStrings } = useStandardLayout();

  useEffect(() => {
    if (query.length === 0) {
      // query가 비어 있을 때만 실행
      setQuery([
        { direction: { $eq: "INBOUND" } },
        {
          $or: [
            { creatorUserCode: userCode },
            { creatorUserCode: "PB" },
            { partnerCode: userCode },
          ],
        },
      ]);
    }
  }, [query, userCode]); // 의존성 배열 유지

  // delete 및 선택 테스트 다 해봐야됨.
  const { mutate: deleteMutate } = useMutation(
    (params: ScmApiPurchaseOrderItemsPurchaseOrderItemIdDeleteRequest) =>
      mutatePurchaseOrderItems
        .delete(params)
        .mutationFn(params as ScmApiPurchaseOrderItemsPurchaseOrderItemIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          `${t("message.deleteSuccess", { item: `${orderTitle}` })}`,
          `${t("common.deleteSuccess")}`,
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: `${orderTitle}` }),
          `${t("common.deleteFail", { item: `${orderTitle}` })}`,
          "red",
        );
      },
    },
  );

  const columns: readonly Column<AuthSignupPost201ResponseEquipmentPurchaseOrderItem>[] = [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "partner.name",
      name: t("partner.name"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <PartnerDetailForm partnerCode={formatterProps?.row.partnerCode} />,
                null,
                t("common.detail", { item: t("partner.partner") }),
              )
            }
          >
            {formatterProps.row.partner?.name}
          </LinkAnchor>
        );
      },
    },
    {
      key: "purchaseOrderId",
      name: `${userCode}` === "PB" ? t("orderItems.id") : t("purchaseOrderItems.id"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Flex w="100%" h="100%" justify="flex-end">
            <LinkAnchor
              onClick={() =>
                openModal(
                  <PurchaseOrderDetailForm
                    purchaseOrderId={formatterProps?.row.purchaseOrderId ?? 0}
                  />,
                  null,
                  t("common.detail", { item: `${orderTitle}` }),
                )
              }
            >
              {formatterProps.row.purchaseOrderId}
            </LinkAnchor>
          </Flex>
        );
      },
    },
    {
      key: "itemName",
      name: t("item.name"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <ItemsDetailForm itemCode={formatterProps?.row?.itemCode} />,
                null,
                t("common.detail", { item: t("item.item") }),
              )
            }
          >
            {formatterProps.row.itemName}
          </LinkAnchor>
        );
      },
    },
    {
      key: "quantity",
      name: t("inventory.quantity"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <Flex justify="flex-end">{formatterProps.row.quantity}</Flex>;
      },
    },
    {
      key: "sumOfProductionPlanQuantity",
      name: t("purchaseOrderItems.sumOfProductionPlanQuantity"),
      sortable: true,
      resizable: true,

      formatter: (formatterProps) => {
        return (
          <Flex justify="flex-end">
            {formatterProps.row.sumOfProductionPlanQuantity === "0" ? (
              <div>{formatterProps.row.sumOfProductionPlanQuantity}</div>
            ) : (
              <LinkAnchor
                onClick={() =>
                  openModal(
                    <ProductionPlanForm purchaseOrderItemID={formatterProps?.row?.id} />,
                    null,
                    t("common.detail", { item: t("productionPlan.productionPlan") }),
                  )
                }
              >
                {formatterProps.row.sumOfProductionPlanQuantity}
              </LinkAnchor>
            )}
          </Flex>
        );
      },
    },
    {
      key: "sumOfStockPlanQuantity",
      name: t("orderItems.sumOfStockPlanQuantity"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <Flex justify="flex-end">{formatterProps.row.sumOfStockPlanQuantity}</Flex>;
      },
    },
    {
      key: "sumOfDeliveredQuantity",
      name: t("orderItems.sumOfDeliveredQuantity"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Flex h="100%" justify="flex-end" align="center">
            {formatterProps.row.sumOfDeliveredQuantity === "0" ? (
              <Text>{formatterProps.row.sumOfDeliveredQuantity}</Text>
            ) : (
              <LinkAnchor
                onClick={() =>
                  openModal(
                    <DeliveredForm purchaseOrderItemID={formatterProps?.row?.id} />,
                    null,
                    t("common.detail", { item: t("delivery.deliveryComplete") }),
                  )
                }
              >
                {formatterProps.row.sumOfDeliveredQuantity}
              </LinkAnchor>
            )}
          </Flex>
        );
      },
    },
    {
      key: "manager",
      name: t("order.manager"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <UsersDetailForm UserCode={formatterProps?.row?.userCode} />,
                null,
                t("common.detail", { item: t("user.user") }),
              )
            }
          >
            {formatterProps.row.manager}
          </LinkAnchor>
        );
      },
    },
    {
      key: "createdAt",
      name: t("order.createdAt"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Flex>
            {formatterProps.row.createdAt !== null
              ? DatetimeUtil(formatterProps.row.createdAt as string)
              : ""}
          </Flex>
        );
      },
    },
    {
      key: "deliveryDeadline",
      name: t("order.deliveryDate"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Flex>
            {formatterProps.row.deliveryDeadline !== null
              ? timeUtil(formatterProps.row.deliveryDeadline as string)
              : ""}
          </Flex>
        );
      },
    },
    {
      key: "options",
      name: t("common.action"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updatePurchaseOrderItemActionView = () => {
          openModal(
            <PurchaseOrderItemsUpdateForm formatterProps={formatterProps.row} />,
            null,
            t("common.edit", { item: `${orderTitle}` }),
          );
        };

        const deletePurchaseOrderItemAction = () => {
          openModal(
            <ConfirmForm
              message={t("message.rowDelete")}
              yesCallback={() => {
                deleteMutate({ purchaseOrderItemId: formatterProps?.row?.id as number });
              }}
              noCallback={() => {
                closeModal(null);
              }}
            />,
            null,
            t("common.delete", { item: `${orderTitle}` }),
          );
        };

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon variant="subtle" onClick={updatePurchaseOrderItemActionView} color="yellow">
              <IconEdit />
            </ActionIcon>
            <ActionIcon
              variant="subtle"
              onClick={() => deletePurchaseOrderItemAction()}
              color="red"
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: `${t("purchaseOrder.purchaseOrder")}` })}`,
        `${t("common.deleteFail", { item: `${t("purchaseOrder.purchaseOrder")}` })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ purchaseOrderItemId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      `${t("common.delete", { item: `${orderTitle}` })}`,
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <Flex gap="sm">
          <Button
            leftIcon={<IconPlus />}
            onClick={() =>
              openModal(
                <PurchaseOrderItemsCreateForm />,
                null,
                t("common.create", { item: `${orderTitle}` }),
              )
            }
          >
            {t("common.create", { item: `${orderTitle}` })}
          </Button>
          <Button
            leftIcon={<IconPlus />}
            color="orange"
            disabled={[...selectedRows].length !== 1}
            onClick={() =>
              openModal(
                <DeliveryForm
                  formatterProps={
                    JSON.parse(
                      [...selectedRows].toString(),
                    ) as AuthSignupPost201ResponseEquipmentPurchaseOrderItem
                  }
                />,
                null,
                t("delivery.action"),
              )
            }
          >
            {t("delivery.action")}
          </Button>
          <Button
            leftIcon={<IconPlus />}
            color="green"
            disabled={[...selectedRows].length !== 1}
            onClick={() =>
              openModal(
                <ArrivalDeliveryStockPlanForm
                  formatterProps={
                    JSON.parse(
                      [...selectedRows].toString(),
                    ) as AuthSignupPost201ResponseEquipmentPurchaseOrderItem
                  }
                  purchaseOrder={true}
                />,
                null,
                t("stockPlan.outgoing"),
                true,
              )
            }
          >
            {t("purchaseOrderItems.outgoing")}
          </Button>
          <Button
            leftIcon={<IconPlus />}
            color="orange"
            disabled={[...selectedRows].length !== 1}
            onClick={() =>
              openModal(
                <ProductionPlanCreateForm
                  formatterProps={
                    JSON.parse(
                      [...selectedRows].toString(),
                    ) as AuthSignupPost201ResponseEquipmentPurchaseOrderItem
                  }
                />,
                null,
                t("common.create", { item: `${t("productionPlan.productionPlan")}` }),
                true,
              )
            }
          >
            {t("common.create", { item: `${t("productionPlan.productionPlan")}` })}
          </Button>
        </Flex>
        <Flex gap="sm">
          <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
            {t("common.reset")}
          </Button>
          <Button rightIcon={<IconTrash />} color="red" onClick={() => handleDelete()}>
            {t("common.rowDelete")}
          </Button>
        </Flex>
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: `${orderTitle}` })}
        </Text>
        <Text fz="xs">
          {t("common.subTitle", { item: `${orderTitle}` })} ({t("purchaseOrderItems.customer")})
        </Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<
            PurchaseOrderItemsGet200Response,
            AuthSignupPost201ResponseEquipmentPurchaseOrderItem
          >
            columns={columns}
            rowKeyGetter={(row: AuthSignupPost201ResponseEquipmentPurchaseOrderItem) =>
              JSON.stringify(row)
            }
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<PurchaseOrderItemsGet200Response> />
      </Flex>
    </Flex>
  );
};
