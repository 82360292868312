import { mutateTransportation } from "@/api/transportation/useTransportationQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Select, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  ScmApiTransportationPostRequest,
  TransportationGetRequestStatusEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

interface TransportationCreateFormProps {
  orderId?: number;
  name?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export const TransportationCreateForm = (props: TransportationCreateFormProps) => {
  const { autoCompleteOnSubmit } = props;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: createMutate } = useMutation(
    (params: ScmApiTransportationPostRequest) =>
      mutateTransportation
        .create(params)
        .mutationFn(params as ScmApiTransportationPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["transportation"]);
        closeModal(form.values);
        customAlert(
          t("message.createSuccess", { item: t("transportation.transportation") }),
          t("common.createSuccess", t("transportation.transportation")),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("transportation.transportation") }),
          t("common.createFail", { item: t("transportation.transportation") }),
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      partnerCode: undefined,
      code: undefined,
      direction: undefined,
      status: TransportationGetRequestStatusEnum.SCHEDULED,
      scheduledAt: undefined,
      additional: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        transportationGetRequest: {
          partnerCode: form.values.partnerCode!,
          code: form.values.code!,
          direction: form.values.direction!,
          status: form.values.status!,
          scheduledAt: form.values.scheduledAt!,
          additional: form.values.additional!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("transportation.transportation") }),
        t("common.createFail", { item: t("transportation.transportation") }),
        "red",
      );
    }
  };

  return (
    <Create.Wrapper>
      <PartnerAutoComplete
        label={t("partner.partner")}
        placeholder={t("placeholder.select", { item: t("partner.partner") })}
        {...form.getInputProps("partnerCode")}
        withAsterisk
      />
      <TextInput
        label={t("transportation.code")}
        placeholder={t("placeholder.code", { item: t("transportation.code") })}
        {...form.getInputProps("code")}
        withAsterisk
      />
      <Select
        withAsterisk
        label={t("transportation.direction")}
        placeholder={t("placeholder.select", { item: t("transportation.direction") })}
        data={[
          { label: t("purchaseOrder.purchaseOrder"), value: "INBOUND" },
          { label: t("order.order"), value: "OUTBOUND" },
        ]}
        {...form.getInputProps("direction")}
      />
      <Select
        label={t("transportation.status")}
        placeholder={t("placeholder.select", { item: t("transportation.status") })}
        data={[
          { label: t("transportationStatus.SCHEDULED"), value: "SCHEDULED" },
          { label: t("transportationStatus.LOADING"), value: "LOADING" },
          { label: t("transportationStatus.DONE"), value: "DONE" },
          { label: t("transportationStatus.ON_ROAD"), value: "ON_ROAD" },
          { label: t("transportationStatus.ERROR"), value: "ERROR" },
        ]}
        {...form.getInputProps("status")}
        withinPortal
      />
      <Create.DateInput
        popoverProps={{ withinPortal: true }}
        icon={<IconCalendar size="1.2rem" />}
        label={t("stockPlan.scheduledAt")}
        valueFormat="YYYY-MM-DD"
        {...form.getInputProps("scheduledAt")}
      />
      <Create.ButtonBox>
        <Create.Button color="gray" onClick={closeModal}>
          {t("common.cancel")}
        </Create.Button>
        <Create.Button
          disabled={validateFormValues({
            pageType: "transportation",
            values: form.values,
          })}
          onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
        >
          {t("common.additional", { item: t("transportation.transportation") })}
        </Create.Button>
      </Create.ButtonBox>
    </Create.Wrapper>
  );
};
