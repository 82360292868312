import { mutateRoutings } from "@/api/routing/useRoutingQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { RoutingCreateForm } from "@/features/routing/components/form/RoutingCreateForm";
import { handleErrorResponse } from "@/utils/errorMessage";
import styled from "@emotion/styled";
import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { DefaultApiRoutingsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EventHandler, ReactNode, forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import customAlert from "../../alert/alert";
import {
  RoutingOption,
  useRoutingsAutoComplete,
  useRoutingsCode,
} from "./auto-useGetRoutingsQuery";

interface RoutingProps extends React.ComponentPropsWithoutRef<"div"> {
  group: string;
  label: string;
  value: string;
  itemCode: string;
}

export interface RoutingAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (routingCode: string | null) => void;
  maxDropdownHeight?: number;
  outCode?: string | null;
  width?: string;
}

export const RoutingAutoComplete = (params: RoutingAutoCompleteProps) => {
  const { value: routingCode, onChange, maxDropdownHeight, outCode, width, ...etcParams } = params;

  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const { data: options } = useRoutingsAutoComplete(focused, searchKeyword);

  const { data: initialOptions } = useRoutingsCode(!!routingCode, routingCode ?? null);

  let selectedRouting = initialOptions?.find((item) => {
    return item.value === routingCode;
  });

  const onChangeHandler = (e: string | null) => {
    selectedRouting = options?.find((item) => item.value === e);
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, RoutingProps>(
    ({ label: name, value: code, itemCode, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <div>
            <Text>{name}</Text>
            <Text fz="xs">
              {" "}
              (code: {code}, itemCode: {itemCode})
            </Text>
          </div>
        </Flex>
      </div>
    ),
  );

  return (
    <RoutingSelect
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedRouting?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "10px",
              }}
            >
              {"code: " + selectedRouting?.value}
            </div>
          )}
        </div>
      )}
      value={routingCode && routingCode}
      itemComponent={SelectItem}
      searchValue={searchKeyword}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: RoutingProps[], option: RoutingProps) => {
          return unique.some((u) => u.value === option.value) || option.value === outCode
            ? unique
            : [...unique, option];
        },
        [],
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={RoutingInfo({
        routing: selectedRouting as RoutingOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewRouting({
        routingName: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const RoutingInfo = (params: {
  routing?: RoutingOption;
  onChange: (routingCode: string | null) => void;
}) => {
  const { routing, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return routing?.value ? (
    <RoutingInfoLabel>
      <IconX size="1rem" onClick={clearHandler} />{" "}
    </RoutingInfoLabel>
  ) : null;
};

const AddNewRouting = (params: {
  routingName: string;
  onChange: (routingCode: string) => void;
}) => {
  const { routingName, onChange } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: postMutate } = useMutation(
    (params: DefaultApiRoutingsPostRequest) =>
      mutateRoutings.create(params).mutationFn(params as DefaultApiRoutingsPostRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["routings"]);
        closeModal(res.data);
        customAlert(
          t("message.createSuccess", { item: t("routing.routing") }),
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("routing.code") }),
          t("common.createFail", { item: t("routing.routing") }),
          "red",
        );
      },
    },
  );

  const onCloseHandler: EventHandler<any> = (values) => {
    postMutate({
      routingsGetRequest: {
        itemCode: values.itemCode,
        code: values.code,
        routingType: values.routingType,
        consumeType: values.consumeType,
        operationCode: values.operationCode,
        seq: values.seq,
      },
    });
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    openModal(
      <RoutingCreateForm code={routingName} autoCompleteOnSubmit={onCloseHandler} />,
      null,
      t("common.newCreate", { item: t("routing.routing") }),
      true,
    ).then((result) => {
      onChange(result?.code);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      {t("common.newCreate", { item: `${t("routing.routing")} ${routingName}` })}
    </Button>
  );
};
const RoutingInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;

const RoutingSelect = styled(Select)<{ value: string | null | undefined }>`
  width: 100%;

  .mantine-Select-input {
    font-size: 0.8rem;
    padding-bottom: ${(props) => (props.value ? "0.8rem !important" : "")};

    &.item-dirty {
      color: cadetblue !important;
      border-color: cadetblue;
    }
  }
  .mantine-Select-error {
    font-size: 11px;
    color: red;
  }
`;
