import styled from "@emotion/styled";
import React from "react";
import ApexCharts from "react-apexcharts";

const AnalysisPressChart: React.FC = () => {
  const INITIAL_CHART_Y_AXIOS_OPTION = [
    {
      show: false,
    },
    {
      opposite: true,
      title: {
        text: "SIZL_SIZL_SIZL",
        style: {
          color: "transparent",
        },
      },
      min: 0,
      tickAmount: 13,
      labels: {
        formatter: (value: any, index: number) => {
          if (index === 13) {
            return "(UPH : ea)";
          } else {
            return Math.round(value).toString();
          }
        },
      },
    },
    {
      title: {
        text: "SIZL_SIZL_SIZL",
        style: {
          color: "transparent",
        },
      },
      axisTicks: {
        show: true,
      },
      tickAmount: 13,
      min: 0,
      labels: {
        formatter: (value: any, index: number) => {
          if (index === 13) {
            return "(생산량 : ea)";
          } else {
            return Math.round(value).toString();
          }
        },
      },
    },
  ];

  const MONITOR_INITIAL_CHART_Y_AXIOS_OPTION = [
    {
      title: {
        text: "SIZL_SIZL_SIZL",
        style: {
          color: "transparent",
        },
      },
      axisTicks: {
        show: true,
      },
      yaxisTicks: {
        show: true,
      },
      tickAmount: 5,
      min: 0,
      labels: {
        formatter: (value: any, index: number) => {
          if (index === 5) {
            return "생산량:ea";
          } else {
            return Math.round(value).toString();
          }
        },
      },
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line" as const,
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: "Line Series",
        type: "line",
        data: [
          10, 20, 30, 40, 50, 60, 70, 80, 10, 20, 30, 40, 50, 60, 70, 80, 10, 20, 30, 40, 50, 60,
          70, 80,
        ],
      },
      {
        name: "Bar Series",
        type: "bar",
        data: [
          23, 12, 54, 61, 32, 56, 81, 23, 12, 54, 61, 32, 56, 81, 23, 12, 54, 61, 32, 56, 81, 23,
          12, 54,
        ],
      },
    ],
    stroke: {
      width: [2, 0],
    },
    xaxis: {
      categories: [
        "00-01",
        "01-02",
        "02-03",
        "03-04",
        "04-05",
        "05-06",
        "06-07",
        "07-08",
        "08-09",
        "09-10",
        "10-11",
        "11-12",
        "12-13",
        "13-14",
        "14-15",
        "15-16",
        "16-17",
        "17-18",
        "18-19",
        "19-20",
        "20-21",
        "21-22",
        "22-23",
        "23-24",
      ],
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#008FFB",
        },
        labels: {
          style: {
            colors: "#008FFB",
          },
        },
        title: {
          text: "Line Series",
          style: {
            color: "#008FFB",
          },
        },
      },
      {
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#00E396",
        },
        labels: {
          style: {
            colors: "#00E396",
          },
        },
        title: {
          text: "Bar Series",
          style: {
            color: "#00E396",
          },
        },
      },
    ],
  };

  return (
    <Container>
      <ApexCharts options={options} series={options.series} type="line" height={350} />
    </Container>
  );
};

export default AnalysisPressChart;

const Container = styled.div`
  width: 70%;
`;
