import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { EventHandler, ReactNode, forwardRef, useMemo, useState } from "react";

import { useModal } from "@/features/modal/ModalStackManager";
import {
  EquipmentOption,
  useEquipmentsAutoComplete,
  useEquipmentsCode,
} from "@/features/ui/autoComplete/equipment/auto-useGetEquipmentsQuery";
import styled from "@emotion/styled";

import { mutateEquipment } from "@/api/equipment/useEquipmentQuery";
import { EquipmentCreateForm } from "@/features/equipment/components/form/EquipmentCreateForm";
import { handleErrorResponse } from "@/utils/errorMessage";
import {
  AuthSignupPost201ResponseEquipment,
  MasterApiEquipmentsPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import customAlert from "../../alert/alert";

interface EquipmentProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
  equipment: AuthSignupPost201ResponseEquipment;
}

export interface EquipmentAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (equipmentCode: string | null) => void;
  onSelectedData?: (equipment: AuthSignupPost201ResponseEquipment | undefined) => void;
  maxDropdownHeight?: number;
  width?: string;
  initCodes?: string[] | null;
}

// 입력값: value (equipment 모델의 code)
// 출력값: onChange (equipment 모델의 code)

export const EquipmentAutoComplete = (params: EquipmentAutoCompleteProps) => {
  const {
    value: equipmentCode,
    onChange,
    maxDropdownHeight,
    width,
    onSelectedData,
    initCodes,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useEquipmentsAutoComplete(focused, searchKeyword);
  const { data: initialOptions } = useEquipmentsCode(!!equipmentCode, equipmentCode ?? null);

  const filteredOptions = useMemo(() => {
    if (!options) return [];
    return options.filter((option) =>
      initCodes ? initCodes.includes(option.equipment.code) : true,
    );
  }, [options, initCodes]);

  let selectedEquipment = initialOptions?.find((equipment) => equipment.value === equipmentCode);
  const onChangeHandler = (e: string | null) => {
    selectedEquipment = options?.find((equipment) => equipment.value === e);
    onSelectedData && onSelectedData(selectedEquipment?.equipment);
    onChange && onChange(e);
  };

  const SelectEquipment = forwardRef<HTMLDivElement, EquipmentProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            <Text fz="xs">(설비코드: {code})</Text>
          </Flex>
        </Flex>
      </div>
    ),
  );

  return (
    <EquipmentSelect
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedEquipment?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "10px",
              }}
            >
              {"code: " + selectedEquipment?.value}
            </div>
          )}
        </div>
      )}
      value={equipmentCode}
      itemComponent={SelectEquipment}
      searchValue={searchKeyword}
      data={[...(filteredOptions ?? []), ...(initialOptions ?? [])].reduce(
        (unique: EquipmentProps[], option: EquipmentProps) => {
          return unique.some((u) => u.value === option.value) ? unique : [...unique, option];
        },
        [],
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={EquipmentInfo({
        equipment: selectedEquipment as EquipmentOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewEquipment({
        equipmentName: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const EquipmentInfo = (params: {
  equipment?: EquipmentOption;
  onChange: (equipmentCode: string | null) => void;
}) => {
  const { equipment, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return equipment?.value ? (
    <EquipmentInfoLabel>
      {/* ({equipment.value})  */}
      <IconX size="1rem" onClick={clearHandler} />{" "}
    </EquipmentInfoLabel>
  ) : null;
};

const AddNewEquipment = (params: {
  equipmentName: string;
  onChange: (equipmentCode: string) => void;
}) => {
  const { equipmentName, onChange } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate } = useMutation(
    (params: MasterApiEquipmentsPostRequest) =>
      mutateEquipment.create(params).mutationFn(params as MasterApiEquipmentsPostRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["equipments"]);
        closeModal(res.data);
        customAlert(
          t("message.createSuccess", { item: t("equipment.equipment") }),
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("equipment.code") }),
          t("common.createFail", { item: t("equipment.equipment") }),
          "red",
        );
      },
    },
  );

  const onCloseHandler: EventHandler<any> = (values) => {
    mutate({
      equipmentsGetRequest: {
        siteCode: values.siteCode,
        code: values.code,
        name: values.name,
        equipmentType: values.equipmentType,
        seq: values.seq,
        toLocationCode: values.toLocationCode,
        targetLocationCode: values.targetLocationCode,
      },
    });
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    openModal(
      <EquipmentCreateForm name={equipmentName} autoCompleteOnSubmit={onCloseHandler} />,
      null,
      t("common.newCreate", { item: t("equipment.equipment") }),
      true,
    ).then((result) => {
      onChange(result.code);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      {t("common.newCreate", { item: `${t("equipment.equipment")} ${equipmentName}` })}
    </Button>
  );
};
const EquipmentInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  position: absolute;
`;

const EquipmentSelect = styled(Select)<{ value: string | null | undefined }>`
  .mantine-Select-input {
    font-size: 0.8rem;
    padding-bottom: 0.8rem !important;

    &.item-dirty {
      color: cadetblue !important;
      border-color: cadetblue;
    }
  }
  .mantine-Select-error {
    font-size: 11px;
    color: red;
  }
`;
