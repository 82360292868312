import { Button } from "@mantine/core";

interface CancelButtonProps<T> {
  onCancel?: () => void;
  children?: React.ReactNode;
}

export const CancelButton = <T,>({ onCancel, children }: CancelButtonProps<T>) => {
  return (
    <Button onClick={onCancel} variant="filled" color="gray">
      {children}
    </Button>
  );
};
