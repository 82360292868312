import { DrawerProvider } from "@/features/drawer/DrawerContext";
import ModalStackManager from "@/features/modal/ModalStackManager";
import { WorkProvider } from "@/features/workByManagement/context/WorkProvider";
import i18n from "@/language/i18n";
import Router from "@/router";
import { ColorScheme, ColorSchemeProvider, MantineProvider } from "@mantine/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useState } from "react";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { IoProvider } from "socket.io-react-hook";

function App() {
  const queryClient = new QueryClient();
  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <React.StrictMode>
      <IoProvider>
        <RecoilRoot>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <ColorSchemeProvider
                  colorScheme={colorScheme}
                  toggleColorScheme={toggleColorScheme}
                >
                  <MantineProvider
                    theme={{ colorScheme, loader: "oval" }}
                    withGlobalStyles
                    withNormalizeCSS
                  >
                    <WorkProvider>
                      <ModalStackManager>
                        <DrawerProvider>
                          <Router />
                        </DrawerProvider>
                      </ModalStackManager>
                    </WorkProvider>
                  </MantineProvider>
                </ColorSchemeProvider>
              </QueryClientProvider>
            </BrowserRouter>
          </I18nextProvider>
        </RecoilRoot>
      </IoProvider>
    </React.StrictMode>
  );
}
export default App;
