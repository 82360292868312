import { customFunctions } from "@/config/customFunction";
import { DeliveryIncomingFormProps } from "@/features/orderItems/form/deliveryIncomingForm";
import { useCreateLot } from "@/hooks/useCreateLot";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem } from "@sizlcorp/sizl-api-document/dist/models";
import { useEffect, useMemo } from "react";

type useCreatePurchaseOrderItemsWithLotProps = {
  items: AuthSignupPost201ResponseEquipmentPurchaseOrderItem[] | undefined;
};

export const useCreatePurchaseDeliveryIncomingWithLot = ({
  items,
}: useCreatePurchaseOrderItemsWithLotProps) => {
  const { createLot, createdLotNames } = useCreateLot();

  const updatedPurchaseDeliveryIncomingWithLot = useMemo(() => {
    return items?.map((item, index) => ({
      ...item,
      inputQuantity: customFunctions.ADD_ORDER_AUTO_CREATE_LOT ? "1" : undefined,
      lotInfo: {
        name: createdLotNames[index],
        expiration: undefined,
      },
    })) as DeliveryIncomingFormProps[];
  }, [createdLotNames]);

  useEffect(() => {
    if (items && customFunctions.ADD_ORDER_AUTO_CREATE_LOT) {
      const usesLotNameItem = items.filter((item) => item.usesLotName);
      const itemCodeList = usesLotNameItem?.map((item) => item.itemCode) as string[];
      createLot(itemCodeList);
    }
  }, []);

  return { updatedPurchaseDeliveryIncomingWithLot };
};
