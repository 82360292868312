import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Button, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  DefaultApiWmsDiligencePostRequest,
  WmsDiligencePostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Diligence = Object.assign({}, FormMain, {
  Button: Button,
  Input: TextInput,
  Date: DateInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  ItemAutoComplete: ItemAutoComplete,
});

export const DiligenceForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: diligenceMutate } = useMutation(
    (params: DefaultApiWmsDiligencePostRequest) =>
      mutateInventories
        .diligence(params)
        .mutationFn(params as DefaultApiWmsDiligencePostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
        closeModal(form.values);
        customAlert(
          `${t("inventory.successMessage", { item: `${t("inventory.diligence")}` })}`,
          `${t("inventory.success", { item: `${t("inventory.diligence")}` })}`,
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("inventory.diligence") }),
          `${t("inventory.fail", { item: `${t("inventory.diligence")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm<Partial<WmsDiligencePostRequest>>({
    initialValues: {
      itemCode: formatterProps.itemCode,
      locationCode: formatterProps.locationCode,
      quantity: undefined,
      lotId: formatterProps.lotId,
      lotInfo: {
        name: formatterProps.lotName,
        expiration:
          formatterProps.lotExpiration !== null
            ? new Date(formatterProps.lotExpiration).toISOString()
            : null,
      },
    },
  });

  const onSubmit = () => {
    try {
      const { itemCode, locationCode, quantity } = form.values;
      if (!itemCode && !locationCode && !quantity) return;

      diligenceMutate({
        wmsDiligencePostRequest: {
          itemCode: formatterProps.itemCode || (form.values.itemCode as string),
          locationCode: formatterProps.locationCode || (form.values.locationCode as string),
          quantity: form.values.quantity as string,
          lotId: Number(formatterProps.lotId || form.values.lotId),
          lotInfo: {
            name:
              formatterProps.lot.name ||
              (form.values.lotInfo && form.values.lotInfo.name ? form.values.lotInfo.name : null),
            expiration: form.values.lotInfo?.expiration
              ? new Date(form.values.lotInfo.expiration).toISOString()
              : null,
          },
          actionFrom: "WEB",
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("inventory.diligence") }),
        `${t("inventory.fail", { item: `${t("inventory.diligence")}` })}`,
        "red",
      );
    }
  };

  return (
    <>
      <Diligence.Wrapper>
        <Diligence.ItemAutoComplete
          disabled
          label={t("item.code")}
          withAsterisk
          {...form.getInputProps("itemCode")}
        />
        <LocationAutoComplete
          disabled
          label={t("location.code")}
          withAsterisk
          {...form.getInputProps("locationCode")}
        />
        <Diligence.Input
          label={t("inventory.quantity")}
          withAsterisk
          type="number"
          {...form.getInputProps("quantity")}
          defaultValue={formatterProps?.quantity}
        />
        <Diligence.Input disabled label={t("lot.id")} {...form.getInputProps("lotId")} />
        <Diligence.Input disabled label={t("lot.name")} {...form.getInputProps("lotInfo.name")} />
        <Diligence.Date
          disabled
          label={t("lot.expiration")}
          //   placeholder="만료일을 선택하세요."
          minDate={new Date()}
          valueFormat="YYYY-MM-DD HH:mm:ss"
          value={
            form.values.lotInfo?.expiration ? new Date(form.values.lotInfo.expiration) : undefined
          }
        />
        <Diligence.ButtonBox>
          <Diligence.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Diligence.Button>
          <Diligence.Button onClick={onSubmit}>{t("inventory.diligence")}</Diligence.Button>
        </Diligence.ButtonBox>
      </Diligence.Wrapper>
    </>
  );
};
