export type State = Record<string, boolean>;

type Action = { type: "TOGGLE"; name: string } | { type: "SET_ALL"; payload: boolean };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "TOGGLE":
      return { ...state, [action.name]: !state[action.name] };
    case "SET_ALL":
      return Object.keys(state).reduce((acc, key) => ({ ...acc, [key]: action.payload }), {});
    default:
      throw new Error("Unsupported action type");
  }
};
