import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { FormActionButtonGroup } from "@/features/ui/formActionButtonGroup/FormActionButtonGroup";
import { NewLotCreateInput } from "@/features/ui/newLotCreateInput/NewLotCreateInput";
import { MantineSelectableTable } from "@/features/ui/table/MantineSelectableTable";
import { useCreatePurchaseOrderItemsWithLot } from "@/hooks/useCreatePurchaseOrderItemsWithLot";
import { Flex, NumberInput, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  ItemsGet200ResponseRowsInnerLotsInner,
  PurchaseOrdersGet200ResponseRowsInner,
  PurchaseOrdersGet200ResponseRowsInnerPurchaseOrderItemsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { MRT_ColumnDef } from "mantine-react-table";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useOrderItemsIncoming } from "../hook/useOrderItemsIncoming";

export interface PurchaseOrderItemsSelectFormProps {
  purchaseOrder: PurchaseOrdersGet200ResponseRowsInner | undefined;
  setLoading: Dispatch<SetStateAction<boolean>>;
}
const OrderItemsIncoming = Object.assign(
  {},
  {
    TextInput: TextInput,
    NumberInput: NumberInput,
    NewLotCreateInput: NewLotCreateInput,
    LocationAutoComplete: LocationAutoComplete,
    FormActionButtonGroup: FormActionButtonGroup,
    MantineSelectableTable: MantineSelectableTable,
  },
);

export interface OrderItemIncomingFormProps
  extends PurchaseOrdersGet200ResponseRowsInnerPurchaseOrderItemsInner {
  lotId?: number;
  lotInfo?: {
    name: string | undefined;
    expiration: string | undefined;
  };
  isChecked?: boolean;
  inputValue?: number;
}

export const OrderItemsIncomingForm = ({
  purchaseOrder,
  setLoading,
}: PurchaseOrderItemsSelectFormProps) => {
  const { closeModal } = useModal();
  const { orderItemsSubmit } = useOrderItemsIncoming({ setLoading });
  const { updatedPurchaseOrderItemsWithLot } = useCreatePurchaseOrderItemsWithLot({
    items: purchaseOrder?.purchaseOrderItems,
  });

  const form = useForm<{
    targetLocationCode: string | undefined;
    Items: OrderItemIncomingFormProps[];
  }>({
    initialValues: {
      targetLocationCode: purchaseOrder?.targetLocationCode,
      Items: [],
    },
  });

  const selectedIndex = form.values.Items.findIndex((item) => item.isChecked);
  const isItemSelected = !form.values.Items.some((item) => item.isChecked);
  const usesLotName = form.values.Items?.[selectedIndex]?.item?.usesLotName;
  const usesLotExpiration = form.values.Items?.[selectedIndex]?.item?.usesLotExpiration;
  const isLotCreationEnabled = !usesLotName && !usesLotExpiration;

  const lotDescription = (() => {
    if (usesLotName && usesLotExpiration) return "이름 + 만료일";
    if (usesLotName) return "이름";
    if (usesLotExpiration) return "만료일";
    return "관리 안함";
  })();

  const columns = useMemo<MRT_ColumnDef[]>(() => {
    const baseColumns = [
      {
        accessorKey: "partnerCode",
        header: "협력사명",
        Cell: ({ cell }: any) => (<span>{cell.getValue()}</span>) as JSX.Element,
      },
      {
        accessorKey: "itemCode",
        header: "품목명",
      },
      {
        accessorKey: "quantity",
        header: "최초수량",
      },
      {
        accessorKey: "inputEligibleQuantity",
        header: "입고가능수량",
      },
    ];

    if (customFunctions.ADD_ORDER_AUTO_CREATE_LOT) {
      baseColumns.push(
        {
          accessorKey: "inputValue",
          header: "입고개수",
          Cell: ({ row }) => {
            const isSelected = row.getIsSelected();
            const index = row.index;

            return (
              <OrderItemsIncoming.NumberInput
                max={Number(form.values.Items?.[index]?.inputEligibleQuantity)}
                min={1}
                disabled={!isSelected} // selectedItem이 없는 경우 비활성화
                {...form.getInputProps(`Items.${index}.inputValue`)}
                value={Number(form.values.Items?.[index]?.inputValue)}
              />
            );
          },
        },
        {
          accessorKey: "purchaseOrderItemQty",
          header: "분할개수",
          Cell: ({ row }) => {
            const isUsesLotName = row.original.item?.usesLotName;
            const isSelected = row.getIsSelected();
            const index = row.index;

            return isUsesLotName ? (
              <OrderItemsIncoming.NumberInput
                placeholder="분할 개수"
                min={1}
                disabled={!isSelected} // selectedItem이 없는 경우 비활성화
                {...form.getInputProps(`Items.${index}.purchaseOrderItemQty`)}
                value={Number(form.values.Items?.[index]?.purchaseOrderItemQty)}
              />
            ) : (
              <Text color="red">{"로트가 존재하지 않아 분할을 하실 수 없습니다."}</Text>
            );
          },
        },
        {
          accessorKey: "lotName",
          header: "로트번호",
          Cell: ({ row }) => {
            const isUsesLotName = row.original.item?.usesLotName;

            return isUsesLotName ? (
              <Text>{row.original.lotInfo.name}</Text>
            ) : (
              <Text color="red">{"로트를 사용하지 않는 품목입니다."}</Text>
            );
          },
        },
      );
    }

    return baseColumns;
  }, [customFunctions.ADD_ORDER_AUTO_CREATE_LOT, form.values.Items]);

  const handleLotCreation = (data: ItemsGet200ResponseRowsInnerLotsInner) => {
    form.setFieldValue("Items", [
      ...form.values.Items.slice(0, selectedIndex),
      {
        ...form.values.Items[selectedIndex],
        lotId: data.id,
        lotInfo: {
          name: data.name,
          expiration: data.expiration,
        },
      },
      ...form.values.Items.slice(selectedIndex + 1),
    ]);
  };

  const onRowSelection = (selectedRows: OrderItemIncomingFormProps[]) => {
    const updatedItems = form.values.Items.map((item) => ({
      ...item,
      isChecked: selectedRows.some((selectedRow) => selectedRow.id === item.id),
    }));
    form.setFieldValue("Items", updatedItems);
  };

  const onSubmit = () => {
    for (const item of form.values.Items ?? []) {
      if (item.isChecked) {
        orderItemsSubmit({ item: item, targetLocationCode: form.values.targetLocationCode });
      }
    }
    closeModal(true);
  };

  useEffect(() => {
    if (customFunctions.ADD_ORDER_AUTO_CREATE_LOT) {
      form.setFieldValue("Items", updatedPurchaseOrderItemsWithLot);
    } else {
      form.setFieldValue(
        "Items",
        purchaseOrder?.purchaseOrderItems?.map((item) => {
          return { ...item, inputValue: Number(item.inputEligibleQuantity) };
        }) as PurchaseOrdersGet200ResponseRowsInnerPurchaseOrderItemsInner[],
      );
    }
  }, [purchaseOrder, updatedPurchaseOrderItemsWithLot]);

  console.log(form.values, "values");

  return (
    <>
      {purchaseOrder?.purchaseOrderItems?.length === 0 ? (
        <Flex>
          <Text size="xl" fw="bold">
            발주항목이 없습니다. 발주항목을 생성해주세요.
          </Text>
        </Flex>
      ) : (
        <Flex direction={"column"} gap={30}>
          <OrderItemsIncoming.MantineSelectableTable
            data={form.values.Items ?? []}
            columns={columns}
            enableMultiRowSelection={customFunctions.ADD_ORDER_AUTO_CREATE_LOT}
            onRowSelection={onRowSelection}
          />
          {!customFunctions.ADD_ORDER_AUTO_CREATE_LOT && (
            <>
              <OrderItemsIncoming.TextInput
                disabled
                label="품목코드"
                withAsterisk
                value={form.values.Items?.[0]?.itemCode}
              />
              <OrderItemsIncoming.NewLotCreateInput
                lotDescription={lotDescription}
                disabled={isLotCreationEnabled}
                itemCode={form.values.Items?.[selectedIndex]?.itemCode as string}
                newIdValue={form.values.Items?.[selectedIndex]?.lotId as number}
                defaultValue={form.values.Items?.[selectedIndex]?.lotId?.toString() ?? ""}
                onLotCreated={handleLotCreation}
                onRowChange={(lot) => {
                  form.setFieldValue(`Items.${selectedIndex}.lotId`, Number(lot.value));
                  form.setFieldValue(`Items.${selectedIndex}.lotInfo`, {
                    name: lot.label,
                    expiration: lot.expiration === "null" ? undefined : lot.expiration,
                  });
                }}
              />
            </>
          )}
          <OrderItemsIncoming.LocationAutoComplete
            label="로케이션코드"
            withAsterisk
            {...form.getInputProps("targetLocationCode")}
          />
          {!customFunctions.ADD_ORDER_AUTO_CREATE_LOT && (
            <>
              <OrderItemsIncoming.NumberInput
                label="수량"
                withAsterisk
                value={form.values.Items?.[selectedIndex]?.inputValue}
                onChange={(value) => {
                  form.setFieldValue(`Items.${selectedIndex}.inputValue`, value);
                }}
              />
              <OrderItemsIncoming.TextInput
                disabled
                label="만료일"
                value={form.values.Items?.[selectedIndex]?.lotInfo?.expiration}
              />
            </>
          )}
          <OrderItemsIncoming.FormActionButtonGroup
            disabled={isItemSelected}
            form={form}
            onCancel={() => closeModal({})}
            onSubmit={onSubmit}
          >
            {"입고"}
          </OrderItemsIncoming.FormActionButtonGroup>
        </Flex>
      )}
    </>
  );
};
