import FrequentlyChartLegendShape from "@/features/PMS/frequently/FrequentlyChartLegendShape";
import styled from "@emotion/styled";
import React from "react";
import { FrequentlyChartLegendShapeType } from "./Frequently";
import FrequentlyLabel from "./FrequentlyLabel";

interface Props {
  value: string;
  type: FrequentlyChartLegendShapeType;
  onClick: (value: string, isVisible?: boolean) => void;
  isVisible?: boolean;
  compulsion?: boolean;
  selectedPress?: any;
}

const Container = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: 8,
  cursor: "pointer",
}));

const FrequentlyChartLegend: React.FunctionComponent<Props> = ({
  value,
  type,
  onClick,
  isVisible,
  compulsion,
  selectedPress,
}) => {
  const [legendVisible, setLegendVisible] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (isVisible !== undefined) {
      setLegendVisible(isVisible);
    }
  }, [isVisible]);

  const onClickHandler = React.useCallback(() => {
    setLegendVisible(!legendVisible);

    if (onClick) {
      if (value.search("가동률") !== -1) {
        onClick(value, compulsion ? compulsion : !legendVisible);
      } else {
        typeof compulsion === "undefined" && onClick(value, !legendVisible);
      }
    }
  }, [value, legendVisible, onClick, isVisible, compulsion]);

  React.useEffect(() => {
    setLegendVisible(true);
  }, [selectedPress]);

  return (
    <Container
      onClick={onClickHandler}
      style={{
        opacity:
          typeof compulsion === "undefined" ? (legendVisible ? 1 : 0.5) : compulsion ? 1 : 0.5,
      }}
    >
      <FrequentlyChartLegendShape type={type} />
      <FrequentlyLabel
        text={value}
        size={12}
        fontFamily={"Roboto-Regular"}
        containerStyles={{ paddingBottom: 6, paddingRight: 6 }}
      />
    </Container>
  );
};

export default React.memo(FrequentlyChartLegend);
