import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Main } from "@/features/standard/Main";
import customAlert from "@/features/ui/alert/alert";
import { getUserCodeByUser } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Select, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  PurchaseOrdersGet200ResponseRowsInner,
  ScmApiPurchaseOrdersPurchaseOrderIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Update = Object.assign({}, Main, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  DateInput: DateInput,
});

interface OrderStatusFormProps {
  formatterProps: PurchaseOrdersGet200ResponseRowsInner | undefined;
}

export const OrderStatusForm = (params: OrderStatusFormProps) => {
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const userCode = getUserCodeByUser();
  const { t } = useTranslation();

  const { mutate: updateMutate } = useMutation(
    (params: ScmApiPurchaseOrdersPurchaseOrderIdPutRequest) =>
      mutatePurchaseOrders
        .update(params)
        .mutationFn(params as ScmApiPurchaseOrdersPurchaseOrderIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["purchaseOrders"]);
        closeModal(formatterProps);
        customAlert(
          `${userCode === "FLOUR" ? t("message.changeSuccess", { item: t("purchaseOrder.status") }) : t("message.changeSuccess", { item: t("order.status") })}`,
          t("common.changeSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({
            error,
            errorContext: `${userCode === "FLOUR" ? t("purchaseOrder.status") : t("order.status")}`,
          }),
          t("common.changeFail"),
          "red",
        );
      },
    },
  );

  const { closeModal } = useModal();

  const form = useForm({
    initialValues: {
      partnerCode: formatterProps?.partnerCode,
      status: formatterProps?.status,
      code: formatterProps?.code,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate({
        purchaseOrderId: formatterProps?.id ?? 0,
        purchaseOrdersGetRequest: {
          direction: "OUTBOUND",
          code: form.values.code!,
          partnerCode: form.values.partnerCode!,
          status: form.values.status!,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Update.Wrapper>
        <Select
          label={userCode === "FLOUR" ? t("purchaseOrder.status") : t("order.status")}
          placeholder={
            userCode === "FLOUR"
              ? t("placeholder.select", { item: t("purchaseOrder.status") })
              : t("placeholder.select", { item: t("order.status") })
          }
          data={[
            { value: "DRAFT", label: t("purchaseStatus.DRAFT") },
            { value: "IN_PROGRESS", label: t("purchaseStatus.IN_PROGRESS") },
            { value: "DONE", label: t("purchaseStatus.DONE") },
            { value: "CANCELED", label: t("purchaseStatus.CANCELED") },
          ]}
          defaultValue={formatterProps?.status}
          {...form.getInputProps("status")}
          error={form.isDirty("status") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("status") ? "cadetblue !important" : "inherit",
              borderColor: form.isDirty("status") ? "cadetblue !important" : "inherit",
            },
            error: { fontSize: 11, color: "cadetblue" },
          }}
        />
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Update.Button>
          <Update.Button
            disabled={validateFormValues({
              pageType: "purchaseOrder",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            {userCode === "FLOUR"
              ? t("common.change", { item: t("purchaseOrder.status") })
              : t("common.change", { item: t("order.status") })}
          </Update.Button>
        </Update.ButtonBox>
      </Update.Wrapper>
    </>
  );
};
