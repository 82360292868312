import { MasterInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import {
  MasterApiItemChangeAttachmentsItemChangeAttachmentIdDeleteRequest,
  MasterApiItemChangeAttachmentsItemChangeAttachmentIdGetRequest,
  MasterApiItemChangesFindPostRequest,
  MasterApiItemChangesGetRequest,
  MasterApiItemChangesItemChangeIdDeleteRequest,
  MasterApiItemChangesItemChangeIdGetRequest,
  MasterApiItemChangesItemChangeIdPutRequest,
  MasterApiItemChangesPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const itemChanges = createQueryKeys("itemChanges", {
  all: null,
  get: (params: MasterApiItemChangesGetRequest) => {
    const query = { ...params }; // params 객체를 복사합니다.

    if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => MasterInstance.itemChangesGet(query),
    };
  },
  detail: (params: MasterApiItemChangesItemChangeIdGetRequest) => ({
    queryKey: [params.itemChangeId, params],
    queryFn: () => MasterInstance.itemChangesItemChangeIdGet(params),
  }),
  find: (params: MasterApiItemChangesFindPostRequest) => {
    const query = { ...params }; // params 객체를 복사합니다.

    if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => MasterInstance.itemChangesFindPost(query),
    };
  },
  fileDetail: (params: MasterApiItemChangeAttachmentsItemChangeAttachmentIdGetRequest) => ({
    queryKey: [params],
    queryFn: () => MasterInstance.itemChangeAttachmentsItemChangeAttachmentIdGet(params),
  }),
});

export const mutateItemChanges = createMutationKeys("itemChanges", {
  create: (params: MasterApiItemChangesPostRequest) => ({
    mutationKey: [params.itemChangesGetRequest],
    mutationFn: () => MasterInstance.itemChangesPost(params),
  }),
  update: (params: MasterApiItemChangesItemChangeIdPutRequest) => ({
    mutationKey: [params.itemChangeId, params.itemChangesGetRequest],
    mutationFn: () => MasterInstance.itemChangesItemChangeIdPut(params),
  }),
  delete: (params: MasterApiItemChangesItemChangeIdDeleteRequest) => ({
    mutationKey: [params.itemChangeId],
    mutationFn: () => MasterInstance.itemChangesItemChangeIdDelete(params),
  }),
  deleteAttachment: (params: MasterApiItemChangeAttachmentsItemChangeAttachmentIdDeleteRequest) => {
    return {
      mutationKey: [params.itemChangeAttachmentId],
      mutationFn: () => MasterInstance.itemChangeAttachmentsItemChangeAttachmentIdDelete(params),
    };
  },
});
