import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Button, Group, Radio, TextInput } from "@mantine/core";

import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";

import { mutateLocations } from "@/api/locations/useLocationsQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import { MasterApiLocationsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

interface LocationCreateFormProps {
  name?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export const LocationCreateForm = (params: LocationCreateFormProps) => {
  const { name, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: createMutate } = useMutation(
    (params: MasterApiLocationsPostRequest) =>
      mutateLocations.create(params).mutationFn(params as MasterApiLocationsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["locations"]);
        closeModal(form.values);
        customAlert(
          `${t("message.createSuccess", { item: `${t("location.location")}` })}`,
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("location.code") }),
          `${t("common.createFail", { item: `${t("location.location")}` })}`,
          "red",
        );
      },
    },
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [isAvailable, setIsAvailable] = useState("true");

  const form = useForm({
    initialValues: {
      code: undefined,
      name: name ?? undefined,
      siteCode: undefined,
      isAvailable: "true",
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        locationsGetRequest: {
          code: form.values.code!,
          name: name ?? form.values.name!,
          siteCode: form.values.siteCode!,
          isAvailable: isAvailable === "true" ? true : false,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("location.code") }),
        `${t("common.createFail", { item: `${t("location.location")}` })}`,
        "red",
      );
    }
  };
  const handleRadioChange = (value: string) => {
    setIsAvailable(value);
    form.setFieldValue("isAvailable", value);
  };

  return (
    <>
      <Create.Wrapper>
        <Create.Input
          label={t("location.code")}
          placeholder={t("placeholder.input", { item: `${t("location.code")}` })}
          withAsterisk
          {...form.getInputProps("code")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const regex = /^[A-Za-z0-9_]*$/;
            if (regex.test(e.target.value)) {
              form.getInputProps("code").onChange(e);
              setErrorMessage(""); // 유효한 입력인 경우 에러 메시지 제거
            } else {
              setErrorMessage(t("message.noKorean")); // 유효하지 않은 입력인 경우 에러 메시지 설정
            }
          }}
          error={errorMessage ? errorMessage : ""}
        />
        <Create.Input
          label={t("location.name")}
          placeholder={t("placeholder.input", { item: `${t("location.name")}` })}
          withAsterisk
          {...form.getInputProps("name")}
        />
        <SitesAutoComplete
          width="100%"
          label={t("site.code")}
          placeholder={t("placeholder.select", { item: `${t("site.code")}` })}
          withAsterisk
          {...form.getInputProps("siteCode")}
        />
        {customFunctions.ADD_DEFECT_LOCATION && (
          <Radio.Group
            {...form.getInputProps("isAvailable")}
            value={isAvailable}
            onChange={handleRadioChange}
            name={t("location.isAvailable")}
            label={t("location.isAvailable")}
            withAsterisk
          >
            <Group mt="xs">
              <Radio value="true" label={t("location.able")} />
              <Radio value="false" label={t("location.disable")} />
            </Group>
          </Radio.Group>
        )}
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "location",
              values: form.values,
            })}
            onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
          >
            {t("common.create", { item: `${t("location.location")}` })}
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
