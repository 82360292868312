import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Button, NumberInput, TextInput } from "@mantine/core";

import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";

import { mutateMolds } from "@/api/mold/useMoldQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import { MasterApiMoldsMoldIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const MoldUpdateForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiMoldsMoldIdPutRequest) =>
      mutateMolds.update(params).mutationFn(params as MasterApiMoldsMoldIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["molds"]);
        closeModal(formatterProps);
        customAlert(
          `${t("message.editSuccess", { item: `${t("mold.mold")}` })}`,
          `${t("common.editSuccess")}`,
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("mold.code") }),
          `${t("common.editFail", { item: `${t("mold.mold")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      siteCode: formatterProps.siteCode,
      capacity: formatterProps.capacity ?? 0,
    },
  });

  const onSubmit = () => {
    try {
      updateMutate({
        moldId: formatterProps.id as number,
        moldsGetRequest: {
          code: form.values.code!,
          siteCode: form.values.siteCode!,
          capacity: form.values.capacity!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("mold.code") }),
        `${t("common.editFail", { item: `${t("mold.mold")}` })}`,
        "red",
      );
    }
  };

  return (
    <>
      <Update.Wrapper>
        <Update.Input
          label={t("mold.code")}
          withAsterisk
          defaultValue={formatterProps?.code}
          placeholder={t("placeholder.input", { item: `${t("mold.code")}` })}
          {...form.getInputProps("code")}
          error={form.isDirty("code") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("code") ? "red !important" : "",
              borderColor: form.isDirty("code") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <SitesAutoComplete
          label={t("site.code")}
          placeholder={t("placeholder.select", { item: `${t("site.code")}` })}
          width="100%"
          defaultValue={formatterProps?.siteCode}
          {...form.getInputProps("siteCode")}
          error={form.isDirty("siteCode") ? t("common.toSave") : ""}
        />
        <NumberInput
          label={t("mold.capacity")}
          // defaultValue={formatterProps?.capacity}
          {...form.getInputProps("capacity")}
          value={!formatterProps.capacity ? 0 : Number(formatterProps.capacity)}
          error={form.isDirty("capacity") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("capacity") ? "red !important" : "",
              borderColor: form.isDirty("capacity") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Update.Button>
          <Update.Button
            disabled={validateFormValues({
              pageType: "mold",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            {t("common.edit", { item: `${t("mold.mold")}` })}
          </Update.Button>
        </Update.ButtonBox>
      </Update.Wrapper>
    </>
  );
};
