import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { useModal } from "@/features/modal/ModalStackManager";
import { OrderItemsCreateForm } from "@/features/orderItems/form/createForm";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { ConfirmForm } from "@/features/ui/confirm";
import { getUserCodeByUser } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { ScmApiPurchaseOrdersPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

export const OrderCreateForm = () => {
  const { openModal, closeModal } = useModal();
  const userCode = getUserCodeByUser();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: createMutate } = useMutation(
    (params: ScmApiPurchaseOrdersPostRequest) =>
      mutatePurchaseOrders
        .create(params)
        .mutationFn(params as ScmApiPurchaseOrdersPostRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["purchaseOrders"]);
        closeModal(form.values);
        customAlert(
          `${userCode === "FLOUR" ? t("message.createSuccess", { item: t("purchaseOrder.purchaseOrder") }) : t("message.createSuccess", { item: t("order.order") })}`,
          t("common.createSuccess"),
          "green",
        );
        openModal(
          <ConfirmForm
            message={`${userCode === "FLOUR" ? t("purchaseOrderItems.createMessage") : t("orderItems.createMessage")}`}
            yesCallback={() => {
              openModal(
                <OrderItemsCreateForm order={res.data} />,
                null,
                `${userCode === "FLOUR" ? t("common.additional", { item: t("purchaseOrderItems.purchaseOrderItems") }) : t("common.additional", { item: t("orderItems.orderItems") })}`,
                true,
              ).then(() => closeModal(null));
            }}
            noCallback={() => closeModal(null)}
          />,
          null,
          `${userCode === "FLOUR" ? t("common.additional", { item: t("purchaseOrderItems.purchaseOrderItems") }) : t("common.additional", { item: t("orderItems.orderItems") })}`,
          true,
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({
            error,
            errorContext: `${userCode === "FLOUR" ? t("purchaseOrder.purchaseOrder") : t("order.order")}`,
          }),
          `${userCode === "FLOUR" ? t("common.createFail", { item: t("purchaseOrder.purchaseOrder") }) : t("common.createFail", { item: t("order.order") })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      partnerCode: undefined,
      // status: undefined,
      code: undefined,
      orderDate: undefined,
      deliveryDeadline: undefined,
      userCode: undefined,
      targetLocationCode: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        purchaseOrdersGetRequest: {
          partnerCode: form.values.partnerCode!,
          direction: "OUTBOUND",
          code: form.values.code!,
          // status: form.values.status!,
          orderDate: form.values.orderDate!,
          deliveryDeadline: form.values.deliveryDeadline!,
          userCode: form.values.userCode!,
          targetLocationCode: form.values.targetLocationCode!,
        },
      });
    } catch (e) {
      customAlert(
        `${userCode === "FLOUR" ? t("message.createFail", { item: t("purchaseOrder.purchaseOrder") }) : t("message.createFail", { item: t("order.order") })}`,
        t("common.createFail", { item: t("order.order") }),
        "red",
      );
    }
  };

  return (
    <>
      <Create.Wrapper>
        <PartnerAutoComplete
          withAsterisk
          label={t("partner.partner")}
          placeholder={t("placeholder.select", { item: t("partner.partner") })}
          {...form.getInputProps("partnerCode")}
        />
        <Create.Input
          withAsterisk
          label={`${userCode === "FLOUR" ? t("purchaseOrder.code") : t("order.code")}`}
          placeholder={`${userCode === "FLOUR" ? t("placeholder.input", { item: t("purchaseOrder.code") }) : t("placeholder.input", { item: t("order.code") })}`}
          {...form.getInputProps("code")}
        />
        {/* <Select
                    label="발주상태"
                    placeholder="발주상태"
                    data={[
                        { value: "DRAFT", label: "초안" },
                        { value: "IN_PROGRESS", label: "진행중" },
                        { value: "DONE", label: "완료" },
                        { value: "CANCELED", label: "취소됨" },
                    ]}
                    {...form.getInputProps("status")}
                /> */}
        <UserAutoComplete
          label={t("order.manager")}
          placeholder={t("placeholder.select", { item: t("order.manager") })}
          {...form.getInputProps("userCode")}
        />
        <LocationAutoComplete
          label={t("inventory.toLocation")}
          placeholder={t("placeholder.select", { item: t("inventory.toLocation") })}
          {...form.getInputProps("targetLocationCode")}
        />
        <Create.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={t("order.orderDate")}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("orderDate")}
        />
        <Create.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={t("order.deliveryDate")}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("deliveryDeadline")}
        />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "purchaseOrder",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            {userCode === "FLOUR"
              ? t("common.create", { item: t("purchaseOrder.purchaseOrder") })
              : t("common.create", { item: t("order.order") })}
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
