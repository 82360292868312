import { ScmInstance } from "@/instance/axios";
import { TransportationGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export type TransportationsOption = {
  value: string;
  label: string;
  // group?: string;
};
const getAutoCompleteKeyword = (keyword: string) =>
  ScmInstance.transportationGet({
    search: keyword,
    searchFields: ["code"],
  });

export const useTransportationsAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: TransportationsOption[]) => void;
  },
) => {
  return useQuery(
    ["transportation-get-autocomplete-keyword", keyword],
    () => getAutoCompleteKeyword(keyword),
    {
      enabled: focus,
      select: (data: AxiosResponse<TransportationGet200Response, any>): TransportationsOption[] =>
        data?.data?.rows?.map((transportation) => {
          return {
            value: transportation.id?.toString() ?? "",
            label: transportation.code ?? "",
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    },
  );
};

const getTransportationByCode = (code: string | null) =>
  ScmInstance.transportationGet({
    ...(code && { search: code }),
    searchFields: ["code"],
  });

export const useTransportationCode = (
  enabled: boolean = false,
  code: string | null,
  options?: {
    onSuccess: (data?: TransportationsOption[]) => void;
  },
) => {
  return useQuery(["transportation-get-code", code], () => getTransportationByCode(code), {
    enabled,
    select: (data: AxiosResponse<TransportationGet200Response, any>) =>
      data?.data?.rows?.map((transportation) => {
        return {
          value: transportation?.id?.toString() ?? "",
          label: transportation?.code as string,
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
