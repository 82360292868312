import { useModal } from "@/features/modal/ModalStackManager";
import { Box } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import "dayjs/locale/ko";
import { useState } from "react";

export const DateModal = (params: any) => {
  const [value, setValue] = useState<[Date | any, Date | any]>([null, null]);
  const { closeModal } = useModal();
  const { actions } = params;

  const onChange = (date: Date | any) => {
    actions.setDate(date);
    setValue(date);
    // 2번쨰 날짜가 선택되기 전 까지 모달 닫기 x
    if (date[1] !== null) {
      closeModal(date);
    }
  };

  return (
    <>
      <Box h="auto" w="auto">
        <DatePicker
          type="range"
          allowSingleDateInRange // 동일날짜 선택가능
          value={value}
          onChange={onChange}
          size="md"
          locale="ko"
          monthLabelFormat="MM/YYYY"
        />
      </Box>
    </>
  );
};
