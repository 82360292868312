import { DefaultInstance } from "@/instance/axios";
import { MesOverviewGet200ResponseInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export const GET_OVERVIEW_QUERY_KEY = "/overview";

const getOverviewFetch = (params?: any) => {
  if (params) {
    const query: any = {};
    // query를 보내는 조건은 필터 검색을 할 때이다.
    if (params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    return DefaultInstance.mesOverviewGet({
      ...query,
      sort: params?.sort,
    });
  } else return DefaultInstance.mesOverviewGet({});
};

const useGetOverViewQuery = (params?: any) => {
  return useQuery<AxiosResponse<MesOverviewGet200ResponseInner[] | any>>({
    queryKey: params
      ? [
          GET_OVERVIEW_QUERY_KEY,
          params.activePage,
          JSON.stringify(params.query),
          params.searchFields,
          params.search,
        ]
      : [GET_OVERVIEW_QUERY_KEY],
    queryFn: () => (params ? getOverviewFetch(params) : getOverviewFetch()),
  });
};

export default useGetOverViewQuery;
