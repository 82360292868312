import styled from "@emotion/styled";

interface WorkValueTextProps {
  children: React.ReactNode;
  color?: string;
}

export const WorkValueText = ({ children, color }: WorkValueTextProps) => {
  return <WorkText style={{ color: `${color}` }}>{children}</WorkText>;
};

const WorkText = styled.p`
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.5rem;
  white-space: pre-wrap;
  text-align: center;
`;
