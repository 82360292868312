import { EventEmitter } from "eventemitter3";
import { useCallback, useEffect } from "react";

const emitter = new EventEmitter();

export const useSub = (
  event: string | symbol,
  callback: (...args: any[]) => void,
  dependencies: any[] = [],
) => {
  const memoizedCallback = useCallback(callback, [callback, ...dependencies]);

  useEffect(() => {
    emitter.on(event, memoizedCallback);

    // 클린업 함수로 unsubscribe 처리
    return () => {
      emitter.off(event, memoizedCallback);
    };
  }, [event, memoizedCallback]);

  return () => emitter.off(event, memoizedCallback);
};

export const usePub = () => {
  return (event: string | symbol, data?: any) => {
    emitter.emit(event, data);
  };
};
