import { productionPlans } from "@/api/productionPlan/useProductionPlanQuery";
import { LotTrackingLedgerTrackingContent } from "@/features/lotTracking/components/ledgerTracking";
import { useLotTrackingActions } from "@/features/lotTracking/hook/useLotTrackingActions";
import { useLotTrackingState } from "@/features/lotTracking/hook/useLotTrackingState";
import { LotTrackingReverceItemContent } from "@/features/lotTracking/reverce/components/reverceItem";
import { LotTrackingReverceLedgerTracking } from "@/features/lotTracking/reverce/components/reverceLedgerTracking";
import { LotTrackingReverceSummaryContent } from "@/features/lotTracking/reverce/components/reverceSummary";
import { LotTrackingReverceWorkHistoryContent } from "@/features/lotTracking/reverce/components/reverceWorkHistory";
import { LotTrackingReverceActionHeader } from "@/features/lotTracking/reverce/header/lotReverceActionHeader";
import { Flex, Text, Title } from "@mantine/core";
import { ProductionPlansConsumedLotIdLotIdGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const LotTrackingRevercePage = () => {
  const state = useLotTrackingState();
  const actions = useLotTrackingActions();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    // 필요한 경우 조건 추가
    if (state.itemCodeValue && state.lotId && location.state?.value === "click") {
      actions.setProductionPlan(undefined);
    } else if (state.itemCodeValue || state.lotId || state.productionPlan) {
      actions.setProductionPlan(undefined);
      actions.setLotId(null);
      actions.setItemCodeValue(null);
    }
  }, []);

  const { data: consumedLot } = useQuery({
    ...productionPlans.productionLot({
      lotId: Number(state.lotId),
    }),
    enabled: !!state.lotId,
  });

  return (
    <Flex w="100%" h="100%" direction="column">
      <Flex px="sm">
        <LotTrackingReverceActionHeader />
      </Flex>
      <Flex direction={"column"}>
        <Title p={"sm"} mb={8} order={1}>
          {t("lotTracking.reverceLotTracking")}
        </Title>
        <Text fz={12} p={"sm"}>
          {t("lotTracking.reverceDescription")}
        </Text>
        <LotTrackingReverceItemContent />
        <LotTrackingReverceSummaryContent
          consumedLotData={consumedLot?.data as ProductionPlansConsumedLotIdLotIdGet200Response[]}
        />
        <LotTrackingReverceWorkHistoryContent />
        <LotTrackingLedgerTrackingContent
          consumedLotData={consumedLot?.data as ProductionPlansConsumedLotIdLotIdGet200Response[]}
          reverce={true}
        />
        <LotTrackingReverceLedgerTracking />
        {/* <LotTrackingQualityHistoryContent /> */}
      </Flex>
    </Flex>
  );
};
