import { Box, Button, Flex, Select, Table, TextInput } from "@mantine/core";

// TO BE : 기계 검색 후 검색내용 화면에 바인딩
export const UsersSearchBox = () => {
  return (
    <Box h="auto" w="auto">
      <Flex gap="md" direction={"column"}>
        <Flex gap="md">
          {/* 검색 항목 */}
          <Select
            data={[
              { label: "성명", value: "name" },
              { label: "직책", value: "level" },
              { label: "전화번호", value: "phone" },
              { label: "이메일", value: "email" },
              { label: "권한", value: "authorization" },
            ]}
            w="8rem"
          />
          <TextInput placeholder="검색어를 입력하세요." w="40rem" />
          {/* 검색기능 */}
          <Button>검색</Button>
        </Flex>
        <Table striped withBorder withColumnBorders>
          <thead>
            <tr>
              <th>성명</th>
              <th>직책</th>
              <th>전화번호</th>
              <th>이메일</th>
              <th>권한</th>
            </tr>
          </thead>
          {/* 데이터 받아오기 */}
          <tbody>
            <tr>
              <td>제조사1</td>
              <td>기계1</td>
              <td>기계</td>
              <td>용접</td>
              <td>2022-01-01</td>
            </tr>
          </tbody>
        </Table>
        <Flex gap="sm">
          <Button w="50%" color="gray">
            취소
          </Button>
          {/* 선택 시 데이터 들어가게 */}
          <Button w="50%">선택</Button>
        </Flex>
      </Flex>
    </Box>
  );
};
