import { users } from "@/api/users/useUsersQuery";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { useQuery } from "@tanstack/react-query";
import { UsersDetailHeader } from "../header/UsersDetailHeader";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
});

interface UsersDetailFormProps {
  UserCode: string | undefined;
}

export const UsersDetailForm = (params: UsersDetailFormProps) => {
  const { UserCode } = params;

  const { data: userData } = useQuery(
    users.get({
      query: { $and: [{ code: { $eq: UserCode } }] },
    }),
  );

  return (
    <>
      <Detail.Container>
        <Detail.Wrapper>
          <Detail.Content>
            <UsersDetailHeader data={userData} />
          </Detail.Content>
        </Detail.Wrapper>
      </Detail.Container>
    </>
  );
};
