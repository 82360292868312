import CustomTable from "@/features/ui/table/CustomTable";
import { Button, Checkbox, Flex, Text, TextInput } from "@mantine/core";
import { useState } from "react";

export const MoldProblemPage = () => {
  const [tableData, setTableData] = useState([
    { id: 1, checked: false, 순서: "1", "문제점 유형": "문제점 유형1" },
    { id: 2, checked: false, 순서: "2", "문제점 유형": <TextInput /> },
  ]);

  const handleAddRow = () => {
    const newId = tableData.length + 1;
    setTableData([
      ...tableData,
      { id: newId, checked: false, 순서: `${newId}`, "문제점 유형": <TextInput /> },
    ]);
  };

  const handleDeleteRows = () => {
    const updatedTableData = tableData.filter((row) => !row.checked);
    updatedTableData.forEach((row, index) => {
      row["순서"] = `${index + 1}`;
    });
    setTableData(updatedTableData);
  };

  const handleSelectRow = (id: number) => {
    setTableData((prev) =>
      prev.map((row) => {
        if (row.id === id) {
          return { ...row, checked: !row.checked };
        }
        return row;
      }),
    );
  };

  return (
    <Flex gap="md" direction={"column"}>
      <Flex justify={"space-between"}>
        <Text size="xl">금형 문제 유형 등록</Text>
        <Flex gap="sm">
          <Button onClick={handleAddRow} color="lime">
            행 추가
          </Button>
          <Button>저장하기</Button>
          <Button onClick={handleDeleteRows} color="red">
            행 삭제
          </Button>
        </Flex>
      </Flex>
      <CustomTable
        headers={["", "순서", "문제점 유형"]}
        data={tableData.map((row) => ({
          ...row,
          "": <Checkbox checked={row.checked} onChange={() => handleSelectRow(row.id)} />,
        }))}
        onSelectRow={handleSelectRow}
      />
    </Flex>
  );
};
