import { DailyRegisterButton } from "@/features/PMS/dailyRegister/button";
import { ButtonFormatter } from "@/features/ui/PMS/formatterButton";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useColumnList = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      completeAdmin: [
        { key: "equipment_manufacturer", name: "기계 제조사", width: 120 },
        { key: "equipment_name", name: "기계 이름", width: 120 },
        { key: "equipment_type", name: "기계 종류", width: 120 },
        { key: "weldingType", name: "용접 종류", width: 120 },
        { key: "day", name: "제조 연월일", width: 120 },
        { key: "number", name: "제조 번호", width: 120 },
        { key: "manager", name: "담당자", width: 120 },
        { key: "epuipmentPhoto", name: "기계 사진", width: 120 },
        { key: "specPhoto", name: "스펙 명판 사진", width: 120 },
        { key: "abilityPhoto", name: "능력 명판 사진", width: 120 },
        { key: "guide", name: "사용 설명서", width: 120 },
        { key: "overhaul", name: "오버홀", width: 120 },
        { key: "device", name: "주변 장치", width: 120 },
        { key: "ton", name: "톤수", width: 120 },
        { key: "volt", name: "사용 전압", width: 120 },
        { key: "company", name: "공장", width: 120 },
        { key: "companyDetail", name: "공장 세분화명", width: 120 },
        { key: "item", name: "생산 품목", width: 120 },
        { key: "requestDate", name: "요청날짜", width: 120 },
        { key: "requestPhoto", name: "요청 사진", width: 120 },
        { key: "problemType", name: "문제 유형", width: 120 },
        { key: "requester", name: "요청자", width: 120 },
        { key: "completeRequestDate", name: "완료 요청 날짜", width: 120 },
        { key: "completeDate", name: "완료 날짜", width: 120 },
        { key: "completePhoto", name: "완료 사진", width: 120 },
        { key: "completeContent", name: "완료 내용", width: 120 },
        { key: "repairManager", name: "수리 담당자", width: 120 },
        { key: "statusChange", name: "상태 변경", width: 120 },
      ],

      pressDaily_status: [
        { key: "name", name: "기계 이름", width: 120 },
        { key: "mfrCode", name: "제조 번호(필수)", width: 120 },
        // 일일현황표
        { key: "check1", name: "01", width: 40 },
        { key: "check2", name: "02", width: 40 },
        { key: "check3", name: "03", width: 40 },
        { key: "check4", name: "04", width: 40 },
        { key: "check5", name: "05", width: 40 },
        { key: "check6", name: "06", width: 40 },
        { key: "check7", name: "07", width: 40 },
        { key: "check8", name: "08", width: 40 },
        { key: "check9", name: "09", width: 40 },
        { key: "check10", name: "10", width: 40 },
        { key: "check11", name: "11", width: 40 },
        { key: "check12", name: "12", width: 40 },
        { key: "check13", name: "13", width: 40 },
        { key: "check14", name: "14", width: 40 },
        { key: "check15", name: "15", width: 40 },
        { key: "check16", name: "16", width: 40 },
        { key: "check17", name: "17", width: 40 },
        { key: "check18", name: "18", width: 40 },
        { key: "check19", name: "19", width: 40 },
        { key: "check20", name: "20", width: 40 },
        { key: "check21", name: "21", width: 40 },
        { key: "check22", name: "22", width: 40 },
        { key: "check23", name: "23", width: 40 },
        { key: "check24", name: "24", width: 40 },
        { key: "check25", name: "25", width: 40 },
        { key: "check26", name: "26", width: 40 },
        { key: "check27", name: "27", width: 40 },
        { key: "check28", name: "28", width: 40 },
        { key: "check29", name: "29", width: 40 },
        { key: "check30", name: "30", width: 40 },
        { key: "check31", name: "31", width: 40 },
      ],

      pressDaily_register: [
        { key: "equipment_manufacturer", name: "기계 제조사", width: 120 },
        { key: "equipment_name", name: "기계 이름", width: 120 },
        { key: "equipment_type", name: "기계 종류", width: 120 },
        { key: "day", name: "제조 연월일", width: 120 },
        { key: "number", name: "제조 번호(필수)", width: 120 },
        { key: "manager", name: "담당자", width: 220 },
        { key: "daily_register", name: "일상점검", width: 220 },
      ],

      leadTimeItemTable: [
        { key: "productionPlanId", name: t("productionPlan.id") },
        { key: "scheduledAt", name: t("kpi.scheduledAt") },
        { key: "itemCode", name: t("item.code") },
        { key: "workStartTime", name: t("kpi.workStartTime") },
        { key: "workEndTime", name: t("kpi.workEndTime") },
        { key: "workerName", name: t("kpi.worker") },
        { key: "leadTime", name: t("kpi.leadtime.leadtimeHour") },
      ],

      manhourTable: [
        { key: "id", name: t("work.id") },
        { key: "productionPlanId", name: t("productionPlan.id") },
        { key: "itemCode", name: t("item.code") },
        { key: "operationName", name: t("operation.name") },
        { key: "equipmentName", name: t("equipment.name") },
        { key: "workStartTime", name: t("kpi.workStartTime") },
        { key: "workEndTime", name: t("kpi.workEndTime") },
        { key: "pauseDuration", name: t("kpi.pauseDuration") },
        { key: "totalQuantity", name: t("kpi.totalQuantity") },
        { key: "goodQuantity", name: t("kpi.goodQuantity"), cellClass: "right-align" },
        { key: "defectQuantity", name: t("kpi.defectQuantity"), cellClass: "right-align" },
        { key: "workerName", name: t("kpi.worker") },
        { key: "manHourCost", name: t("kpi.manhour.manhourHour") },
      ],
      defectTable: [
        { key: "id", name: t("work.id") },
        { key: "productionPlanId", name: t("productionPlan.id") },
        { key: "itemCode", name: t("item.code") },
        { key: "operationName", name: t("operation.name") },
        { key: "equipmentName", name: t("equipment.name") },
        { key: "workStartTime", name: t("kpi.workStartTime") },
        { key: "workEndTime", name: t("kpi.workEndTime") },
        { key: "pauseDuration", name: t("kpi.pauseDuration") },
        { key: "totalQuantity", name: t("kpi.totalQuantity"), cellClass: "right-align" },
        { key: "goodQuantity", name: t("kpi.goodQuantity"), cellClass: "right-align" },
        { key: "defectQuantity", name: t("kpi.defectQuantity"), cellClass: "right-align" },
        { key: "workerName", name: t("kpi.worker") },
        { key: "defectRate", name: t("kpi.defect.defectRate") },
      ],
      leadTimeOrderTable: [
        { key: "purchaseOrderId", name: t("purchaseOrder.id") },
        { key: "contractor", name: t("kpi.leadtimeOrder.contracotr") },
        { key: "orderDate", name: t("kpi.leadtimeOrder.orderDate") },
        { key: "deadline", name: t("kpi.leadtimeOrder.deadline") },
        { key: "firstDeliveryTime", name: t("kpi.leadtimeOrder.firstDeliveryTime") },
        {
          key: "orderedQuantity",
          name: t("kpi.leadtimeOrder.orderedQuantity"),
          cellClass: "right-align",
        },
        {
          key: "deliveredQuantity",
          name: t("kpi.leadtimeOrder.deliveredQuantity"),
          cellClass: "right-align",
        },
        { key: "leadTime", name: t("kpi.leadtimeOrder.leadtimeOrderDay") },
      ],
      powerUsageTable: [
        { key: "id", name: t("work.id") },
        { key: "productionPlanId", name: t("productionPlan.id") },
        // { key: 'scheduledAt', name: '작업 예정일'},
        { key: "operationName", name: t("operation.name") },
        { key: "equipmentName", name: t("equipment.name") },
        { key: "equipmentVolt", name: t("kpi.powerUsage.volt") },
        { key: "itemCode", name: t("item.code") },
        { key: "workStartTime", name: t("kpi.workStartTime") },
        { key: "workEndTime", name: t("kpi.workEndTime") },
        { key: "pauseDuration", name: t("kpi.pauseDuration") },
        { key: "totalQuantity", name: t("kpi.totalQuantity"), cellClass: "right-align" },
        { key: "goodQuantity", name: t("kpi.goodQuantity"), cellClass: "right-align" },
        { key: "defectQuantity", name: t("kpi.defectQuantity"), cellClass: "right-align" },
        // { key: 'createdAt', name: '생성일'},
        // { key: 'updatedAt', name: '수정일'},
        { key: "workerName", name: t("kpi.worker") },
        { key: "electricPower", name: t("kpi.powerUsage.powerUsageUnit") },
      ],
      uphTable: [
        // { ...SelectColumn,
        //   width: 70,
        //   maxWidth: 500,
        //   resizable: true,
        //   headerCellClass: css`
        //     & > * {
        //       justify-content: flex-start;
        //       padding-left: 24px;
        //     }
        //   `,},
        { key: "id", name: t("work.id"), resizable: true },
        { key: "productionPlanId", name: t("productionPlan.id") },
        { key: "itemCode", name: t("item.code") },
        { key: "operationName", name: t("operation.name") },
        { key: "equipmentName", name: t("equipment.name") },
        { key: "workStartTime", name: t("kpi.workStartTime") },
        { key: "workEndTime", name: t("kpi.workEndTime") },
        { key: "pauseDuration", name: t("kpi.pauseDuration") },
        { key: "totalQuantity", name: t("kpi.totalQuantity"), cellClass: "right-align" },
        { key: "goodQuantity", name: t("kpi.goodQuantity"), cellClass: "right-align" },
        { key: "defectQuantity", name: t("kpi.defectQuantity"), cellClass: "right-align" },
        { key: "workerName", name: t("kpi.worker") },
        { key: "uph", name: t("kpi.uph.uph") },
      ],
      rateOfoperationTable: [
        { key: "id", name: t("work.id") },
        { key: "productionPlanId", name: t("productionPlan.id") },
        // { key: 'scheduledAt', name: '작업 예정일'},
        { key: "operationName", name: t("operation.name") },
        { key: "equipmentName", name: t("equipment.name") },
        // { key: 'influxEquipmentCode', name: '인플럭스 코드'},
        // { key: 'itemName', name: '품목 명'},
        // { key: 'itemSpec', name: '규격'},
        { key: "itemCode", name: t("item.code") },
        { key: "itemStandardUph", name: t("item.standardUph") },
        { key: "workStartTime", name: t("kpi.workStartTime") },
        { key: "workEndTime", name: t("kpi.workEndTime") },
        { key: "pauseDuration", name: t("kpi.pauseDuration") },
        { key: "totalQuantity", name: t("kpi.totalQuantity"), cellClass: "right-align" },
        { key: "goodQuantity", name: t("kpi.goodQuantity"), cellClass: "right-align" },
        { key: "defectQuantity", name: t("kpi.defectQuantity"), cellClass: "right-align" },
        // { key: 'createdAt', name: '생성일'},
        // { key: 'updatedAt', name: '수정일'},
        { key: "workerName", name: t("kpi.worker") },
        { key: "capacityUtilization", name: t("kpi.rateOperation.rateOperationUnit") },
      ],

      moldDailyRegisterTable: [
        { key: "code", name: "CODE", width: 120 },
        { key: "name", name: "금형명", width: 140 },
        {
          key: "register",
          name: "일상점검",
          width: 880,
          formatter: DailyRegisterButton,
          style: { width: 792, height: 32, backgroundColor: "#19B9DF" },
        },
      ],
      moldDaily_status: [
        { key: "code", name: "CODE", width: 120, frozen: true },
        { key: "name", name: "금형명", width: 140, frozen: true },
        { key: "check1", name: "01", width: 40 },
        { key: "check2", name: "02", width: 40 },
        { key: "check3", name: "03", width: 40 },
        { key: "check4", name: "04", width: 40 },
        { key: "check5", name: "05", width: 40 },
        { key: "check6", name: "06", width: 40 },
        { key: "check7", name: "07", width: 40 },
        { key: "check8", name: "08", width: 40 },
        { key: "check9", name: "09", width: 40 },
        { key: "check10", name: "10", width: 40 },
        { key: "check11", name: "11", width: 40 },
        { key: "check12", name: "12", width: 40 },
        { key: "check13", name: "13", width: 40 },
        { key: "check14", name: "14", width: 40 },
        { key: "check15", name: "15", width: 40 },
        { key: "check16", name: "16", width: 40 },
        { key: "check17", name: "17", width: 40 },
        { key: "check18", name: "18", width: 40 },
        { key: "check19", name: "19", width: 40 },
        { key: "check20", name: "20", width: 40 },
        { key: "check21", name: "21", width: 40 },
        { key: "check22", name: "22", width: 40 },
        { key: "check23", name: "23", width: 40 },
        { key: "check24", name: "24", width: 40 },
        { key: "check25", name: "25", width: 40 },
        { key: "check26", name: "26", width: 40 },
        { key: "check27", name: "27", width: 40 },
        { key: "check28", name: "28", width: 40 },
        { key: "check29", name: "29", width: 40 },
        { key: "check30", name: "30", width: 40 },
        { key: "check31", name: "31", width: 40 },
      ],
      moldMaintenance: [
        { key: "code", name: "CODE", width: 120 },
        { key: "name", name: "금형명", width: 140 },
        { key: "cavity", name: "캐비티", width: 100 },
        { key: "spm", name: "SPM", width: 100 },
        { key: "slideLocation", name: "슬라이드 위치", width: 140 },
        { key: "maxBats", name: "최대 타수", width: 120 },
        { key: "inspectionBats", name: "점검 타수", width: 120 },
        { key: "currentBats", name: "현재 타수", width: 120 },
        { key: "dutyCycle", name: "사용률", width: 120 },
        {
          key: "repairRegister",
          name: "수리 등록",
          width: 120,
          formatter: ButtonFormatter,
          buttonText: "금형 수리 등록",
        },
        {
          key: "inspectionAlert",
          name: "점검 알림",
          width: 120,
          formatter: ButtonFormatter,
          buttonText: "수리 등록",
        },
      ],

      moldCompleteAdmin: [
        { key: "code", name: "금형코드", width: 120, frozen: true },
        { key: "name", name: "금형명", width: 140, frozen: true },
        { key: "cavity", name: "캐비티", width: 100 },
        { key: "spm", name: "SPM", width: 100 },
        { key: "slideLocation", name: "슬라이드 위치", width: 140 },
        { key: "maxBats", name: "최대 타수", width: 120 },
        { key: "inspectionBats", name: "점검 타수", width: 120 },
        { key: "currentBats", name: "현재 타수", width: 120 },
        { key: "order", name: "우선순위", width: 120 },
        { key: "requestData", name: "요청 날짜 (필수)", width: 140 },
        { key: "requestInfo", name: "요청 사진", width: 120 },
        { key: "questionType", name: "문제 유형(필수)", width: 120 },
        { key: "requestUser", name: "요청자", width: 120 },
        { key: "requestCompleteDate", name: "완료 요청 날짜(필수)", width: 140 },
        { key: "completeDate", name: "완료 날짜(필수)", width: 140 },
        {
          key: "completeData",
          name: "완료 사진",
          width: 120,
          formatter: ButtonFormatter,
          buttonText: "파일 첨부하기",
        },
        { key: "completeContent", name: "완료 내용", width: 120 },
        { key: "repairUser", name: "수리 담당자", width: 120 },
        {
          key: "statusChanger",
          name: "상태 변경",
          width: 120,
          formatter: ButtonFormatter,
          buttonText: "완료하기",
        },
      ],

      qualityWorkstandard: [
        { key: "code", name: "품목코드" },
        { key: "name", name: "품목명" },
        { key: "spec", name: "규격" },
        { key: "type", name: "품목 종류" },
        { key: "workStandard", name: "작업 표준서" },
      ],
      qualityChangeList: [
        { key: "itemCode", name: "품목코드" },
        { key: "itemName", name: "품목명" },
        { key: "itemSpec", name: "규격" },
        { key: "title", name: "제목", width: 300 },
        { key: "createdAt", name: "등록 날짜" },
        { key: "creatorUserName", name: "작성자" },
      ],
      preWarehouseList: [
        { key: "itemCode", name: "품목코드" },
        { key: "lotId", name: "로트아이디" },
        { key: "lotName", name: "로트명" },
        { key: "createdAt", name: "등록 날짜" },
        { key: "option", name: "옵션", formatter: ButtonFormatter, buttonText: "수입검사" },
      ],
    }),
    [t],
  );
};

export default useColumnList;
