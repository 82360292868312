import styled from "@emotion/styled";

export type WorkTableActionBarProps = {
  children: React.ReactNode;
};

export const WorkTableActionBar = ({ children }: WorkTableActionBarProps) => {
  return <WorkTableActionBarContainer>{children}</WorkTableActionBarContainer>;
};

const WorkTableActionBarContainer = styled.div`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`;
