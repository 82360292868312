import { useQuery } from "@tanstack/react-query";

import { DefaultInstance } from "@/instance/axios";
import {
  DowntimeReasonsGet200Response,
  DowntimeReasonsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";

export type DowntimeReasonsOption = {
  value: string;
  label: string;
};

export const useDowntimeReasonsMultiAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: DowntimeReasonsOption[]) => void;
  },
) => {
  return useQuery(
    ["downtimeReasons`-get-multi-autocomplete-keyword", keyword],
    () =>
      DefaultInstance.downtimeReasonsFindPost({
        search: keyword,
        searchFields: ["code", "name"],
      }).then((res) => res.data),
    {
      enabled: focus,
      select: (data: DowntimeReasonsGet200ResponseRowsInner[]): DowntimeReasonsOption[] =>
        data.map((downtimeReason) => {
          return {
            value: String(downtimeReason.code),
            label: String(downtimeReason.name),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    },
  );
};

const getDowntimeReasonsByCode = (code: string | null) =>
  DefaultInstance.downtimeReasonsGet({
    ...(code && { search: code }),
    searchFields: ["code"],
  }).then((res) => res.data);

export const useDowntimeReasonsMultiCode = (
  enabled: boolean = false,
  code: string | null,
  options?: {
    onSuccess: (data?: DowntimeReasonsOption[]) => void;
  },
) => {
  return useQuery(["downtimeReasons-multi-get-code", code], () => getDowntimeReasonsByCode(code), {
    enabled,
    select: (data: DowntimeReasonsGet200Response) =>
      data?.rows?.map((DowntimeReason) => {
        return {
          value: DowntimeReason.code as string,
          label: DowntimeReason.name as string,
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
