import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Space } from "@mantine/core";
import { ItemCategoriesGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

const DetailItemCategory = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
});

interface AbstractItemDetailHeaderProps {
  data: AxiosResponse<ItemCategoriesGet200Response, any[]> | undefined;
}

export const ItemCategoryDetailHeader = (params: AbstractItemDetailHeaderProps) => {
  const { data } = params;
  const { t } = useTranslation();
  const abstractItem = data?.data?.rows && data?.data?.rows[0];

  return (
    <DetailItemCategory>
      <DetailItemCategory.Title>
        {`[${t("common.detail", { item: `${t("category.category")}` })}]`}
      </DetailItemCategory.Title>
      <Space h={8} />
      <DetailItemCategory.Field>
        <DetailItemCategory.Label>{t("category.name")}</DetailItemCategory.Label>
        <DetailItemCategory.Content>{abstractItem?.name}</DetailItemCategory.Content>
      </DetailItemCategory.Field>
    </DetailItemCategory>
  );
};
