import { mutatePurchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { PurchaseOrderAutoComplete } from "@/features/ui/autoComplete/purchaseOrder/purchaseOrders-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { getUserCodeByUser } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
  ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

interface PurchaseOrderUpdateFormProps {
  purchaseOrderId?: number;
  formatterProps: AuthSignupPost201ResponseEquipmentPurchaseOrderItem;
}

export const PurchaseOrderItemsUpdateForm = (props: PurchaseOrderUpdateFormProps) => {
  const { purchaseOrderId, formatterProps } = props;
  const userCode = getUserCodeByUser();
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: createMutate } = useMutation(
    (params: ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest) =>
      mutatePurchaseOrderItems
        .update(params)
        .mutationFn(params as ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["purchaseOrderItems"]);
        closeModal(form.values);
        customAlert(
          `${userCode === "PB" ? t("message.editSuccess", { item: t("orderItems.orderItems") }) : t("message.editSuccess", { item: t("purchaseOrderItems.purchaseOrderItems") })}`,
          t("common.editSuccess", { item: `${t("purchaseOrderItems.purchaseOrderItems")}` }),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({
            error,
            errorContext: `${userCode === "PB" ? t("orderItems.orderItems") : t("purchaseOrderItems.purchaseOrderItems")}`,
          }),
          `${userCode === "PB" ? t("common.editFail", { item: t("orderItems.orderItems") }) : t("common.editFail", { item: t("purchaseOrderItems.purchaseOrderItems") })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      partnerCode: formatterProps.partnerCode,
      itemCode: formatterProps.itemCode,
      quantity: formatterProps.quantity,
      orderDate: formatterProps.orderDate ? new Date(formatterProps.orderDate) : undefined,
      deliveryDeadline: formatterProps.deliveryDeadline
        ? new Date(formatterProps.deliveryDeadline)
        : undefined,
      userCode: formatterProps.userCode,
      sourceLocationCode: formatterProps.sourceLocationCode,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        purchaseOrderItemId: formatterProps.id ?? 0,
        purchaseOrderItemsGetRequest: {
          purchaseOrderId: formatterProps.purchaseOrderId,
          partnerCode: form.values.partnerCode!,
          direction: "INBOUND",
          itemCode: form.values.itemCode!,
          quantity: form.values.quantity!,
          orderDate: form.values.orderDate?.toDateString()!,
          deliveryDeadline: form.values.deliveryDeadline!?.toDateString(),
          userCode: form.values.userCode!,
          sourceLocationCode: form.values.sourceLocationCode!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({
          error,
          errorContext: `${userCode === "PB" ? t("orderItems.orderItems") : t("purchaseOrderItems.purchaseOrderItems")}`,
        }),
        `${userCode === "PB" ? t("common.editFail", { item: t("orderItems.orderItems") }) : t("common.editFail", { item: t("purchaseOrderItems.purchaseOrderItems") })}`,
        "red",
      );
    }
  };

  return (
    <>
      <Update.Wrapper>
        <PurchaseOrderAutoComplete
          label={`${userCode === "PB" ? t("order.code") : t("purchaseOrder.code")}`}
          placeholder={`${userCode === "PB" ? t("placeholder.select", { item: t("order.code") }) : t("placeholder.select", { item: t("purchaseOrder.code") })}`}
          {...form.getInputProps("purchaseOrderId")}
          defaultValue={formatterProps.purchaseOrderCode}
          error={form.isDirty("purchaseOrderId") ? t("common.toSave") : ""}
          direction="INBOUND"
        />
        <PartnerAutoComplete
          label={t("partner.partner")}
          placeholder={t("placeholder.select", { item: t("partner.partner") })}
          defaultValue={formatterProps.partnerCode}
          {...form.getInputProps("partnerCode")}
          withAsterisk
          error={form.isDirty("partnerCode") ? t("common.toSave") : ""}
        />
        <ItemAutoComplete
          label={t("item.code")}
          placeholder={t("placeholder.select", { item: t("item.code") })}
          {...form.getInputProps("itemCode")}
          defaultValue={formatterProps.itemCode}
          error={form.isDirty("itemCode") ? t("common.toSave") : ""}
        />
        <Update.Input
          label={t("inventory.quantity")}
          placeholder={t("placeholder.input", { item: t("inventory.quantity") })}
          {...form.getInputProps("quantity")}
          defaultValue={formatterProps.quantity}
          error={form.isDirty("quantity") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("quantity") ? "red !important" : "",
              borderColor: form.isDirty("quantity") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <UserAutoComplete
          label={t("order.manager")}
          placeholder={t("placeholder.select", { item: t("order.manager") })}
          {...form.getInputProps("userCode")}
          defaultValue={formatterProps.userCode}
          error={form.isDirty("userCode") ? t("common.toSave") : ""}
        />
        <LocationAutoComplete
          label={t("inventory.fromLocation")}
          placeholder={t("placeholder.select", { item: t("inventory.fromLocation") })}
          {...form.getInputProps("sourceLocationCode")}
          defaultValue={formatterProps.sourceLocationCode}
          error={form.isDirty("sourceLocationCode") ? t("common.toSave") : ""}
        />
        <Update.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={t("purchaseOrder.purchaseOrderDate")}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("orderDate")}
          error={form.isDirty("orderDate") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("orderDate") ? "red !important" : "",
              borderColor: form.isDirty("orderDate") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Update.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={t("order.deliveryDate")}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("deliveryDeadline")}
          error={form.isDirty("deliveryDeadline") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("deliveryDeadline") ? "red !important" : "",
              borderColor: form.isDirty("deliveryDeadline") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Update.Button>
          <Update.Button
            disabled={validateFormValues({
              pageType: "purchaseOrderItems",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            {userCode === "PB"
              ? t("common.edit", { item: t("orderItems.orderItems") })
              : t("common.edit", { item: t("purchaseOrderItems.purchaseOrderItems") })}
          </Update.Button>
        </Update.ButtonBox>
      </Update.Wrapper>
    </>
  );
};
