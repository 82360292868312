import { MasterInstance } from "@/instance/axios";
import { GradesGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export type GradesOption = {
  value: string;
  label: string;
  // group?: string;
};
const getAutoCompleteKeyword = (keyword: string) =>
  MasterInstance.gradesGet({
    search: keyword,
    searchFields: ["name", "code"],
  });

export const useGradesAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: GradesOption[]) => void;
  },
) => {
  return useQuery(
    ["grade-get-autocomplete-keyword", keyword],
    () => getAutoCompleteKeyword(keyword),
    {
      enabled: focus,
      select: (data: AxiosResponse<GradesGet200Response, any>) =>
        data?.data?.rows?.map((grade) => {
          return {
            value: grade.code as string,
            label: grade.name as string,
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    },
  );
};

const getGradeByCode = (code: string | null) =>
  MasterInstance.gradesGet({
    ...(code && { search: code }),
    searchFields: ["code"],
  });

export const useGradeCode = (
  enabled: boolean = false,
  code: string | null,
  options?: {
    onSuccess: (data?: GradesOption[]) => void;
  },
) => {
  return useQuery(["grade-get-code", code], () => getGradeByCode(code), {
    enabled,
    select: (data: AxiosResponse<GradesGet200Response, any>) =>
      data?.data?.rows?.map((grade) => {
        return {
          value: grade?.code as string,
          label: grade?.name as string,
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
