import styled from "@emotion/styled";

interface DetailContainerProps {
  children: React.ReactNode;
}

export const DetailContainer = ({ children }: DetailContainerProps) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  width: 75rem;
  overflow: auto;
`;
