import { InvoiceStatementDetailForm } from "@/features/invoiceStatement/detail/detailForm";
import { InvoiceSearchBox } from "@/features/invoiceStatement/invoiceSearchBox";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { invoiceTypeColor, setInvoiceStatus } from "@/utils/invoiceStatus";
import { purchaseOrderStatusColor, setPurchaseOrderStatus } from "@/utils/scmDataStatus";
import timeUtil from "@/utils/timeUtil";
import { Avatar, Badge, Flex, Text, Tooltip } from "@mantine/core";
import {
  InvoicesGet200Response,
  InvoicesGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconFilterPlus } from "@tabler/icons-react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const InvoiceStatementCompletePage = () => {
  const { openModal } = useModal();
  const { t } = useTranslation();

  const columns: readonly Column<InvoicesGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
    },
    {
      key: "id",
      name: t("invoice.id"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Flex h="100%" justify="flex-end" align="center">
            <LinkAnchor
              onClick={() =>
                openModal(
                  <InvoiceStatementDetailForm invoiceId={formatterProps.row.id} />,
                  null,
                  `${t("common.detail", { item: `${t("invoice.invoice")}` })}`,
                )
              }
            >
              {formatterProps.row.id}
            </LinkAnchor>
          </Flex>
        );
      },
    },
    {
      key: "code",
      name: t("invoice.code"),
      sortable: true,
      resizable: true,
    },
    {
      key: "invoiceType",
      name: t("invoice.type"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Badge
            variant="filled"
            color={invoiceTypeColor[row.invoiceType as keyof typeof invoiceTypeColor]}
          >
            {setInvoiceStatus(row.invoiceType ?? "")}
          </Badge>
        );
      },
    },
    {
      key: "status",
      name: t("invoice.status"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Badge
            variant="filled"
            color={
              purchaseOrderStatusColor[
                formatterProps.row.status as keyof typeof purchaseOrderStatusColor
              ]
            }
          >
            {setPurchaseOrderStatus(formatterProps.row.status ?? "")}
          </Badge>
        );
      },
    },
    {
      key: "numberOfInvoiceItems",
      name: t("invoice.numberOfInvoice"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const numberOfInvoiceItems = row?.numberOfInvoiceItems ?? 0;
        return <Flex justify="flex-end">{numberOfInvoiceItems}</Flex>;
      },
    },
    {
      key: "locationName",
      name: t("invoice.fromLocationName"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={formatterProps?.row?.locationCode} />,
                null,
                `${t("common.detail", { item: `${t("location.location")}` })}`,
              )
            }
          >
            {formatterProps.row.locationName}
          </LinkAnchor>
        );
      },
    },
    {
      key: "invoiceDate",
      name: t("invoice.invoiceDate"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <div>
            {formatterProps.row.invoiceDate !== null
              ? timeUtil(formatterProps.row.invoiceDate as string)
              : ""}
          </div>
        );
      },
    },
    {
      key: "createdAt",
      name: t("invoice.createdAt"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <div>
            {formatterProps.row.createdAt !== null
              ? DatetimeUtil(formatterProps.row.createdAt as string)
              : ""}
          </div>
        );
      },
    },
    {
      key: "creatorUserName",
      name: t("invoice.creatorUser"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <UsersDetailForm UserCode={formatterProps.row?.creatorUserCode} />,
                null,
                `${t("common.detail", { item: `${t("user.user")}` })}`,
              )
            }
          >
            {formatterProps.row.creatorUserName}
          </LinkAnchor>
        );
      },
    },
  ];

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column" mt="sm">
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("invoice.invoiceComplete")}
        </Text>
        <Text fz="xs">{t("invoice.subTitle")}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <InvoiceSearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<InvoicesGet200Response, InvoicesGet200ResponseRowsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<InvoicesGet200Response> />
      </Flex>
    </Flex>
  );
};
