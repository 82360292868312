import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface QueryStringState {
  page: number;
  pageSize: string;
  search: string;
  searchFields: string[];
  query: string;
  sort: string[];
}

interface QueryDefaultValuesTypeValue {
  page: number;
  pageSize: string;
  query: Record<string, any>;
  search: string;
  searchFields: string[];
  sort: string[];
}

const useUpdateUrlParams = () => {
  const navigate = useNavigate();

  const updateUrlParams = useCallback(
    (queryString: QueryStringState, initialValues: QueryDefaultValuesTypeValue) => {
      const queryParams: Record<string, string> = {};

      const addParamIfChanged = (key: keyof QueryStringState) => {
        const initialValue = initialValues[key as keyof QueryDefaultValuesTypeValue];
        const currentValue = queryString[key];

        if (key === "query") {
          if (currentValue !== JSON.stringify(initialValue)) {
            queryParams[key] = Array.isArray(currentValue)
              ? currentValue.join(",")
              : currentValue.toString();
          }
        } else {
          if (JSON.stringify(currentValue) !== JSON.stringify(initialValue)) {
            queryParams[key] = Array.isArray(currentValue)
              ? currentValue.join(",")
              : currentValue.toString();
          }
        }
      };

      addParamIfChanged("page");
      addParamIfChanged("pageSize");
      addParamIfChanged("search");
      addParamIfChanged("searchFields");
      addParamIfChanged("query");
      addParamIfChanged("sort");

      navigate({
        search: new URLSearchParams(queryParams).toString(),
      });
    },
    [navigate],
  );

  return updateUrlParams;
};

export default useUpdateUrlParams;
