import DefaultInstance from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  DefaultApiLedgersFindPostRequest,
  DefaultApiLedgersGetRequest,
  DefaultApiLedgersLedgerIdDeleteRequest,
  DefaultApiLedgersLedgerIdGetRequest,
  DefaultApiLedgersLedgerIdPutRequest,
  DefaultApiLedgersPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const ledgers = createQueryKeys("ledgers", {
  all: null,
  get: (params: DefaultApiLedgersGetRequest) => {
    // const queryParams = { ...params };
    const queryParams = {
      ...params,
      query: JSON.stringify({ $and: params?.query }),
      sort: params.sort?.length ? params.sort : "-id",
    }; // 전체 입출고 내역 조회 시 기본 정렬 표시

    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => DefaultInstance.ledgersGet(queryParams),
    };
    // if (params.query.createdAt.$between[0] !== null) {

    //     const [beforeDate, afterDate] = params.query.createdAt.$between;

    //     const before = dayjs(
    //         dayjs(new Date(beforeDate)).format("YYYY-MM-DD")
    //     ).toISOString();

    //     const after = afterDate
    //         ? dayjs(afterDate).add(1, "day").subtract(1, "seconds").toISOString()
    //         : dayjs(before).add(1, "day").subtract(1, "seconds").toISOString();

    //     const date = after ? [before, after] : [before, before];

    //     query["query"] = JSON.stringify({
    //         $and: [],
    //         createdAt: {
    //             $between: date,
    //         },
    //     });
    // }
  },
  detail: (params: DefaultApiLedgersLedgerIdGetRequest) => {
    return {
      queryKey: [params.ledgerId],
      queryFn: () => DefaultInstance.ledgersLedgerIdGet(params),
    };
  },
  find: (params: DefaultApiLedgersFindPostRequest) => {
    const queryParams = {
      ...params,
      query: JSON.stringify({ $and: params?.query }),
      sort: params.sort?.length ? params.sort : "-id",
    };

    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => DefaultInstance.ledgersFindPost(queryParams),
    };
  },
});

export const mutateLedgers = createMutationKeys("ledgers", {
  create: (params: DefaultApiLedgersPostRequest) => ({
    mutationKey: [params.ledgersGetRequest],
    mutationFn: () => DefaultInstance.ledgersPost(params),
  }),
  update: (params: DefaultApiLedgersLedgerIdPutRequest) => ({
    mutationKey: [params.ledgerId, params.ledgersGetRequest],
    mutationFn: () => DefaultInstance.ledgersLedgerIdPut(params),
  }),
  delete: (params: DefaultApiLedgersLedgerIdDeleteRequest) => ({
    mutationKey: [params.ledgerId],
    mutationFn: () => DefaultInstance.ledgersLedgerIdDelete(params),
  }),
});

export const ledgersQuery = mergeQueryKeys(ledgers, mutateLedgers);
