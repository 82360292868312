import { MasterInstance } from "@/instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiWlWithEslViewGetRequest } from "@sizlcorp/sizl-api-document/dist/models";

const WIWITHESL_KEY = "wiWithEslView";

export const wiWithEsl = createQueryKeys(WIWITHESL_KEY, {
  all: null,
  get: (params: MasterApiWlWithEslViewGetRequest) => {
    const queryParams = {
      ...params,
      query: JSON.stringify({ $and: params?.query }),
      sort: params.sort?.length ? params.sort : "-id",
    };
    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => MasterInstance.wlWithEslViewGet(queryParams),
    };
  },
});
