import DefaultInstance from "@/instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  DefaultApiSplitLotFindPostRequest,
  DefaultApiSplitLotGetRequest,
  DefaultApiSplitLotWorkLogIdGetRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const productionPerformances = createQueryKeys("productionPerformance", {
  all: null,
  get: (params: DefaultApiSplitLotGetRequest) => {
    const query = { ...params }; // params 객체를 복사합니다.
    if (Array.isArray(params?.query?.$and) && params?.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params?.query);
    }
    // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    if (params?.search) {
      query["search"] = params?.search;
      query["searchFields"] = params?.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.splitLotGet(query),
    };
  },
  detail: (params: DefaultApiSplitLotWorkLogIdGetRequest) => ({
    queryKey: [params],
    queryFn: () => DefaultInstance.splitLotWorkLogIdGet(params),
  }),
  find: (params: DefaultApiSplitLotFindPostRequest) => ({
    queryKey: [params],
    queryFn: () => DefaultInstance.splitLotFindPost(params),
  }),
});
