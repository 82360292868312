import styled from "@emotion/styled";
import React from "react";
import { FrequentAlreadyHaveIsVisible } from "./Frequently";
import FrequentlyLabel from "./FrequentlyLabel";

interface Props {
  list: FrequentAlreadyHaveIsVisible[];
  selected?: FrequentAlreadyHaveIsVisible[] | undefined;
  onChange: (machine: FrequentAlreadyHaveIsVisible) => void;
}

const DropdownContainer = styled.div(() => ({
  display: "flex",
  backgroundColor: "#111319",
  cursor: "pointer",
  padding: 16,
  width: "100%",
}));

const ArrowContainer = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 30,
  backgroundColor: "#111319",
  height: 56,
}));

const Box = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 144,
  height: 56,
  backgroundColor: "#353B48",
  "&:hover": {
    backgroundColor: "#464D5D",
  },
  marginRight: 16,
}));

const SELECTED_COLOR = [
  "#19B9DF",
  "#F3E2A9",
  "#F9CDAC",
  "#F6CEE3",
  "#F3A8A1",
  "#ED8495",
  "#E8608A",
  "#CD4A89",
  "#A73B8E",
  "#822C94",
  "#63218F",
  "#521B75",
  "#40155B",
  "#330033",
  "#330066",
  "#000066",
  "#000033",
];

const FrequentlyRowItemList: React.FunctionComponent<Props> = ({ list, selected, onChange }) => {
  const [allSelect, setAllSelect] = React.useState<boolean>(false);

  const allSelectGrid = React.useCallback(() => {
    if (list.length > 1) {
      return (
        <Box
          style={{ backgroundColor: allSelect ? SELECTED_COLOR[0] : undefined }}
          onClick={() => {
            setAllSelect(!allSelect);
            onChange({
              name: "",
              pk: "all",
              isVisible: allSelect,
            });
          }}
        >
          <FrequentlyLabel text={"모두 보기"} size={16} textAlign={"center"} />
        </Box>
      );
    }
  }, [list, allSelect]);

  const onSelectInfo = (target: any) => {
    onChange(target);
  };

  const indexFilter = React.useCallback((index: number) => {
    if (index === 0) {
      return {
        marginLeft: 8,
      };
    } else if (index === list.length - 1) {
      return {
        marginRight: 8,
      };
    }
  }, []);

  const selectedColorPicker = React.useCallback(
    (index: number) => {
      const find =
        selected &&
        selected.find((selectedPressInformation) => selectedPressInformation.pk === list[index].pk);

      return (
        find && {
          backgroundColor: SELECTED_COLOR[index + 1],
        }
      );
    },
    [list, selected],
  );

  return (
    <React.Fragment>
      <DropdownContainer>
        <ArrowContainer
          style={{
            position: "relative",
            left: 0,
          }}
        >
          <FrequentlyLabel
            text={"<"}
            size={"16"}
            weight={"bold"}
            containerStyles={{ cursor: "pointer" }}
          />
        </ArrowContainer>
        {/* {allSelectGrid()} */}
        {list.map((item, index) => (
          <Box
            style={{ ...indexFilter(index), ...selectedColorPicker(index) }}
            key={item.pk}
            onClick={() => onSelectInfo(item)}
          >
            <FrequentlyLabel
              text={item.name}
              size={16}
              textAlign={"center"}
              weight={"bold"}
              fontFamily={"Noto Sans CJK KR"}
            />
          </Box>
        ))}
        <ArrowContainer style={{ position: "relative", right: 0, marginLeft: "auto" }}>
          <FrequentlyLabel
            text={">"}
            size={"16"}
            weight={"bold"}
            containerStyles={{ cursor: "pointer" }}
          />
        </ArrowContainer>
      </DropdownContainer>
    </React.Fragment>
  );
};

export default FrequentlyRowItemList;
