import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { Checkbox, Space } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";

const DetailProductionPlan = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
  CheckBox: Checkbox,
});

interface DetailLedgerProps {
  data: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner | undefined;
}

export const LedgerDetailInForm = (params: DetailLedgerProps) => {
  const { data } = params;

  return (
    <>
      <DetailProductionPlan>
        <DetailProductionPlan.Title>{`[재고 상세] ${data?.id}`}</DetailProductionPlan.Title>
        <Space h={8} />
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{"재고 상세 번호"}</DetailProductionPlan.Label>
          <DetailProductionPlan.Content>{data?.id}</DetailProductionPlan.Content>
        </DetailProductionPlan.Field>
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{"생산 품목코드"}</DetailProductionPlan.Label>
          <DetailLinkWrapper>
            <DetailProductionPlan.Content>{data?.itemCode}</DetailProductionPlan.Content>
          </DetailLinkWrapper>
        </DetailProductionPlan.Field>
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{"로트 정보"}</DetailProductionPlan.Label>
          <DetailProductionPlan.CheckBox
            label={"로트명"}
            checked={data?.lot?.name === null ? false : true}
          />
          <DetailProductionPlan.CheckBox
            label={"로트 유효기한"}
            checked={data?.lot?.expiration === null ? false : true}
          />
        </DetailProductionPlan.Field>
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{"최종 재고"}</DetailProductionPlan.Label>
          <DetailProductionPlan.Content>
            {setToLocaleString(data?.quantity)} {data?.unitText}
          </DetailProductionPlan.Content>
        </DetailProductionPlan.Field>
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{"수정일시"}</DetailProductionPlan.Label>
          <DetailProductionPlan.Content>
            {DatetimeUtil(data?.updatedAt ?? "")}
          </DetailProductionPlan.Content>
        </DetailProductionPlan.Field>
      </DetailProductionPlan>
    </>
  );
};
