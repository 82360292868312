import { customFunctions } from "@/config/customFunction";
import { BadgeBox } from "@/features/standard/badge/BadgeBox";
import { BadgeContainer } from "@/features/standard/badge/BadgeContainer";
import { BadgeIcon } from "@/features/standard/badge/BadgeIcon";
import { BadgeMain } from "@/features/standard/badge/BadgeMain";
import { BadgeQuantity } from "@/features/standard/badge/BadgeQuantity";
import { BadgeSmallBox } from "@/features/standard/badge/BadgeSmallBox";
import { BadgeTextBox } from "@/features/standard/badge/BadgeTextBox";
import { theme } from "@/styles/theme";
import { setFilteringDecimalPoint, setToLocaleString } from "@/utils/unitMark";
import { Text } from "@mantine/core";
import { IconBuildingWarehouse } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const Badge = Object.assign(BadgeMain, {
  Box: BadgeBox,
  SmallBox: BadgeSmallBox,
  Quantity: BadgeQuantity,
  Icon: BadgeIcon,
  Text: Text,
  TextBox: BadgeTextBox,
  Container: BadgeContainer,
});

export const WorkDataBar = ({ workDataBarData }: any) => {
  const { t } = useTranslation();
  if (!workDataBarData) return null;
  const {
    fromLocationCode,
    toLocationCode,
    targetQuantity,
    summary,
    unitText,
    defectLocationCode,
  } = workDataBarData;

  return (
    <Badge.Container>
      <Badge.Box>
        <Badge.SmallBox>
          <Badge.Icon>
            <IconBuildingWarehouse size={26} color={theme.colors?.indigo?.[5]} />
          </Badge.Icon>
          <Badge.TextBox>
            <Badge.Quantity fz="lg" color={theme.colors?.indigo?.[5]}>
              {fromLocationCode}
            </Badge.Quantity>
            <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>
              {t("work.fromLocation")}
            </Badge.Text>
          </Badge.TextBox>
        </Badge.SmallBox>
        <Badge.SmallBox>
          <Badge.Icon>
            <IconBuildingWarehouse size={26} color={theme.colors?.indigo?.[5]} />
          </Badge.Icon>
          <Badge.TextBox>
            <Badge.Quantity fz="lg" color={theme.colors?.indigo?.[5]}>
              {toLocationCode}
            </Badge.Quantity>
            <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>
              {t("work.toLocation")}
            </Badge.Text>
          </Badge.TextBox>
        </Badge.SmallBox>
        {customFunctions.ADD_DEFECT_LOCATION && (
          <Badge.SmallBox>
            <Badge.Icon>
              <IconBuildingWarehouse size={26} color={theme.colors?.indigo?.[5]} />
            </Badge.Icon>
            <Badge.TextBox>
              <Badge.Quantity fz="lg" color={theme.colors?.indigo?.[5]}>
                {defectLocationCode}
              </Badge.Quantity>
              <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>
                {t("work.defectLocation")}
              </Badge.Text>
            </Badge.TextBox>
          </Badge.SmallBox>
        )}
        <Badge.SmallBox>
          <Badge.Icon>
            <IconBuildingWarehouse size={26} color={theme.colors?.indigo?.[5]} />
          </Badge.Icon>
          <Badge.TextBox>
            <Badge.Quantity fz="lg" color={theme.colors?.indigo?.[5]}>
              {setToLocaleString(targetQuantity)} {unitText}
            </Badge.Quantity>
            <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>
              {t("work.targetQuantity")}
            </Badge.Text>
          </Badge.TextBox>
        </Badge.SmallBox>
        <Badge.SmallBox>
          <Badge.Icon>
            <IconBuildingWarehouse size={26} color={theme.colors?.orange?.[5]} />
          </Badge.Icon>
          <Badge.TextBox>
            <Badge.Quantity fz="lg" color={theme.colors?.orange?.[5]}>
              {setToLocaleString(summary?.todoQuantity)} {unitText}
            </Badge.Quantity>
            <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>
              {t("work.remainQuantity")}
            </Badge.Text>
          </Badge.TextBox>
        </Badge.SmallBox>
        <Badge.SmallBox>
          <Badge.Icon>
            <IconBuildingWarehouse size={26} color={theme.colors?.green?.[5]} />
          </Badge.Icon>
          <Badge.TextBox>
            <Badge.Quantity fz="lg" color={theme.colors?.green?.[5]}>
              {setToLocaleString(summary?.totalQuantity)} {unitText}
            </Badge.Quantity>
            <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>
              {t("work.goodQuantity")}
            </Badge.Text>
          </Badge.TextBox>
        </Badge.SmallBox>
        <Badge.SmallBox>
          <Badge.Icon>
            <IconBuildingWarehouse size={26} color={theme.colors?.red?.[5]} />
          </Badge.Icon>
          <Badge.TextBox>
            <Badge.Quantity fz="lg" color={theme.colors?.red?.[5]}>
              {setToLocaleString(summary?.defectTotal)} {unitText}
            </Badge.Quantity>
            <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>
              {t("work.defectQuantity")}
            </Badge.Text>
          </Badge.TextBox>
        </Badge.SmallBox>
        <Badge.SmallBox>
          <Badge.Icon>
            <IconBuildingWarehouse size={26} color={theme.colors?.violet?.[6]} />
          </Badge.Icon>
          <Badge.TextBox>
            <Badge.Quantity fz="lg" color={theme.colors?.violet?.[6]}>
              {setFilteringDecimalPoint(summary?.percent, 2)}%
            </Badge.Quantity>
            <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>
              {t("work.progress")}
            </Badge.Text>
          </Badge.TextBox>
        </Badge.SmallBox>
      </Badge.Box>
    </Badge.Container>
  );
};
