import { theme } from "@/styles/theme";
import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

export const WorkProductionPlanTableHead = () => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr>
        <th></th>
        <th>
          <Text fz="sm" fw={500} c="black" ta="center">
            {t("item.name")}
          </Text>
        </th>
        <th>
          <Text fz="sm" fw={500} c="black" ta="center">
            {t("item.code")}
          </Text>
        </th>
        <th>
          <Text fz="sm" fw={500} color={theme.colors?.blue?.[9]} ta="center">
            {t("work.targetQuantity")} / {t("work.completedQuantity")}
          </Text>
        </th>
        <th>
          <Text fz="sm" fw={500} c="black" ta="center">
            {t("productionPlan.code")}
          </Text>
        </th>
      </tr>
    </thead>
  );
};
