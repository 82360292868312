import { itemCategories } from "@/api/itemCategories/useItemCategoriesQuery";
import { ItemCategoryDetailHeader } from "@/features/itemCategory/header/DetailHeader";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { useQuery } from "@tanstack/react-query";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
});

interface ItemCategoryDetailFormProps {
  ItemCategoryName: string | undefined;
}

export const ItemCategoryDetailForm = (params: ItemCategoryDetailFormProps) => {
  const { ItemCategoryName } = params;

  const { data: itemCategory } = useQuery(
    itemCategories.get({
      query: { $and: [{ name: { $eq: ItemCategoryName } }] },
    }),
  );

  return (
    <>
      <Detail.Container>
        <Detail.Wrapper>
          <Detail.Content>
            <ItemCategoryDetailHeader data={itemCategory} />
          </Detail.Content>
        </Detail.Wrapper>
      </Detail.Container>
    </>
  );
};
