import { itemChanges, mutateItemChanges } from "@/api/itemChange/useItemChangeQuery";
import { items } from "@/api/items/useItemQuery";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import CustomTable from "@/features/ui/table/CustomTable";
import { BASE_URL } from "@/instance/axios";
import { Button, FileInput, Flex, Image, Stack, Text, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Viewer } from "@react-pdf-viewer/core"; // React PDF Viewer
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  ItemChangesGet200ResponseRowsInner,
  MasterApiItemChangeAttachmentsItemChangeAttachmentIdDeleteRequest,
  MasterApiItemChangesItemChangeIdPutRequest,
  MasterApiItemChangesPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const ChangeRegisterInfomationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [item, setItem] = useState<string | null>("");
  const [preview, setPreview] = useState<string | null>(null);
  const [files, setFiles] = useState<{
    file1: File | null;
    file2: File | null;
    file3: File | null;
  }>({ file1: null, file2: null, file3: null });
  const queryClient = useQueryClient();
  const { mutate: createMuatate } = useMutation(
    (params: MasterApiItemChangesPostRequest) =>
      mutateItemChanges.create(params).mutationFn(params as any | MasterApiItemChangesPostRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["itemChanges"]);
      },
    },
  );
  const { mutate: updateMuatate } = useMutation(
    (params: MasterApiItemChangesItemChangeIdPutRequest) =>
      mutateItemChanges
        .update(params)
        .mutationFn(params as any | MasterApiItemChangesItemChangeIdPutRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["itemChanges"]);
      },
    },
  );

  const { mutate: deleteAttachment } = useMutation(
    (params: MasterApiItemChangeAttachmentsItemChangeAttachmentIdDeleteRequest) =>
      mutateItemChanges
        .deleteAttachment(params)
        .mutationFn(
          params as any | MasterApiItemChangeAttachmentsItemChangeAttachmentIdDeleteRequest,
        ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["itemChanges"]);
      },
    },
  );

  const { data } = useQuery({
    ...items.get({
      query: {
        $and: [{ code: item }],
      },
    }),
    enabled: !!item,
  });

  const itemData = data?.data.rows && data?.data.rows[0];

  const { data: itemChangeIdData } = useQuery({
    ...itemChanges.detail({
      itemChangeId: location.state?.selectedValues ? location.state.selectedValues[0] : null,
      populate: ["attachments", "item"],
    }),
    enabled: !!location.state?.selectedValues,
  });

  const itemChangeId = itemChangeIdData?.data;

  const form = useForm({
    initialValues: {
      title: "",
      content: "",
      file1: null as File | null,
      file2: null as File | null,
      file3: null as File | null,
      date: dayjs(new Date()).format("YYYY-MM-DD"),
    },
  });

  useEffect(() => {
    if (itemChangeId) {
      form.setValues({
        title: itemChangeId.title ?? "",
        content: itemChangeId.content ?? "",
        date: itemChangeId.createdAt ?? dayjs(new Date()).format("YYYY-MM-DD"),
      });
    }
  }, [itemChangeId, location.state?.selectedValues]);

  const Header = {
    item: ["품목코드", "품목명", "규격"],
  };

  const BodyData = {
    item: [
      {
        품목코드: itemData?.code ?? itemChangeId?.itemCode ?? "-",
        품목명: itemData?.name ?? itemChangeId?.itemName ?? "-",
        규격: itemData?.spec ?? itemChangeId?.itemSpec ?? "-",
      },
    ],
  };

  // 파일 업로드
  const handleAttachmentSave = (data: AxiosResponse<ItemChangesGet200ResponseRowsInner, any>) => {
    const formData = new FormData();

    // 선택된 파일들만 FormData에 추가
    if (files.file1) formData.append("file", files.file1);
    if (files.file2) formData.append("file", files.file2);
    if (files.file3) formData.append("file", files.file3);

    if (data?.data?.id) {
      axios({
        method: "post",
        url: `${BASE_URL}/itemChanges/${data?.data?.id}/attachment`,
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
        .then((result: any) => {
          if (result.status === 200) {
            alert("업로드 되었습니다.");
            navigate("/quality/change-list");
          }
        })
        .catch((err: AxiosError) => {
          alert("파일 업로드가 불가합니다."); // 파일 용량이 초과되었습니다.
          console.log(err);
        });
    } else {
      alert("파일 업로드가 불가합니다."); // 파일 용량이 초과되었습니다.
    }
  };

  // 저장하기
  const handleSave = () => {
    createMuatate(
      {
        itemChangesGetRequest: {
          title: form.values.title,
          itemCode: itemData?.code ?? "",
          content: form.values.content,
        },
      },
      {
        onSuccess: async (data) => {
          await handleAttachmentSave(data);
        },
      },
    );
  };

  useEffect(() => {
    if (itemChangeId) {
      form.setValues({
        title: itemChangeId.title ?? "",
        content: itemChangeId.content ?? "",
        date: itemChangeId.createdAt ?? dayjs(new Date()).format("YYYY-MM-DD"),
      });

      if (itemChangeId.attachments) {
        // 첨부파일을 state에 설정하고 FileInput에 보여주기 위한 작업
        if (itemChangeId.attachments[0]?.filePath) {
          const file = createFileObject(itemChangeId.attachments[0]?.filePath);
          setFiles((prev) => ({ ...prev, file1: file }));
          form.setFieldValue("file1", file);
        }
        if (itemChangeId.attachments[1]?.filePath) {
          const file = createFileObject(itemChangeId.attachments[1]?.filePath);
          setFiles((prev) => ({ ...prev, file2: file }));
          form.setFieldValue("file2", file);
        }
        if (itemChangeId.attachments[2]?.filePath) {
          const file = createFileObject(itemChangeId.attachments[2]?.filePath);
          setFiles((prev) => ({ ...prev, file3: file }));
          form.setFieldValue("file3", file);
        }
      }
    }
  }, [itemChangeId]);

  // 수정하기
  const handleUpdate = () => {
    updateMuatate(
      {
        itemChangeId: location.state.selectedValues[0],
        itemChangesGetRequest: {
          title: form.values.title,
          itemCode: itemChangeId?.itemCode ?? "",
          content: form.values.content,
        },
      },
      {
        onSuccess: async (res) => {
          await handleAttachmentSave(res);
          customAlert("변경점이 수정되었습니다.", "수정 성공", "green");
          navigate("/quality/change-list");
        },
      },
    );
  };

  // 이미 업로드된 파일을 File 객체로 변환
  const createFileObject = (filePath: string): File | null => {
    const fileName = filePath.split("/").pop() || "";
    const file = new File([], fileName); // 빈 File 객체를 생성 (사이즈 없이)
    return file;
  };

  // 파일 미리보기 함수
  const renderPreview = (file: File | null, filePath: string | undefined) => {
    // 파일이 없으면 미리보기 없음
    if (!file && !filePath) return null;

    // 서버에서 받은 파일 경로가 있는 경우 이를 사용
    const previewUrl = file
      ? URL.createObjectURL(file)
      : `${BASE_URL}${filePath?.startsWith("/") ? "" : "/"}${filePath}`;
    const fileType = file ? file.type : filePath ? filePath.split(".").pop() : "";

    // 이미지 파일 처리
    if (fileType?.includes("png") || fileType?.includes("jpeg") || fileType?.includes("jpg")) {
      return <Image src={previewUrl} alt="Uploaded Image" width={200} height={200} />;
    }

    // PDF 파일 처리
    if (fileType === "pdf") {
      return (
        <div style={{ height: "500px", width: "100%" }}>
          <Viewer fileUrl={previewUrl} />
        </div>
      );
    }

    // return <p>{file?.name || filePath}</p>;
  };

  const handleFileChange = (file: File | null, num: number) => {
    const fileKey = `file${num}` as keyof typeof files;
    setFiles({ ...files, [fileKey]: file });

    if (file === null) {
      const isConfirmed = window.confirm("첨부파일을 삭제하시겠습니까?");
      if (isConfirmed) {
        // 파일이 삭제될 때 삭제 API 호출
        const attachmentId = itemChangeId?.attachments && itemChangeId?.attachments[num - 1]?.id;
        if (attachmentId) {
          deleteAttachment(
            {
              itemChangeAttachmentId: attachmentId,
            },
            {
              onSuccess: () => {
                customAlert("첨부파일이 삭제되었습니다.", "삭제 성공", "green");
              },
              onError: () => {
                customAlert("첨부파일 삭제에 실패했습니다.", "삭제 실패", "red");
              },
            },
          );
        }
      }
    }
  };

  return (
    <Flex direction={"column"} gap="md" p="sm">
      <Flex justify={"space-between"}>
        <Button onClick={() => navigate("/quality/change-list")}>뒤로가기</Button>
        {location.state.pageKey === "info" ? (
          <Button type="submit" onClick={handleUpdate}>
            수정하기
          </Button>
        ) : (
          <Button type="submit" onClick={handleSave}>
            저장하기
          </Button>
        )}
      </Flex>
      <Text size="lg">
        {location.state.pageKey === "info" ? "변경점 정보" : "변경점 정보 등록"}
      </Text>
      <ItemAutoComplete
        placeholder="품목을 선택하세요"
        onChange={(value) => setItem(value)}
        defaultValue={itemChangeId?.itemName}
        disabled={location.state.pageKey === "info"}
      />
      <CustomTable headers={Header.item} data={BodyData.item} />
      <Stack w="100%">
        <TextInput
          label="제목"
          placeholder="제목"
          defaultValue={itemChangeId?.title}
          {...form.getInputProps("title")}
        />
        <Textarea
          label="설명"
          placeholder="설명"
          minRows={5}
          defaultValue={itemChangeId?.content}
          {...form.getInputProps("content")}
        />
        <Button>첨부파일 목록</Button>
        {[1, 2, 3].map((num) => (
          <div key={num}>
            <FileInput
              label={`첨부파일 0${num}`}
              accept=".hwp,.xlsx,.doc,.docx,.jpeg,.png,.pdf"
              value={files[`file${num}` as keyof typeof files]} // 파일 상태를 FileInput에 전달
              onChange={(file) => handleFileChange(file, num)}
              clearable
              name={`file${num}`}
            />
            {renderPreview(
              files[`file${num}` as keyof typeof files],
              itemChangeId?.attachments && itemChangeId?.attachments[num - 1]?.filePath,
            )}
          </div>
        ))}
      </Stack>
    </Flex>
  );
};
