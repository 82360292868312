import { mutateDefect } from "@/api/defect/useDefectsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { OperationAutoComplete } from "@/features/ui/autoComplete/operation/operation-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiDefectsDefectCodePutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

// interface DefectUpdateFormValues {
//   code: string | undefined;
//   name: string | undefined;
// }

// interface DefectUpdateFormProps {
//   onSubmit?: (values: DefectUpdateFormValues) => void;
//   formatterProps: DefectsGet200ResponseRowsInner;
// }

export const DefectUpdateForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: updateMutate } = useMutation(
    (params: DefaultApiDefectsDefectCodePutRequest) =>
      mutateDefect.update(params).mutationFn(params as DefaultApiDefectsDefectCodePutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["defects"]);
        closeModal(formatterProps);
        customAlert(
          `${t("message.editSuccess", { item: `${t("defect.defect")}` })}`,
          `${t("common.editSuccess")}`,
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("defect.code") }),
          `${t("common.editFail", { item: `${t("defect.defect")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      operationCode: formatterProps.operationCode,
    },
  });
  const onSubmit = async () => {
    try {
      updateMutate({
        defectCode: formatterProps.code,
        defectsGetRequest: {
          code: form.values.code,
          name: form.values.name,
          operationCode: form.values.operationCode,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("defect.code") }),
        `${t("common.editFail", { item: `${t("defect.defect")}` })}`,
        "red",
      );
    }
  };

  return (
    <Box w={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            withAsterisk
            label={t("defect.code")}
            placeholder={t("placeholder.input", { item: `${t("defect.code")}` })}
            disabled
            {...form.getInputProps("code")}
            defaultValue={formatterProps.code}
          />
          <TextInput
            withAsterisk
            label={t("defect.name")}
            placeholder={t("placeholder.input", { item: `${t("defect.name")}` })}
            {...form.getInputProps("name")}
            defaultValue={formatterProps.name}
            error={form.isDirty("name") ? t("common.toSave") : ""}
            styles={{
              input: {
                color: form.isDirty("name") ? "red !important" : "",
                borderColor: form.isDirty("name") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <OperationAutoComplete
            label={t("operation.code")}
            placeholder={t("placeholder.select", { item: `${t("operation.code")}` })}
            defaultValue={formatterProps?.operationCode}
            {...form.getInputProps("operationCode")}
            error={form.isDirty("operationCode") ? t("common.toSave") : ""}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              {t("common.cancel")}
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "defects",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              {t("common.edit", { item: `${t("defect.code")}` })}
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
