import { DATA_DISPLAY_QUERY_KEY, dataDisplay } from "@/api/dataDisplay";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import useQueryString from "@/hooks/useQueryString";
import styled from "@emotion/styled";
import { Button, Flex, Pagination } from "@mantine/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid from "react-data-grid";

const DataDisplayWrap = {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  Wrapper: Wrapper,
};

export const DataDisplayPage = () => {
  const queryClient = useQueryClient();
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);
  const [activePage, setPage] = useState(initialQueryString.page);

  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<any> => new Set());

  const { data: DisplayData } = useQuery(
    dataDisplay.get({
      page: activePage,
      pageSize: 10,
    }),
  );

  const rows = DisplayData?.data.rows ?? [];
  const totalPage = DisplayData?.data.totalPages ?? 0;
  const columns: readonly Column<any>[] = [
    {
      key: "id",
      name: "순번",
      sortable: true,
      resizable: true,
    },
    {
      key: "manufactureCompany",
      name: "측정 일시",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <>{dayjs(formatterProps.row.manufacturedAt).format("YYYY-MM-DD HH:mm:ss")}</>;
      },
    },
    {
      key: "voltage",
      name: "전압(V)",
      sortable: true,
      resizable: true,
    },
    {
      key: "current",
      name: "전류(A)",
      sortable: true,
      resizable: true,
    },
    {
      key: "power",
      name: "전력(W)",
      sortable: true,
      resizable: true,
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      queryClient.invalidateQueries([DATA_DISPLAY_QUERY_KEY]);
    }, 5000); // 5 초

    return () => clearInterval(intervalId);
  }, [queryClient]);

  return (
    <DataDisplayWrap.Wrapper>
      <DataDisplayWrap.Header>
        <DataDisplayWrap.HeaderTitle>데이터 표출</DataDisplayWrap.HeaderTitle>
        <DataDisplayWrap.HeaderSubTitle>
          {"우리 회사에서 다루는 데이터표출에 대해 조회할 수 있는 페이지 입니다."}
        </DataDisplayWrap.HeaderSubTitle>
      </DataDisplayWrap.Header>
      <Flex w="100%" justify="space-between">
        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center"></Flex>
      </Flex>
      <Flex w="100%" h="100%">
        <GridWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={rows}
            rowHeight={40}
            rowKeyGetter={(row) => row.logDate}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
          <Pagination
            onChange={setPage}
            value={activePage}
            total={totalPage} // 전체 페이지 계산
            size="lg"
            radius="sm"
            position="center"
          />
        </GridWrapper>
      </Flex>
    </DataDisplayWrap.Wrapper>
  );
};

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: {(props) =>
    props.isDarkMode ? "white" : "none"};
    --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: {(props) =>
    props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
