import { productionPlans } from "@/api/productionPlan/useProductionPlanQuery";
import { ProductionPlanDetailForm } from "@/features/productionPlan/detail/form";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs, Text, Title } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ProductionPlanDetailActionHeader } from "../detail/header/productionPlanActionHeader";
import { RoutingTabs } from "../detail/tabs/routingTabs";
import { SummaryTabs } from "../detail/tabs/summaryTabs";
import { WorkInventoryTabs } from "../detail/tabs/workInventoryTabs";
import { WorkRecordsTabs } from "../detail/tabs/workRecordsTabs";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
});

export interface ProductionPlanFormProps {
  ProductionPlanId?: number;
  data?: ProductionPlansGet200ResponseRowsInner | undefined;
}

export const ProductionPlanViewForm = ({ ProductionPlanId }: ProductionPlanFormProps) => {
  // const { data: planData } = useProductionPlanIdGetQuery(ProductionPlanId);
  const { t } = useTranslation();

  const { data: planData } = useQuery({
    ...productionPlans.detail({
      productionPlanId: ProductionPlanId ?? 0,
      populate: ["works", "equipment", "itemUnit"],
    }),
    enabled: !!ProductionPlanId,
  });

  return (
    <>
      <Detail.Container>
        <ProductionPlanDetailActionHeader />
        <Detail.Wrapper>
          <Detail.Content>
            <ProductionPlanDetailForm data={planData?.data} />
            <Tabs
              variant="outline"
              defaultValue="summary"
              styles={(theme) => ({
                tab: {
                  "&:not([data-active])": {
                    backgroundColor: theme.colors?.gray?.[0],
                  },
                },
              })}
            >
              <Tabs.List>
                <LeftSpace />
                <Tabs.Tab value="summary">{t("productionPlanTab.summary")}</Tabs.Tab>
                <Tabs.Tab value="routing">{t("productionPlanTab.routing")}</Tabs.Tab>
                <Tabs.Tab value="workRecords">{t("productionPlanTab.workRecord")}</Tabs.Tab>
                <Tabs.Tab value="workInventory">{t("productionPlanTab.workInventory")}</Tabs.Tab>
                <RightSpace />
              </Tabs.List>
              <Tabs.Panel value="summary" p={20}>
                <Title order={3}>{t("work.workOrder")}</Title>
                <Text>{t("summary.description")}</Text>
                <SummaryTabs ProductionPlanId={ProductionPlanId} />
              </Tabs.Panel>
              <Tabs.Panel value="routing" p={20}>
                <Title order={3}>{t("productionPlanTab.routing")}</Title>
                <Text>{t("routingInfo.description")}</Text>
                <RoutingTabs ProductionPlanId={ProductionPlanId} />
              </Tabs.Panel>
              <Tabs.Panel value="workRecords" p={20}>
                <Title order={3}>{t("productionPlanTab.workRecord")}</Title>
                <Text>{t("workRecord.description")}</Text>
                <WorkRecordsTabs data={planData && planData?.data} />
              </Tabs.Panel>
              <Tabs.Panel value="workInventory" p={20}>
                <Title order={3}>{t("productionPlanTab.workInventory")}</Title>
                <Text>{t("workInventory.description")}</Text>
                <WorkInventoryTabs data={planData && planData?.data} />
              </Tabs.Panel>
            </Tabs>
          </Detail.Content>
        </Detail.Wrapper>
      </Detail.Container>
    </>
  );
};

const DetailFormContainer = styled.div`
  width: 75rem;
`;

const ProductionPlanContainer = styled.div`
  padding: 8px;
  background: ${theme.colors?.gray?.[2]};
`;

const ProductionPlanWrapper = styled.div`
  background-color: white;
`;

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
