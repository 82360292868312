import styled from "@emotion/styled";
import { Flex, Table, Text, Title } from "@mantine/core";
import {
  ItemsGet200ResponseRowsInnerRoutingsInner,
  ItemsOneStepCreatePostRequestRoutingsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";
import { RoutingBomRow } from "./RoutingBomRow";

interface RoutingBomListProps<T> {
  formData: T extends "PREVIEW"
    ? ItemsOneStepCreatePostRequestRoutingsInner[] | undefined
    : ItemsGet200ResponseRowsInnerRoutingsInner[] | undefined;
}

export const RoutingBomList = <T,>({ formData }: RoutingBomListProps<T>) => {
  const { t } = useTranslation();
  return (
    <Flex w="100%" p="md" direction="column" gap="xs">
      <Title order={2}>{t("routing.routing")}</Title>
      <Text fz="xs">{t("item.bomDescription")}</Text>
      <Table withBorder withColumnBorders>
        <thead>
          <tr>
            <Th width={5}>&nbsp;</Th>
            <Th width={5}>{t("routing.sequence")}</Th>
            <Th width={30}>{t("operation.name")}</Th>
            <Th width={30}>{t("routing.code")}</Th>
            <Th width={10}>{t("routing.type")}</Th>
            <Th width={10}>{t("routing.consumeType")}</Th>
          </tr>
        </thead>
        <tbody>
          {formData &&
            formData.map((data, i) => {
              return (
                <RoutingBomRow
                  bomRow={
                    data as T extends "PREVIEW"
                      ? ItemsOneStepCreatePostRequestRoutingsInner
                      : ItemsGet200ResponseRowsInnerRoutingsInner
                  }
                  key={data.code ?? "" + i}
                  rowIndex={i}
                />
              );
            })}
        </tbody>
      </Table>
    </Flex>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
`;
