import { inspections } from "@/api/inspect/inspectSpecification/useInspectionQuery";
import { DateSlashtimeUtil } from "@/utils/dateTimeUtil";
import { Container, Table, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

interface DetailFormProps {
  inspectionId: number;
}

export const InspectionDetailForm = (props: DetailFormProps) => {
  const { inspectionId } = props;
  const { t } = useTranslation();
  const { data } = useQuery(
    inspections.detail({
      inspectionId: inspectionId,
      populate: ["lot"],
    }),
  );
  const inspectionData = data?.data;

  let recordIndex = 0; // recordIndex 초기화

  const tBodyRows = inspectionData?.specificationData?.criteria?.map((criteria, index) => {
    return (
      <Fragment key={JSON.stringify(criteria)}>
        <tr key={index} style={{ fontWeight: "bold" }}>
          <td>
            {index + 1}. {criteria.name}
          </td>
          <td>{criteria.type === "boolean" ? t("inspect.check") : ""}</td>
          {inspectionData?.record?.[recordIndex]?.map((recordItem: any, itemIndex: number) => {
            return (
              <td key={itemIndex}>
                {recordItem?.isSaved
                  ? recordItem?.value
                    ? t("inspectRecord.acceptance")
                    : t("inspectRecord.failure")
                  : ""}
              </td>
            );
          })}
          <td></td>
        </tr>
        <tr>
          <td colSpan={2} style={{ textAlign: "center" }}>
            {t("inspectRecord.inspector")}
          </td>
          {inspectionData?.record?.[recordIndex]?.map((recordItem: any, itemIndex: number) => {
            return (
              <td key={itemIndex}>{recordItem?.isSaved ? recordItem?.creatorUserName : ""}</td>
            );
          })}
          <td></td>
        </tr>
        <tr>
          <td colSpan={2} style={{ textAlign: "center" }}>
            {t("inspectRecord.createdAt")}
          </td>
          {inspectionData?.record?.[recordIndex]?.map((recordItem: any, itemIndex: number) => {
            return (
              <td key={itemIndex}>
                {recordItem?.isSaved ? DateSlashtimeUtil(recordItem?.createdAt) : ""}
              </td>
            );
          })}
          <td></td>
        </tr>
      </Fragment>
    );
  });

  return (
    <Container w={"80rem"}>
      <Title order={2} align="center" mt="md" mb="md">
        {t("inspectRecord.sheet")}
      </Title>
      <Table withBorder withColumnBorders mb={"1rem"}>
        <thead>
          <tr>
            <th>{t("item.name")}</th>
            <th>{inspectionData?.itemCode}</th>
            <th>{t("lot.name")}</th>
            <th>{inspectionData?.lot?.name}</th>
            <th>{t("productionPlan.id")}</th>
            <th>{inspectionData?.productionPlanId}</th>
          </tr>
        </thead>
      </Table>
      <Table striped highlightOnHover withBorder withColumnBorders>
        <thead>
          <tr>
            <th>{t("inspectRecord.inspectCategory")}</th>
            <th>{t("inspect.inspectType")}</th>
            <th colSpan={3}></th>
            <th>{t("routing.descript")}</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th>x1</th>
            <th>x2</th>
            <th>x3</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{tBodyRows}</tbody>
      </Table>
      {/* <Flex justify="end" mt="md">
                <Button>
                    인쇄하기
                </Button>
            </Flex> */}
    </Container>
  );
};
