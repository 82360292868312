// @fileoverview : 영업관리 > 납품현황
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LotDetailForm } from "@/features/lot/form/lotDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { Avatar, Button, Flex, Tooltip, Text } from "@mantine/core";
import {
  WmsLogsGet200Response,
  WmsLogsGet200ResponseRowsInner,
  WmsLogsGet200ResponseRowsInnerActionTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconFilterPlus, IconRecycle } from "@tabler/icons-react";
import { useEffect } from "react";
import { Column } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const DeliveryStatusPage = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { query, setQuery, resetQueryStrings } = useStandardLayout();

  useEffect(() => {
    if (query.length === 0) {
      // query가 비어 있을 때만 실행
      setQuery([
        { purchaseOrderItemId: { $not: null } },
        {
          actionType: {
            $or: [
              { $eq: WmsLogsGet200ResponseRowsInnerActionTypeEnum.OUTGOING },
              { $eq: WmsLogsGet200ResponseRowsInnerActionTypeEnum.INCOMING },
            ],
          },
        },
      ]);
    }
  }, [query]);

  const columns: readonly Column<WmsLogsGet200ResponseRowsInner>[] = [
    {
      key: "purchaseOrderItemId",
      name: t("purchaseOrder.id"),
      sortable: true,
      resizable: true,
    },
    {
      key: "partnerCode",
      name: t("partner.code"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(
                <PartnerDetailForm
                  partnerCode={formatterProps?.row?.purchaseOrderItem?.partnerCode}
                />,
                null,
                "",
              );
            }}
          >
            {formatterProps?.row?.purchaseOrderItem?.partnerCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "itemCode",
      name: t("item.code"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps: any) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(
                <ItemsDetailForm itemCode={formatterProps?.row?.actionParam?.params?.itemCode} />,
                null,
                "",
                false,
              );
            }}
          >
            {formatterProps?.row?.actionParam?.params?.itemCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "lotInfo.name",
      name: t("lot.name"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(
                <LotDetailForm
                  lotName={formatterProps?.row?.actionParam?.params?.lotInfo?.name}
                  lotId={formatterProps?.row?.actionParam?.params?.lotId}
                />,
                null,
                "",
              );
            }}
          >
            {formatterProps?.row?.actionParam?.params?.lotInfo?.name}
          </LinkAnchor>
        );
      },
    },
    {
      key: "lotInfo.expiration",
      name: t("lot.expiration"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <Text>{formatterProps?.row?.lotInfo?.expiration}</Text>;
      },
    },
    {
      key: "quantity",
      name: t("inventory.quantity"),
      sortable: true,
      resizable: true,

      formatter: (formatterProps) => {
        return <Text ta="end">{formatterProps?.row?.actionParam?.params?.quantity}</Text>;
      },
    },
    {
      key: "deliveryDeadline",
      name: t("delivery.deadline"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Text>
            {formatterProps?.row?.purchaseOrderItem?.deliveryDeadline
              ? DatetimeUtil(formatterProps?.row?.purchaseOrderItem?.deliveryDeadline as string)
              : ""}
          </Text>
        );
      },
    },
  ];

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="flex-end" rowGap="md">
        <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
          {t("common.reset")}
        </Button>
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: `${t("delivery.deliveryStatus")}` })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${t("delivery.deliveryStatus")}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<WmsLogsGet200Response, WmsLogsGet200ResponseRowsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<WmsLogsGet200Response> />
      </Flex>
    </Flex>
  );
};
