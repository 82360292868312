import { downtimeReasons } from "@/api/downtimeReasons/useDowntimeReasonsQuery";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { useQuery } from "@tanstack/react-query";
import { DownTimeReasonsDetailHeader } from "../header/DetailHeader";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
});

interface DowntimeReasonsDetailFormProps {
  downtimeReasonCode: string | undefined;
}

export const DowntimeReasonsDetailForm = (params: DowntimeReasonsDetailFormProps) => {
  const { downtimeReasonCode } = params;

  const { data: downtimeReasonData } = useQuery(
    downtimeReasons.get({
      query: { $and: [{ code: { $eq: downtimeReasonCode } }] },
    }),
  );

  return (
    <>
      <Detail.Container>
        <Detail.Wrapper>
          <Detail.Content>
            <DownTimeReasonsDetailHeader data={downtimeReasonData} />
          </Detail.Content>
        </Detail.Wrapper>
      </Detail.Container>
    </>
  );
};
