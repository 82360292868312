import { mutatePartners } from "@/api/partner/usePartnerQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiPartnersPartnerIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const PartnerUpdateForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiPartnersPartnerIdPutRequest) =>
      mutatePartners
        .update(params)
        .mutationFn(params as MasterApiPartnersPartnerIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partners"]);
        closeModal(formatterProps);
        customAlert(
          `${t("message.editSuccess", { item: `${t("partner.partner")}` })}`,
          `${t("common.editSuccess")}`,
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("partner.code") }),
          `${t("common.editFail", { item: `${t("partner.partner")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      contact: formatterProps.contact,
      type: formatterProps.type,
    },
  });
  const onSubmit = async () => {
    try {
      updateMutate({
        partnerId: formatterProps.id,
        partnersGetRequest: {
          code: form.values.code!,
          name: form.values.name!,
          contact: form.values.contact!,
          type: form.values.type!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("partner.code") }),
        `${t("common.editFail", { item: `${t("partner.partner")}` })}`,
        "red",
      );
    }
  };

  return (
    <Box w={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            withAsterisk
            label={t("partner.code")}
            placeholder={t("placeholder.input", { item: `${t("partner.code")}` })}
            {...form.getInputProps("code")}
            defaultValue={formatterProps.code}
            error={form.isDirty("code") ? t("common.toSave") : ""}
            styles={{
              input: {
                color: form.isDirty("code") ? "red !important" : "",
                borderColor: form.isDirty("code") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <TextInput
            withAsterisk
            label={t("partner.name")}
            placeholder={t("placeholder.input", { item: `${t("partner.name")}` })}
            {...form.getInputProps("name")}
            defaultValue={formatterProps.name}
            error={form.isDirty("name") ? t("common.toSave") : ""}
            styles={{
              input: {
                color: form.isDirty("name") ? "red !important" : "",
                borderColor: form.isDirty("name") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <TextInput
            withAsterisk
            type="number"
            label={t("partner.contact")}
            placeholder={t("placeholder.input", { item: `${t("partner.contact")}` })}
            {...form.getInputProps("contact")}
            defaultValue={formatterProps.contact}
            error={form.isDirty("name") ? t("common.toSave") : ""}
            styles={{
              input: {
                color: form.isDirty("contact") ? "red !important" : "",
                borderColor: form.isDirty("contact") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <Select
            withAsterisk
            label={t("partner.type")}
            placeholder={t("placeholder.input", { item: `${t("partner.type")}` })}
            {...form.getInputProps("type")}
            defaultValue={formatterProps.type}
            error={form.isDirty("type") ? t("common.toSave") : ""}
            data={[
              { value: "SALES", label: t("partner.SALES") },
              { value: "PURCHASE", label: t("partner.PURCHASE") },
              { value: "BI_DIRECTION", label: t("partner.BI_DIRECTION") },
            ]}
            styles={{
              input: {
                color: form.isDirty("type") ? "red !important" : "",
                borderColor: form.isDirty("type") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              {t("common.cancel")}
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "partner",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              {t("common.edit", { item: `${t("partner.partner")}` })}
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
