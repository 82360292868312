import { authEnum } from "@/config/auth";
import { authTokenAtom } from "@/recoil/authAtom";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

export function useLogout() {
  const navigate = useNavigate();
  const setAuthTokenAtom = useSetRecoilState(authTokenAtom);

  return () => {
    setAuthTokenAtom("");
    localStorage?.removeItem(authEnum.authToken);
    localStorage?.removeItem("siteId");

    fetch(`${process.env.REACT_APP_BASE_URL}/auth/logout`, {
      method: "POST", // HTTP 메소드 지정
      body: JSON.stringify({ username: localStorage.getItem("username") }),
      headers: {
        "Content-Type": "application/json", // 내용 유형을 JSON으로 설정
      },
    });

    navigate("/");
  };
}
