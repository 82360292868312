import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Space } from "@mantine/core";
import { useTranslation } from "react-i18next";

const DetailRouting = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
});

interface InspectSpecificationInformWithItemProps {
  formData: any;
}

export const InspectSpecificationInformWithItem = (
  params: InspectSpecificationInformWithItemProps,
) => {
  const { formData } = params;
  const { t } = useTranslation();

  return (
    <DetailRouting gap={15}>
      <DetailRouting.Title>{t("inspect.inspectSpecification")}</DetailRouting.Title>
      <DetailRouting.Inform>{t("inspect.inspectSpecification")}</DetailRouting.Inform>
      <Space h={10} />
      <DetailRouting.Field align={"center"}>
        <DetailRouting.Label>{t("item.item")}</DetailRouting.Label>
        <ItemAutoComplete {...formData.getInputProps("itemCode")} />
      </DetailRouting.Field>
      <DetailRouting.Field>
        <DetailRouting.Label>{t("routing.code")}</DetailRouting.Label>
        <DetailRouting.Content></DetailRouting.Content>
      </DetailRouting.Field>
      <DetailRouting.Field>
        <DetailRouting.Label>{t("inspect.type")}</DetailRouting.Label>
        <DetailRouting.Content>{t("inspect.inspect")}</DetailRouting.Content>
      </DetailRouting.Field>
      <DetailRouting.Field>
        <DetailRouting.Label>{t("info.createInformation")}</DetailRouting.Label>
        <DetailRouting.Content>
          {/* {`${data?.creatorUserCode ?? ""}(${dayjs(data?.createdAt).format(
            "YYYY-MM-DD HH:mm:ss"
          )})`} */}
        </DetailRouting.Content>
      </DetailRouting.Field>
      <DetailRouting.Field>
        <DetailRouting.Label>{t("info.updateInformation")}</DetailRouting.Label>
      </DetailRouting.Field>
    </DetailRouting>
  );
};
