import styled from "@emotion/styled";

interface DetailContentProps {
  children: React.ReactNode;
}

export const DetailContent = ({ children }: DetailContentProps) => {
  return <Content>{children}</Content>;
};

const Content = styled.div`
  background-color: white;
`;
