import { useStandardLayout } from "@/features/standardLayout/Context";
import { Button, Flex } from "@mantine/core";
import { IconPlus, IconRecycle, IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

// 제네릭 타입을 포함하는 ActionHeaderProps 인터페이스 정의
interface ActionHeaderProps {
  createFn: () => void;
  createDataTitle?: string;
  deleteFn: () => void;
}

// 제네릭을 포함한 ES6 문법의 ActionHeader 컴포넌트
export const ActionHeader = ({ createFn, createDataTitle = "", deleteFn }: ActionHeaderProps) => {
  const { resetQueryStrings } = useStandardLayout();
  const { t } = useTranslation();
  return (
    <>
      <Flex>
        <Button leftIcon={<IconPlus />} onClick={() => createFn()}>
          {t("common.additional", { item: createDataTitle })}
        </Button>
      </Flex>
      <Flex gap="md">
        <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
          {t("common.reset")}
        </Button>
        <Button rightIcon={<IconTrash />} color="red" onClick={() => deleteFn()}>
          {t("common.rowDelete")}
        </Button>
      </Flex>
    </>
  );
};
