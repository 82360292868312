import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { useModal } from "@/features/modal/ModalStackManager";
import { PurchaseOrderItemsCreateForm } from "@/features/purchaseOrderItems/form/createForm";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { ConfirmForm } from "@/features/ui/confirm";
import { getUserCodeByUser } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { ScmApiPurchaseOrdersPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

interface PurchaseOrderCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export const PurchaseOrderCreateForm = (params: PurchaseOrderCreateFormProps) => {
  const { code, autoCompleteOnSubmit } = params;
  const { openModal, closeModal } = useModal();
  const userCode = getUserCodeByUser();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    async (params: ScmApiPurchaseOrdersPostRequest) => {
      // mutationFn을 비동기 함수로 정의
      return await mutatePurchaseOrders
        .create(params)
        .mutationFn(params as ScmApiPurchaseOrdersPostRequest | any);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["purchaseOrders"]); // 성공 시 'purchaseOrders' 캐시 무효화
        closeModal(form.values);
        customAlert(
          `${userCode === "PB" ? t("message.createSuccess", { item: t("order.order") }) : t("message.createSuccess", { item: t("purchaseOrder.purchaseOrder") })}`,
          t("common.createSuccess"),
          "green",
        );

        openModal(
          <ConfirmForm
            message={`${userCode === "PB" ? t("orderItems.createMessage") : t("purchaseOrderItems.createMessage")}`}
            yesCallback={() => {
              openModal(
                <PurchaseOrderItemsCreateForm purchaseOrder={res.data} />,
                null,
                `${userCode === "PB" ? t("common.additional", { item: t("orderItems.orderItems") }) : t("common.additional", { item: t("purchaseOrderItems.purchaseOrderItems") })}`,
                true,
              ).then(() => closeModal(null));
            }}
            noCallback={() => closeModal(null)}
          />,
          null,
          `${userCode === "PB" ? t("common.additional", { item: t("orderItems.orderItems") }) : t("common.additional", { item: t("purchaseOrderItems.purchaseOrderItems") })}`,
          true,
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({
            error,
            errorContext: `${userCode === "PB" ? t("order.order") : t("purchaseOrder.purchaseOrder")}`,
          }),
          `${userCode === "PB" ? t("common.createFail", { item: t("order.order") }) : t("common.createFail", { item: t("purchaseOrder.purchaseOrder") })}`,
          "red",
        );
      },
    },
  );
  const form = useForm({
    initialValues: {
      partnerCode: undefined,
      // status : undefined,
      code: code ?? undefined,
      orderDate: undefined,
      deliveryDeadline: undefined,
      userCode: undefined,
      sourceLocationCode: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        purchaseOrdersGetRequest: {
          partnerCode: form.values.partnerCode!,
          direction: "INBOUND",
          code: code ?? form.values.code!,
          // status : form.values.status!,
          orderDate: form.values.orderDate!,
          deliveryDeadline: form.values.deliveryDeadline!,
          userCode: form.values.userCode!,
          sourceLocationCode: form.values.sourceLocationCode!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({
          error,
          errorContext: `${userCode === "PB" ? t("order.order") : t("purchaseOrder.purchaseOrder")}`,
        }),
        `${userCode === "PB" ? t("common.createFail", { item: t("order.order") }) : t("common.createFail", { item: t("purchaseOrder.purchaseOrder") })}`,
        "red",
      );
    }
  };

  return (
    <>
      <Create.Wrapper>
        <PartnerAutoComplete
          label={t("partner.partner")}
          placeholder={t("plcaeholder.select", { item: t("partner.partner") })}
          {...form.getInputProps("partnerCode")}
          withAsterisk
        />
        <Create.Input
          withAsterisk
          label={`${userCode === "PB" ? t("order.code") : t("purchaseOrder.code")}`}
          placeholder={`${userCode === "PB" ? t("placeholder.input", { item: t("order.code") }) : t("placeholder.input", { item: t("purchaseOrder.code") })}`}
          {...form.getInputProps("code")}
        />
        <UserAutoComplete
          label={t("order.manager")}
          placeholder={t("placeholder.select", { item: t("order.manager") })}
          {...form.getInputProps("userCode")}
        />
        <LocationAutoComplete
          label={t("inventory.fromLocation")}
          placeholder={t("placeholder.select", { item: t("inventory.fromLocation") })}
          {...form.getInputProps("sourceLocationCode")}
        />
        <Create.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={t("purchaseOrder.purchaseOrderDate")}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("orderDate")}
        />
        <Create.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={t("order.deliveryDate")}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("deliveryDeadline")}
        />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "purchaseOrder",
              values: form.values,
            })}
            onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
          >
            {userCode === "PB"
              ? t("common.create", { item: t("order.order") })
              : t("common.create", { item: t("purchaseOrder.purchaseOrder") })}
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
