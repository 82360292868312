import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Button, Flex, NumberInput, Tabs, Text, TextInput } from "@mantine/core";

import { mutateMolds } from "@/api/mold/useMoldQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import { MasterApiMoldsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  FormWrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  Tab: Tabs,
});

interface MoldCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export const MoldCreateForm = (params: MoldCreateFormProps) => {
  const { code, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: createMutate } = useMutation(
    (params: MasterApiMoldsPostRequest) =>
      mutateMolds.create(params).mutationFn(params as MasterApiMoldsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["molds"]);
        closeModal(form.values);
        customAlert(
          `${t("message.createSuccess", { item: `${t("mold.mold")}` })}`,
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("mold.code") }),
          `${t("common.createFail", { item: `${t("mold.mold")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: code ?? undefined,
      siteCode: undefined,
      capacity: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        moldsGetRequest: {
          code: code ?? form.values.code!,
          siteCode: form.values.siteCode!,
          capacity: form.values.capacity!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("mold.code") }),
        `${t("common.createFail", { item: `${t("mold.mold")}` })}`,
        "red",
      );
    }
  };

  return (
    <>
      <Create.FormWrapper>
        <Create.Input label={t("mold.code")} withAsterisk {...form.getInputProps("code")} />
        <SitesAutoComplete
          width="100%"
          label={t("site.code")}
          {...form.getInputProps("siteCode")}
        />
        <NumberInput label={t("capacity")} {...form.getInputProps("capacity")} />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "mold",
              values: form.values,
            })}
            onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
          >
            {t("common.create", { item: `${t("mold.mold")}` })}
          </Create.Button>
        </Create.ButtonBox>
      </Create.FormWrapper>
    </>
  );
};
