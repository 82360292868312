type StatusObjType = {
  [key: string]: string;
};

// 수발주 상태
export const invoiceTypeColor = {
  DOMESTIC: "blue.7",
  INTERNATIONAL: "green.7",
};

export const invoiceStatus: StatusObjType = {
  DOMESTIC: "국내",
  INTERNATIONAL: "해외",
};

export const setInvoiceStatus = (status: string) => {
  return invoiceStatus[status];
};
