// @fileoverview : 재고관리 > 전체입출고내역
import { wmsLog } from "@/api/delivery/useDeliveryQuery";
import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { DateHeader } from "@/features/ledgersAll/components/DateHeader";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { SiteDetailForm } from "@/features/site/components/form/SiteDetailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { isEmpty } from "@/utils/checkData";
import DatetimeUtil from "@/utils/dateTimeUtil";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { Avatar, Button, Flex, Text, Tooltip } from "@mantine/core";
import {
  LedgersGet200Response,
  LedgersGet200ResponseRowsInner,
  WorkLogsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconFilterPlus, IconRecycle } from "@tabler/icons-react";
import { useQueries, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo } from "react";
import { Column } from "react-data-grid";
import { useTranslation } from "react-i18next";

interface Additional {
  productionPlanId: number;
  workLogId: number;
  workId: number;
  wmsLogId: number;
}

interface LocationData {
  site_code?: string;
  updated_at?: string;
  name: string;
}

interface WorkLogData {
  id: number;
  workLogType: string;
  defectCode: string;
  defectName: string;
}

type LedgersGet200ResponseRowsInnerWithWorkLog = LedgersGet200ResponseRowsInner & {
  workLogData?: WorkLogData | null; // workLogData 속성 추가
};

export const LedgerAllPage = () => {
  const { openModal } = useModal();
  const { data, query, setQuery, startDate, endDate, resetQueryStrings } =
    useStandardLayout<AxiosResponse<LedgersGet200Response>>();
  const { t } = useTranslation();

  useEffect(() => {
    setQuery((prev) => {
      // 기존 query에서 createdAt 조건이 이미 존재하는지 확인
      const hasCreatedAt = prev.some(
        (item) =>
          item.createdAt?.$between?.[0] === startDate && item.createdAt?.$between?.[1] === endDate,
      );

      if (hasCreatedAt) {
        // 조건이 동일하면 상태를 업데이트하지 않음
        return prev;
      }

      // 기존 query에서 createdAt 조건 제거
      const filteredQuery = prev.filter((item) => !item.createdAt);

      // 새로운 createdAt 조건 추가
      return [
        ...filteredQuery,
        {
          createdAt: {
            $between: [startDate, endDate],
          },
        },
      ];
    });
  }, [endDate, startDate, setQuery]);

  const ledgerAllResponse = data?.data || {
    rows: [] as LedgersGet200ResponseRowsInnerWithWorkLog[],
  };
  const additional: Additional[] =
    ledgerAllResponse.rows
      ?.map((row) => row.additional)
      .filter((item): item is Additional => item !== undefined) ?? [];

  const { data: workLogData } = useQuery(
    workLogs.get({
      query: {
        $and: [{ id: additional && additional.map((row) => row?.workLogId).filter(Boolean) }],
      },
      populate: [
        "itemUnit",
        "defectName",
        "alreadyCanceled",
        "creatorUser",
        "downtimeReasonName",
        "getEquipment",
      ],
    }),
  );

  const wmsLogQueries = useQueries({
    queries: additional
      .map((row) => row?.wmsLogId)
      .filter(Boolean)
      .map((id) => {
        const { queryKey, queryFn } = wmsLog.detail({ wmsLogId: id });
        return {
          queryKey,
          queryFn,
          enabled: !!id, // id가 존재할 때만 쿼리가 활성화되도록 설정
        };
      }),
  });

  const wmsLogsData = wmsLogQueries.map((query) => query.data);

  const mergeDataFn = useCallback(
    (rows: LedgersGet200ResponseRowsInnerWithWorkLog[]) => {
      return rows.map((row) => {
        // WorkLog 데이터 병합
        const matchedWorkLogData = workLogData?.data.rows?.find(
          (workLog: WorkLogsGet200ResponseRowsInner) =>
            workLog.id === (row.additional as Additional)?.workLogId,
        );

        // WmsLog 데이터 병합
        const matchedWmsLogData = wmsLogsData.find(
          (wmsLog) => wmsLog?.data?.id === (row.additional as Additional)?.wmsLogId,
        );

        return {
          ...row,
          workLogData: matchedWorkLogData,
          wmsLogData: matchedWmsLogData?.data, // 필요에 따라 데이터 병합
        };
      });
    },
    [workLogData, wmsLogsData],
  );

  const columns: readonly Column<LedgersGet200ResponseRowsInnerWithWorkLog>[] = useMemo(
    () => [
      {
        key: "itemCode",
        name: t("item.code"),
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: LedgersGet200ResponseRowsInner }) => {
          const detailItemAction = () => {
            openModal(
              <ItemsDetailForm itemCode={row.itemCode} />,
              null,
              `${t("common.detail", { item: `${t("item.item")}` })}`,
            );
          };
          return <LinkAnchor onClick={detailItemAction}>{row.itemCode}</LinkAnchor>;
        },
      },
      {
        key: "locationCode",
        name: t("location.location"),
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: LedgersGet200ResponseRowsInner }) => {
          const detailItemAction = () => {
            openModal(
              <LocationDetailForm locationCode={row.locationCode ?? ""} />,
              null,
              `${t("common.detail", { item: `${t("location.location")}` })}`,
            );
          };
          return (
            <LinkAnchor onClick={detailItemAction}>
              {(row.locationData as LocationData)?.name}
            </LinkAnchor>
          );
        },
      },
      {
        key: "locationData.site_code",
        name: t("site.site"),
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: LedgersGet200ResponseRowsInner }) => (
          <LinkAnchor
            onClick={() =>
              openModal(
                <SiteDetailForm siteCode={(row.locationData as LocationData)?.site_code} />,
                null,
                "",
              )
            }
          >
            {(row.locationData as LocationData)?.site_code}
          </LinkAnchor>
        ),
      },
      {
        key: "userCode",
        name: t("user.user"),
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: LedgersGet200ResponseRowsInner }) => {
          return (
            <LinkAnchor
              onClick={() => openModal(<UsersDetailForm UserCode={row.userCode} />, null, "")}
            >
              {row.name}
            </LinkAnchor>
          );
        },
      },
      {
        key: "lotData.name",
        name: t("lot.name"),
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: LedgersGet200ResponseRowsInner }) => (
          <Flex>{row?.lotData?.name}</Flex>
        ),
      },
      {
        key: "lotData.expiration",
        name: t("lot.expiration"),
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: LedgersGet200ResponseRowsInner }) => (
          <Flex>
            {row?.lotData?.expiration === null
              ? ""
              : timeUtil(row.lotData?.expiration!) === "NaN-NaN-NaN"
                ? ""
                : timeUtil(row.lotData?.expiration!)}
          </Flex>
        ),
      },
      {
        key: "additional.productionPlanId",
        name: t("productionPlan.id"),
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: LedgersGet200ResponseRowsInner }) => {
          return (
            <Flex h="100%" justify="flex-end" align="center">
              <LinkAnchor
                onClick={() =>
                  openModal(
                    <ProductionPlanViewForm
                      ProductionPlanId={(row?.additional as Additional)?.productionPlanId}
                    />,
                    null,
                    `${t("common.detail", { item: `${t("productionPlan.productionPlan")}` })}`,
                  )
                }
              >
                {(row?.additional as Additional)?.productionPlanId}
              </LinkAnchor>
            </Flex>
          );
        },
      },
      {
        key: "workLogData.workLogType",
        name: t("ledger.detail"),
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: LedgersGet200ResponseRowsInnerWithWorkLog }) => {
          const defectName = isEmpty(row.workLogData?.defectName)
            ? ""
            : ` (${row.workLogData?.defectName})`;
          const wmsLogId = (row?.additional as Additional).wmsLogId;

          const outgoingLog = wmsLogsData?.find(
            (wmsLog) =>
              wmsLog?.data?.id === wmsLogId &&
              wmsLog?.data?.purchaseOrderItemId &&
              wmsLog?.data?.actionType === "OUTGOING",
          );
          const incomingLog = wmsLogsData?.find(
            (wmsLog) => wmsLog?.data?.id === wmsLogId && wmsLog?.data?.actionType === "INCOMING",
          );

          const delivery = outgoingLog
            ? `${t("delivery.delivery")}`
            : incomingLog
              ? `${t("inventory.incoming")}`
              : "";

          return (
            <span>
              {row.workLogData?.workLogType
                ? t(row.workLogData?.workLogType) + defectName
                : delivery}
            </span>
          );
        },
      },
      {
        key: "quantity",
        name: t("ledger.quantityChange"),
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          return (
            <Flex justify="flex-end">
              {setToLocaleString(row.quantity)} {row.itemData?.unit_text}
            </Flex>
          );
        },
      },
      {
        key: "createdAt",
        name: t("ledger.createdAt"),
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          return <Flex>{DatetimeUtil(row.createdAt ?? "")}</Flex>;
        },
      },
    ],
    [openModal, t, wmsLogsData],
  );

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="flex-end" rowGap="md">
        <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
          {t("common.reset")}
        </Button>
      </Flex>
      <Flex p="sm" justify="space-between" rowGap="md">
        <DateHeader />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("ledger.ledgerAll")}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${t("ledger.ledger")}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<
            LedgersGet200Response,
            LedgersGet200ResponseRowsInner | WorkLogsGet200ResponseRowsInner
          >
            columns={columns}
            additionalDataFn={(rows: LedgersGet200ResponseRowsInner[]) => mergeDataFn(rows)}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<LedgersGet200Response> />
      </Flex>
    </Flex>
  );
};
