import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import useQueryString from "@/hooks/useQueryString";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Flex, Pagination, Text } from "@mantine/core";
import { useRef, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

const WarehousingRegister = {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  Wrapper: Wrapper,
};

export const WarehousingRegisterPage = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);
  const [activePage, setPage] = useState(initialQueryString.page);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<any> => new Set());

  const pageSize = 10;
  const totalRows = ShipmentStatusPageMockupData.length;

  // 페이지에 맞는 데이터 슬라이싱
  const paginatedRows = ShipmentStatusPageMockupData.slice(
    (activePage - 1) * pageSize,
    activePage * pageSize,
  );

  const reigsterRows = reigsterMockupData.length;

  // 페이지에 맞는 데이터 슬라이싱
  const paginatedRegisterRows = reigsterMockupData.slice(
    (activePage - 1) * pageSize,
    activePage * pageSize,
  );

  const registerColumns: readonly Column<any>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "partnerCode",
      name: "거래처",
      sortable: true,
      resizable: true,
    },
    {
      key: "createdAt",
      name: "입고일(필수)",
      sortable: true,
      resizable: true,
    },
    {
      key: "id",
      name: "식별자",
      sortable: true,
      resizable: true,
    },
  ];

  const columns: readonly Column<any>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "id",
      name: "입고번호",
      sortable: true,
      resizable: true,
    },
    {
      key: "code",
      name: "CODE",
      sortable: true,
      resizable: true,
    },
    {
      key: "itemName",
      name: "품목명",
      sortable: true,
      resizable: true,
    },
    {
      key: "spec",
      name: "규격",
      sortable: true,
      resizable: true,
    },
    {
      key: "locationCode",
      name: "창고",
      sortable: true,
      resizable: true,
    },
    {
      key: "LOTNO",
      name: "LOTNO",
      sortable: true,
      resizable: true,
    },
    {
      key: "serialNo",
      name: "시리얼번호",
      sortable: true,
      resizable: true,
    },
    {
      key: "quantity",
      name: "입고량",
      sortable: true,
      resizable: true,
    },
    {
      key: "price",
      name: "단가",
      sortable: true,
      resizable: true,
    },
    {
      key: "amount",
      name: "총 금액",
      sortable: true,
      resizable: true,
    },
    {
      key: "locationCode",
      name: "로케이션",
      sortable: true,
      resizable: true,
    },
    {
      key: "totalAmount",
      name: "총 금액",
      sortable: true,
      resizable: true,
    },
    {
      key: "test",
      name: "테스트 필요여부",
      sortable: true,
      resizable: true,
    },
    {
      key: "status",
      name: "상태",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return <Text>{row.status === "Active" ? "입고 완료" : "입고 대기"}</Text>;
      },
    },
    {
      key: "incomingStatus",
      name: "입고완료가능 여부",
      sortable: true,
      resizable: true,
    },
  ];

  return (
    <WarehousingRegister.Wrapper>
      <Flex w="100%" gap="md">
        <Flex w="35%">
          <WarehousingRegister.HeaderTitle>입고 등록</WarehousingRegister.HeaderTitle>
        </Flex>
        <Flex w="65%">
          <WarehousingRegister.HeaderTitle>입고 등록 상세</WarehousingRegister.HeaderTitle>
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" gap="md">
        <Flex w="35%">
          <GridWrapper>
            <DataGrid
              columns={registerColumns ?? []}
              rows={paginatedRegisterRows}
              rowHeight={40}
              rowKeyGetter={(row) => row.id}
              selectedRows={selectedRows}
              onSelectedRowsChange={(e) => {
                setSelectedRows(e);
                const newSelectedRows = new Set(selectedRowsRef.current);
                newSelectedRows.add(e);
                selectedRowsRef.current = newSelectedRows;
              }}
              onRowClick={(row) => {
                setSelectedRow(row);
              }}
            />
            <Pagination
              onChange={setPage}
              value={activePage}
              total={Math.ceil(reigsterRows / pageSize)} // 전체 페이지 계산
              size="lg"
              radius="sm"
              position="center"
            />
          </GridWrapper>
        </Flex>
        <Flex w="65%">
          <GridWrapper>
            <DataGrid
              columns={columns ?? []}
              rows={
                selectedRow
                  ? ShipmentStatusPageMockupData.filter(
                      (data) => data.registerId === selectedRow.id,
                    )
                  : []
              }
              rowHeight={40}
              rowKeyGetter={(row) => row.id}
              selectedRows={selectedRows}
              onSelectedRowsChange={(e) => {
                setSelectedRows(e);
                const newSelectedRows = new Set(selectedRowsRef.current);
                newSelectedRows.add(e);
                selectedRowsRef.current = newSelectedRows;
              }}
            />
            <Pagination
              onChange={setPage}
              value={activePage}
              total={Math.ceil(totalRows / pageSize)} // 전체 페이지 계산
              size="lg"
              radius="sm"
              position="center"
            />
          </GridWrapper>
        </Flex>
      </Flex>
    </WarehousingRegister.Wrapper>
  );
};

const ShipmentStatusPageMockupData = [
  {
    id: "1",
    LOTNO: "11-1111-11",
    test: "불필요",
    code: "X2001",
    itemName: "Gadget Pro",
    spec: "500ml",
    serialNo: "Z9876",
    partnerCode: "Supplier Alpha",
    date: "2024-10-30",
    quantity: 320,
    price: 28,
    amount: 8960,
    locationCode: "LOC3",
    totalAmount: 9600,
    status: "Inactive",
    registerId: "20241030-0-1",
  },
  {
    id: "2",
    LOTNO: "11-1111-12",
    test: "불필요",
    code: "L5009",
    itemName: "Widget Basic",
    spec: "1L",
    serialNo: "A1234",
    partnerCode: "Supplier Beta",
    date: "2024-10-29",
    quantity: 150,
    price: 45,
    amount: 6750,
    locationCode: "LOC2",
    totalAmount: 7200,
    status: "Active",
    registerId: "20241030-0-1",
  },
  {
    id: "3",
    LOTNO: "11-1211-12",
    test: "필요",
    code: "Z8934",
    itemName: "Component X",
    spec: "750ml",
    serialNo: "G2345",
    partnerCode: "Supplier Gamma",
    date: "2024-10-28",
    quantity: 600,
    price: 15,
    amount: 9000,
    locationCode: "LOC4",
    totalAmount: 10000,
    status: "Inactive",
    registerId: "20241030-0-1",
  },
  {
    id: "4",
    LOTNO: "11-1121-12",
    test: "불필요",
    code: "M4002",
    itemName: "Accessory Prime",
    spec: "250ml",
    serialNo: "H6543",
    partnerCode: "Supplier Delta",
    date: "2024-10-27",
    quantity: 80,
    price: 85,
    amount: 6800,
    locationCode: "LOC1",
    totalAmount: 7400,
    status: "Active",
    registerId: "20241011-0-1",
  },
  {
    id: "5",
    LOTNO: "12-1111-12",
    test: "필요",
    code: "K9876",
    itemName: "Tool Master",
    spec: "100ml",
    serialNo: "F2346",
    partnerCode: "Supplier Zeta",
    date: "2024-10-26",
    quantity: 900,
    price: 12,
    amount: 10800,
    locationCode: "LOC5",
    totalAmount: 11500,
    status: "Inactive",
    registerId: "20241812-0-1",
  },
  {
    id: "6",
    LOTNO: "11-1111-12",
    test: "불필요",
    code: "W3005",
    itemName: "Utility Gear",
    spec: "300ml",
    serialNo: "B1237",
    partnerCode: "Supplier Alpha",
    date: "2024-10-25",
    quantity: 200,
    price: 19,
    amount: 3800,
    locationCode: "LOC2",
    totalAmount: 4200,
    status: "Active",
    registerId: "20241026-0-1",
  },
  {
    id: "7",
    LOTNO: "11-1221-12",
    test: "필요",
    code: "P1123",
    itemName: "Device Ultra",
    spec: "450ml",
    serialNo: "D7543",
    partnerCode: "Supplier Beta",
    date: "2024-10-24",
    quantity: 530,
    price: 25,
    amount: 13250,
    locationCode: "LOC1",
    totalAmount: 14000,
    status: "Inactive",
    registerId: "20241019-1-1",
  },
  {
    id: "8",
    LOTNO: "18-1111-12",
    test: "필요",
    code: "T3002",
    itemName: "Component Y",
    spec: "200ml",
    serialNo: "Q8765",
    partnerCode: "Supplier Gamma",
    date: "2024-10-23",
    quantity: 110,
    price: 58,
    amount: 6380,
    locationCode: "LOC3",
    totalAmount: 6800,
    status: "Active",
    registerId: "20241019-0-1",
  },
  {
    id: "9",
    LOTNO: "11-1111-19",
    test: "불필요",
    code: "B7001",
    itemName: "Gizmo Lite",
    spec: "600ml",
    serialNo: "W2341",
    partnerCode: "Supplier Delta",
    date: "2024-10-22",
    quantity: 75,
    price: 95,
    amount: 7125,
    locationCode: "LOC5",
    totalAmount: 7800,
    status: "Inactive",
    registerId: "20241019-2-1",
  },
  {
    id: "10",
    LOTNO: "11-1010-12",
    test: "불필요",
    code: "C1003",
    itemName: "Accessory Lite",
    spec: "50ml",
    serialNo: "K1239",
    partnerCode: "Supplier Zeta",
    date: "2024-10-21",
    quantity: 850,
    price: 8,
    amount: 6800,
    locationCode: "LOC4",
    totalAmount: 7500,
    status: "Active",
    registerId: "20241019-1-1",
  },
  {
    id: "11",
    LOTNO: "10-1010-12",
    test: "필요",
    code: "N8004",
    itemName: "Gadget Deluxe",
    spec: "700ml",
    serialNo: "U7632",
    partnerCode: "Supplier Alpha",
    date: "2024-10-20",
    quantity: 95,
    price: 92,
    amount: 8740,
    locationCode: "LOC1",
    totalAmount: 9500,
    status: "Inactive",
    registerId: "20241018-1-1",
  },
  {
    id: "12",
    LOTNO: "12-1210-12",
    test: "불필요",
    code: "V6008",
    itemName: "Device Mini",
    spec: "900ml",
    serialNo: "I9876",
    partnerCode: "Supplier Beta",
    date: "2024-10-19",
    quantity: 100,
    price: 10,
    amount: 1000,
    locationCode: "LOC2",
    totalAmount: 1100,
    status: "Active",
    registerId: "20241018-0-1",
  },
  {
    id: "13",
    LOTNO: "13-1010-12",
    test: "불필요",
    code: "R5555",
    itemName: "Component Prime",
    spec: "800ml",
    serialNo: "O2345",
    partnerCode: "Supplier Gamma",
    date: "2024-10-18",
    quantity: 75,
    price: 82,
    amount: 6150,
    locationCode: "LOC3",
    totalAmount: 7000,
    status: "Inactive",
    registerId: "20241011-1-1",
  },
  {
    id: "14",
    LOTNO: "11-1010-14",
    test: "필요",
    code: "S9888",
    itemName: "Tool Ultra",
    spec: "1L",
    serialNo: "L6543",
    partnerCode: "Supplier Delta",
    date: "2024-10-17",
    quantity: 300,
    price: 33,
    amount: 9900,
    locationCode: "LOC4",
    totalAmount: 10500,
    status: "Active",
    registerId: "20241011-0-1",
  },
  {
    id: "15",
    LOTNO: "11-1010-15",
    test: "필요",
    code: "E1239",
    itemName: "Utility Master",
    spec: "350ml",
    serialNo: "P2341",
    partnerCode: "Supplier Zeta",
    date: "2024-10-16",
    quantity: 420,
    price: 17,
    amount: 7140,
    locationCode: "LOC5",
    totalAmount: 7700,
    status: "Inactive",
    registerId: "20241005-0-1",
  },
  {
    id: "16",
    LOTNO: "11-1010-16",
    test: "불필요",
    code: "Y1002",
    itemName: "Gizmo Prime",
    spec: "400ml",
    serialNo: "C9872",
    partnerCode: "Supplier Alpha",
    date: "2024-10-15",
    quantity: 630,
    price: 21,
    amount: 13230,
    locationCode: "LOC2",
    totalAmount: 14000,
    status: "Active",
    registerId: "20241004-3-1",
  },
  {
    id: "17",
    LOTNO: "11-1010-17",
    test: "불필요",
    code: "F4321",
    itemName: "Gadget Master",
    spec: "450ml",
    serialNo: "V2346",
    partnerCode: "Supplier Beta",
    date: "2024-10-14",
    quantity: 200,
    price: 50,
    amount: 10000,
    locationCode: "LOC1",
    totalAmount: 11000,
    status: "Inactive",
    registerId: "20241004-3-1",
  },
  {
    id: "18",
    LOTNO: "18-1010-12",
    test: "필요",
    code: "G6789",
    itemName: "Accessory Deluxe",
    spec: "600ml",
    serialNo: "X8765",
    partnerCode: "Supplier Gamma",
    date: "2024-10-13",
    quantity: 120,
    price: 60,
    amount: 7200,
    locationCode: "LOC3",
    totalAmount: 8000,
    status: "Active",
    registerId: "20241004-1-1",
  },
  {
    id: "19",
    LOTNO: "11-1010-19",
    test: "필요",
    code: "H2345",
    itemName: "Tool Basic",
    spec: "500ml",
    serialNo: "B9876",
    partnerCode: "Supplier Delta",
    date: "2024-10-12",
    quantity: 900,
    price: 15,
    amount: 13500,
    locationCode: "LOC4",
    totalAmount: 15000,
    status: "Inactive",
    registerId: "20230904-1-1",
  },
  {
    id: "20",
    LOTNO: "21-1010-12",
    test: "불필요",
    code: "I1298",
    itemName: "Device Pro",
    spec: "750ml",
    serialNo: "R2345",
    partnerCode: "Supplier Zeta",
    date: "2024-10-11",
    quantity: 500,
    price: 55,
    amount: 27500,
    locationCode: "LOC5",
    totalAmount: 29000,
    status: "Active",
    registerId: "20230904-0-1",
  },
  {
    id: "21",
    LOTNO: "21-1010-21",
    test: "필요",
    code: "J4328",
    itemName: "Component Lite",
    spec: "800ml",
    serialNo: "Z1234",
    partnerCode: "Supplier Alpha",
    date: "2024-10-10",
    quantity: 75,
    price: 80,
    amount: 6000,
    locationCode: "LOC1",
    totalAmount: 6500,
    status: "Inactive",
    registerId: "20230828-1-1",
  },
  {
    id: "22",
    LOTNO: "21-12210-21",
    test: "불필요",
    code: "K7776",
    itemName: "Utility Basic",
    spec: "1L",
    serialNo: "T9876",
    partnerCode: "Supplier Beta",
    date: "2024-10-09",
    quantity: 620,
    price: 28,
    amount: 17360,
    locationCode: "LOC2",
    totalAmount: 18000,
    status: "Active",
    registerId: "20230828-0-1",
  },
  {
    id: "23",
    LOTNO: "23-1010-21",
    test: "불필요",
    code: "L8889",
    itemName: "Gizmo Ultra",
    spec: "300ml",
    serialNo: "D9876",
    partnerCode: "Supplier Gamma",
    date: "2024-10-08",
    quantity: 450,
    price: 19,
    amount: 8550,
    locationCode: "LOC3",
    totalAmount: 9100,
    status: "Inactive",
    registerId: "20230824-0-1",
  },
  {
    id: "24",
    LOTNO: "21-1240-21",
    test: "필요",
    code: "M1234",
    itemName: "Tool Lite",
    spec: "150ml",
    serialNo: "H1234",
    partnerCode: "Supplier Delta",
    date: "2024-10-07",
    quantity: 700,
    price: 14,
    amount: 9800,
    locationCode: "LOC4",
    totalAmount: 10500,
    status: "Active",
    registerId: "20230824-0-1",
  },
  {
    id: "25",
    LOTNO: "21-1010-25",
    test: "불필요",
    code: "N4321",
    itemName: "Accessory Pro",
    spec: "250ml",
    serialNo: "G6543",
    partnerCode: "Supplier Zeta",
    date: "2024-10-06",
    quantity: 340,
    price: 32,
    amount: 10880,
    locationCode: "LOC5",
    totalAmount: 11500,
    status: "Inactive",
    registerId: "20241019-0-1",
  },
  {
    id: "26",
    LOTNO: "26-1010-26",
    test: "필요",
    code: "O9876",
    itemName: "Component Pro",
    spec: "100ml",
    serialNo: "V2345",
    partnerCode: "Supplier Alpha",
    date: "2024-10-05",
    quantity: 900,
    price: 22,
    amount: 19800,
    locationCode: "LOC1",
    totalAmount: 21000,
    status: "Active",
    registerId: "20241030-1-1",
  },
  {
    id: "27",
    LOTNO: "26-1010-21",
    test: "불필요",
    code: "P6543",
    itemName: "Device Prime",
    spec: "1L",
    serialNo: "W2341",
    partnerCode: "Supplier Beta",
    date: "2024-10-04",
    quantity: 1000,
    price: 30,
    amount: 30000,
    locationCode: "LOC2",
    totalAmount: 32000,
    status: "Inactive",
    registerId: "20241018-0-1",
  },
  {
    id: "28",
    LOTNO: "21-1010-28",
    test: "필요",
    code: "Q1239",
    itemName: "Gizmo Pro",
    spec: "500ml",
    serialNo: "M9876",
    partnerCode: "Supplier Gamma",
    date: "2024-10-03",
    quantity: 500,
    price: 35,
    amount: 17500,
    locationCode: "LOC3",
    totalAmount: 19000,
    status: "Active",
    registerId: "20241019-1-1",
  },
  {
    id: "29",
    LOTNO: "21-1010-29",
    test: "불필요",
    code: "R4567",
    itemName: "Utility Lite",
    spec: "750ml",
    serialNo: "L1239",
    partnerCode: "Supplier Delta",
    date: "2024-10-02",
    quantity: 80,
    price: 65,
    amount: 5200,
    locationCode: "LOC4",
    totalAmount: 5800,
    status: "Inactive",
    registerId: "20241011-0-1",
  },
  {
    id: "30",
    LOTNO: "21-1010-30",
    test: "필요",
    code: "S3333",
    itemName: "Tool Pro",
    spec: "450ml",
    serialNo: "Q8765",
    partnerCode: "Supplier Zeta",
    date: "2024-10-01",
    quantity: 720,
    price: 18,
    amount: 12960,
    locationCode: "LOC5",
    totalAmount: 13500,
    status: "Active",
    registerId: "20231004-0-1",
  },
];

const reigsterMockupData = [
  {
    partnerCode: "A거래처",
    createdAt: "2024-10-11",
    id: "20241011-0-1",
  },
  {
    partnerCode: "춘식",
    createdAt: "2024-08-12",
    id: "20240812-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-03-22",
    id: "20240322-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-11-01",
    id: "20241101-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-10-30",
    id: "20241030-1-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-10-30",
    id: "20241030-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-10-26",
    id: "20241026-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-10-19",
    id: "20241019-1-1",
  },
  {
    partnerCode: "거래처",
    createdAt: "2024-10-19",
    id: "20241019-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-10-19",
    id: "20241019-2-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-10-18",
    id: "20241018-1-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-10-18",
    id: "20241018-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-10-11",
    id: "20241011-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-10-05",
    id: "20241005-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-10-04",
    id: "20241004-3-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-10-04",
    id: "20241004-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2024-10-04",
    id: "20241004-1-1",
  },
  {
    partnerCode: "거래처",
    createdAt: "2023-09-04",
    id: "20230904-1-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-09-04",
    id: "20230904-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-08-28",
    id: "20230828-1-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-08-28",
    id: "20230828-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-08-24",
    id: "20230824-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-08-23",
    id: "20230823-5-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-08-23",
    id: "20230823-4-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-08-23",
    id: "20230823-2-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-08-23",
    id: "20230823-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-08-18",
    id: "20230818-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-08-14",
    id: "20230814-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-08-09",
    id: "20230809-1-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-08-09",
    id: "20230809-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-08-04",
    id: "20230804-1-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-08-04",
    id: "20230804-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-28",
    id: "20230728-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-28",
    id: "20230728-2-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-28",
    id: "20230728-1-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-26",
    id: "20230726-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-26",
    id: "20230726-1-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-25",
    id: "20230725-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-24",
    id: "20230724-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-20",
    id: "20230720-1-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-20",
    id: "20230720-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-19",
    id: "20230719-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-17",
    id: "20230717-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-14",
    id: "20230714-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-12",
    id: "20230712-0-1",
  },
  {
    partnerCode: "work",
    createdAt: "2023-07-11",
    id: "20230711-0-1",
  },
  {
    partnerCode: "거래처",
    createdAt: "2023-07-06",
    id: "20230706-2-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-06",
    id: "20230706-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-04",
    id: "20230704-3-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-04",
    id: "20230704-2-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-04",
    id: "20230704-1-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-04",
    id: "20230704-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-03",
    id: "20230703-6-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-03",
    id: "20230703-5-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-03",
    id: "20230703-4-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-03",
    id: "20230703-3-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-07-03",
    id: "20230703-2-1",
  },
  {
    partnerCode: "work",
    createdAt: "2023-07-03",
    id: "20230703-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-06-30",
    id: "20230630-2-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-06-30",
    id: "20230630-1-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-06-30",
    id: "20230630-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-06-27",
    id: "20230627-0-1",
  },
  {
    partnerCode: "태한",
    createdAt: "2023-06-21",
    id: "20230621-0-1",
  },
];

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    // --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    // --rdg-header-background-color: {(props) =>
    // props.isDarkMode ? "white" : "none"};
    // --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    // --rdg-row-hover-background-color: {(props) =>
    // props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;
