import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { Flex, Radio, Table, Text, Title } from "@mantine/core";
import {
  ProductionPlansConsumedLotIdLotIdGet200Response,
  WorkLogsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TableContainer } from "../../components/ledgerTracking";
import { Container, Tbody, Td, Th, Thead, Tr } from "../../forward/components/forwardSummary";
import { useLotTrackingActions } from "../../hook/useLotTrackingActions";
import { useLotTrackingState } from "../../hook/useLotTrackingState";

const Detail = Object.assign(DetailHeader, {
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
});

interface LotTrackingSummaryContentProps {
  consumedLotData?: ProductionPlansConsumedLotIdLotIdGet200Response[] | undefined;
}

export const LotTrackingReverceSummaryContent = (params: LotTrackingSummaryContentProps) => {
  const { consumedLotData } = params;

  const { openModal } = useModal();
  const navigage = useNavigate();
  const actions = useLotTrackingActions();
  const state = useLotTrackingState();
  const { t } = useTranslation();

  return (
    <Container>
      <Title p={8} order={3}>
        {t("lotTracking.summary")}
      </Title>
      <Text fz={12} px={8}>
        {t("lotTracking.reverceSummary")}
      </Text>
      <TableContainer>
        <Table>
          <Thead backgroundColor="var(--pink-0, #FFF0F6)">
            <Tr>
              <Th flex={0.1}></Th>
              <Th flex={1}>{t("item.item")}</Th>
              <Th flex={1}>{t("lot.name")}</Th>
              <Th flex={1}>{t("productionPlan.productionPlan")}</Th>
            </Tr>
          </Thead>
          {consumedLotData?.length === 0 ? (
            <Flex justify={"center"} align={"center"}>
              <Text px={8}>{t("common.notFound")}</Text>
            </Flex>
          ) : (
            <Tbody>
              {consumedLotData?.map((item, index) => {
                const handleRadioClick = (planId: WorkLogsGet200ResponseRowsInner) => {
                  actions.setProductionPlan(planId);
                };
                return (
                  <Tr key={index}>
                    <Td flex={0.1}>
                      <Flex justify={"center"} align={"center"} pt="md">
                        <Radio
                          name="productionPlan"
                          value={item.productionPlanId}
                          checked={state.productionPlan?.productionPlanId === item.productionPlanId}
                          onChange={() => handleRadioClick(item)}
                        />
                      </Flex>
                    </Td>
                    <Td flex={1}>
                      <Flex align={"center"} pt="md">
                        <Detail.LinkWrapper>
                          <Detail.Link
                            onClick={() =>
                              openModal(<ItemsDetailForm itemCode={item.itemCode} />, "", "")
                            }
                          >
                            {item.itemName}
                          </Detail.Link>
                        </Detail.LinkWrapper>
                      </Flex>
                    </Td>
                    <Td flex={1}>
                      <Flex pt="md">{item.lotName ?? "-"}</Flex>
                    </Td>
                    <Td flex={1}>
                      <Flex direction={"column"} align={"flex-end"}>
                        <Detail.Badge>{t("productionPlan.productionPlan")}</Detail.Badge>
                        <Detail.Link
                          onClick={() =>
                            openModal(
                              <ProductionPlanViewForm ProductionPlanId={item?.productionPlanId} />,
                              "",
                              "",
                            )
                          }
                        >
                          {item.productionPlanId}
                        </Detail.Link>
                      </Flex>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
    </Container>
  );
};
