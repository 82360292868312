import { Button, Text, TextInput } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { OutgoingAllFormItemProps, Td } from "./OutgoingAllForm";

export const BulkAllFormRow: React.FC<OutgoingAllFormItemProps> = ({
  formData,
  seq,
  onChange,
  onDelete,
}) => {
  const { lotId, lotName, itemCode, itemName, spec, quantity } = formData;

  return (
    <tr>
      <Td width={7}>
        <Text align="right">{seq + 1}</Text>
      </Td>
      <Td width={14}>{lotId}</Td>
      <Td width={14}>{lotName}</Td>
      <Td width={14}>{itemCode}</Td>
      <Td width={14}>{itemName}</Td>
      <Td width={14}>{spec}</Td>
      <Td width={14}>
        <TextInput type="number" value={quantity} onChange={(e) => onChange(seq, e.target.value)} />
      </Td>
      <Td width={7}>
        <Button leftIcon={<IconTrash />} color="red" variant="white" onClick={() => onDelete(seq)}>
          삭제
        </Button>
      </Td>
    </tr>
  );
};
