import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import {
  ActionBarContainer,
  LeftActionBar,
  RightActionBar,
} from "@/features/work/WorkView/ActionBar";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Button } from "@mantine/core";
import { ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { OrderStatusForm } from "../form/statusForm";
import { OrderUpdateForm } from "../form/updateForm";
import { OrderDetailHeaderProps } from "./orderDetailHeader";

export const OrderDetailActionBar = (params: OrderDetailHeaderProps) => {
  const { data } = params;
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: deleteMutate } = useMutation(
    (params: ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest) =>
      mutatePurchaseOrders
        .delete(params)
        .mutationFn(params as any | ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["purchaseOrders"]);
      },
    },
  );

  const deleteOrder = () => {
    if (window.confirm(t("message.deleteItem", { item: data?.id }))) {
      deleteMutate(
        {
          purchaseOrderId: data?.id ?? 0,
        },
        {
          onSuccess: (res) => {
            if (res.status === 200) {
              customAlert(
                t("message.deleteSuccess", { item: t("order.order") }),
                t("common.deleteSuccess"),
                "green",
              );
            }
          },
          onError: (error: any) => {
            customAlert(
              handleErrorResponse({
                error,
                errorContext: t("order.code"),
              }),
              t("message.deleteFail", { item: `${t("order.order")}` }),
              "red",
            );
          },
        },
      );
    }
  };
  return (
    <ActionBarContainer>
      <LeftActionBar>
        <Button
          variant="outline"
          onClick={() =>
            openModal(
              <OrderStatusForm formatterProps={data} />,
              null,
              t("common.change", { item: t("order.status") }),
            )
          }
        >
          {t("common.change", { item: t("order.status") })}
        </Button>
      </LeftActionBar>

      <RightActionBar>
        <Button
          color="orange"
          rightIcon={<IconEdit />}
          onClick={() =>
            openModal(
              <OrderUpdateForm formatterProps={data} />,
              null,
              t("common.edit", { item: t("order.order") }),
            )
          }
        >
          {t("workButton.updated")}
        </Button>
        <Button color="red" rightIcon={<IconTrash />} onClick={deleteOrder}>
          {t("workButton.delete")}
        </Button>
      </RightActionBar>
    </ActionBarContainer>
  );
};
