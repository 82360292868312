import { useStandardLayout } from "@/features/standardLayout/Context";
import { Pagination } from "@mantine/core";
import { AxiosResponse } from "axios";

export const PaginationBox = <
  TResponse extends { total?: number; page?: number; pageSize?: number; totalPages?: number },
>() => {
  const { data, setSelectedRows, setPage } = useStandardLayout<AxiosResponse<TResponse>>();
  const response = data?.data;
  const total = response?.totalPages ?? 0;
  const page = response?.page ?? 0;

  return (
    <Pagination
      position="center"
      onChange={(e) => {
        setSelectedRows((): Set<any> => new Set());
        setPage(e);
      }}
      value={page}
      total={total}
      size="lg"
      radius="sm"
    />
  );
};
