import DefaultInstance from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  DefaultApiAbstractItemsAbstractItemIdDeleteRequest,
  DefaultApiAbstractItemsAbstractItemIdPutRequest,
  DefaultApiAbstractItemsFindPostRequest,
  DefaultApiAbstractItemsGetRequest,
  DefaultApiAbstractItemsPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const abstractItems = createQueryKeys("abstractItems", {
  all: null,
  get: (params: DefaultApiAbstractItemsGetRequest) => {
    // const queryParams = { ...params };
    const queryParams = {
      ...params,
      query: JSON.stringify({ $and: params?.query }),
      sort: params.sort?.length ? params.sort : "-id",
    }; // 품목군 조회 시 기본 정렬 표시

    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => DefaultInstance.abstractItemsGet(queryParams),
    };
  },
  // get: (params: DefaultApiAbstractItemsGetRequest) => {
  //     const query = { ...params };
  //     if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
  //         query["query"] = JSON.stringify(params.query);
  //     }
  //     if (params.search) {
  //         query["search"] = params.search;
  //         query["searchFields"] = params.searchFields;
  //     }
  //     return {
  //         queryKey: [query, JSON.stringify(query.query)],
  //         queryFn: () => DefaultInstance.abstractItemsGet(query)
  //     }
  // },
  find: (params: DefaultApiAbstractItemsFindPostRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.abstractItemsFindPost(query),
    };
  },
});

export const mutateAbstractItems = createMutationKeys("abstractItems", {
  create: (params: DefaultApiAbstractItemsPostRequest) => ({
    mutationKey: [params.abstractItemsGetRequest],
    mutationFn: () => DefaultInstance.abstractItemsPost(params),
  }),
  update: (params: DefaultApiAbstractItemsAbstractItemIdPutRequest) => ({
    mutationKey: [params.abstractItemId, params.abstractItemsGetRequest],
    mutationFn: () => DefaultInstance.abstractItemsAbstractItemIdPut(params),
  }),
  delete: (params: DefaultApiAbstractItemsAbstractItemIdDeleteRequest) => ({
    mutationKey: [params.abstractItemId],
    mutationFn: () => DefaultInstance.abstractItemsAbstractItemIdDelete(params),
  }),
});

export const abstractItemsQueryKeys = mergeQueryKeys(abstractItems, mutateAbstractItems);
