import { MasterInstance } from "@/instance/axios";
import {
  ItemsGet200Response,
  ItemsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export type ItemOption = {
  value: string;
  label: string;
  group: string;
  unitText?: string;
};
const getAutoCompleteKeyword = (keyword: string) =>
  MasterInstance.itemsFindPost(
    {
      search: keyword,
      searchFields: ["name", "code"],
      populate: ["itemCategoryId"],
    },
    {
      params: {
        query: {
          itemType: ["MATERIAL", "SUBSIDIARY", "SEMI_PRODUCT"],
        },
      },
    },
  );

export const useItemsAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: ItemOption[]) => void;
  },
) => {
  return useQuery(
    ["items-get-autocomplete-keyword", keyword],
    () => getAutoCompleteKeyword(keyword),
    {
      enabled: focus,
      select: (data: AxiosResponse<ItemsGet200ResponseRowsInner[], any>) =>
        data?.data?.map((item) => {
          return {
            value: item.code,
            label: item.name,
            group: item.itemType,
            unitText: item.unitText,
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    },
  );
};

const getItemByCode = (code: string | null) =>
  MasterInstance.itemsGet({
    ...(code && { search: code }),
    searchFields: ["code"],
  });

export const useItemsCode = (
  enabled: boolean = false,
  code: string | null,
  options?: {
    onSuccess: (data?: ItemOption[]) => void;
  },
) => {
  return useQuery(["items-get-code", code], () => getItemByCode(code), {
    enabled,
    select: (data: AxiosResponse<ItemsGet200Response, any>) =>
      data?.data?.rows?.map((item) => {
        return {
          value: item.code,
          label: item.name,
          group: item.itemType,
          unitText: item.unitText,
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
