import en from "@/language/en.json";
import ko from "@/language/ko.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    ko: {
      translation: ko,
    },
  },
  lng: "ko",
  fallbackLng: {
    "ko-KR": ["ko-KR"],
    default: ["en-US"],
  },
  returnObjects: true,
  debug: false,
  defaultNS: "translation",
  ns: "translation",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
