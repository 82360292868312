import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import styled from "@emotion/styled";
import { Flex, Radio, Table, Text, Title } from "@mantine/core";
import {
  ProductionPlansConsumedLotIdLotIdGet200Response,
  WorkLogsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TableContainer } from "../../components/ledgerTracking";
import { useLotTrackingActions } from "../../hook/useLotTrackingActions";
import { useLotTrackingState } from "../../hook/useLotTrackingState";

const Detail = Object.assign(DetailHeader, {
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
});

export interface LotTrackingSummaryContentProps {
  consumedLotData?: ProductionPlansConsumedLotIdLotIdGet200Response[] | undefined;
  reverce?: boolean;
}

export const LotTrackingForwardSummaryContent = (params: LotTrackingSummaryContentProps) => {
  const { consumedLotData } = params;
  const navigate = useNavigate();
  const { openModal } = useModal();
  const actions = useLotTrackingActions();
  const state = useLotTrackingState();
  const { t } = useTranslation();

  return (
    <Container>
      <Title p={8} order={3}>
        {t("lotTracking.summary")}
      </Title>
      <Text fz={12} px={8}>
        {t("lotTracking.forwardSummary")}
      </Text>
      <TableContainer>
        <Table>
          <Thead backgroundColor="var(--pink-0, #FFF0F6)">
            <Tr>
              <Th flex={0.1}> </Th>
              <Th flex={1}>{t("item.item")}</Th>
              <Th flex={1}>{t("lot.name")}</Th>
              <Th flex={1}>{t("productionPlan.productionPlan")}</Th>
              <Th flex={1}></Th>
            </Tr>
          </Thead>
          {consumedLotData?.length === 0 ? (
            <Flex justify={"center"} align={"center"}>
              <Text px={8}>{t("common.notFound")}</Text>
            </Flex>
          ) : (
            <Tbody>
              {consumedLotData?.map((item, index) => {
                const handleRadioClick = (plan: WorkLogsGet200ResponseRowsInner) => {
                  actions.setProductionPlan(plan);
                };
                return (
                  <Tr key={index}>
                    <Td flex={0.1}>
                      <Flex justify={"center"} align={"center"} pt="md">
                        <Radio
                          name="productionPlan"
                          value={item?.productionPlanId}
                          checked={state.productionPlan?.productionPlanId === item.productionPlanId}
                          onChange={() => handleRadioClick(item)}
                        />
                      </Flex>
                    </Td>
                    <Td flex={1}>
                      <Flex align={"center"} pt="md">
                        <Detail.LinkWrapper>
                          <Detail.Link
                            onClick={() =>
                              openModal(<ItemsDetailForm itemCode={item?.itemCode} />, "", "")
                            }
                          >
                            {item?.itemName ?? "-"}
                          </Detail.Link>
                        </Detail.LinkWrapper>
                      </Flex>
                    </Td>
                    <Td flex={1}>
                      <Flex pt="md">{item?.lotName ?? "-"}</Flex>
                    </Td>
                    <Td flex={1}>
                      <Flex align={"flex-end"} direction={"column"}>
                        <Detail.Badge>{t("productionPlan.productionPlan")}</Detail.Badge>
                        <Detail.Link
                          onClick={() =>
                            openModal(
                              <ProductionPlanViewForm ProductionPlanId={item?.productionPlanId} />,
                              "",
                              "",
                            )
                          }
                        >
                          {item.productionPlanId}
                        </Detail.Link>
                      </Flex>
                    </Td>
                    <Td flex={1}>
                      <Flex pt="md">
                        <Detail.LinkWrapper>
                          <Detail.Link
                            onClick={() => {
                              navigate("/lotReverce", {
                                state: { value: "click" },
                              });
                              actions.setItemCodeValue(item?.itemCode ?? "");
                              actions.setLotId(item.lotId?.toString() ?? "");
                            }}
                          >
                            {t("lotTracking.reverceGo")}
                          </Detail.Link>
                        </Detail.LinkWrapper>
                      </Flex>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  max-height: 334px;
  padding: 8px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: var(--pink-0, #fff0f6);
  overflow: auto;
`;

export const Thead = styled.thead<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${(props) => props.backgroundColor ?? "inherit"};
`;

export const Tr = styled.tr`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  width: 100%;
  line-height: inherit;
`;

export const Th = styled.th<{ flex?: number }>`
  display: flex;
  height: 40px;
  padding: 7px 10px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: ${(props) => (props.flex ? props.flex : `1 0 0`)};
`;

export const Tbody = styled.tbody`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const Td = styled.td<{ flex?: number }>`
  padding: 7px 10px;
  height: 70px;
  align-items: flex-start;
  gap: 10px;
  word-break: break-word;
  flex: ${(props) => (props.flex ? props.flex : `1 0 0`)};
`;
