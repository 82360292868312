import { GridCell, GridCellKind } from "@glideapps/glide-data-grid";
import { itemTypeColor } from "./itemTypes";

const LINK_COLOR = "#1E90FF";

// 기본버튼셀
export const buttonCell = (
  title: string,
  onClick: (() => void) | null,
  color = LINK_COLOR,
  additionalData: Record<string, unknown> = {},
): GridCell => ({
  kind: GridCellKind.Custom,
  allowOverlay: false,
  copyData: title.includes("🔗") ? title.replace("🔗", "") : title,
  data: {
    kind: "button-cell",
    title,
    onClick,
    color,
    ...additionalData,
  },
});

// 텍스트셀 (클릭 x)
export const textCell = (data = "", displayData = data, copyData = data): GridCell => ({
  kind: GridCellKind.Text,
  data,
  allowOverlay: false,
  displayData,
  copyData,
});
export const numCell = (data = "", displayData = data, copyData = data): GridCell => ({
  kind: GridCellKind.Text,
  data,
  allowOverlay: false,
  displayData,
  copyData,
  contentAlign: "right",
});

// 품목타입셀
export const badgeCell = (itemType: string) => {
  const { nm, bgcolor, color } = itemTypeColor[itemType];
  return buttonCell(nm, null, color, {
    backgroundColor: bgcolor,
    // borderColor: color,
    borderRadius: 12,
  });
};

// 링크용셀
export const linkCell = (nm: string, func: () => void) => {
  return buttonCell(nm, func, LINK_COLOR, {
    color: "#1E90FF",
  });
};
