// @fileoverview : 기준정보 > 불량

import { mutateDefect } from "@/api/defect/useDefectsQuery";
import { DefectCreateForm } from "@/features/defect/components/form/DefectCreateForm";
import { DefectDetailForm } from "@/features/defect/components/form/DefectDetailForm";
import { DefectUpdateForm } from "@/features/defect/components/form/DefectUpdateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { OperationDetailForm } from "@/features/operations/components/form/operationDetailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import {
  DefaultApiDefectsDefectCodeDeleteRequest,
  DefectsGet200Response,
  DefectsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const Defects = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { t } = useTranslation();
  const { mutate: deleteMutate } = useMutation(
    (params: DefaultApiDefectsDefectCodeDeleteRequest) =>
      mutateDefect
        .delete(params)
        .mutationFn(params as DefaultApiDefectsDefectCodeDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          t("message.deleteSuccess", { item: `${t("defect.defect")}` }),
          t("common.deleteSuccess"),
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("defect.code") }),
          t("common.deleteFail", { item: t("defect.defect") }),
          "red",
        );
      },
    },
  );

  const columns: readonly Column<DefectsGet200ResponseRowsInner>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "code",
        name: t("defect.code"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(<DefectDetailForm defectCode={formatterProps.row.code} />, null, "")
              }
            >
              {formatterProps.row.code}
            </LinkAnchor>
          );
        },
      },
      {
        key: "name",
        name: t("defect.name"),
        sortable: true,
        resizable: true,
      },
      {
        key: "operationCode",
        name: t("operation.code"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <OperationDetailForm operationCode={formatterProps.row.operationCode} />,
                  null,
                  "",
                )
              }
            >
              {formatterProps.row.operationCode}
            </LinkAnchor>
          );
        },
      },
      {
        key: "options",
        name: t("common.action"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const updateDefectActionView = () => {
            openModal(
              <DefectUpdateForm formatterProps={formatterProps?.row} />,
              null,
              `${t("common.edit", { item: `${t("defect.defect")}` })}`,
              true,
            );
          };

          // DefectsGet200ResponseRowsInner 타입에서 defectCode를 뜻하는 code가 선택적 매개변수로 정의되어 있어, string으로 타입 캐스팅
          const deleteDefectAction = () => {
            openModal(
              <ConfirmForm
                message={t("message.rowDelete")}
                yesCallback={() => deleteMutate({ defectCode: formatterProps?.row.code as string })}
                noCallback={() => {}}
                commonCallback={() => closeModal(null)}
              />,
              null,
              `${t("common.delete", { item: `${t("defect.defect")}` })}`,
            );
          };

          return (
            <Flex w="100%" h="100%" justify="center" align="center" gap="md">
              <ActionIcon variant="subtle" onClick={updateDefectActionView} color="yellow">
                <IconEdit />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={() => deleteDefectAction()} color="red">
                <IconTrash />
              </ActionIcon>
            </Flex>
          );
        },
      },
    ],
    [deleteMutate, openModal, closeModal, t],
  );

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: `${t("defect.defect")}` })}`,
        `${t("common.deleteFail", { item: `${t("defect.defect")}` })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ defectCode: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      `${t("common.delete", { item: `${t("defect.defect")}` })}`,
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader
          createFn={() =>
            openModal(
              <DefectCreateForm />,
              null,
              `${t("common.additional", { item: `${t("defect.defect")}` })}`,
              true,
            )
          }
          createDataTitle={t("defect.defect")}
          deleteFn={() => handleDelete()}
        />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: `${t("defect.defect")}` })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${t("defect.defect")}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<DefectsGet200Response, DefectsGet200ResponseRowsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<DefectsGet200Response> />
      </Flex>
    </Flex>
  );
};
