import { useModal } from "@/features/modal/ModalStackManager";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { theme } from "@/styles/theme";
import { Button, Flex } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { InspectCriteriaCreateForm } from "../form/InspectCriteriaCreateForm";

const DetailRouting = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
  Button: Button,
});

interface InspectCriteriaInformProps {
  data?: any;
}

export const InspectCriteriaCreateInform = (params: InspectCriteriaInformProps) => {
  const { data } = params;
  const { t } = useTranslation();
  const { openModal } = useModal();

  return (
    <DetailRouting bg={"#FFFFFF"}>
      <Flex w={"100%"} justify={"space-between"} align={"center"}>
        <DetailRouting.Title size={26}>{t("inspect.inspectSpecification")}</DetailRouting.Title>
        <DetailRouting.Button
          disabled={data.criteria?.length || !data.itemCode}
          bg={theme.colors?.blue?.[6]}
          rightIcon={<IconEdit />}
          onClick={() => {
            openModal(
              <InspectCriteriaCreateForm data={data} />,
              "",
              t("common.additional", { item: t("inspect.inspectSpecification") }),
              true,
            );
          }}
        >
          {t("common.additional", { item: t("inspect.inspectSpecification") })}
        </DetailRouting.Button>
      </Flex>
      <DetailRouting.Inform>{t("inspect.subTitle")}</DetailRouting.Inform>
    </DetailRouting>
  );
};
