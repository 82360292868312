import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Main } from "@/features/standard/Main";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { getUserCodeByUser } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Select, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  PurchaseOrdersGet200ResponseRowsInner,
  ScmApiPurchaseOrdersPurchaseOrderIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Update = Object.assign({}, Main, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  DateInput: DateInput,
});

interface PurchaseOrderUpdateFormProps {
  formatterProps: PurchaseOrdersGet200ResponseRowsInner | undefined;
}

export const PurchaseOrderUpdateForm = (params: PurchaseOrderUpdateFormProps) => {
  const { formatterProps } = params;
  const userCode = getUserCodeByUser();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: updateMutate } = useMutation(
    (params: ScmApiPurchaseOrdersPurchaseOrderIdPutRequest) =>
      mutatePurchaseOrders
        .update(params)
        .mutationFn(params as ScmApiPurchaseOrdersPurchaseOrderIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["purchaseOrders"]);
        closeModal(formatterProps);
        customAlert(
          `${userCode === "PB" ? t("message.editSuccess", { item: t("order.order") }) : t("message.editSuccess", { item: t("purchaseOrder.purchaseOrder") })}`,
          `${userCode === "PB" ? t("common.editSuccess", { item: t("order.order") }) : t("common.editSuccess", { item: t("purchaseOrder.purchaseOrder") })}`,
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({
            error,
            errorContext: `${userCode === "PB" ? t("order.order") : t("purchaseOrder.purchaseOrder")}`,
          }),
          `${userCode === "PB" ? t("common.editFail", { item: t("order.order") }) : t("common.editFail", { item: t("purchaseOrder.purchaseOrder") })}`,
          "red",
        );
      },
    },
  );

  const { closeModal } = useModal();

  const form = useForm({
    initialValues: {
      partnerCode: formatterProps?.partnerCode,
      status: formatterProps?.status,
      code: formatterProps?.code,
      userCode: formatterProps?.userCode,
      sourceLocationCode: formatterProps?.sourceLocationCode,
      orderDate: formatterProps?.orderDate ? new Date(formatterProps.orderDate) : null,
      deliveryDeadline: formatterProps?.deliveryDeadline
        ? new Date(formatterProps.deliveryDeadline)
        : null,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate({
        purchaseOrderId: formatterProps?.id ?? 0,
        purchaseOrdersGetRequest: {
          direction: "INBOUND",
          partnerCode: form.values.partnerCode!,
          code: form.values.code!,
          status: form.values.status!,
          userCode: form.values.userCode!,
          sourceLocationCode: form.values.sourceLocationCode!,
          orderDate: form.values.orderDate?.toDateString()!,
          deliveryDeadline: form.values.deliveryDeadline?.toDateString()!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({
          error,
          errorContext: `${userCode === "PB" ? t("order.order") : t("purchaseOrder.purchaseOrder")}`,
        }),
        `${userCode === "PB" ? t("common.editFail", { item: t("order.order") }) : t("common.editFail", { item: t("purchaseOrder.purchaseOrder") })}`,
        "red",
      );
    }
  };

  return (
    <>
      <Update.Wrapper>
        <PartnerAutoComplete
          label={t("partner.partner")}
          placeholder={t("placeholder.select", { item: t("partner.partner") })}
          defaultValue={formatterProps?.partnerCode}
          {...form.getInputProps("partnerCode")}
          error={form.isDirty("partnerCode") ? t("common.toSave") : ""}
        />
        <Update.Input
          label={`${userCode === "PB" ? t("order.code") : t("purchaseOrder.code")}`}
          placeholder={`${userCode === "PB" ? t("placeholder.input", { item: t("order.code") }) : t("placeholder.input", { item: t("purchaseOrder.code") })}`}
          defaultValue={formatterProps?.code}
          {...form.getInputProps("code")}
          error={form.isDirty("code") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("code") ? "red !important" : "",
              borderColor: form.isDirty("code") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Select
          label={`${userCode === "PB" ? t("order.status") : t("purchaseOrder.status")}`}
          placeholder={`${userCode === "PB" ? t("placeholder.select", { item: t("order.status") }) : t("placeholder.select", { item: t("purchaseOrder.status") })}`}
          data={[
            { value: "DRAFT", label: t("purchaseStatus.DRAFT") },
            { value: "IN_PROGRESS", label: t("purchaseStatus.IN_PROGRESS") },
            { value: "DONE", label: t("purchaseStatus.DONE") },
            { value: "CANCELED", label: t("purchaseStatus.CANCELED") },
          ]}
          defaultValue={formatterProps?.status}
          {...form.getInputProps("status")}
          error={form.isDirty("status") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("status") ? "red !important" : "",
              borderColor: form.isDirty("status") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <UserAutoComplete
          label={t("order.manager")}
          placeholder={t("placeholder.select", { item: t("order.manager") })}
          defaultValue={formatterProps?.userCode}
          {...form.getInputProps("userCode")}
          error={form.isDirty("userCode") ? t("common.toSave") : ""}
        />
        <LocationAutoComplete
          label={t("inventory.fromLocation")}
          placeholder={t("placeholder.select", { item: t("inventory.fromLocation") })}
          defaultValue={formatterProps?.sourceLocationCode}
          {...form.getInputProps("sourceLocationCode")}
          error={form.isDirty("sourceLocationCode") ? t("common.toSave") : ""}
        />
        <Update.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={`${userCode === "PB" ? t("order.orderDate") : t("purchaseOrder.purchaseOrderDate")}`}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("orderDate")}
          error={form.isDirty("orderDate") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("orderDate") ? "red !important" : "",
              borderColor: form.isDirty("orderDate") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Update.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={t("order.deliveryDate")}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("deliveryDeadline")}
          error={form.isDirty("deliveryDeadline") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("deliveryDeadline") ? "red !important" : "",
              borderColor: form.isDirty("deliveryDeadline") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Update.Button>
          <Update.Button
            disabled={validateFormValues({
              pageType: "purchaseOrder",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            {userCode === "PB"
              ? t("common.edit", { item: t("order.order") })
              : t("common.edit", { item: t("purchaseOrder.purchaseOrder") })}
          </Update.Button>
        </Update.ButtonBox>
      </Update.Wrapper>
    </>
  );
};
