import { theme } from "@/styles/theme";
import styled from "@emotion/styled";

interface BadgeSmallBoxProps {
  children: React.ReactNode;
}

export const BadgeSmallBox = (params: BadgeSmallBoxProps) => {
  const { children } = params;

  return <Item>{children}</Item>;
};

const Item = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-left: 1px solid ${theme.colors?.gray?.[3]};
`;
