import { works } from "@/api/works/useWorkQuery";
import { consumeTypeColor } from "@/constants/routings";
import { useModal } from "@/features/modal/ModalStackManager";
import { OperationDetailForm } from "@/features/operations/components/form/operationDetailForm";
import { RoutingDetailForm } from "@/features/routing/components/form/RoutingDetailForm";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { WorkView } from "@/features/work/WorkView/WorkView";
import { useToggle } from "@/hooks/useToggle";
import styled from "@emotion/styled";
import { Badge, Flex, Table, Text, Title } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerConsumeSummary } from "@sizlcorp/sizl-api-document/dist/models";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TableContainer } from "../../components/ledgerTracking";
import { Tbody, Td, Th, Thead, Tr } from "../../forward/components/forwardSummary";
import { useLotTrackingActions } from "../../hook/useLotTrackingActions";
import { useLotTrackingState } from "../../hook/useLotTrackingState";
import { LotTrackingReverceWorkHistoryMaterial } from "./reverceWorkHistoryMaterial";

const Links = Object.assign(DetailHeader, {
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
});

export const LotTrackingReverceWorkHistoryContent = () => {
  const state = useLotTrackingState();
  const actions = useLotTrackingActions();
  const { openModal } = useModal();
  const { isOpen, toggle } = useToggle();
  const { t } = useTranslation();

  useEffect(() => {
    if (state.itemCodeValue || state.lotId) {
      actions.setProductionPlan(undefined);
    }
  }, [state.itemCodeValue, state.lotId]);

  const { data: work } = useQuery({
    ...works.find({
      query: {
        $and: [{ productionPlanId: { $eq: state.productionPlan?.productionPlanId } }],
      },
      populate: ["consumeSummary", "routingData"],
    }),
    enabled: !!state.productionPlan,
  });

  return (
    <Container>
      <Title p={8} order={3}>
        {t("work.workOrder")}
      </Title>
      <Text fz={12} px={8}>
        {t("lotTracking.workDescription")}
      </Text>
      <TableContainer>
        <Table>
          <Thead backgroundColor="var(--green-0, #EBFBEE)">
            <Tr>
              <Th flex={0.1}></Th>
              <Th>{t("workLog.workId")}</Th>
              <Th>{t("routing.code")}</Th>
              <Th>{t("operation.name")}</Th>
              <Th>{t("lotTracking.operationSeq")}</Th>
              <Th>{t("routing.consumeType")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!state.productionPlan && (
              <Tr>
                <Td colSpan={6}>
                  <Flex justify={"center"} align={"center"}>
                    <Text px={8}>{t("lotTracking.notFound")}</Text>
                  </Flex>
                </Td>
              </Tr>
            )}
            {work?.data.length === 0 ? (
              <Tr>
                <Td colSpan={4}>
                  <Flex justify={"center"} align={"center"}>
                    <Text px={8}>{t("common.notFound")}</Text>
                  </Flex>
                </Td>
              </Tr>
            ) : (
              work?.data.map((works, index) => {
                return (
                  <>
                    <Tr key={index}>
                      <Td flex={0.1}>
                        <Text ta="center">
                          {!isOpen(index.toString()) ? (
                            <IconPlus
                              onClick={() => toggle(index.toString())}
                              style={{ verticalAlign: "middle", cursor: "pointer" }}
                            />
                          ) : (
                            <IconMinus
                              onClick={() => toggle(index.toString())}
                              style={{ verticalAlign: "middle", cursor: "pointer" }}
                            />
                          )}
                        </Text>
                      </Td>
                      <Td>
                        <Flex justify={"flex-end"}>
                          <Links.Link
                            onClick={() =>
                              openModal(
                                <WorkView workId={works?.id} />,
                                t("common.detail", { item: t("work.workOrder") }),
                                "",
                              )
                            }
                          >
                            {works?.id}
                          </Links.Link>
                        </Flex>
                      </Td>
                      <Td>
                        <Links.Link
                          onClick={() =>
                            openModal(
                              <RoutingDetailForm routingCode={works?.routingCode} />,
                              t("common.detail", { item: t("routing.routing") }),
                              "",
                            )
                          }
                        >
                          {works?.routingCode}
                        </Links.Link>
                      </Td>
                      <Td>
                        <Links.Link
                          onClick={() =>
                            openModal(
                              <OperationDetailForm
                                operationCode={works?.routingData?.operationCode}
                              />,
                              t("common.detail", { item: t("operation.operation") }),
                              "",
                            )
                          }
                        >
                          {works?.routingData?.operation?.name}
                        </Links.Link>
                      </Td>
                      <Td>
                        <Flex justify={"flex-end"}>{works?.routingData?.seq}</Flex>
                      </Td>
                      <Td>
                        <Badge size="lg" color={consumeTypeColor[works?.routingData?.consumeType!]}>
                          {t(works?.routingData?.consumeType ?? "")}
                        </Badge>
                      </Td>
                    </Tr>
                    {isOpen(index.toString()) && (
                      <Tr>
                        <Td colSpan={6}>
                          <LotTrackingReverceWorkHistoryMaterial
                            consumeSummary={
                              work?.data[0]?.consumeSummary as
                                | ProductionPlansGet200ResponseRowsInnerWorksInnerConsumeSummary[]
                                | undefined
                            }
                          />
                        </Td>
                      </Tr>
                    )}
                  </>
                );
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  max-height: 534px;
  padding: 8px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: var(--green-0, #ebfbee);
  overflow: auto;

  Td {
    height: auto !important;
    max-height: none !important;
    overflow: visible !important;
  }
`;
