import DefaultInstance from "@/instance/axios";
import { useMutation } from "@tanstack/react-query";

// "생산계획 가져오기" 버튼 클릭 시 ERP와 연동하여 생산계획을 가져오는 API

export const setInputERPWorkOrder = () => {
  return DefaultInstance.erpSyncInputErpWorkOrderPost();
};

export const usePostProductionPlanInputERPWorkOrder = () => {
  return useMutation(["input-erp-work-order"], () => setInputERPWorkOrder());
};

// "생산계획 가져오기" 버튼 클릭 시 ERP와 연동하여 사외 외주 생산계획을 가져오는 API
export const setInputFromERPOutsourcingOrder = () => {
  return DefaultInstance.erpSyncInputInputFromErpOutsourcingOrderPost();
};

export const usePostProductionPlanInputFromERPOutsourcingOrder = () => {
  return useMutation(["input-from-erp-outsourcing-order"], () => setInputFromERPOutsourcingOrder());
};

// "생산계획 삭제하기" 버튼 클릭 시 선택한 생산계획을 삭제하는 API
export const deleteERPWorkOrder = (workId: number) => {
  return DefaultInstance.erpSyncPushDeletedErpWorkPost({
    erpSyncPushErpWorkRecordPostRequest: {
      workIds: [workId],
    },
  });
};

// "생산계획 삭제하기" 버튼 클릭 시 erp와 연동하여 삭제된 생산계획을 ERP에 전송하는 API
export const deleteERPOutsourcingOrder = (workId: number) => {
  return DefaultInstance.erpSyncPushDeletedErpOutsourcingWorkPost({
    erpSyncPushErpWorkRecordPostRequest: {
      workIds: [workId],
    },
  });
};

const purchasedOrderItem = () => {
  return DefaultInstance.erpSyncInputInputFromErpPurchasedOrderItemPost();
};

export const usePurchaseOrderItem = () => {
  return useMutation(["purchase-order-item"], () => purchasedOrderItem());
};

// const generateOutsourceWork = (purchaseOrderItemsGenerateOutsourceWorkPostRequest: PurchaseOrderItemsGenerateOutsourceWorkPostRequest) => {
//     return ScmInstance.purchaseOrderItemsGenerateOutsourceWorkPost({ purchaseOrderItemsGenerateOutsourceWorkPostRequest });
// }

// export const useGenerateOutsourceWork = () => {
//     return useMutation(
//         ["generate-outsource-work"],
//         (purchaseOrderItemsGenerateOutsourceWorkPostRequest: PurchaseOrderItemsGenerateOutsourceWorkPostRequest) => generateOutsourceWork(purchaseOrderItemsGenerateOutsourceWorkPostRequest),
//     );
// }
