import DefaultInstance, { ProductionInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import {
  DefaultApiInventoriesBetweenFindPostRequest,
  DefaultApiInventoriesBetweenGetRequest,
  DefaultApiInventoriesFindPostRequest,
  DefaultApiInventoriesGetRequest,
  DefaultApiWmsBatchPostRequest,
  DefaultApiWmsBulkDiligencePutRequest,
  DefaultApiWmsBulkInputPostRequest,
  DefaultApiWmsClosePostRequest,
  DefaultApiWmsDeferredLotInfoPostRequest,
  DefaultApiWmsDiligencePostRequest,
  DefaultApiWmsFlushPutRequest,
  DefaultApiWmsIncomingPostRequest,
  DefaultApiWmsOutgoingPostRequest,
  DefaultApiWmsPurchaseOrderStockSerialPostRequest,
  DefaultApiWmsStockSerialPostRequest,
  DefaultApiWmsTransferPostRequest,
  ProductionActionApiWorksWorkIdInputPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosRequestConfig } from "axios";
import dayjs from "dayjs";

export const inventories = createQueryKeys("inventories", {
  all: null,
  get: (params: DefaultApiInventoriesGetRequest, option?: AxiosRequestConfig) => {
    const query = { ...params };
    if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.inventoriesGet(query, option),
    };
  },
  find: (params: DefaultApiInventoriesFindPostRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }

    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.inventoriesFindPost(query),
    };
  },
  betweenGet: (params: DefaultApiInventoriesBetweenGetRequest, option?: AxiosRequestConfig) => {
    // const queryParams = { ...params };
    const queryParams = {
      ...params,
      query: JSON.stringify({
        $and: [...(params?.query || [])],
        createdAt: {
          $lte: dayjs(option?.params.targetDateEndString).endOf("day").toISOString(),
        },
      }),
      sort: params.sort?.length ? params.sort : "-createdAt",
    }; // 재고 조회 시 기본 정렬 표시

    return {
      queryKey: [queryParams, JSON.stringify({ ...queryParams, ...option })],
      queryFn: () => DefaultInstance.inventoriesBetweenGet(queryParams, option),
    };
  },
  // betweenGet: (params: DefaultApiInventoriesBetweenGetRequest, option?: AxiosRequestConfig) => {
  //     const query = { ...params };
  //     if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
  //         query["query"] = JSON.stringify(params.query);
  //     }
  //     if (params.search) {
  //         query["search"] = params.search;
  //         query["searchFields"] = params.searchFields;
  //     }
  //     return {
  //         queryKey: [query, JSON.stringify(query.query)],
  //         queryFn: () => DefaultInstance.inventoriesBetweenGet(query, option),
  //     }
  // },
  betweenFind: (params: DefaultApiInventoriesBetweenFindPostRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }

    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.inventoriesBetweenFindPost(query),
    };
  },
});

export const mutateInventories = createMutationKeys("inventories", {
  // 입고
  incoming: (params: DefaultApiWmsIncomingPostRequest) => {
    return {
      mutationKey: [params.workLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner],
      mutationFn: () => DefaultInstance.wmsIncomingPost(params),
    };
  },
  // 일괄입고
  incomingAll: (params: DefaultApiWmsStockSerialPostRequest) => {
    return {
      mutationKey: [params.wmsStockSerialPostRequest],
      mutationFn: () => DefaultInstance.wmsStockSerialPost(params),
    };
  },
  // 벌크입고 (바코드)
  bulkIncoming: (params: DefaultApiWmsBulkInputPostRequest) => {
    return {
      mutationKey: [params.wmsBulkInputPostRequest],
      mutationFn: () => DefaultInstance.wmsBulkInputPost(params),
    };
  },
  // 출고
  outgoing: (params: DefaultApiWmsOutgoingPostRequest) => {
    return {
      mutationKey: [params.workLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner],
      mutationFn: () => DefaultInstance.wmsOutgoingPost(params),
    };
  },
  // 벌크출고
  outgoingAll: (params: DefaultApiWmsBatchPostRequest) => {
    return {
      mutationKey: [params.wmsBatchPostRequest],
      mutationFn: () => DefaultInstance.wmsBatchPost(params),
    };
  },
  // 이동
  transfer: (params: DefaultApiWmsTransferPostRequest) => {
    return {
      mutationKey: [params.wmsTransferPostRequest],
      mutationFn: () => DefaultInstance.wmsTransferPost(params),
    };
  },
  // 벌크이동
  transferAll: (params: DefaultApiWmsBatchPostRequest) => {
    return {
      mutationKey: [params.wmsBatchPostRequest],
      mutationFn: () => DefaultInstance.wmsBatchPost(params),
    };
  },
  // 원부자재 투입
  workInput: (params: ProductionActionApiWorksWorkIdInputPutRequest) => {
    return {
      mutationKey: [params.workId, params.worksWorkIdInputPutRequest],
      mutationFn: () => ProductionInstance.worksWorkIdInputPut(params),
    };
  },
  // 재고실사
  diligence: (params: DefaultApiWmsDiligencePostRequest) => {
    return {
      mutationKey: [params.wmsDiligencePostRequest],
      mutationFn: () => DefaultInstance.wmsDiligencePost(params),
    };
  },
  // 재고 일괄 조정
  diligenceAll: (params: DefaultApiWmsBulkDiligencePutRequest) => {
    return {
      mutationKey: [params.wmsBulkDiligencePutRequest],
      mutationFn: () => DefaultInstance.wmsBulkDiligencePut(params),
    };
  },
  // 재고마감
  close: (params: DefaultApiWmsClosePostRequest) => {
    return {
      mutationKey: [params.wmsClosePostRequest],
      mutationFn: () => DefaultInstance.wmsClosePost(params),
    };
  },
  // 재고정보 재입력
  deferredLotInfo: (params: DefaultApiWmsDeferredLotInfoPostRequest) => {
    return {
      mutationKey: [params.wmsDeferredLotInfoPostRequest],
      mutationFn: () => DefaultInstance.wmsDeferredLotInfoPost(params),
    };
  },
  // 플러싱
  flush: (params: DefaultApiWmsFlushPutRequest) => {
    return {
      mutationKey: [params.wmsFlushPutRequest],
      mutationFn: () => DefaultInstance.wmsFlushPut(params),
    };
  },

  // 수발주 일괄입고
  purchaseOrderStockSerial: (params: DefaultApiWmsPurchaseOrderStockSerialPostRequest) => {
    return {
      mutationKey: [params.wmsPurchaseOrderStockSerialPostRequest],
      mutationFn: () => DefaultInstance.wmsPurchaseOrderStockSerialPost(params),
    };
  },
});
