import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Button, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  DefaultApiWmsClosePostRequest,
  InventoriesBetweenGet200ResponseRowsInner,
  WmsClosePostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Close = Object.assign({}, FormMain, {
  Button: Button,
  Input: TextInput,
  Date: DateInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  ItemAutoComplete: ItemAutoComplete,
});

interface CloseFormProps {
  formatterProps: InventoriesBetweenGet200ResponseRowsInner;
}

export const CloseForm = ({ formatterProps }: CloseFormProps) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: closeMutate } = useMutation(
    (params: DefaultApiWmsClosePostRequest) =>
      mutateInventories.close(params).mutationFn(params as DefaultApiWmsClosePostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
        closeModal(form.values);
        customAlert(
          `${t("inventory.successMessage", { item: `${t("inventory.close")}` })}`,
          `${t("inventory.success", { item: `${t("inventory.close")}` })}`,
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("inventory.close") }),
          `${t("inventory.fail", { item: `${t("inventory.close")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm<Partial<WmsClosePostRequest>>({
    initialValues: {
      itemCode: formatterProps.itemCode,
      locationCode: formatterProps.locationCode,
      quantity: formatterProps.quantity,
      lotId: formatterProps.lotId,
    },
  });

  const onSubmit = () => {
    try {
      const { itemCode, locationCode, quantity } = form.values;
      if (!itemCode && !locationCode && !quantity) return;

      closeMutate({
        wmsClosePostRequest: {
          itemCode: formatterProps.itemCode || (form.values.itemCode as string),
          locationCode: formatterProps.locationCode || (form.values.locationCode as string),
          quantity: form.values.quantity as string,
          lotId: Number(formatterProps.lotId || form.values.lotId),
          actionFrom: "WEB",
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("inventory.close") }),
        `${t("inventory.fail", { item: `${t("inventory.close")}` })}`,
        "red",
      );
    }
  };

  return (
    <>
      <Close.Wrapper>
        <Close.ItemAutoComplete
          disabled
          label={t("item.code")}
          withAsterisk
          {...form.getInputProps("itemCode")}
          defaultValue={formatterProps?.itemCode}
        />
        <LocationAutoComplete
          disabled
          label={t("location.code")}
          withAsterisk
          {...form.getInputProps("locationCode")}
          defaultValue={formatterProps?.locationCode}
        />
        <Close.Input
          label={t("inventory.quantity")}
          withAsterisk
          type="number"
          {...form.getInputProps("quantity")}
          defaultValue={formatterProps?.quantity}
        />

        <Close.ButtonBox>
          <Close.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Close.Button>
          <Close.Button onClick={onSubmit}>{t("inventory.close")}</Close.Button>
        </Close.ButtonBox>
      </Close.Wrapper>
    </>
  );
};
