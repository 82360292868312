import { DEPARTMENT_QUERY_KEY, mutateDepartment } from "@/api/department/useDepartmentQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Box, Button, Checkbox, Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  DepartmentsGet200ResponseRowsInner,
  MasterApiDepartmentsDepartmentIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Checkbox,

  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
});

export const DepartmentUpdateForm = (params: DepartmentsGet200ResponseRowsInner) => {
  const { code, name, id } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: updateMutate } = useMutation(
    (params: MasterApiDepartmentsDepartmentIdPutRequest) =>
      mutateDepartment.update(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([DEPARTMENT_QUERY_KEY]);
        customAlert(
          `${t("message.editSuccess", { item: `${t("department.department")}` })}`,
          `${t("common.editSuccess")}`,
          "green",
        );
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("department.code") }),
          `${t("common.editFail", { item: `${t("department.department")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm<DepartmentsGet200ResponseRowsInner>({
    initialValues: {
      code: code ?? undefined,
      name: name ?? undefined,
      id: id ?? undefined,
    },
    validate: {
      code: (value) => (value ? null : t("placeholder.input", { item: `${t("department.code")}` })),
      name: (value) => (value ? null : t("placeholder.input", { item: `${t("department.name")}` })),
    },
  });

  const onSubmit = async (values: DepartmentsGet200ResponseRowsInner) => {
    updateMutate({
      departmentId: values.id as number,
      departmentsGetRequest: {
        code: values.code as string,
        name: values.name as string,
      },
    });
    closeModal({});
  };

  return (
    <Box>
      <form
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          width: "30rem",
          height: "15rem",
        }}
        onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}
      >
        <Update.Input
          withAsterisk
          label={t("department.code")}
          placeholder={t("placeholder.input", { item: `${t("department.code")}` })}
          {...form.getInputProps("code")}
          error={form.isDirty("code") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("code") ? "cadetblue !important" : "inherit",
              borderColor: form.isDirty("code") ? "cadetblue !important" : "inherit",
            },
            error: { fontSize: 11, color: "cadetblue" },
          }}
        />
        <Update.Input
          withAsterisk
          label={t("department.name")}
          placeholder={t("placeholder.input", { item: `${t("department.name")}` })}
          {...form.getInputProps("name")}
          error={form.isDirty("name") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("name") ? "cadetblue !important" : "inherit",
              borderColor: form.isDirty("name") ? "cadetblue !important" : "inherit",
            },
            error: { fontSize: 11, color: "cadetblue" },
          }}
        />
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Update.Button>
          <Update.Button disabled={!form.isValid()} type="submit">
            {t("common.edit", { item: `${t("department.department")}` })}
          </Update.Button>
        </Update.ButtonBox>
      </form>
    </Box>
  );
};
