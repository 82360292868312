import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { Main } from "@/features/standard/Main";
import { SplitLotGet200ResponseRowsInnerConsumeLogsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { ConsumeDetailView } from "./consumeDetailView";

export interface ConsumeDetailFormProps {
  consumeData: SplitLotGet200ResponseRowsInnerConsumeLogsInner;
}

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
});
export const ConsumeDetailForm = (params: ConsumeDetailFormProps) => {
  const { consumeData } = params;

  return (
    <Detail.Container>
      <Detail.Wrapper>
        <Detail.Content>
          <ConsumeDetailView consumeData={consumeData} />
        </Detail.Content>
      </Detail.Wrapper>
    </Detail.Container>
  );
};
