import { mutateStockPlans } from "@/api/stockPlan/useStockPlanQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { LotSelectComplete } from "@/features/ui/autoComplete/lot/lot-\bselectComplete";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { WmsApiStockPlansPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

export const StockPlanCreateForm = () => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: createMutate } = useMutation(
    (params: WmsApiStockPlansPostRequest) =>
      mutateStockPlans.create(params).mutationFn(params as WmsApiStockPlansPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stockPlans"]);
        closeModal(form.values);
        customAlert(
          t("message.createSuccess", { item: t("stockPlan.stockPlan") }),
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("stockPlan.stockPlan") }),
          t("common.createFail", { item: t("stockPlan.stockPlan") }),
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      partnerCode: undefined,
      scheduledAt: undefined,
      itemCode: undefined,
      lotId: undefined,
      quantity: undefined,
      userCode: undefined,
      sourceLocationCode: undefined,
      targetLocationCode: undefined,
      transportationId: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        stockPlansGetRequest: {
          partnerCode: form.values.partnerCode!,
          direction: "INBOUND",
          scheduledAt: form.values.scheduledAt!,
          itemCode: form.values.itemCode!,
          lotId: form.values.lotId!,
          quantity: form.values.quantity!,
          userCode: form.values.userCode!,
          sourceLocationCode: form.values.sourceLocationCode!,
          targetLocationCode: form.values.targetLocationCode!,
          transportationId: form.values.transportationId!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("stockPlan.stockPlan") }),
        t("common.createFail", { item: t("stockPlan.stockPlan") }),
        "red",
      );
    }
  };

  return (
    <>
      <Create.Wrapper>
        <PartnerAutoComplete
          label={t("partner.partner")}
          placeholder={t("placeholder.select", { item: t("partner.partner") })}
          {...form.getInputProps("partnerCode")}
        />
        <Create.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={t("stockPlan.scheduledAt")}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("scheduledAt")}
          withAsterisk
        />
        <ItemAutoComplete
          label={t("item.code")}
          placeholder={t("placeholder.select", { item: t("item.code") })}
          {...form.getInputProps("itemCode")}
          withAsterisk
        />
        <LotSelectComplete
          label={t("lot.lot")}
          placeholder={t("placeholder.select", { item: t("lot.lot") })}
          itemCodeValue={form.values.itemCode ?? ""}
          {...form.getInputProps("lotId")}
        />
        <Create.Input
          label={t("inventory.quantity")}
          placeholder={t("placeholder.input", { item: t("inventory.quantity") })}
          {...form.getInputProps("quantity")}
          withAsterisk
        />
        <UserAutoComplete
          label={t("order.manager")}
          placeholder={t("placeholder.select", { item: t("order.manager") })}
          {...form.getInputProps("userCode")}
        />
        <LocationAutoComplete
          label={t("inventory.fromLocation")}
          placeholder={t("placeholder.select", { item: t("inventory.fromLocation") })}
          {...form.getInputProps("sourceLocationCode")}
        />
        <LocationAutoComplete
          label={t("inventory.toLocation")}
          placeholder={t("placeholder.select", { item: t("inventory.toLocation") })}
          {...form.getInputProps("targetLocationCode")}
        />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "stockPlan",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            {t("common.additional", { item: t("stockPlan.stockPlan") })}
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
