import { Table } from "@mantine/core";
import React, { useState } from "react";

type TableData = {
  [key: string]: string | number | React.ReactNode;
};

interface CustomTableProps {
  headers: string[];
  data: TableData[];
  width?: string;
  onSelectRow?: (id: number) => void;
  onRowClick?: (row: any) => void;
}

const CustomTable = ({ headers, data, width, onRowClick }: CustomTableProps) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const isEmpty = data.length === 0;
  const hasNoItemName = data.every((row) => row["품목코드"] === "-");
  const hasNoEquipmentName = data.every((row) => row["설비명"] === "-");

  const handleRowClick = (row: any, rowIndex: number) => {
    setSelectedRowIndex(rowIndex);
    if (onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <Table w={width} withBorder withColumnBorders>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th
              key={index}
              style={{ textAlign: "center", backgroundColor: "white", fontWeight: "800" }}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isEmpty ? (
          <tr>
            <td colSpan={headers.length} style={{ textAlign: "center", backgroundColor: "white" }}>
              데이터를 추가해주세요
            </td>
          </tr>
        ) : hasNoItemName ? (
          <tr>
            <td colSpan={headers.length} style={{ textAlign: "center", backgroundColor: "white" }}>
              품목을 선택해주세요
            </td>
          </tr>
        ) : hasNoEquipmentName ? (
          <tr>
            <td colSpan={headers.length} style={{ textAlign: "center", backgroundColor: "white" }}>
              설비를 선택해주세요
            </td>
          </tr>
        ) : (
          data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              onClick={() => handleRowClick(row, rowIndex)}
              style={{
                backgroundColor: selectedRowIndex === rowIndex ? "#f0f0f0" : "white",
                cursor: "pointer",
              }}
            >
              {headers.map((header, cellIndex) => (
                <td key={cellIndex} style={{ textAlign: "center", backgroundColor: "inherit" }}>
                  {row[header]}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default CustomTable;
