import { customFunctions } from "@/config/customFunction";

export type StatusObjType = {
  [key: string]: string;
};

export const wmsStatusColor = {
  STOCK_SERIAL: "blue.7",
  INCOMING: "pink.7",
  OUTGOING: "yellow.7",
  TRANSFER: "red.7",
  BATCH: "yellow.7",
  START: "green.7",
  END: "purple.7",
  DILIGENCE: "lime.7",
  CLOSE: "gray.7",
  DEFERRED_LOT_INFO: "red.4",
  FLUSH: "green.4",
};

export const wmsStatus: StatusObjType = {
  STOCK_SERIAL: "inventory.stockSerial",
  INCOMING: "inventory.incoming",
  OUTGOING: "inventory.outgoing",
  TRANSFER: "inventory.transfer",
  TRANSFER_INPUT: "inventory.transferInput",
  BATCH: "inventory.batch",
  DILIGENCE: "inventory.diligence",
  CLOSE: "inventory.close",
  FLUSH: "inventory.flush",
  // DEFERRED_LOT_INFO: "재고 정보 재입력",
  // START: "시작",
  // END: "종료",
};

export const lotWmsStatus: StatusObjType = {
  STOCK_SERIAL: "벌크 입고",
  INCOMING: "입고",
  OUTGOING: "출고",
  TRANSFER: "이동",
  BATCH: "벌크 이동",
  DILIGENCE: "재고 조정",
  CLOSE: "재고 마감",
  FLUSH: "재고 초기화",
  DEFERRED_LOT_INFO: "재고 정보 재입력",
  START: "시작",
  END: "종료",
};

export const setWmsSatus = (status: string) => {
  return lotWmsStatus[status];
};

customFunctions.ADD_TRANSFORM_TYPE && (wmsStatus.TRANSFORM = "inventory.transform");
