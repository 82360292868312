// @fileoverview : 기준정보 > 비가동사유

import {
  downtimeReasons,
  mutateDownTimeReasons,
} from "@/api/downtimeReasons/useDowntimeReasonsQuery";
import { ActionHeader } from "@/features/downtimeReasons/DownTimeReasonActionHeader";
import { DowntimeReasonsCreateForm } from "@/features/downtimeReasons/form/CreateForm";
import { DowntimeReasonsDetailForm } from "@/features/downtimeReasons/form/DetailForm";
import { DowntimeReasonsUpdateForm } from "@/features/downtimeReasons/form/UpdateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { OperationDetailForm } from "@/features/operations/components/form/operationDetailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
// import DragAndDropList, { DragAndDropItemDataType } from "@/features/ui/dragAndDropList/DragAndDropList";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import {
  DefaultApiDowntimeReasonsDowntimeReasonCodeDeleteRequest,
  DowntimeReasonsGet200Response,
  DowntimeReasonsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const DowntimeReasonsPage = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { t } = useTranslation();
  const { data: downtimeReasonsData } = useQuery(
    downtimeReasons.find({ query: { $and: [] }, sort: "seq" }),
  );
  const dragAndDropItems = downtimeReasonsData?.data.map((item) => {
    return {
      id: item.code as string,
      content: `${item.name}( ${item.code} )`,
    };
  });

  const maxSeq = downtimeReasonsData?.data?.length
    ? (downtimeReasonsData?.data?.[downtimeReasonsData?.data.length - 1]?.seq as number) + 1
    : 0;

  const { mutate: deleteMutate } = useMutation(
    (params: DefaultApiDowntimeReasonsDowntimeReasonCodeDeleteRequest) =>
      mutateDownTimeReasons
        .delete(params)
        .mutationFn(params as DefaultApiDowntimeReasonsDowntimeReasonCodeDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          `${t("message.deleteSuccess", { item: `${t("downtimeReason.downtimeReason")}` })}`,
          t("common.deleteSuccess"),
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("downtimeReason.code") }),
          `${t("common.deleteFail", { item: `${t("downtimeReason.downtimeReason")}` })}`,
          "red",
        );
      },
    },
  );

  const columns: readonly Column<DowntimeReasonsGet200ResponseRowsInner>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "seq",
        name: t("downtimeReason.seq"),
        sortable: true,
        resizable: true,
      },
      {
        key: "code",
        name: t("downtimeReason.code"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <DowntimeReasonsDetailForm downtimeReasonCode={formatterProps.row.code} />,
                  null,
                  "",
                )
              }
            >
              {formatterProps.row.code}
            </LinkAnchor>
          );
        },
      },
      {
        key: "name",
        name: t("downtimeReason.name"),
        sortable: true,
        resizable: true,
      },
      {
        key: "operationCode",
        name: t("operation.code"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <OperationDetailForm operationCode={formatterProps.row.operationCode} />,
                  null,
                  "",
                )
              }
            >
              {formatterProps.row.operationCode}
            </LinkAnchor>
          );
        },
      },
      {
        key: "options",
        name: t("common.action"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const updateDownTimeReasonActionView = () => {
            openModal(
              <DowntimeReasonsUpdateForm formatterProps={formatterProps.row} />,
              {},
              `${t("common.edit", { item: `${t("downtimeReason.downtimeReason")}` })}`,
              true,
            );
          };

          const deleteDownTimeReasonAction = () => {
            openModal(
              <ConfirmForm
                message={t("message.rowDelete")}
                yesCallback={() =>
                  deleteMutate({
                    downtimeReasonCode: formatterProps.row.code as string,
                  })
                }
                noCallback={() => {}}
                commonCallback={() => closeModal(null)}
              />,
              null,
              `${t("common.delete", { item: `${t("downtimeReason.downtimeReason")}` })}`,
            );
          };

          return (
            <Flex w="100%" h="100%" justify="center" align="center" gap="md">
              <ActionIcon variant="subtle" onClick={updateDownTimeReasonActionView} color="yellow">
                <IconEdit />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={() => deleteDownTimeReasonAction()} color="red">
                <IconTrash />
              </ActionIcon>
            </Flex>
          );
        },
      },
    ],
    [closeModal, deleteMutate, openModal, t],
  );

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: `${t("downtimeReason.downtimeReason")}` })}`,
        `${t("common.deleteFail", { item: `${t("downtimeReason.downtimeReason")}` })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ downtimeReasonCode: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      `${t("common.delete", { item: `${t("downtimeReason.downtimeReason")}` })}`,
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader
          createFn={() =>
            openModal(
              <DowntimeReasonsCreateForm maxSeq={maxSeq} />,
              null,
              `${t("common.additional", { item: `${t("downtimeReason.downtimeReason")}` })}`,
              true,
            )
          }
          createDataTitle={t("downtimeReason.downtimeReason")}
          deleteFn={() => handleDelete()}
          downTimeReasonItems={dragAndDropItems}
        />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: `${t("downtimeReason.downtimeReason")}` })}
        </Text>
        <Text fz="xs">
          {t("common.subTitle", { item: `${t("downtimeReason.downtimeReason")}` })}
        </Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<DowntimeReasonsGet200Response, DowntimeReasonsGet200ResponseRowsInner>
            columns={columns}
            rowKeyGetter={(row: DowntimeReasonsGet200ResponseRowsInner) => row.code}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<DowntimeReasonsGet200Response> />
      </Flex>
    </Flex>
  );
};
