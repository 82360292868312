import DefaultInstance from "@/instance/axios";
import { ItemsGet200ResponseRowsInnerRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export type RoutingOption = {
  value: string;
  label: string;
  group: string;
};

const getAutoCompleteKeyword = (keyword: string) =>
  DefaultInstance.routingsFindPost({
    search: keyword,
    searchFields: ["code"],
  }).then((res: AxiosResponse<ItemsGet200ResponseRowsInnerRoutingsInner[]>) => res.data);

export const useRoutingsAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: RoutingOption[]) => void;
  },
) => {
  return useQuery(["routings-get-autocomplete", keyword], () => getAutoCompleteKeyword(keyword), {
    enabled: focus,
    select: (data: ItemsGet200ResponseRowsInnerRoutingsInner[]) =>
      data?.map((routing) => {
        return {
          value: String(routing.code),
          label: String(routing.code), // routing.itemCode
          itemCode: String(routing.itemCode),
          group: String(routing.operationCode),
        };
      }) ?? [],
    onSuccess: options?.onSuccess,
  });
};

const getRoutingsByCode = (code: string | null) =>
  DefaultInstance.routingsFindPost({
    ...(code && { search: code }),
    searchFields: ["code"],
  }).then((res: AxiosResponse<ItemsGet200ResponseRowsInnerRoutingsInner[]>) => res.data);

export const useRoutingsCode = (
  enabled: boolean,
  code: string | null,
  options?: {
    onSuccess: (data?: RoutingOption[]) => void;
  },
) => {
  return useQuery(["routings-get-code", code], () => getRoutingsByCode(code), {
    enabled: enabled ?? false,
    select: (data: ItemsGet200ResponseRowsInnerRoutingsInner[]) =>
      data?.map((routing) => {
        return {
          value: String(routing.code),
          label: String(routing.code), // routing.itemCode
          group: String(routing.operationCode),
          itemCode: String(routing.itemCode),
        };
      }) ?? [],
    onSuccess: options?.onSuccess,
  });
};
