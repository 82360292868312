import timeUtil from "@/utils/timeUtil";
import styled from "@emotion/styled";
import { QualityDefectStatisticsDefectCodeGet200ResponseInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import ApexCharts from "react-apexcharts";

interface DefectChartProps {
  data: AxiosResponse<QualityDefectStatisticsDefectCodeGet200ResponseInner[], any> | undefined;
}
export const DefectChart = ({ data }: DefectChartProps) => {
  const options = {
    chart: {
      height: 350,
      type: "line" as const,
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    toolbar: {
      show: true,
    },
    series: [
      {
        name: "건수",
        type: "line",
        data: data?.data?.map((item) => Number(item.workCount)) ?? [],
      },
      {
        name: "수량",
        type: "line",
        data: data?.data?.map((item) => Number(item.totalDefects)) ?? [],
      },
    ],
    stroke: {
      width: [2, 2],
    },
    xaxis: {
      categories: data?.data?.map((item) => timeUtil(item.date ?? "")) ?? [],
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#008FFB",
        },
        labels: {
          style: {
            colors: "#008FFB",
          },
        },
        title: {
          text: "건수",
          style: {
            color: "#008FFB",
          },
        },
      },
      {
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#00E396",
        },
        labels: {
          style: {
            colors: "#00E396",
          },
        },
        title: {
          text: "수량",
          style: {
            color: "#00E396",
          },
        },
      },
    ],
  };

  return (
    <Container>
      <ApexCharts options={options} series={options.series} type="line" height={350} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;
