import { Badge, Text } from "@mantine/core";
import {
  MantineReactTable,
  useMantineReactTable,
  // createRow,
  type MRT_ColumnDef,
} from "mantine-react-table";
import { useMemo } from "react";

import {
  SpecificationsGet200ResponseRowsInner,
  SpecificationsGet200ResponseRowsInnerCriteriaInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";

interface InspectCriterialTableProps {
  data?: SpecificationsGet200ResponseRowsInner[];
}

export const InspectCriterialTable = (params: InspectCriterialTableProps) => {
  const { data } = params;
  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<SpecificationsGet200ResponseRowsInnerCriteriaInner>[]>(
    () => [
      {
        accessorKey: "seq",
        header: t("inspect.point"),
        size: 80,
        Cell: (rows) => {
          return <Text ta={"left"}>{rows.row.index + 1}</Text>;
        },
      },
      {
        accessorKey: "name",
        header: t("inspect.pointName"),
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return <Text ta={"left"}>{rows.row._valuesCache.name}</Text>;
        },
      },
      {
        accessorKey: "type",
        header: t("inspect.inspectType"),
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <Text ta={"left"}>
              {rows.row._valuesCache.type === "boolean" ? (
                <Badge size={"9px"} fw={700} radius={2}>
                  {t("inspect.check")}
                </Badge>
              ) : (
                ""
              )}
            </Text>
          );
        },
      },
      {
        accessorKey: "standard",
        header: t("inspect.standard"),
        size: 120,
        mantineEditTextInputProps: {
          type: "number",
          required: true,
        },
        Cell: (rows) => {
          return <Text ta={"left"}>{rows.row._valuesCache.standard && t("inspect.pass")}</Text>;
        },
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    //일단 한 품목에 검사표준은 한개로 가정
    data: data?.[0]?.criteria ?? ([] as SpecificationsGet200ResponseRowsInnerCriteriaInner[]),
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: false,
    enableToolbarInternalActions: false, // 우측상단 아이콘 안보이게
    enablePagination: false,
  });

  return <MantineReactTable table={table} />;
};
