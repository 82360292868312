import { ColumnHeaderProps } from "@/constants/columnHeader";
import { operatorMap } from "@/constants/filters";
import { useModal } from "@/features/modal/ModalStackManager";
import { QueryType, useStandardLayout } from "@/features/standardLayout/Context";
import { ActionIcon, Badge, Button, Flex, Text } from "@mantine/core";
import { IconPlus, IconX } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterForm } from "./StandardInfoBestPracticeFilterForm";

/**
 * 작성자명 : 원종석
 * 작성일자 : 2024.11.05 (화)
 * 작성내용 : ColumnHeader 배열에서 queryData의 키에 해당하는 header를 찾아 반환하는 함수
 * @param queryData - 검색에 사용할 쿼리 데이터 객체
 * @param headers - ColumnHeaderProps 형식의 header 배열
 * @returns 일치하는 첫 번째 header 객체 또는 undefined
 */
const findHeadersByKeys = (queryData: QueryType, headers: ColumnHeaderProps[]) => {
  const [result] = Object.keys(queryData).map((key) => {
    // headers에서 queryData의 key와 value가 일치하는 header 찾기
    return headers.find((header) => header.value === key);
  });

  return result;
};

/**
 * 작성자명 : 원종석
 * 작성일자 : 2024.11.05 (화)
 * 작성내용 : queryData의 조건 키를 기반으로 operatorMap에서 일치하는 operator 객체를 찾는 함수
 * @param queryData - 검색에 사용할 쿼리 데이터 객체
 * @param operators - operatorMap의 객체 배열 (operator 정보를 포함)
 * @returns 일치하는 첫 번째 operator 객체 또는 undefined
 */
const findOperatorsByConditions = (queryData: QueryType, operators: typeof operatorMap) => {
  const [result] = Object.entries(queryData).flatMap(([key, conditions]) => {
    // conditions 객체의 각 조건을 조회하여 일치하는 operator 찾기
    return Object.keys(conditions).map((conditionKey) => {
      return operators.find((operator) => operator.value === conditionKey);
    });
  });

  return result;
};

/**
 * 작성자명 : 원종석
 * 작성일자 : 2024.11.05 (화)
 * 작성내용 : 지정한 문자로 시작하고 끝나는 문자열의 첫 번째와 마지막 문자를 제거하는 함수
 * @param input - 변환할 문자열
 * @param word - 제거할 시작과 끝 문자를 나타내는 문자열
 * @returns 앞뒤에 지정한 문자가 있을 경우 이를 제거한 문자열
 */
const removeSymbols = (input: string, word: string): string => {
  if (input.startsWith(word) && input.endsWith(word)) {
    return input.slice(1, -1); // 첫 번째와 마지막 문자를 제거
  }
  return input; // 앞뒤에 %가 없는 경우 그대로 반환
};

export const Filter = () => {
  const { openModal } = useModal();
  const { t } = useTranslation();
  const { setPage, query, setQuery, searchFieldsHeader } = useStandardLayout();
  const [queryState, setQueryState] = useState<QueryType[]>(() =>
    query.map((item) => ({ ...item, id: JSON.stringify(item) })),
  );

  const onSubmit = (result: QueryType) => {
    if (!result) return;
    setPage(1); // 검색어 변경 시 항상 페이지를 1로 초기화
    setQueryState((prev: QueryType[]) => [...prev, { ...result, id: JSON.stringify(result) }]); // queryState는 id 속성을 추가하여 업데이트
    setQuery((prev: QueryType[]) => [...prev, result]); // query는 id 속성 없이 그대로 업데이트
  };

  const removeFilter = (id: string) => {
    setPage(1); // 검색어 변경 시 항상 페이지를 1로 초기화
    setQueryState((prev: QueryType[]) => prev.filter((item) => item.id !== id));
    setQuery((prev: QueryType[]) => prev.filter((item) => JSON.stringify(item) !== id));
  };

  return (
    <Flex gap="sm" align="center" h="100%" wrap="wrap">
      {queryState.map((item: QueryType) => {
        // id를 제외한 나머지 key-value 쌍만 추출
        const filteredEntries = Object.entries(item).filter(([key]) => key !== "id");
        const filteredItem = Object.fromEntries(filteredEntries);

        let subKey = ""; // 연산자 정보 키 (예: $iLike)
        let subValue: string | number = ""; // 초기값을 string으로 설정
        let subValueArray: string[] = []; // 초기값을 string 배열로 설정 (다중 선택 필드)

        Object.entries(filteredItem).forEach(([_, value]) => {
          if (typeof value === "object" && value !== null) {
            const subEntry = Object.entries(value)[0];
            if (subEntry && (typeof subEntry[1] === "string" || typeof subEntry[1] === "number")) {
              // 타입 검사
              subKey = subEntry[0]; // 예: $iLike
              subValue = subEntry[1]; // 타입이 string일 때만 할당
            } else if (subEntry && Array.isArray(subEntry[1])) {
              subValueArray = subEntry[1]; // 타입이 배열일 때 할당
            }
          }
        });

        // headerByKey = 테이블 헤더 정보(이름, 코드, 유형 등), operatorByCondition = 연산자 정보(포함, 같음, 다름 등), valueData = 필터링 값
        const headerByKey = findHeadersByKeys(filteredItem, searchFieldsHeader);
        const operatorByCondition = findOperatorsByConditions(filteredItem, operatorMap);
        const valueData = subKey.toLowerCase().includes("like")
          ? removeSymbols(subValue, "%")
          : subValue;
        const valueArrayData = subValueArray.map((item) => t(item)).toString();

        const isTextCategory = headerByKey?.category === "text";

        return (
          <Badge
            key={item.id}
            variant="filled"
            size="xl"
            rightSection={
              <ActionIcon variant="filled" size="md" color="blue" radius="xl" onClick={() => {}}>
                <IconX />
              </ActionIcon>
            }
            onClick={() => {
              removeFilter(item.id);
            }}
          >
            <Text h="100%">
              {isTextCategory
                ? headerByKey.isEnum || headerByKey.isFK
                  ? `${headerByKey?.label} - ${valueArrayData}`
                  : `${headerByKey?.label} - "${valueData}" ${operatorByCondition?.label}`
                : `${headerByKey?.label} ${operatorByCondition?.label} ${valueArrayData}`}
            </Text>
          </Badge>
        );
      })}
      <Button
        variant="subtle"
        color="dark"
        leftIcon={<IconPlus />}
        onClick={() =>
          openModal(
            <FilterForm searchFieldsHeader={searchFieldsHeader} />,
            null,
            "필터 추가",
            true,
          ).then((result) => onSubmit(result))
        }
      >
        필터 추가
      </Button>
    </Flex>
  );
};
