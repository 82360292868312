import { LedgerStateContext } from "@/features/ledgersAll/context/ledgerProvider";
import { useContext } from "react";

export function useLedgersState() {
  const value = useContext(LedgerStateContext);
  if (value === undefined) {
    throw new Error("useLedgersState should be used within LedgerProvider");
  }
  return value;
}
