import { mutateDownTimeReasons } from "@/api/downtimeReasons/useDowntimeReasonsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import customAlert from "@/features/ui/alert/alert";
import {
  DragAndDropItemDataType,
  DragAndDropList,
} from "@/features/ui/dragAndDropList/DragAndDropList";
import { Button, Flex } from "@mantine/core";
import { DefaultApiDowntimeReasonsUpdateSeqPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlus, IconRecycle, IconReorder, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

// 제네릭 타입을 포함하는 ActionHeaderProps 인터페이스 정의
interface ActionHeaderProps {
  createFn: () => void;
  createDataTitle?: string;
  deleteFn: () => void;
  downTimeReasonItems?: DragAndDropItemDataType[];
}

// 제네릭을 포함한 ES6 문법의 ActionHeader 컴포넌트
export const ActionHeader = ({
  createFn,
  createDataTitle = "",
  deleteFn,
  downTimeReasonItems,
}: ActionHeaderProps) => {
  const { openModal, closeModal } = useModal();
  const { resetQueryStrings, refetch } = useStandardLayout();
  const { t } = useTranslation();

  const { mutate: updateSeqMutate } = useMutation(
    (params: DefaultApiDowntimeReasonsUpdateSeqPostRequest) =>
      mutateDownTimeReasons
        .updateSeq(params)
        .mutationFn(params as DefaultApiDowntimeReasonsUpdateSeqPostRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          `${t("message.changeSuccess", { item: `${t("downtimeReason.downtimeReason")}` })}`,
          `${t("common.changeSuccess")}`,
          "green",
        );
        closeModal(null);
      },
      onError: () => {
        customAlert(
          `${t("message.changeFail", { item: `${t("downtimeReason.downtimeReason")}` })}`,
          `${t("common.changeFail")}`,
          "red",
        );
      },
    },
  );

  const onSubmit = (items: DragAndDropItemDataType[]) => {
    updateSeqMutate({
      equipmentsUpdateSeqPostRequest: {
        code: items.map((item) => item.id),
        seq: items.map((_, index) => index + 1),
      },
    });
  };

  return (
    <>
      <Flex gap="md">
        <Button leftIcon={<IconPlus />} onClick={() => createFn()}>
          {t("common.additional", { item: createDataTitle })}
        </Button>
        <Button
          leftIcon={<IconReorder />}
          color="cyan"
          onClick={() =>
            openModal(
              <DragAndDropList data={downTimeReasonItems} onSubmit={onSubmit} />,
              null,
              t("downtimeReason.seqSetting"),
              true,
            )
          }
        >
          {t("downtimeReason.seqChange")}
        </Button>
      </Flex>
      <Flex gap="md">
        <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
          {t("common.reset")}
        </Button>
        <Button rightIcon={<IconTrash />} color="red" onClick={() => deleteFn()}>
          {t("common.rowDelete")}
        </Button>
      </Flex>
    </>
  );
};
