import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { BadgeBox } from "@/features/standard/badge/BadgeBox";
import { BadgeContainer } from "@/features/standard/badge/BadgeContainer";
import { BadgeIcon } from "@/features/standard/badge/BadgeIcon";
import { BadgeMain } from "@/features/standard/badge/BadgeMain";
import { BadgeQuantity } from "@/features/standard/badge/BadgeQuantity";
import { BadgeSmallBox } from "@/features/standard/badge/BadgeSmallBox";
import { BadgeTextBox } from "@/features/standard/badge/BadgeTextBox";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import DatetimeUtil from "@/utils/dateTimeUtil";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { Checkbox, Space, Text } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ProductionPlanDataForm } from "./DataForm";

const DetailProductionPlan = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
  CheckBox: Checkbox,
});

const Badge = Object.assign(BadgeMain, {
  Box: BadgeBox,
  SmallBox: BadgeSmallBox,
  Quantity: BadgeQuantity,
  Icon: BadgeIcon,
  Text: Text,
  TextBox: BadgeTextBox,
  Container: BadgeContainer,
});

interface DetailProductionPlanProps {
  data: ProductionPlansGet200ResponseRowsInner | undefined;
}

export const ProductionPlanDetailForm = (params: DetailProductionPlanProps) => {
  const { data } = params;
  const { t } = useTranslation();

  const { data: workLogData } = useQuery({
    ...workLogs.get({
      query: data
        ? {
            $and: [{ productionPlanId: { $eq: data?.id } }, { workLogType: { $eq: "PROGRESS" } }],
          }
        : {},
      populate: [
        "itemUnit",
        "defectName",
        "alreadyCanceled",
        "creatorUser",
        "downtimeReasonName",
        "getEquipment",
      ],
    }),
    enabled: !!data,
  });

  const totalQuantity = workLogData?.data?.rows?.reduce(
    (total: any, row: any) => total + Number(row.quantity),
    0,
  );

  const { openModal } = useModal();

  return (
    <>
      <ProductionPlanDataForm data={data} totalQuantity={totalQuantity} />
      <DetailProductionPlan>
        <DetailProductionPlan.Title>{`[${t("productionPlan.productionPlan")}] ${data?.id}`}</DetailProductionPlan.Title>
        <Space h={8} />
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{t("productionPlan.id")}</DetailProductionPlan.Label>
          <DetailProductionPlan.Content>{data?.id}</DetailProductionPlan.Content>
        </DetailProductionPlan.Field>
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{t("productionPlan.itemName")}</DetailProductionPlan.Label>
          <DetailLinkWrapper>
            <DetailProductionPlan.Badge>{"제품"}</DetailProductionPlan.Badge>
            <DetailProductionPlan.Link
              onClick={() => {
                openModal(<ItemsDetailForm itemCode={data?.itemCode} />, null, "");
              }}
            >
              {data?.itemName}
            </DetailProductionPlan.Link>
          </DetailLinkWrapper>
        </DetailProductionPlan.Field>
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{t("work.targetQuantity")}</DetailProductionPlan.Label>
          <DetailProductionPlan.Content>
            {setToLocaleString(data?.targetQuantity)} {data?.unitText}
          </DetailProductionPlan.Content>
        </DetailProductionPlan.Field>
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{t("productionPlan.scheduledAt")}</DetailProductionPlan.Label>
          <DetailProductionPlan.Content>
            {timeUtil(data?.scheduledAt ?? "")}
          </DetailProductionPlan.Content>
        </DetailProductionPlan.Field>
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{t("productionPlan.dueDate")}</DetailProductionPlan.Label>
          <DetailProductionPlan.Content>{data?.dueDate}</DetailProductionPlan.Content>
        </DetailProductionPlan.Field>
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{t("productionPlan.itemList")}</DetailProductionPlan.Label>
          <DetailProductionPlan.Content>
            {t("productionPlan.itemList")}
          </DetailProductionPlan.Content>
        </DetailProductionPlan.Field>
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{t("info.createInformation")}</DetailProductionPlan.Label>
          <DetailProductionPlan.Content>
            {data?.creatorUserCode}({DatetimeUtil(data?.createdAt ?? "")})
          </DetailProductionPlan.Content>
        </DetailProductionPlan.Field>
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{t("productionPlan.auto")}</DetailProductionPlan.Label>
          <DetailProductionPlan.CheckBox checked={data?.isGenerated} />
        </DetailProductionPlan.Field>
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{t("info.updateInformation")}</DetailProductionPlan.Label>
          <DetailProductionPlan.Content>
            {data?.updaterUserCode}({DatetimeUtil(data?.updatedAt ?? "")})
          </DetailProductionPlan.Content>
        </DetailProductionPlan.Field>
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>{t("routing.descript")}</DetailProductionPlan.Label>
          <DetailProductionPlan.Content>{data?.description}</DetailProductionPlan.Content>
        </DetailProductionPlan.Field>
      </DetailProductionPlan>
    </>
  );
};
