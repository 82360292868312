import { ScmInstance } from "@/instance/axios";
import { PurchaseOrdersGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export type PurchaseOrdersOption = {
  value: string;
  label: string;
  group: string;
};

const getAutoCompleteKeyword = (keyword: string, direction: "INBOUND" | "OUTBOUND") =>
  ScmInstance.purchaseOrdersFindPost(
    {
      search: keyword,
      searchFields: ["code", "partnerCode"],
    },
    {
      params: {
        query: {
          direction: direction,
        },
      },
    },
  ).then((res: AxiosResponse<PurchaseOrdersGet200ResponseRowsInner[]>) => res.data);

export const usePurchaseOrderAutoComplete = (
  focus = false,
  keyword = "",
  direction: "INBOUND" | "OUTBOUND",
  options?: {
    onSuccess: (data: PurchaseOrdersOption[]) => void;
  },
) => {
  return useQuery(
    ["purchaseOrders-get-autocomplete", keyword, direction],
    () => getAutoCompleteKeyword(keyword, direction),
    {
      // enabled: focus,
      select: (data: PurchaseOrdersGet200ResponseRowsInner[]) =>
        data?.map((purchaseOrders) => {
          return {
            value: purchaseOrders.id?.toString() as string,
            label: `협력사명: ${purchaseOrders.partnerCode ?? "-"}, 수주코드 : ${purchaseOrders.code}`,
            group: purchaseOrders.manager ?? "-",
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    },
  );
};

const getPurchaseOrdersByCode = (code: string | null) =>
  ScmInstance.purchaseOrdersFindPost({
    ...(code && { search: code }),
    searchFields: ["code"],
  }).then((res: AxiosResponse<PurchaseOrdersGet200ResponseRowsInner[]>) => res.data);

export const usePurchaseOrdersCode = (
  enabled: boolean,
  code: string | null,
  options?: {
    onSuccess: (data?: PurchaseOrdersOption[]) => void;
  },
) => {
  return useQuery(["purchaseOrders-get-code", code], () => getPurchaseOrdersByCode(code), {
    enabled: enabled ?? false,
    select: (data: PurchaseOrdersGet200ResponseRowsInner[]) =>
      data?.map((purchaseOrders) => {
        return {
          value: purchaseOrders.id?.toString() as string,
          label: `협력사명: ${purchaseOrders.partnerCode ?? "-"}, 수주코드 : ${purchaseOrders.code}`,
          group: purchaseOrders.manager ?? "-",
        };
      }) ?? [],
    onSuccess: options?.onSuccess,
  });
};
