import { mutateItems } from "@/api/items/useItemQuery";
import { customFunctions } from "@/config/customFunction";
import { itemType } from "@/constants/items";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { AbstractItemAutoComplete } from "@/features/ui/autoComplete/abstractItem/abstractItem-autoComplete";
import { GradesAutoComplete } from "@/features/ui/autoComplete/grade/grades-autoComplete";
import { ItemCategoryAutoComplete } from "@/features/ui/autoComplete/itemCategory/itemCategory-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  Group,
  Input,
  NumberInput,
  Select,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiItemsItemIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const ItemsUpdateForm = (params: { formatterProps: any }) => {
  const { t } = useTranslation();
  const lnItemType = itemType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiItemsItemIdPutRequest) =>
      mutateItems.update(params).mutationFn(params as MasterApiItemsItemIdPutRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["items"]);
        closeModal(formatterProps);
        customAlert(
          `${t("message.editSuccess", { item: `${t("item.item")}` })}`,
          t("common.editSuccess"),
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("item.code") }),
          `${t("common.editFail", { item: `${t("item.item")}` })}`,
          "red",
        );
      },
    },
  );

  const formData = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      itemType: formatterProps.itemType,
      itemCategoryName: formatterProps.itemCategoryName,
      abstractItemCode: formatterProps.abstractItemCode,
      spec: formatterProps.spec,
      width: Number(formatterProps.width) ?? undefined,
      height: Number(formatterProps.height) ?? undefined,
      depth: Number(formatterProps.depth) ?? undefined,
      texture: formatterProps.texture,
      standardUph: formatterProps.standardUph ?? undefined,
      unitText: formatterProps.unitText,
      gradeCode: formatterProps.gradeCode ?? undefined,
      usesLotName: formatterProps.usesLotName,
      usesLotExpiration: formatterProps.usesLotExpiration,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate({
        itemId: params.formatterProps.id,
        itemsGetRequest: {
          name: formData.values.name as any,
          code: formData.values.code as any,
          itemType: formData.values.itemType as any,
          itemCategoryName: formData.values.itemCategoryName,
          abstractItemCode: formData.values.abstractItemCode,
          spec: formData.values.spec,
          width: formData.values.width.toString(),
          height: formData.values.height.toString(),
          depth: formData.values.depth.toString(),
          texture: formData.values.texture,
          standardUph: formData.values.standardUph,
          unitText: formData.values.unitText,
          gradeCode: formData.values.gradeCode,
          usesLotName: formData.values.usesLotName,
          usesLotExpiration: formData.values.usesLotExpiration,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("item.code") }),
        `${t("common.editFail", { item: `${t("item.item")}` })}`,
        "red",
      );
    }
  };

  return (
    <ItemInfoWrapper>
      <ItemTitle>
        <InputField>
          <Title order={2}>*{t("item.name")}</Title>
          <Input
            placeholder={t("placeholder.input", { item: `${t("item.name")}` })}
            size="lg"
            {...formData.getInputProps("name")}
            styles={{
              input: {
                color: formData.isDirty("name") ? "red !important" : "",
                borderColor: formData.isDirty("name") ? "red !important" : "",
              },
            }}
          />
        </InputField>
      </ItemTitle>
      <Spacing />
      <Field>
        <LabelText fw={700} c="dimmed">
          *{t("item.type")}
        </LabelText>
        <InputContent>
          <SelectInput
            placeholder={t("placeholder.select", { item: `${t("item.type")}` })}
            data={lnItemType}
            {...formData.getInputProps("itemType")}
            styles={{
              input: {
                color: formData.isDirty("itemType") ? "red !important" : "",
                borderColor: formData.isDirty("itemType") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          *{t("item.code")}
        </LabelText>
        <InputContent>
          <InputText
            placeholder={t("placeholder.input", { item: `${t("item.code")}` })}
            {...formData.getInputProps("code")}
            styles={{
              input: {
                color: formData.isDirty("code") ? "red !important" : "",
                borderColor: formData.isDirty("code") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          {t("category.category")}
        </LabelText>
        <InputContent>
          <ItemCategoryAutoComplete
            placeholder={t("placeholder.select", { item: `${t("category.category")}` })}
            {...formData.getInputProps("itemCategoryName")}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          {t("abstractItem.code")}
        </LabelText>
        <InputContent>
          <AbstractItemAutoComplete
            placeholder={t("placeholder.select", { item: `${t("abstractItem.code")}` })}
            {...formData.getInputProps("abstractItemCode")}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          {t("item.spec")}
        </LabelText>
        <InputContent>
          <InputText
            placeholder={t("placeholder.input", { item: `${t("item.spec")}` })}
            {...formData.getInputProps("spec")}
            styles={{
              input: {
                color: formData.isDirty("spec") ? "red !important" : "",
                borderColor: formData.isDirty("spec") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          {t("item.height")}
        </LabelText>
        <InputContent>
          <NumberInput
            min={1}
            placeholder={t("placeholder.input", { item: `${t("item.height")}` })}
            step={0.01}
            precision={2}
            {...formData.getInputProps("height")}
            styles={{
              input: {
                color: formData.isDirty("height") ? "red !important" : "",
                borderColor: formData.isDirty("height") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          {t("item.depth")}
        </LabelText>
        <InputContent>
          <NumberInput
            min={1}
            placeholder={t("placeholder.input", { item: `${t("item.depth")}` })}
            {...formData.getInputProps("depth")}
            step={0.01}
            precision={2}
            styles={{
              input: {
                color: formData.isDirty("depth") ? "red !important" : "",
                borderColor: formData.isDirty("depth") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          {t("item.width")}
        </LabelText>
        <InputContent>
          <NumberInput
            min={1}
            placeholder={t("placeholder.input", { item: `${t("item.width")}` })}
            step={0.01}
            precision={2}
            {...formData.getInputProps("width")}
            styles={{
              input: {
                color: formData.isDirty("width") ? "red !important" : "",
                borderColor: formData.isDirty("width") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          {t("item.texture")}
        </LabelText>
        <InputContent>
          <InputText
            placeholder={t("placeholder.input", { item: `${t("item.texture")}` })}
            {...formData.getInputProps("texture")}
            styles={{
              input: {
                color: formData.isDirty("texture") ? "red !important" : "",
                borderColor: formData.isDirty("texture") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          {t("item.standardUph")}
        </LabelText>
        <InputContent>
          <NumberInput
            placeholder={t("placeholder.input", { item: `${t("item.standardUph")}` })}
            {...formData.getInputProps("standardUph")}
            styles={{
              input: {
                color: formData.isDirty("standardUph") ? "red !important" : "",
                borderColor: formData.isDirty("standardUph") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          {t("item.unit")}
        </LabelText>
        <InputContent>
          <InputText
            placeholder={t("placeholder.input", { item: `${t("item.unit")}` })}
            {...formData.getInputProps("unitText")}
            styles={{
              input: {
                color: formData.isDirty("unitText") ? "red !important" : "",
                borderColor: formData.isDirty("unitText") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
        </InputContent>
      </Field>
      {customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING && (
        <Field>
          <LabelText fw={700} c="dimmed">
            {t("item.grade")}
          </LabelText>
          <InputContent>
            <GradesAutoComplete
              placeholder={t("placeholder.input", { item: `${t("item.grade")}` })}
              {...formData.getInputProps("gradeCode")}
            />
          </InputContent>
        </Field>
      )}
      <Field>
        <LabelText fw={700} c="dimmed">
          {t("item.lot Management")}
        </LabelText>
        <Group>
          <Checkbox
            label={t("lot.name")}
            {...formData.getInputProps("usesLotName")}
            checked={formData.values.usesLotName}
            styles={{
              input: {
                color: formData.isDirty("usesLotName") ? "red !important" : "",
                borderColor: formData.isDirty("usesLotName") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <Checkbox
            label={t("lot.expiration")}
            {...formData.getInputProps("usesLotExpiration")}
            checked={formData.values.usesLotExpiration}
            styles={{
              input: {
                color: formData.isDirty("usesLotExpiration") ? "red !important" : "",
                borderColor: formData.isDirty("usesLotExpiration") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
        </Group>
      </Field>
      <Spacing />
      <ModalConfirmBtnBox>
        <Button color="gray" onClick={closeModal}>
          {t("common.cancel")}
        </Button>
        <Button onClick={onSubmit}>{t("common.edit", { item: `${t("item.item")}` })}</Button>
      </ModalConfirmBtnBox>
    </ItemInfoWrapper>
  );
};

const ItemInfoWrapper = styled.div`
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const ItemTitle = styled.div`
  display: flex;
  height: 108px;
  align-items: flex-start;
  align-self: stretch;
`;

const Spacing = styled(Space)`
  display: flex;
  height: 16px;
  flex-direction: column;
  align-items: flex-start;
`;

const InputField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
`;

const InputContent = styled.div`
  display: flex;
  padding: 2px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

const LabelText = styled<any>(Text)`
  display: flex;
  width: 120px;
  padding: 2px 0px;
  flex-direction: column;
  align-items: flex-start;
`;

const InputText = styled<any>(Input)`
  display: flex;
  width: 410px;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
`;

const SelectInput = styled<any>(Select)`
  display: flex;
  width: 200px;

  align-items: flex-start;
`;

const Field = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 23px;
  align-self: stretch;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
