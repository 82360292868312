import {
  MRT_PaginationState,
  // createRow,
  type MRT_ColumnDef,
} from "mantine-react-table";
import { useMemo, useState } from "react";

import { productionPlans } from "@/api/productionPlan/useProductionPlanQuery";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { MoldDetailForm } from "@/features/mold/form/moldDetailForm";
import { OperationDetailForm } from "@/features/operations/components/form/operationDetailForm";
import { RoutingDetailForm } from "@/features/routing/components/form/RoutingDetailForm";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { MantineReactCommonTable } from "@/features/ui/mantineTable";
import { Badge, DefaultMantineColor, Flex } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ProductionPlanFormProps } from "../../view";

export interface Row {
  routingData: {
    id: number | any;
    moldCode: string | any;
    operationCode: string | any;
    seq: number | any;
    itemCode: string | any;
    code: string | any;
    routingType: string | any;
    consumeType: string | any;
    operation: {
      name: string | any;
    };
  };
}

export const RoutingTabs = ({ ProductionPlanId }: ProductionPlanFormProps) => {
  const { openModal } = useModal();

  const { t } = useTranslation();

  const routingTypeColor = {
    NORMAL: "green.7",
    ASSEMBLE: "blue.7",
    EXTERNAL: "yellow.7",
  };

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });

  const { data: planData } = useQuery(
    productionPlans.get({
      query: {
        $and: [{ id: { $eq: ProductionPlanId } }],
      },
      populate: ["works", "equipment", "itemUnit"],
      pageSize: pagination.pageSize,
      page: pagination.pageIndex,
    }),
  );

  const columns = useMemo<MRT_ColumnDef<Row>[]>(
    () => [
      {
        accessorFn: (row: any) => (
          <DetailLink
            onClick={() => {
              openModal(
                <ItemsDetailForm itemCode={row.routingData.itemCode} />,
                null,
                t("common.detail", { item: t("item.item") }),
              );
            }}
            justify="flex-start"
          >
            {row.routingData.itemCode}
          </DetailLink>
        ),
        accessorKey: "itemCode",
        header: t("item.code"),
        size: 80,
      },
      {
        accessorFn: (row) => row.routingData.operation.name,
        accessorKey: "operationCode",
        header: t("operation.name"),
        size: 120,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <DetailLink
              onClick={() => {
                openModal(
                  <OperationDetailForm operationCode={rows.row._valuesCache.operationCode} />,
                  null,
                  "",
                );
              }}
              justify="flex-start"
            >
              {rows.row.original.routingData.operation.name}
            </DetailLink>
          );
        },
      },
      {
        accessorFn: (row) => row.routingData.code,
        accessorKey: "routingCode",
        header: t("routing.code"),
        size: 120,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <DetailLink
              onClick={() =>
                openModal(
                  <RoutingDetailForm routingCode={rows.row.original.routingData.code} />,
                  null,
                  "",
                )
              }
              justify="flex-start"
            >
              {rows.row.original.routingData?.code}
            </DetailLink>
          );
        },
      },
      {
        accessorFn: (row) => t(row.routingData.routingType),
        accessorKey: "routingType",
        header: t("routing.type"),
        size: 120,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          const routingType = rows.row._valuesCache.routingType as keyof typeof routingTypeColor;

          return (
            <Badge color={routingTypeColor[routingType] as DefaultMantineColor}>
              {t(rows.row._valuesCache.routingType)}
            </Badge>
          );
        },
      },
      {
        accessorFn: (row) => row.routingData.moldCode,
        accessorKey: "moldCode",
        header: t("summary.useMold"),
        size: 120,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <Flex direction="column">
              {rows.row._valuesCache.moldCode && (
                <Badge size="sm" w={40}>
                  {t("mold.mold")}
                </Badge>
              )}
              <DetailLink
                onClick={() =>
                  openModal(<MoldDetailForm moldCode={rows.row._valuesCache.moldCode} />, null, "")
                }
                justify="flex-start"
              >
                {rows.row._valuesCache.moldCode}
              </DetailLink>
            </Flex>
          );
        },
      },
      {
        accessorFn: (row) => t(row.routingData.consumeType),
        accessorKey: "consumeType",
        header: t("routing.consumeType"),
        size: 120,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
      },
      {
        accessorFn: (row) => row.routingData.seq,
        accessorKey: "seq",
        header: t("routing.seq"),
        size: 120,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return <div style={{ textAlign: "right" }}>{rows.row._valuesCache.seq}</div>;
        },
      },
    ],
    [],
  );

  const worksData: { id?: any }[] | undefined =
    planData?.data && Array.isArray(planData?.data.rows && planData?.data.rows[0]?.works)
      ? planData?.data.rows &&
        (planData?.data.rows[0]?.works?.map((row) => {
          if (Array.isArray(row) && row.length > 0) {
            return {
              ...row,
              id: undefined,
            };
          } else {
            return row;
          }
        }) as unknown as { id?: any }[])
      : [];

  return (
    <MantineReactCommonTable
      data={worksData ?? []}
      columns={columns as MRT_ColumnDef<{ id?: any }>[]} // Update the type of the columns prop
      pagination={pagination}
      setPagination={setPagination}
      totalCount={worksData?.length}
      totalPages={Math.ceil((worksData?.length && worksData?.length) ?? 1 / pagination.pageSize)}
    />
  );
};
