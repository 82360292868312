import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Table } from "@mantine/core";
import { ConsumeDetailFormProps } from "./consumeDetailForm";

const ConsumeDetail = Object.assign(DetailHeader, {
  Title: DetailTitle,
});

export const ConsumeDetailView = (params: ConsumeDetailFormProps) => {
  const { consumeData } = params;

  if (!Array.isArray(consumeData)) {
    console.error("consumeData is not an array");
    return null;
  }

  return (
    <ConsumeDetail>
      <ConsumeDetail.Title>자재 소모 내역</ConsumeDetail.Title>
      <Table>
        <thead>
          <tr>
            <th>로트 명</th>
            <th>품목코드</th>
            <th>수량</th>
          </tr>
        </thead>
        <tbody>
          {consumeData.map((consume, index) => (
            <tr key={index}>
              <td>{consume?.lotName}</td>
              <td>{consume?.itemCode}</td>
              <td style={{ textAlign: "right" }}>{consume?.quantity}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </ConsumeDetail>
  );
};
