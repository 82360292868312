// @fileoverview : 경남-시스템관리 > SystemAccessLog

import { systemAccessLogHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import useQueryString from "@/hooks/useQueryString";
import styled from "@emotion/styled";
import { Flex, Pagination } from "@mantine/core";
import { useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid from "react-data-grid";

const SystemWrap = {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  ActionHeader: ActionHeader,
  Wrapper: Wrapper,
};

export const SystemPage = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const searchFieldsHeader = systemAccessLogHeader.filter(
    (item) => item.category === "text" && !item.isEnum,
  );

  const pageSize = 10;
  const totalRows = SystemPageMockupData.length;

  // 페이지에 맞는 데이터 슬라이싱
  const paginatedRows = SystemPageMockupData.slice(
    (activePage - 1) * pageSize,
    activePage * pageSize,
  );

  const columns: readonly Column<any>[] = [
    {
      key: "action",
      name: "동작",
      sortable: true,
      resizable: true,
    },
    {
      key: "company",
      name: "회사",
      sortable: true,
      resizable: true,
    },
    {
      key: "logDate",
      name: "날짜",
      sortable: true,
      resizable: true,
    },
    {
      key: "user",
      name: "유저",
      sortable: true,
      resizable: true,
    },
  ];

  return (
    <SystemWrap.Wrapper>
      <SystemWrap.Header>
        <SystemWrap.HeaderTitle>시스템 접근 로그</SystemWrap.HeaderTitle>
        <SystemWrap.HeaderSubTitle>
          {
            "사용자 또는 애플리케이션이 시스템에서 요청하는 개별 파일에 대한 전체 요청 목록을 기록합니다."
          }
        </SystemWrap.HeaderSubTitle>
      </SystemWrap.Header>
      <Flex w="100%" justify="space-between">
        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
          <CustomFilter filterType={systemAccessLogHeader} setQuery={setQuery} query={query} />
          <CustomSorter sorterType={systemAccessLogHeader} setSort={setSort} sort={sort} />
        </Flex>
        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
      </Flex>
      <Flex w="100%" h="100%">
        <GridWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={paginatedRows}
            rowHeight={40}
            rowKeyGetter={(row) => row.logDate}
          />
          <Pagination
            onChange={setPage}
            value={activePage}
            total={Math.ceil(totalRows / pageSize)} // 전체 페이지 계산
            size="lg"
            radius="sm"
            position="center"
          />
        </GridWrapper>
      </Flex>
    </SystemWrap.Wrapper>
  );
};

const SystemPageMockupData = [
  { action: "업데이트", company: "SIZL", logDate: "2024-10-10 15:37:09", user: "user2" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-10 14:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-10 13:37:09", user: "supervisor" },
  { action: "추가", company: "SIZL", logDate: "2024-10-10 12:37:09", user: "admin" },
  { action: "업데이트", company: "Global Inc", logDate: "2024-10-10 11:37:09", user: "manager" },
  { action: "접속", company: "ABC Corp", logDate: "2024-10-10 10:37:09", user: "user1" },
  { action: "삭제", company: "SIZL", logDate: "2024-10-10 09:37:09", user: "admin" },
  { action: "업데이트", company: "XYZ Ltd", logDate: "2024-10-10 08:37:09", user: "supervisor" },
  { action: "접속", company: "Tech Solutions", logDate: "2024-10-10 07:37:09", user: "user2" },
  { action: "삭제", company: "Global Inc", logDate: "2024-10-10 06:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-10 05:37:09", user: "manager" },
  { action: "업데이트", company: "ABC Corp", logDate: "2024-10-10 04:37:09", user: "user1" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-10 03:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-10 02:37:09", user: "supervisor" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-10 01:37:09", user: "user2" },
  { action: "접속", company: "SIZL", logDate: "2024-10-10 00:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-09 23:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-09 22:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-09 21:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-09 20:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-09 19:37:09", user: "user2" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-09 18:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-09 17:37:09", user: "supervisor" },
  { action: "추가", company: "SIZL", logDate: "2024-10-09 16:37:09", user: "admin" },
  { action: "업데이트", company: "Global Inc", logDate: "2024-10-09 15:37:09", user: "manager" },
  { action: "접속", company: "ABC Corp", logDate: "2024-10-09 14:37:09", user: "user1" },
  { action: "삭제", company: "SIZL", logDate: "2024-10-09 13:37:09", user: "admin" },
  { action: "업데이트", company: "XYZ Ltd", logDate: "2024-10-09 12:37:09", user: "supervisor" },
  { action: "접속", company: "Tech Solutions", logDate: "2024-10-09 11:37:09", user: "user2" },
  { action: "삭제", company: "Global Inc", logDate: "2024-10-09 10:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-09 09:37:09", user: "manager" },
  { action: "업데이트", company: "ABC Corp", logDate: "2024-10-09 08:37:09", user: "user1" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-09 07:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-09 06:37:09", user: "supervisor" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-09 05:37:09", user: "user2" },
  { action: "접속", company: "SIZL", logDate: "2024-10-09 04:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-09 03:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-09 02:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-09 01:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-09 00:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-08 23:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-08 22:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-08 21:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-08 20:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-08 19:37:09", user: "user2" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-08 18:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-08 17:37:09", user: "supervisor" },
  { action: "추가", company: "SIZL", logDate: "2024-10-08 16:37:09", user: "admin" },
  { action: "업데이트", company: "Global Inc", logDate: "2024-10-08 15:37:09", user: "manager" },
  { action: "접속", company: "ABC Corp", logDate: "2024-10-08 14:37:09", user: "user1" },
  { action: "삭제", company: "SIZL", logDate: "2024-10-08 13:37:09", user: "admin" },
  { action: "업데이트", company: "XYZ Ltd", logDate: "2024-10-08 12:37:09", user: "supervisor" },
  { action: "접속", company: "Tech Solutions", logDate: "2024-10-08 11:37:09", user: "user2" },
  { action: "삭제", company: "Global Inc", logDate: "2024-10-08 10:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-08 09:37:09", user: "manager" },
  { action: "업데이트", company: "ABC Corp", logDate: "2024-10-08 08:37:09", user: "user1" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-08 07:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-08 06:37:09", user: "supervisor" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-08 05:37:09", user: "user2" },
  { action: "접속", company: "SIZL", logDate: "2024-10-08 04:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-08 03:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-08 02:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-08 01:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-08 00:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-07 23:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-07 22:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-07 21:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-07 20:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-07 19:37:09", user: "user2" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-07 18:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-07 17:37:09", user: "supervisor" },
  { action: "추가", company: "SIZL", logDate: "2024-10-07 16:37:09", user: "admin" },
  { action: "업데이트", company: "Global Inc", logDate: "2024-10-07 15:37:09", user: "manager" },
  { action: "접속", company: "ABC Corp", logDate: "2024-10-07 14:37:09", user: "user1" },
  { action: "삭제", company: "SIZL", logDate: "2024-10-07 13:37:09", user: "admin" },
  { action: "업데이트", company: "XYZ Ltd", logDate: "2024-10-07 12:37:09", user: "supervisor" },
  { action: "접속", company: "Tech Solutions", logDate: "2024-10-07 11:37:09", user: "user2" },
  { action: "삭제", company: "Global Inc", logDate: "2024-10-07 10:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-07 09:37:09", user: "manager" },
  { action: "업데이트", company: "ABC Corp", logDate: "2024-10-07 08:37:09", user: "user1" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-07 07:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-07 06:37:09", user: "supervisor" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-07 05:37:09", user: "user2" },
  { action: "접속", company: "SIZL", logDate: "2024-10-07 04:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-07 03:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-07 02:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-07 01:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-07 00:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-06 23:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-06 22:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-06 21:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-06 20:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-06 19:37:09", user: "user2" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-06 18:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-06 17:37:09", user: "supervisor" },
  { action: "추가", company: "SIZL", logDate: "2024-10-06 16:37:09", user: "admin" },
  { action: "업데이트", company: "Global Inc", logDate: "2024-10-06 15:37:09", user: "manager" },
  { action: "접속", company: "ABC Corp", logDate: "2024-10-06 14:37:09", user: "user1" },
  { action: "삭제", company: "SIZL", logDate: "2024-10-06 13:37:09", user: "admin" },
  { action: "업데이트", company: "XYZ Ltd", logDate: "2024-10-06 12:37:09", user: "supervisor" },
  { action: "접속", company: "Tech Solutions", logDate: "2024-10-06 11:37:09", user: "user2" },
  { action: "삭제", company: "Global Inc", logDate: "2024-10-06 10:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-06 09:37:09", user: "manager" },
  { action: "업데이트", company: "ABC Corp", logDate: "2024-10-06 08:37:09", user: "user1" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-06 07:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-06 06:37:09", user: "supervisor" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-06 05:37:09", user: "user2" },
  { action: "접속", company: "SIZL", logDate: "2024-10-06 04:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-06 03:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-06 02:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-06 01:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-06 00:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-05 23:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-05 22:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-05 21:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-05 20:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-05 19:37:09", user: "user2" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-05 18:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-05 17:37:09", user: "supervisor" },
  { action: "추가", company: "SIZL", logDate: "2024-10-05 16:37:09", user: "admin" },
  { action: "업데이트", company: "Global Inc", logDate: "2024-10-05 15:37:09", user: "manager" },
  { action: "접속", company: "ABC Corp", logDate: "2024-10-05 14:37:09", user: "user1" },
  { action: "삭제", company: "SIZL", logDate: "2024-10-05 13:37:09", user: "admin" },
  { action: "업데이트", company: "XYZ Ltd", logDate: "2024-10-05 12:37:09", user: "supervisor" },
  { action: "접속", company: "Tech Solutions", logDate: "2024-10-05 11:37:09", user: "user2" },
  { action: "삭제", company: "Global Inc", logDate: "2024-10-05 10:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-05 09:37:09", user: "manager" },
  { action: "업데이트", company: "ABC Corp", logDate: "2024-10-05 08:37:09", user: "user1" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-05 07:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-05 06:37:09", user: "supervisor" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-05 05:37:09", user: "user2" },
  { action: "접속", company: "SIZL", logDate: "2024-10-05 04:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-05 03:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-05 02:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-05 01:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-05 00:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-04 23:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-04 22:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-04 21:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-04 20:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-04 19:37:09", user: "user2" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-04 18:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-04 17:37:09", user: "supervisor" },
  { action: "추가", company: "SIZL", logDate: "2024-10-04 16:37:09", user: "admin" },
  { action: "업데이트", company: "Global Inc", logDate: "2024-10-04 15:37:09", user: "manager" },
  { action: "접속", company: "ABC Corp", logDate: "2024-10-04 14:37:09", user: "user1" },
  { action: "삭제", company: "SIZL", logDate: "2024-10-04 13:37:09", user: "admin" },
  { action: "업데이트", company: "XYZ Ltd", logDate: "2024-10-04 12:37:09", user: "supervisor" },
  { action: "접속", company: "Tech Solutions", logDate: "2024-10-04 11:37:09", user: "user2" },
  { action: "삭제", company: "Global Inc", logDate: "2024-10-04 10:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-04 09:37:09", user: "manager" },
  { action: "업데이트", company: "ABC Corp", logDate: "2024-10-04 08:37:09", user: "user1" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-04 07:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-04 06:37:09", user: "supervisor" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-04 05:37:09", user: "user2" },
  { action: "접속", company: "SIZL", logDate: "2024-10-04 04:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-04 03:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-04 02:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-04 01:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-04 00:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-03 23:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-03 22:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-03 21:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-03 20:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-03 19:37:09", user: "user2" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-03 18:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-03 17:37:09", user: "supervisor" },
  { action: "추가", company: "SIZL", logDate: "2024-10-03 16:37:09", user: "admin" },
  { action: "업데이트", company: "Global Inc", logDate: "2024-10-03 15:37:09", user: "manager" },
  { action: "접속", company: "ABC Corp", logDate: "2024-10-03 14:37:09", user: "user1" },
  { action: "삭제", company: "SIZL", logDate: "2024-10-03 13:37:09", user: "admin" },
  { action: "업데이트", company: "XYZ Ltd", logDate: "2024-10-03 12:37:09", user: "supervisor" },
  { action: "접속", company: "Tech Solutions", logDate: "2024-10-03 11:37:09", user: "user2" },
  { action: "삭제", company: "Global Inc", logDate: "2024-10-03 10:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-03 09:37:09", user: "manager" },
  { action: "업데이트", company: "ABC Corp", logDate: "2024-10-03 08:37:09", user: "user1" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-03 07:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-03 06:37:09", user: "supervisor" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-03 05:37:09", user: "user2" },
  { action: "접속", company: "SIZL", logDate: "2024-10-03 04:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-03 03:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-03 02:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-03 01:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-03 00:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-02 23:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-02 22:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-02 21:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-02 20:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-02 19:37:09", user: "user2" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-02 18:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-02 17:37:09", user: "supervisor" },
  { action: "추가", company: "SIZL", logDate: "2024-10-02 16:37:09", user: "admin" },
  { action: "업데이트", company: "Global Inc", logDate: "2024-10-02 15:37:09", user: "manager" },
  { action: "접속", company: "ABC Corp", logDate: "2024-10-02 14:37:09", user: "user1" },
  { action: "삭제", company: "SIZL", logDate: "2024-10-02 13:37:09", user: "admin" },
  { action: "업데이트", company: "XYZ Ltd", logDate: "2024-10-02 12:37:09", user: "supervisor" },
  { action: "접속", company: "Tech Solutions", logDate: "2024-10-02 11:37:09", user: "user2" },
  { action: "삭제", company: "Global Inc", logDate: "2024-10-02 10:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-02 09:37:09", user: "manager" },
  { action: "업데이트", company: "ABC Corp", logDate: "2024-10-02 08:37:09", user: "user1" },
  { action: "접속", company: "Global Inc", logDate: "2024-10-02 07:37:09", user: "admin" },
  { action: "삭제", company: "Tech Solutions", logDate: "2024-10-02 06:37:09", user: "supervisor" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-02 05:37:09", user: "user2" },
  { action: "접속", company: "SIZL", logDate: "2024-10-02 04:37:09", user: "admin" },
  { action: "추가", company: "XYZ Ltd", logDate: "2024-10-02 03:37:09", user: "manager" },
  { action: "삭제", company: "ABC Corp", logDate: "2024-10-02 02:37:09", user: "admin" },
  { action: "업데이트", company: "SIZL", logDate: "2024-10-02 01:37:09", user: "user1" },
  { action: "접속", company: "SIZL", logDate: "2024-10-02 00:37:09", user: "admin" },
];

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    // --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    // --rdg-header-background-color: {(props) =>
    // props.isDarkMode ? "white" : "none"};
    // --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    // --rdg-row-hover-background-color: {(props) =>
    // props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;
