import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { ConfirmForm } from "@/features/ui/confirm";
import { getPrinterCodeByUserWithWhoami } from "@/utils/checkData";
import { Button, Flex } from "@mantine/core";
import { IconPlus, IconPrinter, IconRecycle, IconTrash } from "@tabler/icons-react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import customAlert from "../ui/alert/alert";

// 제네릭 타입을 포함하는 ActionHeaderProps 인터페이스 정의
interface ActionHeaderProps {
  createFn: () => void;
  createDataTitle?: string;
  deleteFn: () => void;
}

// 제네릭을 포함한 ES6 문법의 ActionHeader 컴포넌트
export const ActionHeader = ({ createFn, createDataTitle = "", deleteFn }: ActionHeaderProps) => {
  const { openModal, closeModal } = useModal();
  const { resetQueryStrings, selectedRows } = useStandardLayout();
  const { t } = useTranslation();

  const printLocationBarCode = async () => {
    openModal(
      <ConfirmForm
        message={t("message.rowPrinter")}
        yesCallback={async () => {
          await axios.post(customFunctions.ADD_COMPANY_BARCODE_ADDRESS_LOCATION, {
            location_code: [...selectedRows].map((row: any) => JSON.parse(row).code),
            company_code: customFunctions.ADD_COMPANY_CODE,
            printer_code: await getPrinterCodeByUserWithWhoami(),
          });
          customAlert(t("message.printerSuccessMessage"), t("message.printerSuccess"), "green");
        }}
        noCallback={() => {}}
        commonCallback={() => {
          closeModal(null);
        }}
      />,
      null,
      t("barcode.printer"),
      true,
    );
  };

  return (
    <>
      <Flex gap="md">
        <Button leftIcon={<IconPlus />} onClick={() => createFn()}>
          {t("common.additional", { item: createDataTitle })}
        </Button>
        <Button leftIcon={<IconPrinter />} color="green" onClick={() => printLocationBarCode()}>
          {t("barcode.printer")}
        </Button>
      </Flex>
      <Flex gap="md">
        <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
          {t("common.reset")}
        </Button>
        <Button rightIcon={<IconTrash />} color="red" onClick={() => deleteFn()}>
          {t("common.rowDelete")}
        </Button>
      </Flex>
    </>
  );
};
