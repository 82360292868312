import { mutateAbstractItems } from "@/api/abstractItems/useAbstractItemsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  AbstractItemsGet200ResponseRowsInner,
  DefaultApiAbstractItemsAbstractItemIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const AbstractItemUpdateForm = (params: {
  formatterProps: AbstractItemsGet200ResponseRowsInner;
}) => {
    const { closeModal } = useModal();
    const { formatterProps } = params;
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { mutate: updateMutate } = useMutation(
        (params: DefaultApiAbstractItemsAbstractItemIdPutRequest) =>
            mutateAbstractItems.update(params).mutationFn(params as DefaultApiAbstractItemsAbstractItemIdPutRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['abstractItems']);
                closeModal(formatterProps);
                customAlert(
                    `${t("message.editSuccess", { item: `${t("abstractItem.abstractItem")}` })}`,
                    `${t("common.editSuccess")}`,
                    "green"
                )
            },
            onError: (error) => {
                customAlert(handleErrorResponse({ error, errorContext: t('abstractItem.code') }), `${t("common.editFail", { item: `${t("abstractItem.abstractItem")}` })}`, 'red')
            }
        }
    );

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
    },
  });


    const onSubmit = () => {
        try {
            updateMutate(
                {
                    abstractItemId: formatterProps.id as number,
                    abstractItemsGetRequest: {
                        code: form.values.code!,
                    },
                },
            );
        } catch (error) {
            customAlert(handleErrorResponse({ error, errorContext: t('abstractItem.code') }), `${t("common.editFail", { item: `${t("abstractItem.abstractItem")}` })}`, 'red')
        }
    };

    return (
        <Update.Wrapper>
            <Update.Input
                label={t("abstractItem.code")}
                withAsterisk
                placeholder={t("placeholder.input", { item: `${t("abstractItem.code")}` })}
                defaultValue={formatterProps?.code}
                {...form.getInputProps("code")}
                error={form.isDirty("code") ? t("common.toSave") : ""}
                styles={{
                    input: {
                        color: form.isDirty("code") ? "red !important" : "",
                        borderColor: form.isDirty("code")
                            ? "red !important"
                            : "",
                    },
                    error: { fontSize: 11, color: "red" },
                }}
            />
            <Update.ButtonBox>
                <Update.Button color="gray" onClick={closeModal}>
                    {t("common.cancel")}
                </Update.Button>
                <Update.Button
                    color="blue"
                    disabled={validateFormValues({
                        pageType: "abstractItem",
                        values: form.values,
                    })}
                    onClick={onSubmit}
                >
                    {t("common.edit", { item: `${t("abstractItem.abstractItem")}` })}
                </Update.Button>
            </Update.ButtonBox>
        </Update.Wrapper>
    );
};
