import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { getUserCodeByUser } from "@/utils/checkData";
import { purchaseOrderStatusColor, setPurchaseOrderStatus } from "@/utils/scmDataStatus";
import { Badge, Checkbox, Space } from "@mantine/core";
import { PurchaseOrdersGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const PurchaseOrderDetail = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
  CheckBox: Checkbox,
});

export interface PurchaseOrderDetailHeaderProps {
  data: PurchaseOrdersGet200ResponseRowsInner | undefined;
}

export const PurchaseOrderDetailHeader = (params: PurchaseOrderDetailHeaderProps) => {
  const { data } = params;
  const { openModal } = useModal();
  const userCode = getUserCodeByUser();
  const { t } = useTranslation();

  return (
    <>
      <PurchaseOrderDetail>
        <PurchaseOrderDetail.Title>
          {userCode === "PB" ? `[${t("order.order")}]` : [`[${t("purchaseOrder.purchaseOrder")}]`]}{" "}
          {data?.code}
        </PurchaseOrderDetail.Title>
        <Space h={8} />
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>
            {userCode === "PB" ? t("order.id") : t("purchaseOrder.id")}
          </PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>{data?.id}</PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>
            {userCode === "PB" ? t("order.code") : t("purchaseOrder.code")}
          </PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>{data?.code}</PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{t("partner.partner")}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            <PurchaseOrderDetail.Link
              onClick={() =>
                openModal(
                  <PartnerDetailForm partnerCode={data?.partnerCode} />,
                  null,
                  t("common.detail", { item: t("partner.partner") }),
                )
              }
            >
              {data?.partner?.name}
            </PurchaseOrderDetail.Link>
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>
            {userCode === "PB" ? t("order.status") : t("purchaseOrder.status")}
          </PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            <Badge
              variant="filled"
              color={
                purchaseOrderStatusColor[data?.status as keyof typeof purchaseOrderStatusColor]
              }
            >
              {setPurchaseOrderStatus(data?.status ?? "")}
            </Badge>
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{t("order.manager")}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            <PurchaseOrderDetail.Link
              onClick={() =>
                openModal(
                  <UsersDetailForm UserCode={data?.userCode} />,
                  null,
                  t("common.detail", { item: t("user.user") }),
                )
              }
            >
              {data?.manager}
            </PurchaseOrderDetail.Link>
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{t("inventory.fromLocation")}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            <PurchaseOrderDetail.Link
              onClick={() =>
                openModal(
                  <LocationDetailForm locationCode={data?.sourceLocationCode} />,
                  null,
                  t("common.detail", { item: t("location.location") }),
                )
              }
            >
              {data?.sourceLocationName}
            </PurchaseOrderDetail.Link>
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{t("routing.descript")}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>{data?.description}</PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>
            {userCode === "PB" ? t("order.orderDate") : t("purchaseOrder.purchaseOrderDate")}
          </PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            {dayjs(data?.orderDate).format("YYYY-MM-DD")}
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{t("order.deliveryDate")}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            {dayjs(data?.deliveryDeadline).format("YYYY-MM-DD")}
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{t("order.createdAt")}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            {data?.creatorUserName} {dayjs(data?.createdAt).format("YYYY-MM-DD")}
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{t("info.updateInformation")}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            <PurchaseOrderDetail.Link>{data?.updaterUserName}</PurchaseOrderDetail.Link>
            {data?.updatedAt === null ? "" : dayjs(data?.updatedAt).format("YYYY-MM-DD")}
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
      </PurchaseOrderDetail>
    </>
  );
};
