import { createContext, useMemo, useState } from "react";

export interface OrderContextProps {
  lotNum: { [key: number]: number }; // 각 행의 인덱스를 키로 사용하여 값을 저장
}

// 초기 상태에 대한 타입 안정성 강화
const initialOrderState: OrderContextProps = {
  lotNum: {},
};

export const OrderStateContext = createContext<OrderContextProps>(initialOrderState);

export const OrderActionsContext = createContext<{
  setLotNum: (index: number, value: number) => void; // 인덱스와 값을 매개변수로 설정
}>({
  setLotNum: () => {},
});

export const OrderProvider = ({ children }: { children: React.ReactNode }) => {
  const [OrderState, setOrderState] = useState<OrderContextProps>(initialOrderState);

  const actions = useMemo(
    () => ({
      setLotNum: (index: number, value: number) => {
        setOrderState((prev: OrderContextProps) => ({
          ...prev,
          lotNum: { ...prev.lotNum, [index]: value }, // 인덱스를 키로 사용하여 값을 업데이트
        }));
      },
    }),
    [],
  );

  return (
    <OrderActionsContext.Provider value={actions}>
      <OrderStateContext.Provider value={OrderState}>{children}</OrderStateContext.Provider>
    </OrderActionsContext.Provider>
  );
};
