import { Table } from "@mantine/core";

export type WorkProductionPlanTableProps = {
  children: React.ReactNode;
};

export const WorkProductionPlanTable = ({ children }: WorkProductionPlanTableProps) => {
  return (
    <Table withBorder withColumnBorders>
      {children}
    </Table>
  );
};
