import { useStandardLayout } from "@/features/standardLayout/Context";
import { Flex, Text } from "@mantine/core";
import { AxiosResponse } from "axios";
import { useState } from "react";
import DataGrid, { Column } from "react-data-grid";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { useLocalStorage } from "@mantine/hooks"; // Mantine의 useLocalStorage 훅 사용
import { ColumnEditButton, ColumnItem } from "./ColumnEdit";

// Props 타입 정의
interface ListTableProps<TRow> {
  columns: readonly Column<TRow>[];
  rowKeyGetter?: (row: TRow) => any;
  additionalDataFn?: (row: TRow[]) => TRow[];
}

export const ListTable = <TResponse extends { rows?: TRow[] }, TRow>({
  columns,
  rowKeyGetter = (row) => (row as any).id ?? (row as any).code,
  additionalDataFn,
}: ListTableProps<TRow>) => {
  const [columnListVisible, setColumnListVisible] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  const HIDE_COLUMN_EDIT_BUTTON = ["/wmsLogs"];

  // provider에서 data를 받아와서 바인딩
  const { data, selectedRows, setSelectedRows } = useStandardLayout<AxiosResponse<TResponse>>();
  const response = data?.data || { rows: [] as TRow[] }; // rows가 기본적으로 빈 배열을 가지도록 설정
  let rows: TRow[] = response.rows ?? []; // rows가 undefined일 경우 빈 배열을 사용

  // Mantine useLocalStorage 훅 사용하여 localStorage에서 컬럼 상태 관리
  const [checkedColumns, setCheckedColumns] = useLocalStorage<{ id: string; checked: boolean }[]>({
    key: location.pathname,
    defaultValue: columns.map((col) => ({ id: col.name as string, checked: true })), // 기본값은 모든 컬럼이 체크됨
  });

  const visibleColumns = columns.filter((col) => {
    const checkedColumn = checkedColumns.find((checkedCol) => checkedCol.id === col.name);
    return checkedColumn ? checkedColumn.checked : true;
  });

  // 추가 데이터 처리 함수가 있다면 처리
  if (additionalDataFn) {
    rows = additionalDataFn(rows as TRow[]);
  }

  // 컬럼 체크박스 상태 변경 처리 함수
  const handleCheckboxChange = (id: string) => {
    setCheckedColumns((prev) =>
      prev.map((col) => (col.id === id ? { ...col, checked: !col.checked } : col)),
    );
  };

  return (
    <Container>
      {!HIDE_COLUMN_EDIT_BUTTON.includes(location.pathname) && (
        <div>
          <ColumnEditButton onClick={() => setColumnListVisible((prev) => !prev)} />
          {columnListVisible && (
            <ColumnListBox>
              {columns.map(({ name }) => {
                if (!name) return null;
                const isChecked =
                  checkedColumns.find((checkedCol) => checkedCol.id === name)?.checked ?? true;
                return (
                  <ColumnItem
                    // key={name}
                    name={name as string}
                    isChecked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                );
              })}
            </ColumnListBox>
          )}
        </div>
      )}

      <DataGrid
        className="rdg-light"
        columns={visibleColumns}
        rows={rows}
        rowHeight={40}
        rowKeyGetter={rowKeyGetter}
        selectedRows={selectedRows}
        onSelectedRowsChange={(e) => {
          setSelectedRows(e);
        }}
        components={{
          noRowsFallback: (
            <Flex justify="center" align="center" w="100%" bg="white" top={45} pos="absolute">
              <Text fz="lg">{t("common.notFound")}</Text>
            </Flex>
          ),
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const ColumnListBox = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  width: 200px;
  border-radius: 8px;
  padding: 20px;
  gap: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 50;
`;
