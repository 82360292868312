// @fileoverview : 경남-PMS > 고속성형기관리 > 설비수리요청
import { useModal } from "@/features/modal/ModalStackManager";
import { Calendar } from "@/features/ui/Calendar";
import { GridTable } from "@/features/ui/PMS/GridTable";
import { MachineSearchBox } from "@/features/ui/PMS/machineSearchBox";
import useColumnList from "@/hooks/useColumnList";
import { Button, Flex, Input, Select, Text } from "@mantine/core";
import { IconCalendar, IconSearch } from "@tabler/icons-react";
import { SelectColumn } from "react-data-grid";

export const PressFacilitiesPage = () => {
  const { openModal } = useModal();
  const columnlist = useColumnList();

  return (
    <>
      <Flex gap="md" direction="column">
        <Flex justify={"space-between"}>
          <Text size="xl">설비 수리 요청</Text>
          <Flex gap="sm">
            <Button
              onClick={() => {
                openModal(<MachineSearchBox />, null, "기계 검색");
              }}
            >
              추가하기
            </Button>
            <Button color="red">삭제</Button>
          </Flex>
        </Flex>
        <Flex justify={"flex-end"} gap="sm">
          <Button
            leftIcon={<IconCalendar />}
            color="indigo"
            onClick={() => {
              openModal(<Calendar dates={[null, null]} type="range" />, null, "날짜 선택");
            }}
          >
            날짜선택
          </Button>
          <Select
            w={"8rem"}
            data={[
              { label: "제조사명", value: "all" },
              { label: "기계명", value: "all" },
              { label: "제조번호", value: "all" },
              { label: "담당자", value: "all" },
              { label: "문제점", value: "all" },
            ]}
          />
          <Input w={"16rem"} placeholder="검색어를 입력하세요" rightSection={<IconSearch />} />
          <Button>검색</Button>
        </Flex>
        <GridTable
          headerList={[SelectColumn, columnlist.completeAdmin]}
          row={""}
          setRow={() => {}}
        />
      </Flex>
    </>
  );
};
