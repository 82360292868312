import { Flex, Pagination } from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";
import { Dispatch, SetStateAction, useEffect } from "react";

interface CommonTableProps<TData extends Record<string, any> = {}> {
  columns: MRT_TableOptions<TData>["columns"];
  data: TData[];
  totalCount?: number;
  totalPages?: number;
  pagination: MRT_PaginationState;
  setPagination: Dispatch<SetStateAction<MRT_PaginationState>>;
  sorting?: MRT_SortingState;
  setSorting?: Dispatch<SetStateAction<MRT_SortingState>>;
  columnFilters?: MRT_ColumnFiltersState;
  setColumnFilters?: Dispatch<SetStateAction<MRT_ColumnFiltersState>>;
  globalFilter?: string;
  setGlobalFilter?: Dispatch<SetStateAction<string>>;
  rowSelection?: Record<string, boolean>;
  setRowSelection?: Dispatch<SetStateAction<Record<string, boolean>>>;
  selectedRowIds?: string[];
  setSelectedRowIds?: Dispatch<SetStateAction<string[]>>;
  enableRowSelection?: boolean;
  enableRowActions?: boolean;
  positionActionsColumn?: boolean;
  enableEditing?: boolean;
  enableTopToolbar?: boolean;
  renderRowActions?: MRT_TableOptions<TData>["renderRowActions"];
}

export const MantineReactCommonTable = <TData extends { id?: number | any }>({
  columns,
  data,
  totalCount,
  totalPages,
  pagination,
  setPagination,
  sorting = [],
  setSorting,
  columnFilters = [],
  setColumnFilters,
  globalFilter,
  setGlobalFilter,
  rowSelection = {},
  setRowSelection,
  selectedRowIds,
  setSelectedRowIds,
  enableRowSelection,
  enableRowActions,
  positionActionsColumn,
  enableEditing,
  enableTopToolbar,
  renderRowActions,
  ...rest
}: CommonTableProps<TData>) => {
  useEffect(() => {
    setPagination((prevState) => ({ ...prevState, pageIndex: 1 }));
  }, [columnFilters, globalFilter, sorting]);

  const table = useMantineReactTable({
    columns,
    data,
    editDisplayMode: "modal", // default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: enableEditing ?? false,
    enableRowSelection: enableRowSelection ?? false,
    rowCount: totalCount,
    getRowId: (row) => row.id,
    positionActionsColumn: positionActionsColumn ? "last" : undefined, //actions 위치
    enablePagination: true,
    enableTopToolbar: enableTopToolbar ?? false, // top toolbar
    manualFiltering: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters, // column filter onChange
    onGlobalFilterChange: setGlobalFilter, // global filter onChange
    onSortingChange: setSorting, // sort onChange
    selectDisplayMode: "checkbox",
    enableSorting: setSorting ? true : false,
    enableStickyHeader: true,
    state: {
      columnFilters,
      sorting,
      rowSelection,
      globalFilter,
    },
    enableRowActions: enableRowActions ?? false,
    onRowSelectionChange: setRowSelection,
    renderRowActions: renderRowActions,
    ...rest,
  });

  return (
    <>
      <MantineReactTable table={table} />
      <Flex justify="center" style={{ padding: "1rem 0" }}>
        <Pagination
          onChange={(value: number) =>
            setPagination((prevState) => ({ ...prevState, pageIndex: value }))
          }
          value={pagination.pageIndex}
          total={totalPages ?? 0}
          size="lg"
          radius="sm"
        />
      </Flex>
    </>
  );
};
