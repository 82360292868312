import { customFunctions } from "@/config/customFunction";

export interface ColumnHeaderProps {
  label: string;
  value: string;
  category: string;
  isFK: boolean;
  isEnum?: boolean;
}

export const itemsHeader: ColumnHeaderProps[] = [
  { label: "item.name", value: "name", category: "text", isFK: false },
  { label: "item.code", value: "code", category: "text", isFK: false },
  ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
    ? [{ label: "item.gradeName", value: "gradeName", category: "text", isFK: false }]
    : []),
  { label: "item.type", value: "itemType", category: "text", isFK: false, isEnum: true },
  { label: "category.category", value: "itemCategoryName", category: "text", isFK: true },
  { label: "abstractItem.code", value: "abstractItemCode", category: "text", isFK: true },
  { label: "item.spec", value: "spec", category: "text", isFK: false },
  { label: "item.height", value: "height", category: "number", isFK: false },
  { label: "item.depth", value: "depth", category: "number", isFK: false },
  { label: "item.width", value: "width", category: "number", isFK: false },
  { label: "item.texture", value: "texture", category: "text", isFK: false },
  { label: "item.unit", value: "unitText", category: "text", isFK: false },
];

export const equipmentsHeader: ColumnHeaderProps[] = [
  { label: "site.code", value: "siteCode", category: "text", isFK: true },
  { label: "equipment.code", value: "code", category: "text", isFK: false },
  { label: "equipment.name", value: "name", category: "text", isFK: false },
  { label: "equipment.type", value: "equipmentType", category: "text", isFK: false },
];
export const defectsHeader: ColumnHeaderProps[] = [
  { label: "defect.code", value: "code", category: "text", isFK: false },
  { label: "defect.name", value: "name", category: "text", isFK: false },
  { label: "operation.code", value: "operationCode", category: "text", isFK: true },
];
export const operationsHeader: ColumnHeaderProps[] = [
  { label: "operation.code", value: "code", category: "text", isFK: false },
  { label: "site.code", value: "siteCode", category: "text", isFK: true },
  { label: "operation.name", value: "name", category: "text", isFK: false },
];
export const routingsHeader: ColumnHeaderProps[] = [
  // { label: "품목명", value: "itemName", category: "text" },
  { label: "item.code", value: "itemCode", category: "text", isFK: false },
  // { label: "규격", value: "spec", category: "text" },
  { label: "operation.code", value: "operationCode", category: "text", isFK: true },
  { label: "routing.code", value: "code", category: "text", isFK: false },
  { label: "routing.type", value: "routingType", category: "text", isFK: false, isEnum: true },
  { label: "mold.code", value: "moldCode", category: "text", isFK: true },
];
export const usersHeader: ColumnHeaderProps[] = [
  { label: "user.code", value: "code", category: "text", isFK: false },
  { label: "user.name", value: "name", category: "text", isFK: false },
  { label: "user.username", value: "username", category: "text", isFK: false },
  { label: "equipment.code", value: "equipmentCode", category: "text", isFK: true },
  { label: "location.code", value: "locationCode", category: "text", isFK: false },
  { label: "department.code", value: "departmentCode", category: "text", isFK: false },
  { label: "user.printer", value: "printerCode", category: "text", isFK: false },
];
export const sitesHeader: ColumnHeaderProps[] = [
  { label: "site.code", value: "code", category: "text", isFK: false },
  { label: "site.name", value: "name", category: "text", isFK: false },
];
export const locationsHeader: ColumnHeaderProps[] = [
  { label: "location.code", value: "code", category: "text", isFK: false },
  { label: "location.name", value: "name", category: "text", isFK: false },
  { label: "site.code", value: "siteCode", category: "text", isFK: true },
];
export const routingOutsourcesHeader: ColumnHeaderProps[] = [
  { label: "outsource.name", value: "name", category: "text", isFK: false },
  { label: "routing.code", value: "routingCode", category: "text", isFK: true },
  {
    label: "outsource.fromLocationCode",
    value: "fromLocationCode",
    category: "text",
    isFK: true,
  },
  { label: "outsource.toLocationCode", value: "toLocationCode", category: "text", isFK: true },
];

export const inventoriesHeader: ColumnHeaderProps[] = [
  { label: "item.code", value: "itemCode", category: "text", isFK: false },
  { label: "item.type", value: "itemType", category: "text", isFK: false, isEnum: true },
  { label: "lot.name", value: "lotName", category: "text", isFK: false },
  // { label: "로트 유효기한", value: "expiration", category: "date" },
  { label: "location.location", value: "locationCode", category: "text", isFK: true },
  { label: "inventory.addQuantity", value: "increaseQuantity", category: "number", isFK: false },
  {
    label: "inventory.subtractQuantity",
    value: "decreaseQuantity",
    category: "number",
    isFK: false,
  },
  { label: "inventory.changeQuantity", value: "deltaQuantity", category: "number", isFK: false },
  { label: "inventory.totalQuantity", value: "quantity", category: "number", isFK: false },
  // { label: t("inventory.closeAt"), value: "closedAt", category: "date", isFK: false },
  { label: "inventory.closeQuantity", value: "closedQuantity", category: "number", isFK: false },
];

export const wipInventoriesHeader: ColumnHeaderProps[] = [
  { label: "item.code", value: "itemCode", category: "text", isFK: false },
  { label: "location.location", value: "locationCode", category: "text", isFK: true },
  // { label: "재고 라우팅단계", value: "routingStep", category: "text" },
  {
    label: "wipInventory.inventoryQuantity",
    value: "quantity",
    category: "number",
    isFK: false,
  },
];
export const LedgersHeader: ColumnHeaderProps[] = [
  { label: "item.code", value: "itemCode", category: "text", isFK: false },
  { label: "location.location", value: "locationCode", category: "text", isFK: true },
  // { label: "사업장", value: "locationData.site_code", category: "text", isFK: true },
  { label: "user.user", value: "userCode", category: "text", isFK: true },
  { label: "lot.name", value: "lotData.name", category: "text", isFK: false },
  { label: "lot.lotExpiration", value: "lotData.expiration", category: "date", isFK: false },
  { label: "inventory.totalQuantity", value: "quantity", category: "number", isFK: false },
];

export const moldsHeader: ColumnHeaderProps[] = [
  { label: "mold.code", value: "code", category: "text", isFK: false },
  { label: "site.code", value: "siteCode", category: "text", isFK: true },
  { label: "mold.capacity", value: "capacity", category: "number", isFK: false },
];

export const moldStocksHeader: ColumnHeaderProps[] = [
  { label: "moldStock.code", value: "code", category: "text", isFK: false },
  { label: "mold.code", value: "moldCode", category: "text", isFK: true },
];

export const abstractItemsHeader: ColumnHeaderProps[] = [
  { label: "abstractItem.code", value: "code", category: "text", isFK: false },
];

export const specificationsHeader: ColumnHeaderProps[] = [
  { label: "item.code", value: "itemCode", category: "text", isFK: false },
  // { label: "검사종류", value: "type", category: "text" },
  // { label: "검사 기준 요약", value: "criteria", category: "text" },
];

export const itemCategoriesHeader: ColumnHeaderProps[] = [
  { label: "category.category", value: "name", category: "text", isFK: false },
];

export const downtimeReasonsHeader: ColumnHeaderProps[] = [
  { label: "downtimeReason.code", value: "code", category: "text", isFK: false },
  { label: "downtimeReason.name", value: "name", category: "text", isFK: false },
  { label: "operation.code", value: "operationCode", category: "text", isFK: true },
];

export const workLogHeader: ColumnHeaderProps[] = [
  { label: "item.code", value: "itemCode", category: "text", isFK: false },
  { label: "inventory.quantity", value: "quantity", category: "number", isFK: false },
  {
    label: "workLog.location",
    value: "logData.incoming.locationData.name logData.outgoing.locationData.name",
    category: "text",
    isFK: true,
  },
  { label: "workLog.workId", value: "workId", category: "number", isFK: false },
  { label: "productionPlan.id", value: "productionPlanId", category: "number", isFK: false },
  { label: "equipment.code", value: "equipmentCode", category: "text", isFK: true },
  {
    label: "lot.name",
    value: "logData.incoming.lotData.name logData.outgoing.lotData.name",
    category: "text",
    isFK: false,
  },
  // {
  //   label: "로트 유효기한",
  //   value: "logData.incoming.lotData.expiration logData.outgoing.lotData.expiration",
  //   category: "date",
  //   isFK: false,
  // },
  // { label: "생산일시", value: "createdAt", category: "date", isFK: false },
  { label: "workLog.worker", value: "creatorUserCode", category: "text", isFK: false },
  ...(customFunctions.ADD_WORK_LOG_ERP_WORK_ORDER_COLUMN
    ? [
        {
          label: "workLog.erpWorkOrderNo",
          value: "erpWorkOrderNo",
          category: "text",
          isFK: false,
        },
      ]
    : []),
];

export const purchaseOrderHeader: ColumnHeaderProps[] = [
  { label: "purchaseOrder.code", value: "code", category: "text", isFK: false },
  { label: "partner.code", value: "partnerCode", category: "text", isFK: true },
  { label: "wms.fromLocation", value: "sourceLocationName", category: "text", isFK: true },
];

export const orderHeader: ColumnHeaderProps[] = [
  { label: "order.code", value: "code", category: "text", isFK: false },
  { label: "partner.code", value: "partnerCode", category: "text", isFK: true },
  { label: "wms.toLocation", value: "targetLocationName", category: "text", isFK: true },
];
export const purchaseOrderItemsHeader: ColumnHeaderProps[] = [
  // { label: "수주 접수일", value: "orderDate", category: "date", isFK: false },
  // { label: "납기일", value: "deliveryDeadline", category: "date", isFK: false },
  {
    label: "item.code",
    value: "itemCode",
    category: "text",
    isFK: false,
  },
  // { label: "수주량", value: "quantityOrdered", category: "number", isFK: false },
  {
    label: "purchaseOrderItems.contractorName",
    value: "contractorName",
    category: "text",
    isFK: false,
  },
];

export const deliveryStatusHeader: ColumnHeaderProps[] = [
  { label: "purchaseOrder.id", value: "purchaseOrderItemId", category: "number", isFK: true },
  { label: "item.code", value: "itemCode", category: "text", isFK: false },
  { label: "location.location", value: "locationCode", category: "text", isFK: true },
  { label: "delivery.deadline", value: "deliveryDeadline", category: "date", isFK: false },
  // { label: "총 납품수량", value: "contractorName", category: "text", isFK: false },
];

export const wmsLogsHeader: ColumnHeaderProps[] = [
  { label: "purchaseOrder.id", value: "purchaseOrderItemId", category: "number", isFK: false },
  // { label: "출고창고", value: "actionParam.params.sourceLocationCode actionParam.params.locationCode", category: "text", isFK: false },
  // { label: "입고창고", value: "actionParam.params.targetLocationCode actionParam.params.locationCode", category: "text", isFK: false },
  // { label: "작업자", value: "creatorUserCode", category: "text", isFK: false },
];

export const wiWithEslHeader: ColumnHeaderProps[] = [
  { label: "wms.worker", value: "creatorUserCode", category: "text", isFK: false },
  { label: "item.code", value: "actionParam.params.itemCode", category: "text", isFK: false },
];

export const moldMaintenancesHeader: ColumnHeaderProps[] = [
  { label: "mold.code", value: "moldCode", category: "text", isFK: true },
  { label: "mold.name", value: "moldName", category: "text", isFK: false },
];

export const itemChangesHeader: ColumnHeaderProps[] = [
  { label: "item.code", value: "itemCode", category: "text", isFK: false },
  // { label : "품목명", value : "itemName", category : "text", isFK : false },
  // { label : "규격", value : "itemSpec", category : "text", isFK : false },
  { label: "제목", value: "title", category: "text", isFK: false },
  { label: "작성자", value: "creatorUserCode", category: "text", isFK: false },
];

export const preWarehouseListHeader: ColumnHeaderProps[] = [
  { label: "item.code", value: "actionParam.params.itemCode", category: "text", isFK: false },
  // { label: "품목코드", value: "actionParam.params.itemCode", category: "text", isFK: true },
  // { label: "로트명", value: "actionParam.params?.lotInfo?.name", category: "text", isFK: false },
];

export const systemAccessLogHeader: ColumnHeaderProps[] = [
  { label: "동작", value: "action", category: "text", isFK: false },
  { label: "회사", value: "company", category: "text", isFK: false },
  { label: "날짜", value: "logDate", category: "date", isFK: false },
  { label: "유저", value: "user", category: "text", isFK: false },
];

export const systemParameterHeader: ColumnHeaderProps[] = [
  { label: "시스템 파라미터 키 (필수)", value: "systemKey", category: "text", isFK: false },
  { label: "시스템 파라미터 값 (필수)", value: "systemValue", category: "text", isFK: false },
  { label: "비고", value: "description", category: "text", isFK: false },
];

export const commonCodeHeader: ColumnHeaderProps[] = [
  { label: "공통코드", value: "commonCode", category: "text", isFK: false },
  { label: "코드명", value: "codeName", category: "text", isFK: false },
];

export const departmentHeader: ColumnHeaderProps[] = [
  { label: "department.code", value: "code", category: "text", isFK: false },
  { label: "department.name", value: "name", category: "text", isFK: false },
];

export const userLogsHeader: ColumnHeaderProps[] = [
  { label: "사용자명", value: "username", category: "text", isFK: false },
  { label: "아이피", value: "userIp", category: "text", isFK: false },
  { label: "액션", value: "action", category: "text", isFK: false },
  { label: "로그인 시간", value: "createdAt", category: "date", isFK: false },
];

export const bomHeader: ColumnHeaderProps[] = [
  { label: "partner.code", value: "partnerCode", category: "text", isFK: false },
  { label: "모델명", value: "modelName", category: "text", isFK: false },
  { label: "item.bom", value: "bomCode", category: "text", isFK: false },
  { label: "item.name", value: "itemName", category: "text", isFK: false },
  { label: "item.spec", value: "spec", category: "text", isFK: false },
  { label: "item.type", value: "itemType", category: "text", isFK: false },
  { label: "item.unit", value: "unitText", category: "text", isFK: false },
  { label: "operation.code", value: "operationCode", category: "text", isFK: false },
];

export const documentFileHeader: ColumnHeaderProps[] = [
  { label: "파일명", value: "fileName", category: "text", isFK: false },
  { label: "파일형식", value: "fileType", category: "text", isFK: false },
  { label: "파일크기", value: "fileSize", category: "text", isFK: false },
  { label: "등록일", value: "createdAt", category: "text", isFK: false },
];

export const shipmentStatusHeader: ColumnHeaderProps[] = [
  { label: "CODE", value: "code", category: "text", isFK: false },
  { label: "item.name", value: "itemName", category: "text", isFK: false },
  { label: "item.spec", value: "spec", category: "text", isFK: false },
  { label: "Serial 번호", value: "serialNumber", category: "text", isFK: false },
  { label: "거래처", value: "partnerCode", category: "text", isFK: false },
  { label: "날짜", value: "date", category: "text", isFK: false },
  { label: "입고량", value: "quantity", category: "text", isFK: false },
  { label: "단가", value: "price", category: "text", isFK: false },
  { label: "금액", value: "amount", category: "text", isFK: false },
  { label: "로케이션", value: "locationCode", category: "text", isFK: false },
  { label: "총 금액", value: "totalAmount", category: "text", isFK: false },
  { label: "상태", value: "status", category: "text", isFK: false },
];

export const lackSafetyHeader: ColumnHeaderProps[] = [
  { label: "item.name", value: "itemName", category: "text", isFK: false },
  { label: "item.code", value: "itemCode", category: "text", isFK: false },
  { label: "item.spec", value: "spec", category: "text", isFK: false },
  { label: "로케이션/공정명", value: "locationName", category: "text", isFK: false },
  { label: "LOTNO", value: "lotNo", category: "text", isFK: false },
  { label: "LOTDATE", value: "lotDate", category: "text", isFK: false },
  { label: "안전재고", value: "safetyStock", category: "text", isFK: false },
  { label: "현재고", value: "currentStock", category: "text", isFK: false },
  { label: "과부족량", value: "imbalance", category: "text", isFK: false },
  { label: "비고", value: "description", category: "text", isFK: false },
];

export const inventoryLifeHeader: ColumnHeaderProps[] = [
  { label: "item.name", value: "itemName", category: "text", isFK: false },
  { label: "item.code", value: "itemCode", category: "text", isFK: false },
  { label: "item.spec", value: "spec", category: "text", isFK: false },
  { label: "로케이션/공정명", value: "locationName", category: "text", isFK: false },
  { label: "LOTNO", value: "lotNo", category: "text", isFK: false },
  { label: "LOTDATE", value: "lotDate", category: "text", isFK: false },
  { label: "Serial 번호", value: "serialNumber", category: "text", isFK: false },
  { label: "현재고", value: "currentStock", category: "text", isFK: false },
  { label: "남은 유효일수", value: "deadLine", category: "text", isFK: false },
  { label: "비고", value: "description", category: "text", isFK: false },
];

export const peripheralHeader: ColumnHeaderProps[] = [
  { label: "제조일", value: "manufacturedAt", category: "text", isFK: false },
  { label: "장치 제조사", value: "manufactureCompany", category: "text", isFK: false },
  { label: "장치명", value: "modelName", category: "text", isFK: false },
  { label: "장치유형", value: "modelType", category: "text", isFK: false },
  { label: "제조번호", value: "manufactureId", category: "text", isFK: false },
  { label: "담당자", value: "userName", category: "text", isFK: false },
  { label: "공장명", value: "factoryName", category: "text", isFK: false },
  { label: "공장상세명", value: "factoryDetailName", category: "text", isFK: false },
];

export const machineHeader: ColumnHeaderProps[] = [
  { label: "기계 제조사", value: "machineCompany", category: "text", isFK: false },
  { label: "기계명", value: "machineName", category: "text", isFK: false },
  { label: "기계유형", value: "machineType", category: "text", isFK: false },
  { label: "제조일", value: "manufacturedAt", category: "text", isFK: false },
  { label: "제조번호", value: "manufactureId", category: "text", isFK: false },
  { label: "담당자", value: "userName", category: "text", isFK: false },
  { label: "오버홀", value: "isOverHall", category: "text", isFK: false },
  { label: "공장명", value: "factoryName", category: "text", isFK: false },
  { label: "공장상세명", value: "factoryDetailName", category: "text", isFK: false },
];

export const gradesHeader: ColumnHeaderProps[] = [
  { label: "등급코드", value: "code", category: "text", isFK: false },
  { label: "등급명", value: "name", category: "text", isFK: false },
];

export const partnersHeader: ColumnHeaderProps[] = [
  { label: "partner.code", value: "code", category: "text", isFK: false },
  { label: "partner.name", value: "name", category: "text", isFK: false },
  { label: "partner.contact", value: "contact", category: "text", isFK: false },
];

export const invoiceStatementHeader: ColumnHeaderProps[] = [
  { label: "invoice.code", value: "code", category: "text", isFK: false },
  { label: "location.code", value: "locationCode", category: "text", isFK: false },
  { label: "user.code", value: "creatorUserCode", category: "text", isFK: false },
];

export const itemsBomHeader: ColumnHeaderProps[] = [
  { label: "item.name", value: "name", category: "text", isFK: false },
  { label: "item.code", value: "code", category: "text", isFK: false },
  ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
    ? [{ label: "item.gradeName", value: "gradeName", category: "text", isFK: false }]
    : []),
  { label: "item.type", value: "itemType", category: "text", isFK: false, isEnum: true },
  // { label: "품목분류", value: "itemCategoryName", category: "text", isFK: true },
  // { label: "품목군", value: "abstractItemCode", category: "text", isFK: true },
];

export const lotsHeader: ColumnHeaderProps[] = [
  { label: "lot.name", value: "name", category: "text", isFK: false },
  { label: "item.code", value: "itemCode", category: "text", isFK: false },
  { label: "info.createdAt", value: "createdAt", category: "date", isFK: false },
  { label: "info.updatedAt", value: "updatedAt", category: "date", isFK: false },
];

export const transportationsHeader: ColumnHeaderProps[] = [
  { label: "partner.partner", value: "partnerCode", category: "text", isFK: false },
  { label: "transportation.transportation", value: "id", category: "number", isFK: false },
];
