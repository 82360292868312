import { LedgerDetailInForm } from "@/features/inventory/components/Form/detail/DetailInForm";
import { InputTabs } from "@/features/inventory/components/tabs/inputTabs";
import { LedgerTabs } from "@/features/inventory/components/tabs/ledgerTabs";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs, Text, Title } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
});

interface DetailLedgerProps {
  data: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner | undefined;
}

export const LedgerDetailForm = (params: DetailLedgerProps) => {
  const { data } = params;

  return (
    <>
      <Detail.Container>
        <Detail.Wrapper>
          <Detail.Content>
            <LedgerDetailInForm data={data} />
            <Tabs defaultValue="ledger">
              <LeftSpace />
              <Tabs.List>
                <Tabs.Tab value="ledger">재고 입출고 기록</Tabs.Tab>
                <Tabs.Tab value="input">투입 기록</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="ledger" p={20}>
                <Title order={3}>재고 입출고 기록</Title>
                <Text>우리회사에서 다루는 재고 입출고 기록에 대한 정보를 보여줍니다.</Text>
                <LedgerTabs data={data} />
              </Tabs.Panel>
              <Tabs.Panel value="input" p={20}>
                <Title order={3}>투입 기록</Title>
                <Text>우리회사에서 다루는 투입 기록에 대한 정보를 보여줍니다.</Text>
                <InputTabs data={data} />
              </Tabs.Panel>
            </Tabs>
          </Detail.Content>
        </Detail.Wrapper>
      </Detail.Container>
    </>
  );
};

const LeftSpace = styled.div`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
